import Vue from 'vue'
import Vuex from 'vuex'

import { adaptiveObjectStore } from './modules/adaptiveobject.store'
import { adaptiveTestStore } from './modules/adaptivetest.store'
import { adminStore } from './modules/admin.store'
import { administrationStore } from './modules/administration.store'
import { aiStore } from './modules/ai.store'
import { assignmentStore } from './modules/assignment.store'
import { auxStore } from './modules/misc.store'
import { chatStore } from './modules/chat.store'
import { conferenceStore } from './modules/conference.store'
import { courseStore } from './modules/course.store'
import { externalLinkStore } from './modules/externallink.store'
import { flashcardsStore } from './modules/flashcards.store'
import { filesStore } from './modules/files.store'
import { generalStore } from './modules/general.store'
import { groupStore } from './modules/group.store'
import { geogebraStore } from './modules/geogebra.store'
import { libraryStore } from './modules/library.store'
import { materialStore } from './modules/material.store'
import { mediaStore } from './modules/media.store'
import { messagesStore } from './modules/messages.store'
import { newsStore } from './modules/news.store'
import { notificationStore } from './modules/notifications.store'
import { questionnaireStore } from './modules/questionnaire.store'
import { quizStore } from './modules/quiz.store'
import { profileStore } from './modules/profile.store'
import { rawtextStore } from './modules/rawtext.store'
import { statisticsStore } from './modules/statistics.store'
import { timelineStore } from './modules/timeline.store'
import { userStore } from './modules/user.store'
import { counsellorStore } from './modules/counsellor.store'
import { videoChatStore } from './modules/video-chat.store'
import { boardStore } from './modules/board.store'
import { consultationStore } from './modules/consultation.store'
import { webinarStore } from './modules/webinar.store'
import { subscriptionsStore } from './modules/subscriptions.store'
import { talkStore } from './modules/talk.store'

Vue.use(Vuex)

const debug = (process.env.NODE_ENV !== 'production')

export default new Vuex.Store({
    modules: {
        adaptiveObjectStore,
        adaptiveTestStore,
        adminStore,
        administrationStore,
        aiStore,
        assignmentStore,
        auxStore,
        chatStore,
        conferenceStore,
        courseStore,
        externalLinkStore,
        flashcardsStore,
        filesStore,
        generalStore,
        groupStore,
        geogebraStore,
        libraryStore,
        materialStore,
        mediaStore,
        messagesStore,
        newsStore,
        notificationStore,
        questionnaireStore,
        quizStore,
        profileStore,
        rawtextStore,
        statisticsStore,
        timelineStore,
        userStore,
        counsellorStore,
        videoChatStore,
        boardStore,
        consultationStore,
        webinarStore,
        subscriptionsStore,
        talkStore
    },
    strict: debug
})
