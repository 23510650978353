/*
  LANGUAGE FILE FOR SWEDISH LANG
 */

  export default {
      "€0.60/month for each additional learner over the prepaid amount":
          "0,60 €/månad för varje ytterligare elev utöver det förbetalda beloppet",
      "€1.20/month for each additional learner over the prepaid amount":
          "1,20 €/månad för varje ytterligare elev utöver det förbetalda beloppet",
      "2GB storage limit": "2GB lagring",
      "3-digit code placed on the back side of the card, in the signature area":
          "Tresiffrig kod på baksidan av kortet, vid signaturen.",
      Abandon: "Avbryt",
      "Abandon copying to this group": "Avbryt kopieringen till denna kurs",
      "Account already linked": "Kontot finns redan i systemet",
      "Accept terms": "Godta villkoren",
      "Account linked": "Kontot kopplat",
      "Access denied!": "Åtkomst nekad!",
      "Account succesfully linked with": "Kontot sammankopplat med",
      "Account type": "Kontotyp",
      Actions: "Möjligheter",
      Active: "Aktiv",
      "Active learners": "Aktiva elever",
      "Active learners above the plan in this month":
          "Aktiva elever denna månad utöver de som ingår i planen",
      "Active learners in this month": "Aktiva elever denna månad",
      "Active learners on plan": "Aktiva elever på plan",
      Activity: "Aktivitet",
      Activities: "Aktiviteter",
      "Activities, quizzes & assignments": "Aktiviteter, quiz och uppgifter",
      "Adaptive settings": "Adaptiva inställningar",
      "Adaptive test": "Adaptivt test",
      "Adaptive tests": "Adaptiva test",
      Add: "Lägg till",
      "Additional group": "Ytterligare kurs",
      "Add a document": "Lägg till dokument",
      "Add a file": "Lägg till fil",
      "Add a new learner or co-teacher":
          "Bjud in en ny studerande eller lärarkollega",
      "Add activities": "Lägg till resurser",
      "Add additional group": "Lägg till ytterligare kurs",
      "Add answer": "Lägg till svar",
      "Add assignment": "Lägg till uppgift",
      "Add attachment": "Lägg till fil",
      "Add audio": "Lägg till ljud",
      "Add board": "Lägg till anslagstavla",
      "Add board item": "Lägg till anslag",
      "Add category": "Lägg till kategori",
      "Add collection": "Lägg till samling",
      "Add conversation name": "Ge samtalet ett namn",
      "Add course": "Lägg till modul",
      "Add dashboard widget": "Lägg till dashboard widget",
      "Add description": "Lägg till beskrivning",
      "Add discussion topic": "Lägg till diskussionsämne",
      "Add event": "Lägg till evenemang",
      "Add existing material": "Lägg till befintlig lektion",
      "Add expression": "Lägg till uttryck",
      "Add external link": "Lägg till extern länk",
      "Add extra item": "Lägg till en extra artikel",
      "Add feedback": "Lägg till återkoppling",
      "Add file": "Lägg till fil",
      "Add files/folders": "Lägg till filer/mappar",
      "Add first learning element to this course":
          "Lägg till din första lärresurs till denna kurs",
      "Add flashcard": "Lägg till flashkort",
      "Add folder": "Lägg till mapp",
      "Add geogebra": "Lägg till geogebra",
      "Add image": "Lägg till bild",
      "Add information": "Lägg till information",
      "Add item": "Lägg till objekt",
      "Add label": "Lägg till etikett",
      "Add labels to the image": "Lägg till etiketter till bilden",
      "Add learning activities": "Lägg till lärresurser",
      "Add learning activity": "Lägg till lärresurs",
      "Add lesson": "Lägg till lektion",
      "Add lessons to your course": "Lägg till lektioner till din modul",
      "Add image label": "Lägg till bildetikett",
      "Add match": "Lägg till match",
      "Add match set": "Lägg till match set",
      "Add material": "Lägg till lektion",
      "Add member": "Lägg till medlem",
      "Add members": "Lägg till medlemmar",
      "Add members manually to the class below":
          "Lägg manuellt till medlemmar till denna kurs nedan",
      "Add members to this class": "Lägg till medlemmar till denna kurs",
      "Add teachers to your plan": "Lägg till lärare i din plan",
      "Add participants": "Lägg till medlemmar",
      "Add new course": "Lägg till ny modul",
      "Add new dependency": "Lägg till ny förutsättning",
      "Add new flashcard": "Lägg till nytt flashkort",
      "Add new institution": "Lägg till ny institution",
      "Add new item": "Lägg till ny resurs",
      "Add new learning element": "Lägg till ny lärresurs",
      "Add new match option": "Lägg till nytt matchval",
      "Add new material": "Lägg till ny lektion",
      "Add new question": "Lägg till ny fråga",
      "Add new tag": "Lägg till nytt ämne",
      "Add news": "Lägg till nyhet",
      "Add numeric variable": "Lägg till en numerisk variabel",
      "Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.":
          "Lägg endast till ämnen som inte redan finns i systemet. Säkerställ att du sökt efter etikettn innan du lägger till den.",
      "Add question": "Lägg till fråga",
      "Add questions": "Lägg till fråga",
      "Add question from questionbank": "Lägg till fråga från frågebanken",
      "Add questionnaire": "Lägg till frågeformulär",
      "Add quiz": "Lägg till quiz",
      "Add one learning element at a time. Save changes in order to continue creating.":
          "Lägg till en lärresurs åt gången. Spara ändringarna för att fortsätta skapa.",
      "Add text": "Lägg till text",
      "Add text label": "Lägg till textetikett",
      "Add timeline": "Lägg till tidslinje",
      "Add to existing activity": "Lägg till i befintlig resurs",
      "Add to word list": "Lägg till i ren ordlista",
      "Add video": "Lägg till video",
      "Add Vimeo video": "Lägg till Vimeo-video",
      "Add youtube video": "Lägg till Youtube-video",
      "Add your first document that will be shared with your class":
          "Lägg till din första fil som ska delas med kursen",
      Added: "Tillagd",
      "Add groups": "Lägg till kurs",
      "Add new answer possibility": "Lägg till nytt svarsalternativ",
      "Add new element": "Lägg till ny resurs",
      "Add new expression": "Lägg till nytt uttryck",
      "Add new nummeric variable": "Lägg till ny nummerisk variabel",
      "Add submission example": "Lägg till exempel på inlämning",
      "Add title": "Lägg till titel",
      "Add vacation/holiday": "Lägg till lov/helgdag",
      "Add word": "Lägg till ord",
      "Add words to the word list. Any number of words from the list can be chosen at random.":
          "Lägg till ord i ordlistan. Varje ord i ordlistan kan väljas ut slumpvis.",
      "Adjust your settings and start a call":
          "Anpassa dina inställningar och starta samtalet",
      Admin: "Lärare",
      Admins: "Lärare",
      Administration: "Administration",
      "Advanced integrations": "Avancerade integrationer",
      "Advanced settings": "Avancerade inställningar",
      Afrikaans: "Afrikaans",
      Albanian: "Albanska",
      All: "Alla",
      "All changes were saved": "Alla ändringar har sparats",
      "All classes, courses and content will be transferred, and nothing will be lost.":
          "Alla kurser, moduler och innehåll kommer att föras över. Ingenting kommer att gå förlorat.",
      "All correct answers must be chosen": "Alla korrekta svar måste väljas",
      "All correct answers must be selected":
          "Alla korrekta svar måste väljas",
      "All communication suspended.": "All kommnikation har stängts av.",
      "All file types": "Alla filtyper",
      "All topics": "Alla ämnen",
      "All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold":
          "Alla variabler måste inledas med en hashtag och det lägre värdet måste vara lägre än det högre värdet.",
      "Allow counsellors": "Tillåt vägledare",
      "Allow direct user creation in the group":
          "Tillåt att användare skapas direkt i kursen",
      "Allow groups": "Tillåt gruppinlämning",
      "Allow code join": "Tillåt deltagande via kod",
      "Allow late submission": "Tillåt inlämning efter deadline",
      "Allow late turnin": "Tillåt inlämning efter deadline",
      "Allow questions on material elements": "Tillåt frågor om lärresurser",
      "Allow groups code join": "Tillåt deltagande i kursen via kod",
      "All participants from your class can access this call. If you want to invite others, send them a link.":
          "Alla medlemmar i din kurs har tillgång till detta samtal. Om du vill bjuda in andra, skickar du dem länken till samtalet.",
      "Allow peers": "Tillåt visning av andras inlämningar",
      "Allow students to see results": "Tillåt att eleverna ser resultaten",
      "Allowed file types": "Tillåtna filtyper",
      "Already a user?": "Är du redan en användare?",
      "Already have an account?": "Har du redan ett konto?",
      Amount: "Belopp",
      "An error occurred": "Det uppstod ett fel",
      "An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.":
          "Det uppstod ett fel när quizzen skulle hämtas. Vänligen ladda om webbläsaren. Om problemet kvarstår, kontakta supporten.",
      "Ancient history": "Forntidshistoria",
      Answer: "Svar",
      "Answer's feedback": "Svarets återkoppling",
      "Answer date": "Svar datum",
      "Answer {number} more": "Svar på {number} fler",
      "Answer evaluation criteria": "Utvärderingskriterier för svaret",
      "Answer possibilities": "Svarsalternativ",
      "Answer possibility": "Svarsalternativ",
      "Answer the question to continue the video":
          "Svar på frågan för att kunna fortsätta se videoklippet",
      Answers: "Svar",
      "Answers must have a unique match option assigned":
          "Alla svarsalternativ måste kopplas till en unik matchning",
      "Answers not in category": "Svar utanför kategorin",
      "Answers will be shown after quiz is completed":
          "Svaren visas när frågesporten är avslutad.",
      "Appears on": "Framträder på",
      Apps: "Appar",
      Arabic: "Arabiska",
      Archive: "Arkivera",
      "Archive class": "Arkivera kursen",
      "Archive class for all members": "Arkivera kursen för alla medlemmar",
      Archived: "Arkiverat",
      "Archive for all": "Arkivera för alla",
      "Archive for you": "Arkivera för alla",
      "Archived groups": "Arkiverade kurser",
      "Are you a student or a teacher?": "Är du lärare eller studerande?",
      "Are you sure?": "Är du säker?",
      "Are you sure you have checked the list thoroughly? Remember you can search it too!":
          "Är du säker på att du har kollat igenom listan ordentligt? Kom ihåg att du också kan söka i den!",
      "Are you sure you want to archive this conversation?":
          "Är du säker på att du vill arkivera detta samtal?",
      "Are you sure you want to archive this group?":
          "Är du säker på att du vill arkivera denna kurs?",
      "Are you sure you want to archive this group for all members?":
          "Är du säker på att du vill arkivera denna kurs för alla medlemmar?",
      "Are you sure you want to delete this?":
          "Är du säker på att du vill radera detta?",
      "Are you sure you want to delete this assignment?":
          "Är du säker på att du vill radera denna uppgift?",
      "Are you sure you want to delete this collection?":
          "Är du säker på att du vill radera denna samling?",
      "Are you sure you want to delete this feedback?":
          "Är du säker på att du vill radera denna återkoppling?",
      "Are you sure you want to delete this file?":
          "Är du säker på att du vill radera denna fil?",
      "Are you sure you want to delete this folder?":
          "Är du säker på att du vill radera denna mapp? Detta tar också bort alla undermappar och filer.",
      "Are you sure you want to delete this Geogebra element?":
          "Är du säker på att du vill radera denna Geogebra-resurs?",
      "Are you sure you want to delete this group?":
          "Är du säker på att du vill radera denna kurs?",
      "Are you sure you want to delete this group information?":
          "Är du säker på att du vill ta bort denna information?",
      "Are you sure you want to delete this post?":
          "Är du säker på att du vill radera detta anslag?",
      "Are you sure you want to delete this question?":
          "Är du säker på att du vill radera denna fråga?",
      "Are you sure you want to delete this quiz?":
          "Är du säker på att du vill radera denna quiz?",
      "Are you sure you want to delete this user?":
          "Är du säker på att du vill radera denna användare?",
      "Are you sure you want to delete this widget?":
          "Är du säker på att du vill radera denna widget?",
      "Are you sure you want to discard this learning activity?":
          "Är du säker på att du vill förkasta den här lärresursen?",
      "Are you sure you want to leave the meeting?":
          "Är du säker på att du vill lämna mötet?",
      "Are you sure you want to mark student as a self-study? This will move all assignments years into the future.":
          "Är du säker på att du vill markera denna elev som självstuderande? Detta kommer att senarelägga alla inlämningar med flera år.",
      "Are you sure you want to open learner view without saving the changes?":
          "Är du säker på att du vill öppna studentyn utan att spara dina ändringar?",
      "Are you sure you want to recalculate assignment plan?":
          "Är du säker på att du vill räkna om uppgiftsplanen?",
      "Are you sure you want to remove from your plan?":
          "Är du säker på att du vill ta bort {insertion} från ditt abonnemang?" /* Translate with param */,
      "Are you sure you want to remove this answer?":
          "Är du säker på att du vill ta bort detta svar?",
      "Are you sure you want to remove this course?":
          "Är du säker på att du vill ta bort denna modul?",
      "Are you sure you want to remove this label?":
          "Är du säker på att du vill ta bort denna etikett?",
      "Are you sure you want to remove this learning element?":
          "Är du säker på att du vill ta bort denna lärresurs?",
      "Are you sure you want to remove this lesson?":
          "Är du säker på att du vill ta bort denna lektion?",
      "Are you sure you want to remove this member?":
          "Är du säker på att du vill ta bort denna medlem?",
      "Are you sure you want to remove this match set?":
          "Är du säker på att du vill ta bort detta matchningsset?",
      "Are you sure you want to remove this material?":
          "Är du säker på att du vill ta bort denna lektion?",
      "Are you sure you want to remove this question?":
          "Är du säker på att du vill ta bort denna fråga?",
      "Are you sure you want to remove this subject?":
          "Är du säker på att du vill ta bort detta ämne?",
      "Are you sure you want to remove this topic?":
          "Är du säker på att du vill ta bort detta underämne?",
      "Are you sure you want to remove this variable?":
          "Är du säker på att du vill ta bort denna variabel?",
      "Are you sure you want to remove this user?":
          "Är du säker på att du vill ta bort denna användare?",
      "Are you sure you want to remove this widget?":
          "Är du säker på att du vill ta bort denna widget?",
      "Are you sure you want to remove this word?":
          "Är du säker på att du vill ta bort detta ord?",
      "Are you sure you want to start a video call?":
          "Är du säker på att du vill starta ett videosamtal?",
      "Are you sure you want to start a video call with this user?":
          "Är du säker på att du vill starta ett videosamtal med denna användare?",
      "Are you sure you want to submit this questionnaire?":
          "Är du säker på att du vill lämna in detta frågeformulär?",
      "Are you sure you want to turn in this quiz?":
          "Är du säker på att du vill lämna in denna quiz?",
      "Are you sure you want to unarchive this group?":
          "Är du säker på att du vill ta bort denna kurs från arkivet?",
      "Are you sure you want to unlink your account from":
          "Är du säker på att du vill frånkoppla ditt konto från",
      "Are you sure you want to unsubscribe?":
          "Är du säker på att du vill avsluta ditt abonnemang?",
      "Are you unsure? Click the button below to read a guide on this":
          "Är du osäker? Klicka på knappen nedan för att läsa en guide om ämnet.",
      Armenian: "Armeniska",
      Arrows: "Pilar",
      "As a teacher, you are automatically always in training mode, and we will not store your answers, but you can test the quiz.":
          "Som lärare är du alltid i träningsläge på quizzen. Det betyder att vi inte sparar dina svar, utan du kan testa quizzen.",
      "Ask to join": "Be om åtkomst",
      "Ask your students to go to": "Be dina elever gå till",
      Assign: "Anvisa",
      "Assign category": "Anvisa kategori",
      "Assign learners to counsellors": "Anvisa elever till vägledare",
      "Assign student": "Anvisa elever",
      "Assign student to": "Anvisa elever till",
      "Assign task to Olly": "Ge Olly detta uppdrag",
      Assignment: "Inlämning",
      assignment_objects: "Inlämning",
      assignments: "Inlämningar",
      "Assignments behind": "Inlämningar bakåt",
      "Assignments completion percentage": "Andel färdiga inlämningar",
      "Assignment answer": "Svar på inlämning",
      "Assignment created": "Inlämning skapad",
      "Assignment handin timeline": "Tidslinje för inlämning av uppgifter",
      "Assignment handins/feedback statistics":
          "Statistik över inlämningar/återkoppling",
      "Assignment plans": "Inlämningsplaner",
      "Assignment rejected": "Inlämning avvisad",
      "Assignment removed": "Inlämning borttagen",
      "Assignment saved": "Inlämning sparad",
      "Assignment statistics": "Statistik över inlämningar",
      "Assignment text": "Inlämningstext",
      "Assignment title": "Inlämningstitel",
      "Assignment turnins missing feedback": "Inlämningar utan återkoppling",
      "Assignment turnin overview": "Överblick över inlämnade uppgifter",
      "At least one correct answer chosen": "Minst ett korrekt svar valt",
      "Attach media": "Bifoga fil",
      "Attach to feedback": "Bifoga till återkoppling",
      Attachments: "Bifogade filer",
      Attachment: "Bidogad fil",
      Attempt: "Försök",
      "Average on teams": "Kursgenomsnitt",
      "Average quiz answer percentage for group":
          "Genomsnittlig svarsprocent på quizzen i kursen",
      "Average user completion": "Genomsnittlig genomförandegrad",
      Audio: "Ljud",
      "Audio is on": "Ljudet på",
      Australian: "Australisk",
      "Awaiting feedback": "Inväntar återkoppling",
      Back: "Tillbaka",
      "Back to courses": "Tillbaka till modul",
      "Back to course overview": "Tillbaka till modulöverblick",
      "Back to dashboard": "Tillbaka till dashboard",
      "Back to debate list": "Tillbaka till diskussionsöversikten",
      "Back to groups": "Tillbaka till kurser",
      "Back to information list": "Tillbaka till informationsöversikten",
      "Back to lessons overview": "Tillbaka till översikten över lektioner",
      "Back to overview": "Tillbaka till översikt",
      "Back to the dashboard": "Tillbaka till dashboard",
      "Back to quizzes": "Tillbaka till quiz",
      Bank: "Bank",
      Basic: "Grundläggande",
      "Basic & advanced integrations":
          "Grundläggande och avancerade integrationer",
      "Basic integrations": "Basis integrationer",
      "Below are download links to the most popular browsers, in case you do not have them installed":
          "Nedan återfinns länkar till de mest populära webbläsarna om du inte redan skulle han installerat dem.",
      Billing: "Fakturering",
      "Billing history": "Faktureringshistorik",
      Biology: "Biologi",
      Blue: null,
      Board: "Anslagstavla",
      Bosnian: "Bosniska",
      "Brazilian Portuguese": "Portugisiska (Brasilien)",
      "Build structured blocks of text to inform your students on exams procedures, tell them about yourself, or add a course description.":
          "Skriv strukturerade textstycken för att informera dina användare om examineringsförfaranden, berätta om dig själv eller lägg till en beskrivning av innehållet.",
      "Business economics": "Företagsekonomi",
      "By closing this modal you discard all learning activities prepared by Olly":
          "Genom att stänga denna modal tar du bort alla lärresurser som Olly has skapat",
      "By creating an account I accept Terms and Conditions and Edaptio's default Notification settings":
          "Genom att skapa ett konto godtar jag {termsLink} och Edaptios generella {notifLink}" /* Translate with params like in en_Us */,
      "By deleting the last element you discard the whole learning activity":
          "Genom att ta bort sista {name} tar du också bort hela lärresursen" /* Translate with param */,
      "By signing up you accept our":
          "Genom att skapa ett konto godtar du våra",
      "By unsubscribing you will continue to have plan access and be charged monthly for active learners above count until date. After this period you will only be able to keep 2GB of storage and 15 active learners.":
          "Genom att avregistrera dig kommer du att fortsätta att ha tillgång till planen och debiteras månadsvis för aktiva elever som är över antalet fram till och med datumet. Efter denna period kommer du endast att kunna behålla 2 GB lagringsutrymme och 15 aktiva elever." /* Translate with params like in en_Us */,
      "By unsubscribing you will be downgraded to Basic Plan at the end of this billing cycle. After this period you will only be able to keep 2GB of storage and 15 active learners.":
          "Om du avregistrerar dig kommer du att nedgraderas till Basic Plan i slutet av denna faktureringscykel. Efter denna period kommer du endast att kunna behålla 2 GB lagringsutrymme och 15 aktiva elever.",
      Ca: "C:a",
      Calculate: "Beräkna",
      "Calculate this expression": "Beräkna detta uttryck",
      Camera: "Kamera",
      "Camera off": "Kamera av",
      "Camera on": "Kamera på",
      Cancel: "Avbryt",
      "Cancel call": "Avbryt samtal",
      "Card details": "Kortinformation",
      "Card number": "Kortnummer",
      "Cardholder name": "Namn på kortet",
      "Case sensitive": "Skiftlägeskänslig",
      Catalan: "Katalanska",
      Categories: "Kategorier",
      Category: "Kategori",
      "Category items": "Kategoriobjekter",
      "changed the feedback for the assignment":
          "ändrade sin återkoppling för uppgiften",
      Changelog: "Changelog",
      "Change card": "Byt kort",
      "Change group": "Byt kurs",
      "Change image": "Byt bild",
      "Change institution": "Byt institution",
      "Change moodle relation": "Byt moodle-relation",
      "Change password": "Byt lösenord",
      "Change payment method": "Byt betalningsmetod",
      "Change plan": "Byt abonnemang",
      "Change plan selection": "Byt abonnemangsurval",
      "Change UMS relation": "Byt UMS-relation",
      characters: "tecken",
      chat: "Chat",
      Chat: "Chat",
      Checkbox: "Kryssruta",
      "Check schedule of a specific class or class member":
          "Se schema för en specifik kurs eller kursmedlem",
      "Check your inbox!": "Kolla din inkorg!",
      Chemistry: "Kemi",
      "Change submission date for user":
          "Ge användaren ett nytt inlämningsdatum för denna uppgift",
      Chinese: "Kinesiska",
      "Connect Facebook": "Koppla Facebook",
      "Connect Microsoft": "Koppla Microsoft",
      "Connect Google": "Koppla Google",
      "Connect Unilogin": "Koppla Unilogin",
      "Connect your account": "Koppla ditt konto",
      Choose: "Välj",
      "Choose another element": "Välj en annan resurs",
      "Choose another group": "Välj en annan kurs",
      "Choose assignment": "Välj uppgift",
      "Choose assignment from group": "Välj uppgift från kurs",
      "Choose between seeing quizzes the user has passed or completed.":
          "Välj mellan att se quiz som en användare har klarat eller fullgjort.",
      "Choose class": "Välj kurs",
      "Choose collection": "Välj samling",
      "Choose collection from group": "Välj samling från kurs",
      "Choose column representation": "Välj representation i kolumner",
      "Choose correct match option":
          "Välj det korrekta matchningsalternativet",
      "Choose course": "Välj modul",
      "Choose CSV file": "Välj CSV-fil",
      "Choose date": "Välj datum",
      "Choose element": "Välj resurs",
      "Choose element type": "Välj resurstyp",
      "Choose from filesharing": "Välj från fildelning",
      "Choose files": "Välj filer",
      "Choose from existing materials": "Välj från befintliga lektioner",
      "Choose gender": "Välj kön",
      "Choose Geogebra": "Välj Geogebra",
      "Choose Geogebra from group": "Välj Geogebra från kurs",
      "Choose grade": "Välj betyg",
      "Choose group": "Välj kurs",
      "Choose institution": "Välj institution",
      "Choose material": "Välj lektion",
      "Choose members": "Välj medlemmar",
      "Choose language": "Välj språk",
      "Choose learning element": "Välj lärresurs",
      "Choose lesson": "Välj lektion",
      "Choose new file": "Välj ny fil",
      "Choose questionnaire": "Välj frågeformulär",
      "Choose questionnaire from group": "Välj frågeformulär från kurs",
      "Choose quiz": "Välj quiz",
      "Choose quiz from group": "Välj quiz från kurs",
      "Choose random words": "Välj slumpvisa ord",
      "Choose subgroup": "Välj undergrupp",
      "Choose time": "Välj tid",
      "Choose the default language of the group":
          "Välj det standardspråk som denna kurs undervisas på (används för uppläsning)",
      "Choose the strategy with which Olly should create activity":
          "Välj strategi som Olly ska bygga {name} med" /* Translate with param */,
      "Choose type": "Välj typ",
      "Choose user": "Välj användare",
      "Choose weekday": "Välj veckodag",
      "Choose what this group is teaching":
          "Välj vad denna kurs undervisar i",
      Chosen: "Valt",
      "Chosen file": "Vald fil",
      "Chosen group": "Vald kurs",
      "Chosen monthly plan summary":
          "Du uppgraderar till {name} Plan. Planen förnyas automatiskt varje månad tills den avbryts." /* Translate with params */,
      "Chosen user": "Vald användare",
      "Chosen yearly plan summary":
          "Du håller på att uppgradera till 1 års {name}-tillgång och sparar 25 % genom att välja engångsbetalning. Abonnemanget förnyas automatiskt varje år tills det sägs upp." /* Translate with params */,
      class: "kurs",
      Class: "Kurs",
      "Class admins": "Lärare",
      "Class code": "Kurskod",
      "class dashboard": "Kurssida",
      "Class tracking & insights": "Kursstatistik och -insikter",
      Classes: "Kurser",
      Classic: "Klassisk",
      "Class members": "Medlemmar",
      "Class notebook link": "Länk till kursanteckningsbok",
      "Class not found": "Kunde inte hitta kursen",
      "Class subject": "Kursämne",
      Clear: "Radera",
      "Click enter to submit": "Klicka enter för att lägga till",
      "Click here to add a new learning element or edit the selected element":
          "Klicka här för att lägga till nya lärresurser eller redigera den valda resursen",
      "Click here to add existing material, create a new material or change the current material order":
          "Klicka här för att lägga till befintliga lektioner, skapa en ny lektion eller ändra ordningsföljden på befintliga lektioner",
      "Click here to create a new assignment":
          "Klicka här för att skapa en ny uppgift",
      "Click here to create a new conference post":
          "Klicka här för att skapa en ny tråd eller en ny fråga",
      "Click here to create a new conversation with a student or a group":
          "Klicka här för att skapa ett nytt samtal med en användare eller en kurs",
      "Click here to create a new course or change the current course order":
          "Klicka här för att skapa en ny modul, eller ändra ordningsföljden på befintliga moduler",
      "Click here to create a new Geogebra element":
          "Klicka här för att skapa en ny Geogebra-resurs",
      "Click here to create a new material":
          "Klicka här för att skapa en ny lektion",
      "Click here to create a new news post":
          "Klicka här för att skapa en ny nyhet i kursen",
      "Click here to create a new quiz":
          "Klicka här för att skapa en ny quiz",
      "Click here to create a new question":
          "Klicka här för att skapa en ny fråga",
      "Click here to edit the group information text":
          "Klicka här för att redigera kursinformationstexten",
      "Click here to go to your class notebook":
          "Klicka här för att gå till din kursanteckningsbok",
      "Click left/right arrows to go to next/previous card":
          "Klicka på pilarna för att gå till förra kortet eller nästa kort",
      "Click on the link to activate your account. Don’t see it? Check your spam folder too :)": null,
      "Click on the answer to the left, and then a match option to the right, in order to make a connection.":
          "Klicka på svaret till vänster, och därefter på matchningsalternativet till höger för att skapa en koppling.",
      "Click on the card to see the back":
          "Klicka på kortet för att se baksidan",
      "Click on the card to see the front":
          "Klicka på kortet för att se framsidan",
      "Click the add learning element button in the menu on the left":
          "Klicka på Lägg till lärresurs i menyn till vänster",
      "Click the create button to get started":
          "Klicka på skapa-knappen för att komma igång",
      "Click the big button in the lower right corner to get started":
          "Klicka på den stora knappen i nedre högra hörnet för att komma igång",
      "Click the button above to begin":
          "Klicka på knappen ovanför för att sätta igång",
      "Click the button to add the first learning element":
          "Klicka på knappen för att lägga till den första lärresursen",
      "Click the create button on the left side to get started":
          "Klicka på skapa-knappen till vänster för att komma igång",
      "Click this button and click one of the sub-buttons to create a new folder or upload a new file":
          "Klicka på den här knappen och sedan på en av de mindre knapparna för att skapa en ny mapp eller lägga till en ny fil.",
      "Click this button to open the menu with your groups on all pages":
          "Klicka på knappen för att öppna menyn med alla dina kurser på alla sidor",
      "Click to start edit": "Klicka för att redigera",
      "Click up/down arrows to flip card":
          "Klicka på upp- och nedpilarna för att vända på kortet",
      Close: "Stäng",
      cloze: "Fyll i luckorna (Cloze)",
      code: "kod",
      Collapse: "Vik ihop",
      Color: "Färg",
      "Color can't be empty.": "Färg kan inte lämnas tomt.",
      Comment: "Kommentar",
      "Comment created": "Kommentar skapad",
      "Comment on news": "Kommentera nyhet",
      "Comment removed": "Kommentar borttagen",
      Comments: "Kommentarer",
      Company: "Företag",
      "Compare plans": "Jämför abonnemang",
      Completed: "Genomförd",
      "Completed consultations": "Genomförda konsultationer",
      Confirm: "Bekräfta",
      "Contact support": "Kontakta supporten",
      "Contact support@edaptio.com for more info":
          "Kontakta support@edaptio.com för mer information",
      "Contact us": "Kontakta oss",
      Continue: "Fortsätt",
      "Continue where you left off": "Fortsätt där du slutade",
      conference: "Forum",
      "Conference thread": "Forumtråd",
      consultations: "Konsultationer",
      Consultations: "Konsultationer",
      Conversations: "Samtal",
      "Conversation creation": "Skapa samtal",
      "Conversation members": "Deltagare i samtalet",
      "Conversation name": "Namn på samtalet",
      "Collapse all": "Vik ihop alla",
      Collection: "samling",
      Collections: "samlingar",
      "Complete registration": "Genomför registrering",
      Copied: "Kopierat",
      "Copied to clipboard": "Kopierat till urklipp",
      Copy: "Kopiera",
      "Copy/paste your text source here. Olly will use this as context for generating content":
          "Kopiera/klistra in din textkälla här. Olly kommer att använda detta som sammanhang för att generera innehåll",
      "Copy activity": "Kopiera lärresurs",
      "Copy answer to the feedback field":
          "Kopiera svar till återkopplingsfält",
      "Copy class": "Kopiera kurs",
      "Copy course": "Kopiera modul",
      "Copy course and bypass sync": "Kopiera modul men synkronisera inte",
      "Copy course and sync": "Kopiera modul och synkronisera",
      "Copy element": "Kopiera resurs",
      "Copy element and bypass sync": "Kopiera resurs men synkronisera inte",
      "Copy element and sync": "Kopiera resurs och synkronisera",
      "Copy element to other group": "Kopiera resurs till en annan kurs",
      "Copy element to these groups?": "Kopiera resurs till dessa kurser?",
      "Copy element to this group?": "Kopiera resurs till denna kurs",
      "Copy file": "Kopiera fil",
      "Copy file url to clipboard": "Kopiera filens webbadress till urklipp",
      "Copy group": "Kopiera kurs",
      "Copy groups": "Kopiera kurser",
      "Copy here": "Kopiera här",
      "Copy lesson": "Kopiera lektion",
      "Copy lesson and bypass sync": "Kopiera lektion men synkronisera inte",
      "Copy lesson and sync": "Kopiera lektion och synkronisera",
      "Copy link": "Kopiera länk",
      "Copy new adaptive test": "Kopiera nytt adaptivt test",
      "Copy of": "Kopia av",
      "Copy submission": "Kopiera uppgift",
      "Copy to clipboard": "Kopiera till urklipp",
      "Copy to other groups": "Kopiera resurs till andra kurser",
      Corrected: "Rättad",
      Correct: "Korrekt",
      "Correct answer": "Korrekt svar",
      "Correct answer(s)": "Korrekt(a) svar",
      "Correct assignment": "Rätta uppgiften",
      "Correct using DirectEdit": "Rätta med DirectEdit",
      "Corresponding answer": "Tillhörande svar",
      course: "modul",
      Course: "Modul",
      "Courses are the top building block of content":
          "Moduler är den översta byggstenen i ditt lärande",
      "Course copied": "Modul kopierad",
      Counsellor: "Vägledare",
      Counsellors: "Vägledare",
      "Counsellors list": "Lista över vägledare",
      "Course created": "Modul skapad",
      "Course moved": "Modul flyttad",
      "Course name": "Modulnamn",
      "Course progression": "Modulframsteg",
      "Course removed": "Modul borttagen",
      "Course statistics": "Modulstatistik",
      "Course title": "Modultitel",
      courses: "moduler",
      Courses: "Moduler",
      "Courses are comparable to chapters in a book, and contain several lessons to provide at logical structure for the class content. Make sure to create a course for all of your major subjects of the class.":
          "Modulerna kan jämföras med kapitel i en bok och innehåller lektioner för att gruppera kunskapen som ska förmedlas. Ge modulens innehåll en logisk struktur! Se till att skapa moduler till alla stora ämnen i din kurs.",
      Create: "Skapa",
      "Create account using": "Skapa konto med",
      "Create another": "Skapa ytterligare",
      "Create a class": "Skapa kurs",
      "Create a course": "Skapa modul",
      "Create a lesson": "Skapa lektion",
      "Create a lesson by inserting its name. You can hide this lesson so students will not see it.":
          "Skapa en lektion genom att skriva in dess namn. Du kan dölja den här lektionen så att eleverna inte kan se den.",
      "Create a new discussion and get opinions from other members":
          "Skapa en ny diskussion och få åsikter från andra medlemmar",
      "Create an account": "Skapa konto",
      "Create assignment": "Skapa uppgift",
      "Create assignments in bulk": "Skapa inlämningsuppgifter i bulk",
      "Create and release feedback": "Skapa och skicka ut återkoppling",
      "Create and show": "Skapa och visa",
      "Create board item": "Lägg till anslag",
      "Create class": "Skapa kurs",
      "Create collection": "Skapa samling",
      "Create consultation": "Skapa konsultation",
      "Create course": "Skapa modul",
      "Create discussion": "Skapa diskussion",
      "Create feedback": "Skapa återkoppling",
      "Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the 'matches with' field to group together fields, that can be placed in the same places in the picture.":
          "Skapa fält och dra dem till rätt ställe på bilden. Du kan välja mellan bilder och text. Justera 'Matcher med'-fältet för att gruppera de fält som kan placeras på samma ställe på bilden.",
      "Create flashcards": "Skapa flashkort",
      "Create folder": "Skapa mapp",
      "Create image label": "Skapa bildetikett",
      "Create geogebra": "Skapa Geogebra-resurs",
      "Create Geogebra element": "Skapa Geogebra-resurs",
      "Create group": "Skapa kurs",
      "Create lesson": "Skapa lektion",
      "Create link": "Skapa länk",
      "Create media": "Skapa media",
      "Create new activity": "Skapa en ny resurs",
      "Create news post": "Skapa nyhet",
      "Create new conversation": "Skapa nytt samtal",
      "Create new course": "Skapa ny modul",
      "Create new discussion": "Skapa ny diskussion",
      "Create new group": "Skapa ny kurs",
      "Create new material": "Skapa ny lektion",
      "Create new question": "Skapa ny fråga",
      "Create now": "Skapa nu",
      "Create conference post": "Skapa forumtråd/-fråga",
      "Create question": "Skapa fråga",
      "Create questionnaire": "Skapa frågeformulär",
      "Create quiz": "Skapa quiz",
      "Create quiz question": "Skapa quizfråga",
      "Create question and show": "Skapa fråga och visa",
      "Create quiz and show": "Skapa quiz och visa",
      "Create permission group": "Skapa roll",
      "Create pinboard": "Skapa anslagstavla",
      "Create post": "Skapa anslag",
      "Create sub group": "Skapa undergrupp",
      "Create subject": "Skapa ämne",
      "Create submission": "Skapa uppgiftsinlämning",
      "Create text": "Skapa text",
      "Create text label": "Skapa textetikett",
      "Create timeline": "Skapa tidslinje",
      "Create topic": "Skapa underämne",
      "Create user": "Skapa användare",
      "Create widget": "Skapa widget",
      "Create your account": "Skapa ditt konto",
      "Create your first class": "Skapa din första kurs",
      "Create your first class and invite students":
          "Skapa din första kurs och bjud in elever",
      "Create your first message": "Skapa ditt första meddelande",
      Created: "Skapad",
      "Created learning activities": "Skapade lärresurser",
      "created the assignment": "skapade uppgiften",
      Croatian: "Kroatiska",
      "Currently unavailable": "Ej tillgänglig för närvarande",
      "Custom DPA": "Anpassad DPA",
      "Custom pricing": "Anpassat pris",
      "Custom integrations": "Anpassade integrationer",
      CVC: "CVC",
      Czech: "Tjeckiska",
      Danish: "Danska",
      Dansk: "Danska",
      "Dark blue": null,
      dashboard: "överblick",
      Dashboard: "Överblick",
      "dashboard-new-teacher-greeting":
          "Du har inte några aktiva kurser på Edaptio ännu. <br/> Klicka nedan för att skapa din första kurs.",
      "Data display": "Datavisning",
      Date: "Datum",
      "Date saved": "Datum sparat",
      "Dedicated support": "Dedikerad support",
      "Default language": "Standardspråk",
      Definition: "Definition",
      "Delete group information": "Ta bort information",
      Delete: "Radera",
      "Delete match": "Ta bort en match",
      "Delete match set": "Radera matchuppsättning",
      "Delete pinboard item?": "Radera anslag?",
      "Delete submission": "Radera inlämning",
      "Depending on course(s)": "Beror på modulen/modulerna",
      "Depending on following courses": "Beror på följande moduler",
      Description: "Beskrivning",
      "Description too long": "Beskrivningen för lång",
      "Descriptive text": "Beskrivningstext",
      Design: "Design",
      Deutsch: "Tyska",
      Dialog: "Dialog",
      Difficulty: "Svårighetsgrad",
      "Direct edit": "Direct edit",
      "Disable assignment links in assignments app":
          "Inaktivera länkar i inlämningsappen (använd endast som översikt).",
      "Disable video": "Stäng av video",
      "Disable audio": "Stäng av ljud",
      Discard: "Förkasta",
      discussions: "diskussioner",
      Discussions: "Diskussioner",
      "Dive deeper by choosing course and lesson.":
          "Fördjupa dig genom att välja modul och lektion.",
      Document: "Dokument",
      Documents: "Dokument",
      Done: "Klar",
      Download: "Ladda ner",
      "Download as csv": "Ladda ner som csv",
      "Download attachment": "Ladda ner fil",
      "Download original file": "Ladda ner originalfil",
      "Download zip": "Ladda ner zip",
      "Do not create sub group": "Skapa inte undergrupp",
      "Do not join": "Gå inte med",
      "Do not have an account?": "Har du inget konto?",
      "Do not use deadline": "Använd inte deadline",
      "Do you have any other tasks for your friend Olly, the virtual teaching assistant?":
          "Har du några andra uppgifter till din kompis Olly, den virtuella lärarassistenten?",
      "Do you know the answer?": "Kan du svaret?",
      "Do you need help?": "Behöver du hjälp?",
      "Do you really want to leave? you have unsaved changes!":
          "Är du säker på att du vill lämna sidan? Det finns sådant du inte sparat!",
      "Do you want to abandon the process?": "Vill du avsluta förfarandet?",
      "Do you want to continue, but only copy new elements?":
          "Vill du fortsätta, men bara kopiera nya resurser?",
      "Do you want to delete this answer?": "Vill du radera detta svar?",
      "Do you want to delete this consultation?":
          "Vill du radera denna konsultation?",
      "Do you want to delete this message for all members?":
          "Är du säker på att du vill radera detta meddelande för alla medlemmar?",
      "Do you want to enable camera after joining call?":
          "Vill du sätta på kameran när du kommer in i samtalet?",
      "Do you want to end this call for all participants or just leave this conversation?":
          "Vill du avsluta samtalet för alla medlemmar eller bara lämna det?",
      "Do you want to evenly distribute the rest of the users assignments afterwards?":
          "Vill du omfördela resten av användarnas inlämningar jämnt?",
      "Do you want to remove": "Vill du ta bort?",
      "Do you want to remove member from this call?":
          "Vill du ta bort {insertion} från samtalet?",
      "Do you want to you want to leave current call?":
          "Vill du lämna samtalet",
      "Do you want to you want to leave current webinar?":
          "Vill du lämna webinaret?",
      "Do you want to synchronize elements that have already been copied?":
          "Vill du synkronisera resurser som redan kopierats?",
      "Do you want to test out some of our math materials?":
          "Vill du pröva ett urval av vårt undervisningsmaterial inom matematik?",
      "Do you wish to join the institution":
          "Vill du anmäla dig institutionen",
      "Do you wish to share this with everyone?":
          "Kan du tänka dig att dela detta med alla?",
      "Drag and drop answers to the dashed boxes with the categories":
          "Dra och släpp svaren i de streckade boxarna med kategorier",
      "Drag and drop labels into this box to create alternative (wrong) labels":
          "Dra och släpp etiketter in i denna box för att skapa alternativa (felaktiga) etiketter",
      "Drag and drop on image": "Dra och släpp på bilden",
      "Drag and drop options from the right to the dashed boxes with the answers":
          "Dra alternativ från höger släpp dem i de mönstrade boxarna med svaren",
      "Drag and drop to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students":
          "Dra och släpp för att ändra ordningsföljden på dina appar och aktivera eller inaktivera dem genom att skjuta knappen på/av. Du kan också gömma appar för dina elever",
      Drama: "Drama",
      "Drop new learning element here": "Dra hit en ny lärresurs",
      "Dropout members": "Avhoppade medlemmar",
      "Dropped out": "Avhoppad",
      Due_date: "Inlämningsdatum",
      "Due date": "Inlämningsdatum",
      "Due date required": "Inlämningsdatum obligatoriskt",
      Duplicate: "Duplicera",
      "Duplicate assignment": "Duplicera uppgift",
      "Duplicate flashcard": "Duplicera flashkort",
      "Duplicate learning activity": "Dubbel inlärningsaktivitet",
      "Duplicate question": "Duplicera fråga",
      Dutch: "Nederländska",
      Economics: "Ekonomi",
      "Edaptio group": "Edaptio-kurs",
      "Edaptio primary": null,
      "Edaptio secondary": null,
      Edit: "Redigera",
      "Edit assignment": "Redigera uppgift",
      "Edit audio": "Redigera ljud",
      "Edit pinboard": "Redigera anslagstavla",
      "Edit board item": "Redigera anslag",
      "Edit collection": "Redigera samling",
      "Edit comment": "Redigera kommentar",
      "Edit consultation": "Redigera konsultation",
      "Edit course": "Redigera modul",
      "Edit courses order": "Redigera modulernas ordningsföljd",
      "Edit discussion": "Redigera diskussion",
      "Edit external link": "Redigera extern länk",
      "Edit feedback": "Redigera återkoppling",
      "Edit file": "Redigera fil",
      "Edit flashcard": "Redigera flashkort",
      "Edit flashcards": "Redigera flashkort",
      "Edit Geogebra element": "Redigera Geogebra-resurs",
      "edit-group": "Kursinställningar",
      "Edit group": "Redigera kurs",
      "Edit group information": "Redigera kursinformation",
      "Edit group name": "Redigera kursnamn",
      "Edit holiday": "Redigera lov/helgdag",
      "Edit image": "Redigera bild",
      "Edit image label": "Redigera bildetikett",
      "Edit learning element": "Redigera lärresurs",
      "Edit material": "Redigera lektion",
      "Edit materials order": "Redigera ordningsföljd på lektioner",
      "Edit message": "Redigera meddelande",
      "Edit news post": "Redigera nyhet",
      "Edit notebook": "Redigera anteckningsbok",
      "Edit payment method": "Redigera betalningsmetod",
      "Edit permission group": "Redigera roll",
      "Edit post": "Redigera anslag",
      "Edit question": "Redigera fråga",
      "Edit questionnaire": "Redigera frågeformulär",
      "Edit questions": "Redigera fråga",
      "Edit quiz": "Redigera quiz",
      "Edit one learning element at a time. Save changes in order to continue editing.":
          "Redigera en lärresurs åt gången. Spara dina ändringar för att redigera en ny resurs.",
      "Edit timeline": "Redigera tidslinje",
      "Edit selected learning element": "Redigera den valda lärresurs",
      "Edit selected user": "Redigera valda användare",
      "Edit subject": "Redigera ämne",
      "Edit tests": "Redigera test",
      "Edit text": "Redigera text",
      "Edit text label": "Redigera textetikett",
      "Edit topic": "Redigera underämne",
      "Edit user": "Redigera användare",
      Element: "Aktivitet",
      "Element already in lesson": "Aktiviteten finns redan i lektionen",
      "Element moved": "Lärresurs flyttad",
      Elements: "Resurser",
      "Edit video": "Redigera video",
      "Edit vimeo": "Redigera Vimeo",
      "Edit youtube": "Redigera Youtube",
      Embedded: "Inbäddad",
      "Enable camera": "Sätt på kameran",
      "Enable video": "Sätt på video",
      "Enable audio": "Sätt på ljud",
      Email: "E-post",
      email: "e-post",
      "Email / Username": "E-post / användarnamn",
      "Email already exists": "E-postadressen finns redan i systemet",
      "End chat": "Avsluta chat",
      "End for all": "Avsluta för alla",
      English: "Engelska",
      "Enter class code": "Skriv in kurskoden",
      "Enter colleague email": "Skriv in din kollegas e-postadress",
      "Enter your email address and we will send you a new temporary password":
          "Skriv din e-postadress så får du ett nytt, temporärt lösenord",
      "Enter your nickname to join this call":
          "Skriv in ditt smeknamn för att gå med i samtalet",
      "Enter your payment details": "Skriv in din betalningsinformation",
      Error: "Fel",
      "Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.":
          "Det uppstod ett fel när vi försökte läsa in din text. Kontakta supporten om detta fortsätter, så fixar vi det.",
      "Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.":
          "Fel vid upprättandet av förbindelse. Kontakta supporten eller försök att starta videosamtalet ånyo.",
      "Error occurred while try sign up": "Det uppstod ett fel vid anmälan",
      "Error: time must be in HH:MM:SS format":
          "Fel: tiden måste vara i HH:MM:SS-format",
      Esperanto: "Esperanto",
      "Evaluate all words": "Uppskatta alla ord",
      "Even more storage": "Ännu mer lagringskapacitet",
      Event: "Evenemang",
      Events: "Evenemang",
      Exam: "Examinering",
      "exam mode": "provläge",
      Exams: "Examineringar",
      "Exam mode": "Examineringsläge",
      "Exam mode limits the students communication features for an entire team during an exam. Can be turned on/off at any time, and works right away.":
          "Examineringsläget begränsar användarnas kommunikationsmöjligheter på Edaptio, så att man kan anordna prov. Funktionen kan sättas på och stängas av närsomhelst och ändringar sker direkt.",
      "Exit fullscreen": "Stäng helskärm",
      Expand: "Vik ut",
      "Expand all": "FVik ut alla",
      "Expiration date of your ongoing subscription plan":
          "Nuvarande abonnemang går ut",
      "Expiry date": "Utgångsdatum",
      Expressions: "Uttryck",
      "External link": "Externa länkar",
      "External service forgotten error":
          "Du kan inte be om ett nytt lösenord hos denna institution, eftersom ni använder ett externt system för inloggning. Kontakt administrationen på din institution.",
      "External service not linked":
          "Extern tjänst är inte kopplad till någon användare",
      "External user id": "Externt användar-id",
      "Extra items (uncategorised)": "Extra artiklar (ej kategoriserade)",
      "Failed to activate video chat. Please contact us.":
          "Aktivering av videochat misslyckades. Vänligen kontakta supporten. ",
      "Failed to delete information": "Kunde inte radera informationen",
      "Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join":
          "Det uppstod ett fel med åtkomsten till videochatten. Kanske är du inte deltagare på videochatten. Försök be om åtkomst. ",
      "Failed to lock\\unlock board":
          "Misslyckades låsa\\låsa upp anslagstavla",
      "Failed to lock\\unlock board item":
          "Misslyckades låsa\\låsa upp anslag",
      "Failed to remove board item": "Misslyckades ta bort anslag",
      "Failed to save board item": "Lyckades inte spara anslag",
      "Failed to save information": "Lyckades inte spara information",
      "Failed to start video chat. Please contact us.":
          "Vi kunde inte starta videochatten. Vänligen kontakta supporten.",
      "Failed to subscribe to board updates. Try to refresh board":
          "Lyckades inte prenumerera på anslagstavleuppdateringer. Testa att ladda om anslagstavlan.",
      "Failed to subscribe to conference updates. Try to refresh conference":
          "Lyckades inte prenumerera på forumuppdateringer.Testa att ladda om forumet.",
      "Failed to subscribe to news updates. Try to refresh news":
          "Lyckades inte prenumerera på nyhetsuppdateringer. Testa att ladda om nyheter.",
      "Failed to subscribe to page updates. Try to refresh the page":
          "Lyckades inte prenumerera på sidouppdateringer. Testa att ladda om sidan.",
      "Failed to subscribe to post answers updates. Try to refresh post answers":
          "Lyckades inte prenumerera på anslagssvarsuppdateringer. Testa att ladda om anslagssvar.",
      FAQ: "Ofta ställda frågor",
      Feedback: "Återkoppling",
      "Feedback created": "Återkoppling skapad",
      "Feedback for this quiz try": "Återkoppling till detta quiz-test",
      "Feedback from past assignments":
          "Återkoppling från tidigare inlämningar",
      "Feedback with this answer": "Återkoppling till detta svar",
      "Feedback for answer": "Återkoppling till svar",
      "Feedback on this answer": "Återkoppling till detta svar",
      "Feedback released": "Återkoppling publicerad",
      "Feedback saved": "Återkoppling sparad",
      Female: "Kvinna",
      Fields: "Fält",
      File: "Fil",
      Files: "Filer",
      "File added": "Fil tillagd",
      "File removed": "Fil borttagen",
      "File saved": "Fil sparad",
      "File path": "Filsökväg",
      "File too big, please upgrade your account to increase file size limit. (Trial limit:":
          "Filen är för stor. Om du vill ladda upp större filer, kan du uppgradera ditt Edaptio-konto. (Nuvarende begränsning: ",
      "Files added": "Filer tillagda",
      file_sharing: "Fildelning",
      "File sharing": "Fildelning",
      "Fill it in to log in": "Fyll i det för att kunna logga in",
      Filter: "Filtrera",
      "Fill in the blank": "Fyll i luckorna",
      "Fill in the blank (Cloze)": "Fyll i luckorna (Cloze)",
      "Find members by email": "Hitta medlemmar via e-post",
      Finish: "Färdig",
      Finnish: "Finska",
      Firstname: "Förnamn",
      "First handin": "Första inlämning",
      "Firstname should not be empty": "Förnamnet kan inte vara tomt",
      "Fixed submission date": "Fastställt inlämningsdatum",
      "Flashcards collection": "Flashcardsamling",
      "Flashcards collection creation": "Skapa flashcards",
      "Flashcards collection edit": "Redigera flashcards",
      "Flashcards tips":
          "Använd {param1} för att byta kort och {param2} för att vända på kortet" /* Write with params like in en_EN.js */,
      "Flexible submission date": "Flexibelt inlämningsdatum",
      Float: "Decimaltal",
      Folders: "Mappar",
      "Folder added": "Mapp tillagd",
      "Folder removed": "Mapp borttagen",
      "Folder saved": "Mapp sparad",
      "Following invites will be sent via email":
          "Följande inbjudningar kommer att mejlas ut",
      For: "Till",
      "Forgot password?": "Glömt lösenordet?",
      "Forgotten password": "Glömt lösenord",
      "for clarification.": "för förtydligande",
      "For the best experience, we recommend switching to a computer.":
          "Bäst upplevelse får du om du använder dator.",
      Fraction: "Bråk",
      Fractions: "Bråktal",
      Flashcard: "Flashkort",
      flashcards: "flashkort",
      Flashcards: "Flashkort",
      Free: "Gratis",
      French: "Franska",
      From: "Från",
      "From the date your plan will switch to plan":
          "Från och med {date} kommer ditt abonnemang att övergå till {plan}",
      "from this call?": "från detta samtal?",
      Front: "Framsida",
      Full: "Full",
      Fullscreen: "Helskärm",
      "gave feedback for the assignment": "gav återkoppling till inlämningen",
      Gender: "Kön",
      General: "Allmänt",
      "General chat": "Allmän chat",
      "General feedback": "Allmän återkoppling",
      "General question types": "Allmänna frågetyper",
      "General settings": "Allmänna inställningar",
      geogebra: "Geogebra",
      Geogebra: "Geogebra",
      "Geogebra creation": "Skapa Geogebra",
      "Geogebra edit": "Redigera Geogebra",
      "Geogebra element": "Geogebra-element",
      "Geogebra element removed": "Geogebra-element borttaget",
      "Geogebra id": "Geogebra-id",
      "Geogebra link or ID": "Geogebra-länk eller -id",
      Geography: "Geografi",
      German: "Tyska",
      "Get help from Olly": "Få hjälp av Olly",
      "Get started": "Kom igång",
      "Get started using Edaptio for free": "Börja använda Edaptio gratis",
      "Get started with Edaptio": "Börja använda Edaptio",
      "Get this code/URL from your teacher.":
          "Få denna kod/URL från din lärare",
      "Get submission as zip": "Hämta inlämningar som zip-fil",
      "Go back to what you have been doing and get a notification when Olly is done":
          "Gå tillbaka till det du höll på med och få en avisering när Olly är klar",
      "Go back to where you came from": null,
      "Go to group": "Gå till kurs",
      "Go to guide": "Gå till guide",
      "Go to link": "Gå till länk",
      "Go to the team": "Gå till kursen",
      "Go to the lesson view to add learning elements!":
          "Gå till lektionen för att lägga till lärresurser!",
      "Good afternoon": "God eftermiddag,",
      "Good evening": "God kväll,",
      "Good morning": "God morgon,",
      Google: "Google",
      "Google linked": "Google länkat",
      Grade: "Betyg",
      "Grade scale": "Betygsskala",
      "grade_scale.danish": "Dansk 7-stegsskala",
      "grade_scale.letters": "Bokstäver A-F",
      "grade_scale.numbers": "Siffror 1-6",
      "grade_scale.romanDTU": "Romerska siffror I-IV",
      Graded: "Betygssatt",
      Gray: null,
      Greek: "Grekiska",
      Group: "Kurs",
      "Group with": "Gruppera ihop med",
      group_courses: "moduler",
      groups: "kurser",
      "Groups in exam mode": "Kurs i provläge",
      "group-information": "Kursinformation",
      "group-statistics": "Kursstatistik",
      "Group archived": "Kurs arkiverad",
      "Group created": "Kurs skapad",
      "Group hand-in allowed": "Gruppinlämning tillåten",
      "Group name": "Kursnamn",
      "Group saved": "Kurs sparad",
      "Group type": "Kurstyp",
      "Group unarchived": "Kurs borttagen från arkivet",
      Half: "Halv",
      "Hatian Creole": "Haitisk kreol",
      "Hand down": "Hand ner",
      "Hand up": "Hand upp",
      "Handed in": "Inlämnad",
      "Handed in after deadline": "Inlämnad efter deadline",
      "has a hand up": "räcker upp handen",
      "has started a video-chat. Click here to attend:":
          "har startat en videochatt. Klicka här för att delta:",
      "Have you received a class code from your teacher? Then click the “join now” button and become a part of your first class.":
          'Har du fått en kod av din lärare? Klicka isåfall på "gå med nu" och bli en del av kursen',
      Help: "Hjälp",
      "Help center": "Hjälpcenter",
      "Here isn't added courses yet": "Ingen modul funnen",
      "Here you can copy all your class contents to another class. Remember that elements you have previously copied will only be synchronized, and not copied again.":
          "Här kan du kopiera allt innehåll i din kurs till en annan kurs. Märk väl att resurser som du har kopierat tidigare, inte kommer att kopieras på nytt, däremot kommer de att synkroniseras.",
      "Here you can put groups in exam mode. Exam mode means that all users in the particular group can not use any communication tools on the platform. E.g messages, news and conference":
          "Här kan du sätta kurser i provläge. Provläge innebär att ingen användare i pågående kurser kan använda någon av plattformens kommunikationsverktyg, såsom meddelanden, nyheter eller diskussioner.",
      "Hey, I am busy at the moment!": "Hallå där, jag är upptagen just nu!",
      "Hey! I am Olly, the virtual teaching assistant!":
          "Hejsan! Jag heter Olly och är en virtuell lärarassistent!",
      "Hey, Olly has your content ready for review":
          "Hallå där, Olly har skapat innehåll som du kan gå igenom",
      "Hey! Olly failed to create learning activities from of the uploaded source":
          "Hallå! Olly lyckades inte skapa några lärresurser av det uppladdade materialet",
      "Hey Edaptio, I am interested in a tailored offer for you platform. My contact information is:":
          "Hallå där, Edaptio! Jag är intresserad av ett skräddarsytt erbjudande. Mina kontaktuppgifter är:",
      "HH:MM:SS": "HH:MM:SS",
      "Hidden lesson": "Göm lektion",
      "Hide element": "Göm element",
      "Hide menu": "Göm meny",
      "Hide/show side menu": "Göm/visa sidomeny",
      "Hide non due date assignments": "Göm inlämningar utan deadline",
      Hidden: "Gömd",
      "Hidden assignment": "Gömda uppgifter",
      "Hidden course": "Gömd modul",
      "Hidden folder": "Gömd mapp",
      "Hidden from students": "Gömd för elever",
      "Hidden material": "Gömt material",
      "Hidden submission": "Gömd inlämning",
      Hindi: "Hindi",
      History: "Historia",
      Home: "Hem",
      "How do i log in?": "Hur loggar jag in?",
      "How do you want to create your acivity?":
          "Hur vill du skapa {name}" /* Translate with param */,
      "How to get started?": "Så här kommer du igång!",
      Hungarian: "Ungerska",
      Icelandic: "Isländska",
      Identifier: "Identifiering",
      "I am using another platform": "Jeg amvänder en annan plattform",
      "I agree": "Jag godtar",
      "I agree to the Terms and Conditions and default Notifications settings":
          "Jag godtar {termsLink} och de allmänna {notifLink}" /* Translate with params like in en_US file */,
      "I can help you create content for your lessons. Just follow the steps and put me to work!":
          "Jag kan hjälpa dig att skapa läromaterial till dina lektioner. Följ bara stegen och sätt mig i arbete!",
      "I don't find the platform valuable":
          "Jeg tycker inte att plattformen är bra",
      "I have read and understood this text":
          "Jag har läst och förstått denna text",
      "If unchecked, the user will be presented with all words form the word list.":
          "Om ej ikryssad, kommer amvändaren att få se alla ord i ordlisten.",
      "If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.":
          "Om du skickats hit av din lärare, har du redan ett konto. Be din lärare om dina uppgifter.",
      'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.':
          'Om du laddat ner inlämningarna via "ladda ner som zip"-knappen, så kan du ladda upp din återkoppling samlad här. Vi ser till att återkopplingen genereras automatiskt och bifogar filerna. Se till att bara ladda upp filer som du inte tidigare laddat upp och endast till inlämningar som du inte redan har räddat.',
      "If you're supposed to have access to this page, try refreshing.":
          "Om det är meningen att du ska ha tillgång till denna sida, kan du testa att ladda om webbläsaren.",
      "I'm sure": "Jag är säker",
      Image: "Bild",
      image: "Bild",
      "Image dimensions": "Bildstorlekar",
      "Image height": "Bildhöjd",
      "Image width": "Bildbredd",
      "image-drag-and-drop": "Drag och släpp på bilden",
      Import: "Importera",
      "Import data": "Importera data",
      "Import existing question": "Importera befintlig fråga",
      "Import users": "Importera användare",
      "Import wiris instance": "Importera en wiris-fråga",
      "In a test, all feedback is received at the end of the quiz.":
          "Under ett prov får användaren all återkoppling i slutet av quizzen.",
      "Includes 10 active learners, €0.80/month for each additional learner":
          "Inkluderar 10 aktiva elever, 0,80 €/månad för varje ytterligare elev",
      "Includes 10 active learners, €1.60/month for each additional learner":
          "Inkluderar 10 aktiva elever, 1,60 €/månad för varje ytterligare elev",
      "Incorrect file name": "Felaktigt filnamn",
      Indonesian: "Indonesiska",
      information: "information",
      Information: "Information",
      "Information deleted": "Information borttagen",
      "Information saved": "Information sparad",
      "Information title": "Titel",
      Innovation: "Innovation",
      "Input not email": "Det du skrev in är inte en e-postadress",
      "Input too short": "Det du skrev in är för kort",
      "Input valid date and time - or skip the deadline":
          "Skriv in korrekt tid och datum – eller välj att inte använda deadline till denna uppgift",
      "Input the class name and click create. You can always add members and co-teachers later under the members tab.":
          'Skriv kursnamnet och klicka på "skapa". Du kan alltid lägga till medlemmar och andra lärare senare under "medlemmar"-fliken.',
      "Insert a code received from your teacher in order to join the class":
          "Skriv in kurskoden som du fått från din lärare för att registrera dig i kursen.",
      "Insert a text below": "Skriv in texten/texterna nedan",
      "Insert a word or phrase you want learners to hear.":
          "Sätt in ett ord eller en fras som du vill att eleverna ska höra.",
      "Insert your personal information and set up the platform’s language. You will be able to edit it later in the profile’s settings.":
          "Skriv in din personlig information och välj språk. Du kan ändra detta senare under dina inställningar.",
      Institution: "Institution",
      "Institution/company name": "Namn på institution/verksamhet",
      "Institution groups": "Institutionens kurser",
      "Institution payments": "Institutionsbetalning",
      Institutions: "Institutioner",
      "Institution language": "Institutionsspråk",
      "Institution settings": "Institutionsinställningar",
      Integer: "Heltal",
      interactive_objects: "Geogebra",
      "Interactive exercise": "Interaktiv övning",
      "International economy": "Internationell ekonomi",
      "Intranet": null,
      Invalid: "Ogiltig",
      "Invalid class code": "Ogiltig kurskod",
      "Invalid form": "Ogiltiga data skickade till servern",
      "Invalid: Must be at least 1": "Ogiltigt: får vara minst 1",
      "Invalid: Must be at most": "Ogiltig: får högst vara",
      "Invalid: Must be between 0 - 100":
          "Ogiltig: måste vara mellan 0 och 100",
      Invite: "Bjud in",
      "Invite colleagues": "Bjud in kolleger",
      "Invite learners": "Bjud in elever",
      "Invite members": "Bjud in medlemmar",
      "Invite members to your plan": "Bjud in medlemmar till ditt abonnemang",
      "Invite more": "Bjud in fler",
      "Invite new learners sharing one of following":
          "Bjud in nya elever via något av följande",
      "Invite Participants": "Bjud in medlemmar",
      "Invite participants via link": "Bjud in medlemmar via länk",
      "Invite plan members via email":
          "Bjud in medlemmar i abonnemanget via e-post",
      "Invited by": "Inbjuden av",
      "Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.":
          "Inbjudningarna har skickats till dina kolleger. De kommer att läggas till så snart de registrerat sig hos Edaptio.",
      "Invite(s) sent.": "Inbjudning(ar) skickad(e)",
      Italian: "Italienska",
      Item: "Objekt",
      "It seems that there are no active elements in this class":
          "Det ser ut som att du inte har några aktiva {insertion} i denna kurs",
      Japanese: "Japanska",
      Join: "Gå med",
      "Join class": "Gå med i kurs",
      Joined: "Gick med",
      "Join call": "Delta i samtalet",
      "Join institution": "Join institution",
      "Join now": "Gå med här",
      "Join your class": "Gå med i din kurs",
      "Join your first class": "Gå med i din första kurs",
      "Join with a code": "Gå med via kod",
      "Join with code not allowed": "Det är inte möjligt att gå med via kod",
      "Keep training": "Fortsätt öva",
      Keyword: "Nyckelord",
      "Knowledge bank": "Kunskapsbank",
      Korean: "Koreanska",
      label: "etikett",
      "Label group": "Etikettgrupp",
      "Labels not on image": "Etiketter som inte finns på bilden",
      Language: "Språk",
      "lang.da": "Danska",
      "lang.en": "Engelska",
      "Language saved": "Språk sparat",
      "Language selection": "Välj språk",
      Lastname: "Efternamn",
      "Lastname should not be empty": "Efternamn får inte vara tomt",
      "Last answered": "Senast besvarat",
      "Last modified": "Senast uppdaterat",
      Later: "Senare",
      "Latest activity": "Senaste aktivitet",
      "Latest answers": "Senaste svar",
      "Latest handin": "Senaste inlämning",
      "Latest sync": "Senaste synkronisering",
      "Latest try": "Senaste försök",
      Latin: "Latin",
      Latvian: "Lettiska",
      "Learning activities": "Lärresurser",
      learning_elements: "Lärresurser",
      "Learning activities added": "Inlärningaktiviteter tillagda",
      "Learning activities created by Olly are displayed below. You can keep, edit or discard each of them.":
          "De lärresurser Olly skapat återfinns nedan. Du kan spara, redigera eller förkasta var och en av dem.",
      "Learning activities to create": "Inlärningaktiviteter att skapa",
      "Learning elements": "Lärresurser",
      "Learning element created": "Lärresurs skapad",
      "Learning element moved": "Lärresurs flyttad",
      "Learning element removed": "Lärresurs raderad",
      "Learning element saved": "Lärresurs sparad",
      "Learn more": "Läs mer",
      Leave: "Lämna",
      "Leave current video chat": "Lämna detta videosamtal",
      "Leave current webinar": "Lämna detta webinarium",
      "Left us": "Gick",
      lesson: "Lektion",
      Lesson: "Lektion",
      "Lesson copied": "Lektion kopierad",
      "Lesson moved": "Lektion flyttad",
      "Lesson name": "Lektionsnamn",
      "Lesson title": "Lektionstitel",
      Lessonbank: "Lektionsbank",
      lessons: "Lektioner",
      Lessons: "Lektioner",
      "let all in": "släpp in alla",
      "Let us know why you are leaving:":
          "Berätta gärna varför du lämnar oss",
      "Let’s begin!": "Då sätter vi igång!",
      Level: "Nivå",
      Levels: "Nivåer",
      Library: "Bibliotek",
      link: "länk",
      Link: "Länk",
      "Link copied!": "Länk kopierad!",
      "Link course to group": "Länka modul till kurs",
      "Link element to group": "Länka lärresurs till kurs",
      "Link file to group": "Länka fil till kurs",
      "Link learning element to group": "Länka lärresurs till kurs",
      "Link lesson to group": "Länka lektion till kurs",
      "Link text": "Länka text",
      "Link to group": "Länk till kurs",
      "Link to join to video chat": "Länk för att gå med i videosamtal",
      Links: "Länkar",
      "List of participants": "Deltagarlista",
      "List of selected attachments": "Valda filer till inlämning",
      "Load all comments": "Hämta alla kommentarer",
      "Load earlier": "Hämta tidigare",
      "Load external quiz": "Hämta externa quiz",
      "Load flashcards’ preview": "Hämta och se flashcards",
      "Load more": "Hämta fler",
      "Load pinboard items": "Hämta anslag",
      "Load student submissions": "Hämta inlämningsöversikt",
      Loading: "Hämtar",
      "Lock board": "Lås anslagstavla",
      "Lock board item": "Lås anslag",
      "Locked course. You have not yet completed the prerequisite course(s) for this course.":
          "Låst modul. Du har ännu inte klarat av den eller de moduler som denna modul bygger på.",
      Login: "Logga in",
      "Login as": "Logga in som",
      "Login failed with the following message":
          "Inloggning misslyckades med följande meddelande",
      Logout: "Logga ut",
      "Log in as": "Logga in som",
      "Login with": "Logga in med",
      "Log in with email": "Logga in med e-postadress",
      "Log in with Facebook": "Logga in med Facebook-konto",
      "Log in with Google": "Logga in med Google-konto",
      "Log in with Unilogin": "Logga in med Unilogin-konto",
      "Log in with Microsoft": "Logga in med Microsoft-konto",
      "Looking for a specific user? Search below.":
          "Letar du efter en specifik användare? Søk nedan.",
      "Looking to log in? Click here": "Vill du logga in? Klicka här",
      "Lower border": "Nedre gräns",
      "Made by": "Gjord av",
      Male: "Man",
      "Manage apps": "Hantera appar",
      "Manage board item": "Hantera anslag",
      "Manage groups": "Hantera kurser",
      "Manage institutions": "Hantera institutioner",
      "Manage moodle": "Hantera moodle",
      "Manage permissions": "Hantera tillåtelser",
      "Manage users": "Hantera användare",
      Mandatory: "Obligatorisk",
      "Maple TA": "Maple TA",
      Macedonian: "Makedonska",
      "Mark all as read": "Markera alla som lästa",
      "Mark as unread": "Markera som oläst",
      "Mark student as self-study": "Markera som självstuderande",
      "Mark unread": "Markera som oläst",
      "Marked as read": "Markerad som läst",
      match: "matcha",
      Match: "Matcha",
      "Match case insensitive":
          "Gör INTE skillnad mellan stora och små bokstäver",
      "Match case sensitive": "Skilj mellan stora och små bokstäver",
      "Match options": "Matcha val",
      "Match set": "Matcha set",
      "Match sets": "Matchuppsättningar",
      "Match with arrows": "Matcha med pilar",
      "Matching cloze tag": "Matchande texttaggar",
      "Matching with": "Matchar med",
      "match-wiris": "Wiris-match",
      material: "Lektion",
      material_objects: "Lektion",
      materials: "Lektioner",
      "Material added": "Lektion tillagd",
      "Material created": "Lektion skapad",
      "Material deleted": "Lektion raderad",
      "Material group": "Lektionsgrupp",
      "Material removed": "Lektion borttagen",
      MathHero: "MatematikTutor",
      Mathematics: "Matematik",
      media: "media",
      Media: "Media",
      Member: "Medlem",
      Members: "Medlemmar",
      members: "medlemmar",
      "Members can comment on news": "Medlemmar kan kommentera nyheter",
      "Members can post news": "Medlemmer kan lägga upp nyheter",
      "Members on plan": "Medlemmar i abonnemanget",
      Menu: "Meny",
      "Message marked as unread": "Meddelande markerat som oläst",
      Messages: "Meddelanden",
      Microphone: "Mikrofon",
      Microsoft: "Microsoft",
      "Microsoft linked": "Microsoft länkat",
      min: "min",
      "Minimum count characters":
          "Minsta {count} tecken" /* Translate with param count */,
      "Minimum number of assignments behind":
          "Minimalt antal inlämningar efter",
      "Minimum number of questions": "Minimalt antal frågor",
      "Miro Mind Maps": "Miro Mind Maps",
      "Missing feedback": "Återkoppling saknas",
      Moderator: "Moderator",
      Moldavian: "Moldaviska",
      Montenegrin: "Montenegrinska",
      Monthly: "Månatlig",
      "Monthly payment": "Månatlig betaling",
      "Monthly payment per active learner":
          "Måntalig betalning per aktiv elev",
      "months-full-array": [
          "Januari",
          "Februari",
          "Mars",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "Augusti",
          "September",
          "Oktober",
          "November",
          "December"
      ],
      "months-short-array": [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Maj",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dec"
      ],
      Moodle: "Moodle",
      "Moodle group": "Moodle-grupp",
      More: "Mer",
      "More active learners": "Fler aktiva elever",
      "More settings": "Fler inställningar",
      "More support options": "Fler supportmöjligheter",
      "Most apps have a central action button - look for it in the same place as this one":
          "De flesta appar har en central handlingsknapp - leta efter den på samma ställe som denna",
      Move: "Flytta",
      "Move activity": "Flytta aktivitet",
      "Move all classes to exam mode": null,
      "Move here": "Flytta hit",
      "Move lesson": "Flyt lektion",
      "Move to folder": "Flytta till annan mapp",
      ms_class_notebook: "Kursanteckningsbok",
      "Multiple choice": "Flerval",
      "multiple-choice": "Flerval",
      "multi-choice-wiris": "Wiris flerval",
      "Multiple choice questions": "Flervalsfrågor",
      "Multiple choice settings": "Flervalsinställningar",
      Music: "Musik",
      "Must be a valid secure link (https)":
          "Måste vara en säker länk (https)",
      "Must be between": "Måste vara mellan",
      Mute: "Stäng av ljud",
      Muted: "Ljud av",
      "Muted mic": "Mikrofon av",
      "mute all": "alla mutade",
      "My institution is not on the list":
          "Min institution finns inte på listan",
      Name: "Namn",
      "Name saved": "Namn sparat",
      "Need help?": "Behöver du hjälp?",
      "Need more flexible options? Upgrade your plan today":
          "Behöver du mer flexibla lösningar? Uppgradera ditt abonnemang idag",
      Never: "Aldrig",
      "New assignment": "Ny uppgift",
      "New consultation": "Ny konsultation",
      "New email": "Ny e-post",
      "New field": "Nytt fält",
      "New flashcard creation": "Skapa nytt flashkort",
      "New Geogebra element": "Nytt Geogebra-element",
      "New image": "Ny bild",
      "New individual due date": "Ny individuell tidsfrist",
      "New information": "Ny information",
      "New learning activity has been created": "Ny lärresurser har skapats",
      "New learning element": "Ny lärresurs",
      "New learning element added": "Ny lärresurs tillagd",
      "New message": "Nytt meddelande",
      "New Post": "Nytt anslag",
      "New question": "Ny fråga",
      "New quiz": "Ny quiz",
      "New password": "Nytt lösenord",
      "New pinboard item": "Nytt anslag",
      "New relation": "Ny relation",
      "New word": "Nytt ord",
      news: "Nyheter",
      News: "Nyheter",
      "News post creation": "Skapa ny nyhet",
      "New password has been successfully sent via email":
          "Ett nytt lösenord har skickats ut via e-post",
      Next: "Nästa",
      "Next assignment": "Nästa inlämning",
      "Next assignment answer without feedback":
          "Nästa inlämning utan återkoppling",
      "Next course": "Nästa modul",
      "Next lesson": "Nästa lektion",
      "Next material": "Nästa lektion",
      "Next month": "Nästa månad",
      "Next payment scheduled for": "Nästa betalning förfaller",
      "Next question": "Nästa fråga",
      "Next topic": "Nästa ämne",
      "Nice, you created a quiz question!":
          "Snyggt, du har skapat en frågesportsfråga!",
      No: "Nej",
      "No admins yet": "Ingen lärare",
      "No answers": "Inga svar",
      "No assignments found": "Inga inlämningar hittade",
      "No collections found": "Inga samlinger hittade",
      "No completed consultations": "Inga genomförda konsultationer",
      "No consultations to display": "Inga konsultationer att visa",
      "No conversations": "Inga samtal",
      "No counsellors": "Inga vägledare",
      "No course": "Ingen modul",
      "No courses found": "Inga moduler hittade",
      "No credit card required": "Kreditkort krävs ej",
      "No data to display":
          "Inga {name} att visa" /* Translate with param like in en_US */,
      "No description": "Ingen beskrivning",
      "No elements in this lesson": "Inga resurser i denna lektion",
      "No files": "Inga filer",
      "No files or folders yet": "Inga filer eller mappar än",
      "No files to export":
          "Inga filer att exportera. Har dina användare lämnat in än?",
      "No Geogebra elements found": "Inga Geogebra-resurser hittade",
      "No group information created": "Ingen kursinformation skapad",
      "No groups": "Inga kurser",
      "No groups found": "Inga kurser hittade",
      "No information": "Ingen information",
      "No learning elements found": "Inga lärresurser hittade",
      "No materials found": "Inga lektioner hittade",
      "No members": "Inga medlemmar",
      "No members yet": "Inga medlemmar än",
      "No messages found": "Inga meddelanden än",
      "No messages to display": "Inga meddelanden att visa",
      "No messages yet": "Inga meddelanden än",
      "No news found for this group": "Inga nyheter i denna kurs än",
      "No notifications": "Inga aviseringar",
      "No, only visible for my institution":
          "Nej, endast synligt för min institution",
      "No participants": "Inga deltagare",
      "No people found": "Inga användare hittade",
      "No people found. Keep typing a full email to invite people.":
          "Inga personer hittade. Fortsätt att skriva in en komplett e-postadress för att bjuda in andra.",
      "No permissions found": "Inga tillåtelser hittade",
      "No permission to access your microphone or\\and camera you (or your companions) may experience some problems.":
          "Du har gett oss tillstånd att använda mikrofon och/eller video. Detta kan skapa problemer för dig själv eller dina gäster i videosamtalet.",
      "No posts found": "Inga anslag hittade",
      "No questions": "Inga frågor",
      "No questions found": "Inga frågor hittade",
      "No questionnaires found": "Inga frågeformulär hittade",
      "No quizzes found": "Inga quiz hittade",
      "No results": "Inga resultat",
      "No results yet": "Inga resultat än",
      "No subjects": "Inga ämnen",
      "No submission date": "Inget inlämningsdatum",
      "No text": "Ingen text",
      "No title": "Ingen titel",
      "No topic": "Inget ämne",
      "No topics": "Inga ämnen",
      "No students": "Inga elever",
      "No tries yet": "Inga försök än",
      "No upcoming consultations": "Inga kommande konsultationer",
      "No videos found": "Inga videor hittade",
      "Non-profit": "Ideellt",
      None: "Inget",
      "Nope, thanks!": "Nej tack!",
      Norwegian: "Norska",
      Notifications: "Aviseringar",
      "Notify me": "Meddela mig",
      "Not assigned to course": "Ikke tildelt till modul",
      "Not enough data": "Inte tillräckligt med data",
      "Not active": "Inaktiv",
      "Not added": "Ej tillagd",
      "Not chosen": "Ej vald",
      "Not correct": "Inkorrekt",
      "Not completed": "Ej genomförd",
      "Not graded": "Inte rättad",
      "Not passed": "Underkänd",
      "Not released": "Ej publicerad",
      "Not submitted": "Ej inlämnad",
      "Not valid video chat session provided. Please restart video call normally Page reload will not help.":
          "Detta är inte en giltig videochatt. Vänligen starta om videochatten. Det hjälper inte att ladda om.",
      "Note that your new subscription plan will be activated on the date":
          "Observera att ditt nya abonnemang kommer att aktiveras {date}" /* Translate with param */,
      "Nothing has been shared with you yet":
          "Inget har ännu blivit delat med dig",
      "Notification settings": "Aviseringsinställningar",
      Now: "Nu",
      "now and share the class activity with your students":
          "Nu kan du dela med dig av klassens aktivitet till dina elever.",
      "Number of active learners": "Antal aktiva elever",
      "Number of assignments": "Antal inlämningar",
      "Number of assignments missing feedback":
          "Antal inlämningar som ännu inte rättats",
      "Number of attempts": "Antal försök",
      "Number of decimals": "Antal decimaler",
      "Number of flashcards": "Antal flashkort",
      "Number of lessons": "Antal lektioner",
      "Number of missing": "Antal saknade",
      "Number of questions": "Antal frågor",
      "Number of votes": "Antal röster",
      "Number of words": "Antal ord",
      "Number type": "Nummertyp",
      "Numeric variables": "Numeriska variabler",
      "Oh no! Something went wrong.": "Å nej! Något gick fel.",
      OK: "OK",
      Okay: "Okej",
      Of: "Av",
      of: "av",
      "Of all questions are answered correctly":
          "Av alla frågor har besvarats korrekt",
      Off: "Av",
      "Old password": "Nuvarande lösenord",
      "Olly has (finally) finished the task":
          "Olly är (äntligen) klar med uppgiften",
      "Olly is working hard to create your content":
          "Olly jobbar hårt på att skapa ditt läromaterial",
      "Olly will use this to create learning activities from.":
          "Olly kommer att använda dem för att skapa lärresurser.",
      On: "På",
      "Only open answer for now": "Endast öppna svar för tillfället",
      "Oops something went wrong. Maybe you didn't grant permissions to access your camera or microphone.":
          "Oj då! Något gick fel. Kontrollera att du godkänt användning av kamera och mikrofon. ",
      "Oops something went wrong. Please try again.":
          "Oj då! Något gick fel. Pröva igen. ",
      Open: "Öppna",
      "Open answer": "Öppna svar",
      "Open external link": "Öppna extern länk",
      "Open link": "Öppna länk",
      "Open video chat": "Öppna videochatt",
      "Open turnin": "Öppna svar",
      "open-answer": "Öppna svar",
      Option: "Valmöjlighet",
      Optional: "Valfritt",
      "Optional settings": "Valfria inställningar",
      Options: "Valmöjlighet",
      or: "eller",
      "Or continue using your phone or tablet with limited functionality.":
          "Eller fortsätt att använda din telefon eller surfplatta med begränsad funktionalitet.",
      Orange: null,
      "Order saved": "Ordningsföljd sparad",
      Organize: "Organisera",
      Other: "Annat",
      "Other Edaptio products": "Andra Edaptio-produkter",
      "Other login options": "Andra inloggningsalternativ",
      "Other reason": "Annan orsak",
      "Oups! This page does not exist!": "Oj då! Den här sidan finns inte!",
      Overview: "Överblick",
      "Paid monthly": "Månatlig betalning",
      "Paid yearly": "Årlig betalning",
      Page: "Sida",
      Pages: "Sidor",
      Participants: "Deltagare",
      Passed: "Godkänd",
      "Passed or completed": "Godkänd eller genomförd",
      Password: "Lösenord",
      "Password saved": "Lösenord sparat",
      "Passwords must match": "Lösenorden måste överensstämma",
      "Pass percentage": "Procent godkända",
      "Past consultations": "Tidigare konsultationer",
      "Paste wiris XML": "Klistra in wiris-kod",
      Pay: "Betala",
      "Payment details": "Betalningsinformation",
      "per active learner": "per aktiv elev",
      "per active learner per month": "per aktiv studerende och månad",
      "per month": "Per månad",
      "Per month paid yearly": "Per månad betalas årligen",
      Percentage: "Procent",
      "Percentage or fractions": "Procent eller bråk",
      Permission: "Tillåtelse",
      Permissions: "Tillåtelser",
      "Permission group": "Roll",
      "Permission groups": "Roller",
      "permissions.admin_bank": "Administratör i banken",
      "permissions.admin_groups_classes":
          "Administrera grupper och kurser för hela institutionen",
      "permissions.admin_institution_settings":
          "Administrera allmänna inställningar för institutionen",
      "permissions.admin_moodle_administration":
          "Administrera MOODLE för institutionen",
      "permissions.admin_permissions":
          "Administrera tilldelning av rättigheter för hela institutionen",
      "permissions.admin_sign_in_as_other": "Logga in som annan användare",
      "permissions.admin_stats":
          "Få tillgång till statistik för hela institutionen",
      // "permissions.auto_admin_in_groups" : "Automatisk underviser på hold, som användaren inviteres til",
      "permissions.can_view_teacher_activity": "Kan se lärarstatistik",
      "permissions.change_language": "Byt språk",
      "permissions.change_profile_picture": "Byt profilbild",
      "permissions.edit_module_assignments":
          "Administratörsrättigheter för app: Inlämningar",
      "permissions.edit_module_conferences":
          "Administratörsrättigheter för app: Konferenser",
      "permissions.edit_module_consultations":
          "Administratörsrättigheter för app: Konsultationer",
      "permissions.editor_bank": "Redaktör i banken",
      "permissions.edit_module_file_sharing":
          "Administratörsrättigheter för app: Fildelning",
      "permissions.edit_module_information":
          "Administratörsrättigheter för app: Information",
      "permissions.edit_module_quizzes":
          "Administratörsrättigheter för app: Quiz",
      "permissions.edit_module_materials":
          "Administratörsrättigheter för app: Lektioner",
      "permissions.edit_module_interactive":
          "Administratörsrättigheter för app: Geogebra",
      "permissions.edit_module_paths":
          "Administratörsrättigheter för app: Moduler",
      "permissions.edit_module_news":
          "Administratörsrättigheter för app: Nyheter",
      "permissions.edit_module_videos":
          "Administratörsrättigheter för app: Videor",
      "permissions.edit_module_homework_aid":
          "Administratörsrättigheter för app: Videohjälp",
      // "permissions.message_groups_classes" : "Skicka meddelande till hele hold och kurser",
      "permissions.profile_assignments": "Se inlämningar för annan användare",
      "permissions.profile_quizzes": "Se quiz för annan användare",
      "permissions.profile_paths": "Se moduler för annan användare",
      "permissions.profile_stats": "Se statistik för annan användare",
      // "permissions.search_entire_institution" : "Sök på Alla hold, inlämningar och användare på hele institutionen",
      // "permissions.see_own_assignments" : "Se inlämningar for egen användare",
      // "permissions.see_own_stats" : "Se statistikker for egen användare",
      // "permissions.send_reminders" : "Skicka påmindelser till användare",
      "permissions.view_bank": "Tillgång till banken",
      // "permissions.view_module_assignments" : "användarerettigheder till app: inlämningar",
      // "permissions.view_module_conferences" : "användarerettigheder till app: Konferencer",
      // "permissions.view_module_file_sharing" : "användarerettigheder till app: Fildelning",
      // "permissions.view_module_information" : "användarerettigheder till app: Information",
      // "permissions.view_module_quizzes" : "användarerettigheder till app: Quizzer",
      // "permissions.view_module_paths" : "användarerettigheder till app: modul",
      // "permissions.view_module_news" : "användarerettigheder till app: Nyheder",
      // "permissions.view_module_materials" : "användarerettigheder till app: lektioner",
      // "permissions.view_module_interactive" : "användarerettigheder till app: Geogebra",
      // "permissions.view_module_videos" : "användarerettigheder till app: Videoer",
      "permissions.user_edit_own_group": "Admin in own group",
      "Personal groups": "Personliga kurser",
      "Personal onboarding": "Personlig introduktion",
      Philosophy: "Filosofi",
      Physics: "Fysik",
      "Pick date": "Välj datum",
      "Pick due date": "Välj inlämningsdatum",
      "Pick due time": "Välj inlämningstid",
      "Pick from file sharing": "Välj från fildelning",
      "Pick new background image": "Välj ny bakgrundsbild",
      "Pick time": "Välj tid",
      "Pick the same date if it is just a single holiday":
          "Välj samma datum om det endast är en enkel helgdag",
      Pin: "Fäst",
      "Pin post": "Fäst anslag",
      "Pin side menu": "Fäst sidomeny",
      pinboard: "anslagstavla",
      Pinboard: "Anslagstavla",
      "Pinboard creation": "Skapa anslagstavla",
      "Pinboard edit": "Redigera anslagstavla ",
      "Pinboard text": "Anslagstext",
      Pinboards: "Anslagstavlor",
      Pink: null,
      Pinned: "Fäst",
      Phone: "Telefon",
      Plan: "Abonnemang",
      "Plan will be canceled from the date":
          "Abonnemanget kommer att sluta gälla från" /* Translate with param */,
      "Please fix all errors and try again":
          "Vänligen korrigera alla fel och försök igen",
      "Please let me finish the previously assigned task before you assign me a new one.":
          "Var snäll och låt mig avsluta den förra uppgiften innan du ger mig en ny.",
      "Please select a user": "Vänligen välj en annan användare",
      "Please select the language of the text in order to get the most accurate results":
          "Välj språk på texten för att få bäst resultat",
      "Please solve the errors before adding this question":
          "Lös felen innan du lägger till denna fråga.",
      "Please use any other browser for browsing our platform.":
          "Vänligen använd en annan webbläsare för att ta dig runt på vår plattform.",
      "Verify your email": "Bekräfta din e-postadress",
      "Please wait until the call owner lets you in":
          "Vänligen vänta tills du blir insläppt av samtalsägaren",
      Polish: "Polska",
      "Pool size": "Frågebankens omfång",
      Portuguese: "Portugisiska",
      Possibilities: "Möjligheter",
      Post: "Lägg upp",
      "Post comment": "Kommentera",
      "Post created": "Inlägg skapat",
      "Post removed": "Inlägg borttaget",
      "Post saved": "Inlägg sparat",
      Practiced: "Övat",
      "Practice again": "Öva igen",
      "Practice quiz": "Övningsquiz",
      "Pre-paid active learners number for this plan":
          "Antal aktiva elever som ingår i detta abonnemang",
      Premium: "Premium",
      Presentation: "Presentation",
      "Press enter to add new field, and backspace to remove":
          "Tryck enter för att lägga till ett nytt fält eller backstegstangenten för att radera",
      "Preview data": "Visa data",
      "Preview question": "Visa fråga",
      "Preview past feedback": "Visa tidigare återkoppling",
      Previous: "Förra",
      "Previous month": "Förra månaden",
      "Previous topic": "Förra ämnet",
      Proceed: "Fortsätt",
      Profile: "Profil",
      "Profile information": "Profilinformation",
      "Profile information change": "Redigera profilinformationen",
      Progress: "Framsteg",
      Progression: "Framsteg",
      "Provide more details": "Ange mer information",
      "Provided email and password do not match. Please try again.":
          "E-postadress och lösenord överensstämmer inte. Pröva på nytt.",
      Psychology: "Psykologi",
      "Pull the rows to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students":
          "Dra i ikonerna för att ändra ordningsföljden på apparna, och aktivera/inaktivera appar genom att sätta på den första knappen. Du kan även gömma appar för dina användare genom att ställa in knappen längst till höger.",
      Purple: null,
      "Put labels in the same group to make them interchangeable.":
          "Placera etiketter i samma kurs för att göra dem ömsesidigt utbytbara.",
      Quarter: "Kvart",
      Question: "Fråga",
      "Question & answers": "Frågor och svar",
      "Question and answers": "Frågor och svar",
      "Question feedback": "Återkoppling av frågor",
      "Question title": "Frågetitel",
      questionnaire: "frågeformulär",
      Questionnaire: "Frågeformulär",
      questionnaires: "frågeformulär",
      Questionnaires: "Frågeformulär",
      Questions: "Frågor",
      "Question bank": "Frågebank",
      "Question overview": "Frågeöversikt",
      "Question statistics": "Frågestatistik",
      "Question type": "Typ av fråga",
      "Questions from the bank": "Fråga från banken",
      "Questions in order": "Frågor i ordningsföljd",
      "Questions must have at least one correct answer and cannot be empty":
          "Frågorna måste ha minst ett korrekt svar och får inte vara tomma",
      Queue: "Kö",
      Quiz: "Quiz",
      quiz: "quiz",
      quiz_objects: "Quiz",
      "quiz-question": "quizfråga",
      quiz_question_objects: "Quizfråga",
      "Quiz completion percentage": "Quiz-genomförandegrad",
      "Quiz matrix": "Quiz-matrix",
      "Quiz mode": "Quiz-funktion",
      "Quiz name": "Quiz-namn",
      "Quiz question creation": "Skapa quizfråga",
      "Quiz question editing": "Redigera quizfråga",
      "Quiz question": "Quizfråga",
      "Quiz questions": "Quizfråga",
      "Quiz statistics": "Quiz-statistik",
      "Quiz status": "Quiz-status",
      "Quiz title": "Frågesportstitel",
      "Quiz type": "Typ av quiz",
      Quizzes: "Quiz",
      quizzes: "quiz",
      "Quizzes status": "Status på quiz",
      "Radio buttons": "Radioknappar",
      "raised a hand": "räcker upp handen",
      "Random pool size": "Antal slumpvisa frågor",
      "Random questions": "Slumpvisa frågor",
      React: "Reaktion",
      "Read messages": "Lästa meddelanden",
      "Read more": "Läs mer",
      "Reading time": "Lästid",
      "Recalculate assignment plan for user":
          "Räkna om uppgiftsplan för användare",
      "Recalculate assignment plans for group":
          "Räkna om uppgiftsplan för kurs",
      "Recalculate cloze options": "Räkna om textval",
      Reconnect: "Återupprätta förbindelse",
      "Refresh board": "Ladda om anslagstavla",
      "Refresh the page if the changes do not appear":
          "Ladda om sidan om inte ändringarna syns",
      Register: "Registrera",
      "Register handin": "Registrera inlämning",
      "Register handin manually": "Registrera inlämning manuellt",
      "Register student submission": "Registrera studentinlämning",
      Rejected: "Avvisad",
      "Reject submission": "Avvisa inlämning",
      Released: "Publicerad",
      "Release feedback": "Publicera återkoppling",
      Religion: "Religion",
      "Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.":
          "Kom ihåg att länka denna quiz till en uppgift i Maple TA efter att du skapat den. Annars kommer dina användare att mötas av en död länk. ",
      Remove: "Ta bort",
      "Remove admin": "Ta bort admin",
      "Remove answer": "Ta bort svar",
      "Remove assignment": "Ta bort uppgift",
      "Remove attachment": "Ta bort bifogad fil",
      "Remove audio": "Ta bort ljud",
      "Remove board": "Ta bort anslagstavla",
      "Remove collection": "Ta bort samling",
      "Remove course": "Ta bort modul",
      "Remove external link": "Ta bort extern länk",
      "Remove feedback": "Ta bort återkoppling",
      "Remove file": "Ta bort fil",
      "Remove flashcard": "Ta bort flashkort",
      "Remove flashcards": "Ta bort flashkort",
      "Remove folder": "Ta bort",
      "Remove from material": "Ta bort från från lektion",
      "Remove geogebra": "Ta bort Geogebra",
      "Remove Geogebra element": "Ta bort Geogebra-element",
      "Remove group": "Ta bort kurs",
      "Remove image": "Ta bort bild",
      "Remove match set": "Ta bort matchande set",
      "Remove material": "Ta bort lektion",
      "Remove member": "Ta bort medlem",
      "Remove label": "Ta bort etikett",
      "Remove learning element": "Ta bort lärresurs",
      "Remove picture": "Ta bort bild",
      "Remove post": "Ta bort anslag",
      "Remove subject": "Ta bort ämne",
      "Remove text": "Ta bort text",
      "Remove topic": "Ta bort underämne",
      "Remove question": "Ta bort fråga",
      "Remove questionnaire": "Ta bort frågeformulär",
      "Remove quiz": "Ta bort quiz",
      "Remove timeline": "Ta bort tidslinje",
      "Remove user": "Ta bort användare",
      "Remove user from the conversation": "Ta bort användare från samtal",
      "Remove vacation/holiday": "Ta bort lov/helgdag",
      "Remove video": "Ta bort video",
      "Remove vimeo": "Ta bort vimeo",
      "Remove your Facebook account": "Ta bort ditt Facebook-konto",
      "Remove your Google account": "Ta bort ditt Google-konto",
      "Remove youtube": "Ta bort Youtube",
      "Remove variable": "Ta bort variabel",
      "Remove word": "Ta bort ord",
      Rename: "Döp om",
      "Reorder elements": "Organisera om lärresurser",
      Repeatable: "Kan repeteras",
      "Repeatable quiz": "Quiz som kan repeteras",
      "Repeat email": "Skriv in e-postadress igen",
      "Repeat new password": "Skriv in nytt lösenord igen",
      "Repeat quiz": "Repetera quiz",
      "Repeat password": "Skriv in lösenord igen",
      "Replace picture": "Ersätt bilden",
      "replied to": "svarade",
      Replies: "Svar",
      "Reply to": "Svar till",
      "Reset password": "Återställ lösenord",
      "Reset completion deadlines": "Återställ deadlines för genomförande",
      Required: "Obligatoriskt",
      "Required pass percentage": "Obligatorisk godkäntnivå",
      "Requires at least": "Kräven minst",
      Result: "Resultat",
      Results: "Resultat",
      Retry: "Pröva på nytt",
      "Retry payment": "Testa att betala igen",
      "Return to dashboard": "Tillbaka till dashboard",
      "Review results": "Granska resultatet",
      Role: "Roll",
      Romanian: "Rumänska",
      Russian: "Ryska",
      Save: "Spara",
      "Save (discount)": "Spara",
      "Save and attach to feedback": "Spara och bifoga till återkoppling",
      "Save and release feedback": "Spara och publicera återkoppling",
      "Save and show": "Spara och visa",
      "Save assignment": "Spara uppgift",
      "Save audio file": "Spara ljudfil",
      "Save changes": "Spara ändringar",
      "Save collection": "Spara samling",
      "Save external link": "Spara extern länk",
      "Save feedback": "Spara återkoppling",
      "Save file": "Spara fil",
      "Save flashcards collection": "Spara flashcards",
      "Save geogebra": "Spara geogebra",
      "Save image": "Spara bild",
      "Save in filesystem": "Spara i fildelning",
      "Save order": "Spara ordningsföljd",
      "Save question and show": "Spara fråga och visa",
      "Save question": "Spara fråga",
      "Save questionnaire": "Spara frågeformulär",
      "Save quiz": "Spara quiz",
      "Save quiz and show": "Spara quiz och visa",
      "Save video": "Spara video",
      "Save Vimeo video": "Spara Vimeo-klipp",
      "Save payment details": "Spara betalningsinformation",
      "Save subject": "Spara ämne",
      "Save submission": "Spara inlämning",
      "Save text": "Spara text",
      "Save timeline": "Spara tidslinjen",
      "Save to": "Spara til",
      "Save to root folder": "Spara i rotkatalogen",
      "Save topic": "Spara underämne",
      "Save widget": "Spara widget",
      "Save your quiz": "Spara din frågesport",
      "Save youtube video": "Spara Youtube-klipp",
      Science: "Naturvetenskap",
      School: "Skola",
      Search: "Sök",
      "Search all students": "Sök alla elever",
      "Search counsellor": "Sök vägledare",
      "Search for users, groups..": "Sök efter kurser, användare..",
      "Search group": "Sök kurs",
      "Search members": "Sök medlemmar",
      "Search person": "Sök person",
      "Search question": "Sök fråga",
      "Search results": "Sökeresultat",
      "Search student": "Sök studerende",
      "Search students in the class": "Sök endast på elver i kursen",
      "Search user": "Sök användare",
      "Search youtube": "Sök Youtube",
      sec: "sek",
      "Security warning": "Säkerhetsvarning",
      "See all": "Se alla",
      "See all news": "Se alla nyheter",
      "See members on plan": "Se medlemmer i abonnemanget",
      "See past feedback": "Se tidigare återkoppling",
      Seen: "Sett",
      "Seen by all": "Sett aav alla",
      "Seen by student": "Sett av studerende",
      "Select activity type": "Välj resurstyp",
      "Select a folder": "Välj en mapp",
      "Select a media type that you want to create":
          "Välj vilken medietyp du vill skapa",
      "Select a month": "Välj en månad",
      "Select a question type": "Välj en frågetyp",
      "Select a widget type you want to add":
          "Välj den typ av widget du vill lägga till",
      "Select a year": "Välj ett år",
      "Select attachments": "Välj filer",
      "Select file type": "Välj filtyper",
      "Select from list": "Välj från lista",
      "Select submission type": "Välj inlämningstyp",
      "Select subscription": "Välj abonnemang",
      "Select plan": "Välj abonnemang",
      "Select tags": "Välj ämnen",
      "Select the language of the source": "Välj språk på källan",
      "Select the learning activities you want to create from the text":
          "Välj de lärresurser du vill skapa utifrån texten",
      "Selected class": "Valda kurser",
      "Selected counsellor's students": "Valda vägledares studenter",
      "Selected groups": "Valda kurser",
      "Selected members": "Valda medlemmar",
      Send: "Skicka",
      "Send new password": null,
      "Skicka a message to start the chat":
          "Skicka ett meddelande för att starta chatten",
      "Skicka copy to colleagues": "Skicka kopia till kolleger",
      "Skicka element to these colleagues?":
          "Skicka element till dessa kolleger?",
      "Skicka element to this colleague?":
          "Skicka element till denna kollega?",
      "Skicka Invites": "Skicka inbjudningar",
      "Skicka message": "Skicka meddelande",
      "Skicka messages": "Skicka meddelanden",
      "Skicka unreleased feedback": "Skicka opublicerad återkoppling",
      Sentence: "Mening",
      Serbian: "Serbiska",
      "Serbo-Croatian": "Serbokroatiska",
      "Set or change an individual due date":
          "Ange eller ändra ett enskilt förfallodatum",
      Setting: "Inställning",
      Settings: "Inställningar",
      "Settings saved": "Inställningar sparade",
      Setup: "Inställningar",
      "Sign up": "Registrera dig",
      "Sign up as user type":
          "Skapa användare som {userType}" /* Translate with param */,
      "Sign up with email": "Registrera dig med e-postadress",
      "Sign up with Facebook": "Registrera dig med Facebook",
      "Sign up with Google": "Registrera dig med Google",
      "Sign up with Unilogin": "Registrera dig med Unilogin",
      "Sign up with Microsoft": "Registrera dig med Microsoft",
      "SimpleMath multiple choice": "SimpleMath multiple choice",
      "Simplemath settings": "Simplemath-inställningar",
      "SimpleMath settings": "SimpleMath-inställningar",
      "SimpleMath short answer": "SimpleMath-kortsvar",
      "simple-math-short-answer": "SimpleMath-kortsvar",
      "simple-math-multiple-choice": "SimpleMath multiple choice",
      Size: "Storlek",
      Share: "Dela",
      "Share class": "Dela kurs",
      "Share course": "Dela modul",
      "Share element": "Dela resurs",
      "Share file": "Del fil",
      "Share folder": "Dela mapp",
      "Share material": "Dela lektion",
      "Share screen": "Dela skärm",
      "Share with colleagues": "Dela med kolleger",
      shared: "Delat",
      "shared the assignment": "delade uppgiften",
      "shared the course": "delade modulen",
      "shared the flashcard": "delade flashkortet",
      "shared the flashcard collection": "delade flashkortssamlingen",
      "shared the folder": "delade mappen",
      "shared the geogebra": "delade Geogebra-resursen",
      "shared the group": "delade kursen",
      "shared the lesson": "delade lektionen",
      "shared the link": "delade länken",
      "shared the media": "delade mediet",
      "shared the text": "delade texten",
      "shared the question": "delade frågan",
      "shared the questionnaire": "delade frågeformuläret",
      "shared the quiz": "delade quizen",
      "shared the timeline": "delade tidslinjen",
      "Shared with me": "delade med mig",
      "Short answer": "Kortsvar",
      "Short answer settings": "Kortsvarsinställningar",
      "short-answer": "Kortsvar",
      "short-answer-wiris": "Wiris kortsvar",
      Show: "Visa",
      "Show all": "Visa alla",
      "Show element": "Visa resurs",
      "Show info": "Visa info",
      "Show less": "Visa mindre",
      "Show more": "Visa mer",
      "Skip first line": "Hoppa över första linjen",
      "Skip over elements that have already been copied":
          "Hoppa över resurser som redan kopierats",
      Slovak: "Slovakiska",
      Socialstudies: "Samhällskunskap",
      "Some elements have already been copied to these groups.":
          "Några resurser har redan kopierats till de här kurserna",
      "Some elements have already been copied to this group.":
          "Några resurser har redan kopierats till den här kursen",
      "Some of the questions failed to be copied":
          "Några av frågorna kunde inte kopieras.",
      "Sorry, we can not connect your account. Please try again later.":
          "Tyvärr kan vi inte länka ditt konto just nu. Försök igen senare.",
      "Sorry, but you do not have permission to access this page":
          "Tyvärr har du inte tillgång till denna sida.",
      Source: "Källa",
      "Source successfully uploaded": "Källan uppladdad utan problem",
      "Sorry to see you go": "Tråkigt att du lämnar oss",
      Spanish: "Spanska",
      "Spanish Latin American": "Spanska (Latinamerika)",
      Speakers: "Högtalare",
      "Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content.":
          "Ange denna frågas svårighetsgrad. Det är din subjektiva värdering om du jämför med resten av innehållet på denna nivå.",
      Spell: "Stava",
      "spell-speech": "Stava ord",
      "Spell word": "Stava ord",
      Standard: "Standard",
      Start: "Starta",
      "Start broadcasting": "Börja sända video",
      "Start by building your content with courses":
          "Sätt igång och strukturera dina moduler med innehåll",
      "Start by adding learning elements listed on the left by pressing a plus button or dragging and dropping them below.":
          "Börja med att lägga till lärresurser från listan till vänster genom att klicka på pluset, eller dra dem och släpp dem nedan. ",
      "Start exam mode": "Starta provläge",
      "Start making great learning content, by verifying your email.":
          "Verifiera din e-postadress och börja med att sätta ihop fantastiska lärresurser!",
      "Start new discussion": "Starta ny diskussion",
      "Start quiz": "Starta quiz",
      "Start reading": "Starta uppläsning",
      "Start video chat": "Starta videochatt",
      Statistics: "Statistik",
      Status: "Status",
      "STEM questions": "Frågor om STEM",
      "Stop broadcasting": "Avbryt videosändning",
      "Stop exam mode": "Avbryt provläge",
      "Stop reading": "Avbryt uppläsning",
      "Stop sharing": "Avbryt delning",
      "Structure course content using lessons":
          "Strukturera ditt modulinnehåll med lektioner",
      Student: "Studerende",
      student: "studerende",
      "Student submission registered by teacher":
          "Studentens inlämning registreras av föreläsaren",
      students: "elever",
      Students: "Elever",
      "Student administration": "Administration",
      "Student handed in offline": "Studenten har lämnat in offline",
      "Student has not handed in this submission yet.":
          "Studenten har ännu inte lämnat in denna uppgift.",
      "Student hours": "Studenttid",
      "Student hours help indicate to your students how much time they should spend on the submission":
          "Studenttid hjälper dina elever att få en uppfattning om hur mycket tid de ska lägga på en inlämning",
      "Student is not part of any class yet":
          "Studenten ingår ännu inte i någon kurs",
      "Students can add flashcards": "Studerande kan lägga till flashkort",
      "Students can see results": "Studerande kan se resultat",
      "Students list": "Studentlista",
      "Student / student average": "Elev / studentmedelvärde",
      "Student submission will be registered when the feedback is saved":
          "Studentens inlämning registreras när feedbacken sparas.",
      "Student Testing": "Studenttestning",
      "Student view": "Studentvy",
      Subgroups: "Undergrupper",
      Subject: "Ämne",
      Subjects: "Ämnen",
      Submission: "Inlämning",
      submission: "inlämning",
      "Submission example": "Exempel på inlämning",
      "Submission creation": "Inlämning skapad",
      "Submission information": "Inlämningsinformation",
      "Submission missing feedback": "Inlämningar som saknar återkoppling",
      "Submission plan": "Inlämningsplan",
      "Submission title": "Inlämningstitel",
      "Submission type": "Inlämningstyp",
      Submissions: "Inlämningar",
      Submit: "Skicka in",
      "Submit answer & continue": "Skicka in svar och fortsätt",
      "Submit questionnaire": "Lämna in frågeformulär",
      Submitted: "Inlämnad",
      Subscription: "Abonnemang",
      "Subscription type": "Abonnemangstyp",
      Success: "Framgång",
      "Support mail": "Support e-post",
      "Sure, lets go!": "Absolut, då kör vi!",
      Surname: "Efternamn",
      Swahili: "Swahili",
      "Swap out": "Byt ut",
      Swedish: "Svenska",
      Switch: "Byt",
      "Switch additional group": "Byt sekundärgrupp",
      "Switch back to main user": "Byt tillbaka till ursprunglig användare",
      "Switch devices now to start adding learning activities into your lessons.":
          "Byt enhet nu för att kunna lägga till lärresurser till dina lektioner.",
      "Switch main group": "Byt huvudgrupp",
      "Switch to add these activities:":
          "Byt för att lägga till dessa resurser:",
      Sync: "Synkronisera",
      "Synchronize elements that have already been copied":
          "Synkronisera resurser som redan kopierats",
      "Synchronize hashtags manually": "Synkronisera hashtaggar manuellt",
      Tags: "Taggar",
      Tamil: "Tamilska",
      teacher: "lärare",
      Teacher: "Lärare",
      Teachers: "Lärare",
      "Teacher view": "Lärarvy",
      "Teachers on plan": "Lärare på plan",
      Term: "Uttryck",
      "Terms & definitions": "Villkor och definitioner",
      "Terms and Conditions": "Användningsvillkor",
      Test: "Test",
      "Test answer": "Testsvar",
      text: "text",
      Text: "Text",
      "Text can't be empty and should be shorter then 255 characters.":
          "Texten kan inte vara tom och ska vara längre än 255 tecken.",
      "Text copied": "Texten kopierad",
      "Text on label": "Text på etikett",
      "Text title": "Texttitel",
      Time: "Tid",
      timeline: "tidslinje",
      Timeline: "Tidslinje",
      "Time in HH:MM:SS": "Tid i TT:MM:SS",
      "Time to complete course (days)":
          "Tid kvar för att genomföra modulen (dage)",
      Timelines: "Tidslinjer",
      Timestamp: "Tidsmarkering",
      Tip: "Tips",
      Title: "Titel",
      "Title required": "Titel obligatorisk",
      "Title too long": "Titeln är för lång",
      Thai: "Thailändska",
      "Thanks for your answer": "Tack för ditt svar",
      "Thank you for your interest in Edaptio.":
          "Tack för ditt intresse för Edaptio.",
      "Thank you for your understanding": "Tack för din förståelse",
      "That was all I got for you this time":
          "Det var allt jag hade till dig den här gången",
      "The amount of random questions each learner receives":
          "Antalet slumpmässiga frågor som varje elev får.",
      "The assignment is closed for handin":
          "Uppgiften är låst för inlämning",
      "The call owner denied your request to join":
          "Videosamtalets ägare avvisade din ansökan om att gå med",
      "The corrections have been attached to the feedback":
          "Rättningen har bifogats din återkoppling",
      "The elements have been added to the existing learning activity":
          "Komponenterna har lagts till den befintliga lärresursen",
      "The Geogebra element has been created":
          "Geogebra-resursen har skapats",
      "The Geogebra element has been saved": "Geogebra-resursen har sparats",
      "The group is currently in exam mode. All communication suspended.":
          "Gruppen befinner sig för närvarande i provläge. All kommunikation är avstängd.",
      "The question has been created": "Frågan har skapats",
      "The question has been saved": "Frågan har sparats",
      "The quiz has been created": "Quizen har skapats",
      "The quiz has been saved": "Quizen har sparats",
      "The message was succesfully deleted": "Meddelandet har nu raderats",
      "The price is too high": "Det kostar för mycket",
      "The system cannot auto-correct open answer questions, but your teacher can see your answer.":
          "Systemet kan inte rätta öppna frågor automatiskt, men din lärare kan se ditt svar.",
      "The system rejected the request with the following message":
          "Systemet avvisade begäran med följande meddelande",
      "The user has received an email with all the necessary information to get started":
          "Användaren har mottagit ett mejl med all relevant information för att komma igång.",
      "The user will write an open ended text here":
          "Användaren vill skriva ett öppet svar här",
      "There are no answers posted. Add a first answer to this post.":
          "Ingen har ännu svarat på detta anslag. Bli den första att svara.",
      "There are no existing data to display. Start by creating one direct":
          "Det finns inga {name} att visa. Skapa en genom att välja den i sidomenyn",
      "There are no items to display": "Det finns inget att visa här",
      "There are no learning elements in this category to display. Start by creating one":
          "Det finns inga lärresurser att visa i denna kategori. Kanske du kan skapa en!",
      "There are no lessons to display. Start by creating one":
          "Inga lektioner hittades. Börja med att skapa en",
      "There are no consultations created. Start by creating one":
          "Du har inte skapat någon konsultation än. Sätt igång genom att skapa en",
      "There are no submissions to display. Start by creating one":
          "Det finns inga inlämningar att visa. Sätt igång genom att skapa en ny",
      "There are unsaved changes": "Det finns ändringar som inte sparats",
      "There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.":
          "För närvarande finns det ett fel med WIRIS-insticksprogrammet och vi har därför tagit bort det. Vi arbetar hårt med att lösa problemet.",
      "This can take up to a few minutes, so feel free to grab a cup of coffee or practice your juggling skills while you wait":
          "Det här kan ta några minuter, så varför inte passa på att ta en kopp kaffe eller öva lite jonglering under tiden?",
      "This course is a reference from another group, and can therefore not be edited here.":
          "Denna modul är en referens till en annan plats och kan därför inte rättas här.",
      "This course is depending on the other courses. Students can not start it until they complete the other.":
          "Denna modul bygger på andra moduler. Användare kan inte påbörja denna modul innan de avslutat de andra modulerna.",
      "This course has been copied to this group before.":
          "Denna modul har kopierats till gurppen tidigare.",
      "This element has been copied to this group before.":
          "Detta element har kopierats till gurppen tidigare.",
      "This lesson has been copied to this group before.":
          "Denna lektion har kopierats till gurppen tidigare.",
      "This group does not have a class notebook yet. Contact your teacher in order to fix this.":
          "Denna kurs har ännu inte någon kursanteckningsbok. Kontakta din lärare för att fixa det.",
      "this guide": "denna guide",
      "This is a beta feature, and errors can occur":
          "Detta är en betafunktion, och fel kan uppstå.",
      "This is a collection of all the videos from your courses":
          "Det här är en samling av alla videoklipp från din modul",
      "This is the number of learners not covered in your pre-paid plan. These will be charged separately according to our pricing plan.":
          "Detta är antalet användare som inte ingår i ditt förbetalda abonnemang. Detta antal kommer att faktureras seperat enligt vår tariff.",
      "This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.":
          "Det här är en fråha med 'öppet svar'. Det betyder att du ska reflektera och svara med dina egna ord, och för att klara av frågan måste du skriva något.",
      "This is an overview of the students progression through the videos in the courses/materials.":
          "Det här är en överblick över användarens framsteg genom videoklippen i modulerna/lektionerna.",
      "This lesson is a reference from another group, and can therefore not be edited here.":
          "Denna lektion är en referens till en annan plats, och kan därför inte rättas här.",
      "This lesson is empty.": "Denna lektion är tom.",
      "This message was deleted": "Meddelandet har raderats",
      "This object is empty": "Denna {} är tom" /* Translate with param */,
      "This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.":
          "Denna typ av quiz kräver en aktiv licens till Maple TA:s programvara, som du själv eler din institution är ansvarig för att införskaffa.",
      "This widget imports all of your courses automatically":
          "Denna widget importerar alla dina moduler automatiskt",
      "This will hold news from your classes":
          "Här kommer det nyheter från dina kurser",
      "Three quarter": "Trekvart",
      to: "till",
      To: "Till",
      Today: "Idag",
      "Toggle fullscreen mode": "Gå till helskärm",
      Top: "Början",
      Topic: "Ämne",
      Topics: "Ämnen",
      "to join your class.": "för att gå med i kursen",
      "To top": "Till början",
      Total: "Totalt",
      "Toggle fullscreen": "Skifta helskärm",
      "Total number of assignment turnins":
          "Totalt antal uppgiftsinlämningar",
      Trainable: "Möjligt att öva på",
      "Train mathematics": "Öva på matematik",
      Translate: "Översätt",
      "Translate word": "Översätt ord",
      Tries: "Testar",
      Try: "Testa",
      "Try again": "Testa igen",
      "Try Edaptio": "Testa Edaptio",
      "Try now": "Testa nu",
      "Try to adjust the text": "Försök att anpassa texten",
      Turkish: "Turkiska",
      "Turnin overview": "Inlämningsöversikt",
      "Turn in quiz": "Aflever quiz",
      "Turn on/off exam mode": "Stäng av eller sätt på provläge",
      "Turn video off": "Stäng av video",
      "Turn video on": "Sätt på videon",
      Type: "Skriv",
      "Type in a definition": "Skriv in en definition",
      "Type in a question": "Skriv in en fråga",
      "Type in a term": "Skriv in ett uttryck",
      "Type in an answer": "Skriv in ett svar",
      "Type in your comment": "Skriv in din kommentar",
      "Type in your message": "Skriv in ditt meddelande",
      "Type of billing": "Faktureringstyp",
      "Type something": "Skriv något",
      "UK English": "Engelska (UK)",
      UMS: "UMS",
      "UMS group": "UMS-kurs",
      Unarchive: "Ta bort ur arkivet",
      "Unarchive group": "Ta bort kurs ur arkivet",
      "Unarchive for all": "Avarkivera för alla",
      "Unarchive for you": "Avarkivera för dig själv",
      Unassign: "Ta bort tilldelning",
      Unilogin: "Unilogin",
      "Unilogin username": "Unilogin-användarenavn",
      Unknown: "Okänd",
      "Unlimited active learners": "Obegränsat antal aktiva användare",
      "Unlimited classes, courses & lessons":
          "Obegränsat med kurser, moduler och lektioner",
      "Unlimited co-teachers": "Obegränsat antal medlärare",
      "Unlimited storage": "Obegränsat med lagring",
      "Unlock board": "Lås upp anslagstavla",
      "Unlock board item": "Lås upp anslag",
      Unmute: "Sätt på ljud",
      Unmuted: "Mic på",
      Unpin: "Ta bort nål från",
      "Unpin post": "Ta bort nål från anslag",
      "Unpin side menu": "Gör sidomenyn flytande",
      "Unread messages": "Olästa meddelanden",
      Unsubscribe: "Avsluta",
      "Up to 100 active learners": "Upp till 100 aktiva studerande",
      "Up to 250 active learners": "Upp till 250 aktiva studerande",
      "Upcoming consultations": "Kommande konsultationer",
      "Update profile picture": "Uppdatera profilbild",
      "Update and log in": "Uppdatera och logga in",
      "updated the assignment": "uppdaterade inlämningen",
      Upgrade: "Uppgradera",
      "Upgrade plan": "Uppgradera abonnemang",
      "Upgrade your plan to increase the storage space":
          "Uppgradera ditt abonnemang för att få mer lagringskapacitet",
      Upload: "Ladda upp",
      "Upload an example submission that yours students will see after they receive your feedback for the submission":
          "Ladda upp ett exempel på ett svar som dina elever automatiskt får se efter att du gett dem återkoppling",
      "Upload feedback": "Ladda upp återkoppling",
      "Upload new file": "Ladda upp ny fil",
      Uploads: "Ladda upp",
      "Upper border": "Övre gräns",
      "Use 0 if you want to disable it":
          "Använd 0 om du vill stänga av funktionen",
      "Use in group": "Använd i kursen",
      "Use course in group": "Använd modul i kursen",
      "Use hashtags before the words in the text that need to be filled in.":
          "Använd hashtaggar {insertion} före de ord i texten som ska fyllas i." /* Translate with param */,
      "Use hashtags to indicate word insertions":
          "Använd hashtaggar för att visa var ord kan sättas in.",
      "Use learning element in group": "Använd lärresurser i kursen",
      "Use lessons to divide your courses into smaller bites, that are easier for users to digest. Lessons are packages of learning elements that provide context to the learning the user has to obtain.":
          "Använd lektioner för att dela upp dina moduler i mindre, mer lättsmälta delar. Lektionerna är paket med lärresurser som ger ett sammanhang till den kunskap som användaren ska lära sig.",
      "Use material in group": "Använd lektion i kursen",
      "Use the arrow keys to switch and flip the cards":
          "Använd piltangenterna på keyboarden för att byta och vända på flashkort",
      "Use the feedback, improve your assignment and hand in again":
          "Använd återkopplingen, finputsa uppgiften och lämna in på nytt.",
      "Use this in a group": "Använd detta i en kurs",
      User: "Användare",
      "User assignments behind": "Användare som ligger efter med inlämningar",
      "User assignment progression throughout courses":
          "Användarens framsteg i olika moduler",
      "User created": "Användare skapad",
      "User creation": "Användare skapad",
      "User is presented with a single random word":
          "Användaren får se ett slumpmässigt ord",
      "User is presented with all words": "Användaren får se alla ord",
      "User name": "Användarnamn",
      "User progression": "Användarframsteg",
      "User quiz progression": "Användarens framsteg i quiz",
      "User deleted": "Användare borttagen",
      "User saved": "Användare sparad",
      "User was removed from conversation":
          "{user_name} togs bort från samtalet {conversation_name}" /* Translate with params like in en_US file */,
      Users: "Användare",
      "Users not yet started": "Användare som ännu inte startat",
      "Users under average completion":
          "Användare som ligger under genomsnittet för kursen",
      Username: "Användarnamn",
      "US English": "Engelska (US)",
      Validating: "Validerar",
      Value: "Värde",
      "video-conference": "Videohjälp",
      Video: "Video",
      "Video chat": "Videochatt",
      "Video chat does not exist or it's already finished.":
          "Videochatten hittades inte.",
      "Video conferencing & messaging": "Videomöten och meddelanden",
      "Video ID/link": "Video ID/link",
      "Video id required": "Video-id krävs",
      "Video is off": "Video av",
      "Video is on": "Video på",
      videos: "Videoklipp",
      "Video preview": "Förhandsvisning av video",
      "Video quiz": "Videoquiz",
      Vietnamese: "Vietnamesiska",
      View: "Visa",
      "Viewing as a student": "Visa som elev",
      "Viewing as a teacher": "Visa som lärare",
      "View messages": "Visa meddelanden",
      Vimeo: "Vimeo",
      "Vimeo video ID/Link": "Vimeo-video ID/länk",
      "Visible to students": "Synlig för användare",
      "Visual arts": "Bildkonst",
      "Voice gender": "Kön på rösten",
      "wants to join": "vill gärna bli insläppt",
      "wants to join video chat": "vill gärna delta i videochatten",
      webinars: "webinarium",
      weekdays: {
          Sunday: "Söndag",
          Monday: "Måndag",
          Tuesday: "Tisdag",
          Wednesday: "Onsdag",
          Thursday: "Torsdag",
          Friday: "Fredag",
          Saturday: "Lördag"
      },
      "Weekdays-full-array": [
          "Söndag",
          "Måndag",
          "Tisdag",
          "Onsdag",
          "Torsdag",
          "Fredag",
          "Lördag"
      ],
      "Weekdays-short-array": [
          "Sön",
          "Mån",
          "Tis",
          "Ons",
          "Tors",
          "Fre",
          "Lör"
      ],
      Welsh: "Kymriska",
      "We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.":
          "Vi håller på att förbereda din fil för att bli inbäddad. Ladda om sidan om ett litet tag för att se resultatet. Detta bör inte ta mer än 2 minuter.",
      "We can not find any data to display. Wait for your teacher to add some.":
          "Vi hittar inte någon {insertion} att visa. Invänta att läraren lägger till det." /* Translate with param like in en_US file */,
      "We can not find any data to display. Wait for your teacher to create submission.":
          "Vi hittar inte någon {insertion} att visa. Invänta att läraren lägger till det." /* Translate with param like in en_US file */,
      "We can not find any data to display. Wait for your teacher to upload some.":
          "Vi hittar inte någon {insertion} att visa. Invänta att läraren laddar upp det." /* Translate with param like in en_US file */,
      "We can not find any lesson in course. Wait for your teacher to add some.":
          "Vi kunde inte hitta några lektioner i modulen '{insertion}'. Vänta på att din lärare skapar dem." /* Translate with param */,
      "We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.":
          "Vi ser att du använder Internet Explorer som webbläsare. Det är en gammal och osäker webbläsare som vi har valt att inte längre stödja. ",
      "We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.":
          "Vi har skickat ett mejl till e-postadressen, om vi har den i systemet. Kolla gärna skräppostfiltret om du inte hittar mejlet.",
      "We have sent you a temporary password via email":
          "Vi har skickat ett temporärt lösenord via e-post",
      "We sent a verification link to":
          "Vi har skickat en verifieringslänk till {email}",
      "Welcome to": "Välkommen till",
      "What are you?": "Vad är du?",
      "What do you want to create?": "Vad vill du skapa?",
      "What educational level?": "Vilken utbildningsnivå?",
      "What media should the users turn in?":
          "Vilken typ av media ska användarna lämna in?",
      "What skills level?": "På vilken nivå?",
      "What type of assignment?": "Vilken typ av uppgift?",
      "What would you like to learn about?": "Vad vill du lära dig mer om",
      "When is the assignment due?": "När ska uppgiften lämnas in?",
      "White labeling": "White labeling",
      "Who are you?": "Vem är du?",
      "WIRIS match": "WIRIS-matching",
      "WIRIS multiple choice": "WIRIS multiple choice",
      "WIRIS short answer": "WIRIS kortsvar",
      "Write your answer": "Skriv ett svar",
      "Write comment": "Skriv en kommentar",
      "Write description": "Skriv en beskrivning",
      "Write here": "Skriv här",
      "Write message": "Skriv ett meddelande",
      "Write the code from the marked area below in the text field, to confirm deletion":
          "Skriv in koden från det markerade området i textfältet för att bekräfta borttagningen",
      "Write what you hear": "Skriv vad du hör",
      "Wrong email": "Fel e-postadress",
      "Wrong username/password": "Fel användarnamn/lösenord",
      "Year/date/title": "År/datum/titel",
      Yearly: "Årlig",
      "yearly total": "årligen totalt",
      "Yearly payment": "Årlig betalning",
      Yes: "Ja",
      "Yes, archive this group": "Ja, arkivera denna kurs",
      "Yes, delete this assignment": "Ja, radera denna uppgift",
      "Yes, delete this collection": "Ja, radera denna samling",
      "Yes, delete this feedback": "Ja, radera detta återkoppling",
      "Yes, delete this file": "Ja, radera denna fil",
      "Yes, delete this folder": "Ja, radera denna mapp",
      "Yes, delete this Geogebra element": "Ja, radera denna Geogebra-resurs",
      "Yes, delete this group": "Ja, radera denna kurs",
      "Yes, delete this group information": "Ja, radera denna information",
      "Yes, delete this post": "Ja, radera detta anslag",
      "Yes, delete this quiz": "Ja, radera denna quiz",
      "Yes, delete this question": "Ja, radera denna fråga",
      "Yes, delete this user": "Ja, radera denna användare",
      "Yes, everyone can use this": "Ja, alla får använda den här",
      "Yes, turn in this questionnaire": "Ja, lämna in frågeformuläret",
      "Yes, turn in this quiz": "Ja, lämna in quizen",
      "Yes, remove this course": "Ja, ta bort denna modul",
      "Yes, remove this learning element": "Ja, ta bort denna lärresurs",
      "Yes, remove this material": "Ja, ta bort denna lektion",
      "Yes, remove this member": "Ja, ta bort denna medlem",
      "Yes, start video call": "Ja, starta videosamtal",
      Yesterday: "Igår",
      You: "Du",
      "Your assignment plan": "Din inlämningsplan",
      "Your assignment turnin": "Din uppgiftsinlämning",
      "Your classes": "Dina kurser",
      "Your class has multiple sub-groups. Choose which one to view for.":
          "Din kurs har en eller flera underkurser. Välj vilken du vill se nedan.",
      "Your files have been uploaded and matched.":
          "Dina filer har laddats upp och matchats med återkoppling.",
      "Your plan": "Ditt abonnemang",
      "Your streak": "Din streak",
      "Your subscription payment failed.": "Din betalning misslyckades",
      "Your subscription plan": "Ditt abonnemang",
      Youtube: "Youtube",
      "Youtube video": "Youtube-video",
      "YouTube video ID": "YouTube-video-ID",
      "You answered correct": "Du svarade korrekt",
      "You are currently in exam mode.":
          "Du befinner dig för närvarande i {insertion}",
      "You are currently in exam mode. All communication suspended.":
          "Du befinner dig för närvarande i provläge. All kommnikation har stängts av.",
      "You are in a waiting room": "Du befinner dig i väntrummet",
      "You are in training mode. Your answers are not recorded, and you get instant återkoppling. Start the quiz to complete it.":
          "Du är i quizens övningsdel och dina svar sparas inte. Starta quizen för att fullföra den.",
      "You are subscribing to": "Du abonnerar nu på",
      "You are up!": "Det är din tur!",
      "You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.":
          "Du användwe Internet Explorer för att nå vår sajt. Vi stöder inte denna webbläsare och delar av sidan kan möjligtvis inte fungera som de ska. Vi rekommenderar att du byter till vilken som helst annan webbläsare.",
      "You can find the course in your group": "Du hittar modulen i din kurs",
      "You can find the element on the material":
          "Du hittar lärresursen i lektionen",
      "You can find the material on the course":
          "Du hittar lektionen i modulen",
      "You can not access your assignments here in this group. This will serve as an overview only.":
          "Du har inte tillgång till dina inlämningar på det här sättet i den här kursen. Detta ger dig bara en överblick.",
      "You can not enter the program this way.":
          "Du kan inte ta dig in i programmet på det här sättet.",
      "You can not have a larger question pool than number of questions attached":
          "Du kan inte ha en större frågebank än antalet frågor som bifogats",
      "You can't join this call": "Du kan inte gå med i det här samtalet",
      "You can only add admins here": "Du får bara lägga till lärare här",
      "You can reject the assignment and force the student to hand in again":
          "Du kan avvisa inlämningen och be studenten att göra en ny inlämning",
      "You can see this guide to get started":
          "Titta på den här guiden för att komma igång",
      "You did not choose all the correct answers":
          "Du har inte valt alla korrekta svar",
      "You do not have much time left for this assignment":
          "Du måste snart lämna in den här uppgiften",
      "You have a good amount of time left for this assignment":
          "Du har bra med dit kvar till den här uppgiften",
      "You have already handed this assignment in.":
          "Du har redan lämnat in den här uppgiften.",
      "You have answered all the questions in this topic. Keep training or go to the next topic.":
          "Du har svaret på alla frågor i detta underämne. Fortsätt öva eller gå till nästa underämne.",
      "You have assigned duplicate rows to represent the same value":
          "Du har tilldelat flera rader samma värde",
      "You have been invited to this class via email":
          "Du har bjudits in till denna modul via e-post",
      "You have created a new course": "Du har skapat en ny modul",
      "You have created a new lesson": "Du har skapat en ny lektion",
      "You have finished this quiz! You got percent correct.":
          "Du är klar med quizet! Du fick {insertion} rätt.",
      "You have missed the due date, or the assignment has been rejected":
          "Du missade deadline för denna inlämning, eller så accepterades inte inlämningen.",
      "You have no messages yet, create your first message to talk to your friends":
          "Du har inga meddelanden än. Skapa ditt första meddelande för att kommunicera med dina vänner",
      "You have not yet completed the course(s) on which this course depends on":
          "Du är ännu inte klar med de moduler som den här modulen bygger på",
      "You have run out of storage":
          "Du har nått gränsen för din lagringskapacitet",
      "You have to have atleast one question for your quiz":
          "Ditt quiz behöver minst en fråga",
      "You must add either a group or a person to the conversation":
          "Du behöver lägga till minst en person eller kurs till samtalet",
      "You must choose minimum one answer": "Du ska välja minst ett svar",
      "You must choose more than one answer": "Du måste välja flera svar",
      "You must choose only one answer": "Du får bara välja ett svar här",
      "You only need to choose one answer":
          "Du behöver bara välja ett svar här",
      "You will automatically be added as admin in the group upon creation":
          "Du kommer automatiskt att läggas till som lärare i kursen när den skapas",
      "You will need a computer for this part to work smoothly!":
          "Du behöver en dator för att kunna arbeta problemfritt med denna del!",
      "Your answer": "Ditt svar",
      "Your answer(s)": "Dina svar",
      "Your answer percentage": "Din svarsprocent",
      "Your answer was not correct": "Ditt svar var inte korrekt",
      "Your class library is empty, create your first class and start using Learnu":
          "Du har inga kurser på LearnU än. Skapa din första kurs för att börja använda LearnU",
      "Your groups": "Dina kurser",
      "Your level": "Din nivå",
      "Your nickname": "Ditt smeknamn",
      "Your submission": "Din uppgiftsinlämning",
      "Your token is not valid or expired. Try again.":
          "Din länk är inte godkänd eller så har den inte längre giltig. Försök igen.",
      "Youtube search": "Youtube-sökning",
      "Want more features and discounts? Request a quote for your entire school or organization today.":
          "Vill du ha fler funktioner och rabatt? Kontakta oss idag för ett skräddarsytt abonnemang till din organisation.",
      "was archived by": "arkiverades av",
      "Waiting list": "Väntelista",
      "Waiting for others to arrive": "Väntar på att fler anländer",
      "Watch out": "Se upp",
      "We can not find any course in class. Your teacher should add some courses soon.":
          "Vi kunde inte hitta någon modul i kursen {className} än. Invänta att din lärare lägger till er modul." /* Set param like in en_US */,
      "We can not find any *** to display. Wait for your teacher to create ***.":
          "Vi kunde inte hitta någon {learningElement} än. Invänta att din lärare lägger in {learningElement}." /* Set param like in en_US */,
      "We could not find matching information to the typed search phrase": null,
      "We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.":
          "Vi kunde inte logga in dig via uni-login. Detta beror antingen på att du ännu inte synkroniserats eller på att din skola inte längre har tillgång till systemet.",
      "What element would you like want to add?":
          "Vilken resurs vill du lägga till?",
      widget: "widget",
      "Wiris settings": "Wiris-inställningar",
      Word: "Ord",
      Words: "Ord",
      "Words list": "Ordlista",
      "Wrong password": "Fel lösenord"
  };
