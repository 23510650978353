import Vue from 'vue'
import geogebraApi from '@/api/geogebraAPI'

export const geogebraStore = {
  // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
  namespaced: true,

  state: {
      geogebraElements : {},
      geogebra_element_obj : {}
  },


  getters: {
      // getLoggedInUserInfo: state => {
      //   return state.user
      // }
  },


  actions : {


        clearGeogebraStore({ commit, state }, { group_id }) {
            commit('CLEAR_STORE', {
                group_id : group_id
            })
            return 1
        },

        async createGeogebraElement({ commit, state }, { group_id, element_data}) {

            const response = await geogebraApi.createGeogebraElement(element_data)

            commit('ADD_GEOGEBRA_ELEMENT', {
                group_id: group_id,
                element_data : response.data
            })

            return response.data.interactive_id

        },

        async fetchGeogebraElements({ commit, state }, group_id) {
            // check if set
            if (state.geogebraElements[group_id] && state.geogebraElements[group_id].length) {
              return state.geogebraElements[group_id]
            } else {
                const response = await geogebraApi.fetchGeogebraElements(group_id)
                const geogebraElements = response.data
                commit('SET_GEOGEBRA_ELEMENTS', {
                    group_id: group_id,
                    geogebraElements : geogebraElements
                })
                return geogebraElements
            }
        },

        async removeGeogebraElement({ commit, state }, { group_id, interactive_id } ) {
            await geogebraApi.removeGeogebraElement(interactive_id)
            commit('REMOVE_GEOGEBRA_ELEMENT', { group_id: group_id, interactive_id : interactive_id})
            return 1
        },

        async saveGeogebraElement({ commit, state }, { interactive_id, group_id, element_data} ) {

            const response = await geogebraApi.saveGeogebraElement({
                interactive_id, element_data
            })

            const new_data = response.data
            commit('SAVE_GEOGEBRA_ELEMENT', { interactive_id : interactive_id, group_id: group_id, element_data : new_data})
            return interactive_id
        },

  },


    mutations: {

        ADD_GEOGEBRA_ELEMENT: (state, { group_id, element_data }) => {
            if (!state.geogebraElements[group_id]) {
                state.geogebraElements[group_id] = []
            }
            state.geogebraElements[group_id].push(element_data)
            Vue.set(state.geogebra_element_obj, element_data['interactive_id'], element_data)
        },

        CLEAR_STORE: (state, { group_id }) => {
            Vue.set(state.geogebraElements, group_id, [])
            Vue.set(state, 'geogebra_element_obj', {})
        },

        REMOVE_GEOGEBRA_ELEMENT: (state, { group_id, interactive_id }) => {
            let elements = state.geogebraElements[group_id]
            for (let element of elements) {
                if (parseInt(element.interactive_id) === interactive_id) {
                    let index = elements.indexOf(element)
                    Vue.delete(state.geogebraElements[group_id], index)
                    Vue.delete(state.geogebra_element_obj, interactive_id)
                }

            }
        },

        SAVE_GEOGEBRA_ELEMENT: (state, { interactive_id, group_id, element_data }) => {

            state.geogebraElements[group_id].map( (element, index) => {
                if (parseInt(element.interactive_id) === parseInt(interactive_id)) {
                    Vue.set(state.geogebraElements[group_id], index, element_data)
                }
            })

            Vue.set(state.geogebra_element_obj, interactive_id, element_data)
        },

        SET_GEOGEBRA_ELEMENTS: (state, { group_id, geogebraElements }) => {
            Vue.set(state.geogebraElements, group_id, geogebraElements)
            for (let element of geogebraElements) {
                Vue.set(state.geogebra_element_obj, element['interactive_id'], element)
            }
        }
    }

}
