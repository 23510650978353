import webinarApi from '@/api/webinarAPI'

export const webinarStore = {
    namespaced: true,

    state: {
        webinar: null,
        availableWebinars: [],
        lastCreatedWebinar: null,
        lastOperationSucceed: null,
        webinarBroadcastCredentials: null,
    },
    mutations: {
        setWebinar(state, webinar) {
            state.webinar = webinar
        },
        setAvailableWebinars(state, webinars) {
            state.availableWebinars = webinars
        },
        setLastCreatedWebinar(state, webinar) {
            state.lastCreatedWebinar = webinar
        },
        setLastOperationSucceed(state, succeed) {
            state.lastOperationSucceed = succeed
        },
        setWebinarBroadcastCredentials(state, credentials) {
            state.webinarBroadcastCredentials = credentials
        }
    },
    actions: {
        async getAvailableWebinarsForGroup({commit}, groupId) {
            try {
                const webinars = (await webinarApi.getWebinarsInGroup(groupId)).data

                commit('setAvailableWebinars', webinars)
                commit('setLastOperationSucceed', true)
            } catch (e) {
                commit('setLastOperationSucceed', false)
                commit('setAvailableWebinars', [])
            }
        },
        async getWebinar({commit}, webinar) {
            const webinarId = typeof webinar === 'object'
                ? +webinar.id
                : +webinar

            try {
                commit(
                    'setWebinar',
                    (await webinarApi.getWebinar(webinarId)).data
                )
                commit('setLastOperationSucceed', true)
            } catch (e) {
                commit('setLastOperationSucceed', false)
                commit('setWebinar', null)
            }
        },
        async createWebinar({commit}, data) {
            try {
                commit(
                    'setLastCreatedWebinar',
                    (await webinarApi.createWebinar(data)).data
                )
                commit('setLastOperationSucceed', true)
            } catch (e) {
                commit('setLastOperationSucceed', false)
                commit('setLastCreatedWebinar', null)
            }
        },
        async startWebinarBroadcast({commit}, webinar) {
            if (typeof webinar.id === 'undefined') {
                commit('setLastOperationSucceed', false)
                return
            }

            try {
                await webinarApi.startWebinarBroadcast(webinar.id)
            } catch (e) {
                commit('setLastOperationSucceed', false)
                return
            }

            commit('setLastOperationSucceed', true)
        },
        async stopWebinarBroadcast({commit}, webinar) {
            if (typeof webinar.id === 'undefined') {
                commit('setLastOperationSucceed', false)
                return
            }

            try {
                await webinarApi.stopWebinarBroadcast(webinar.id)
            } catch (e) {
                commit('setLastOperationSucceed', false)
                return
            }

            commit('setLastOperationSucceed', true)
        },
        async joinWebinarBroadcast({commit}, webinar) {
            if (typeof webinar.id === 'undefined') {
                commit('setLastOperationSucceed', false)
                return
            }

            try {
                commit(
                    'setWebinarBroadcastCredentials',
                    (await webinarApi.joinWebinarBroadcast(webinar.id)).data
                )
            } catch (e) {
                commit('setLastOperationSucceed', false)
                commit('setWebinarBroadcastCredentials', null)
                return
            }

            commit('setLastOperationSucceed', true)
        }
    }
}
