import Vue from 'vue'
import externalLinkAPI from '@/api/externallinkAPI'

export const externalLinkStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        external_links: {}
    },


    getters: {

    },


    actions : {

        async createNewExternalLink({ commit, state },  data) {
            return await externalLinkAPI.createNewExternalLink(data)
        },

        async saveExternalLink({ commit, state }, { link_id, data }) {
            return await externalLinkAPI.saveExternalLink({ link_id, data })
        },


    },


    mutations: {



    }
}
