import axios from 'axios'

export default {

    async fetchAllGroupNews(group_id) {
        return await axios.get('/news/group/'+group_id)
    },

    async pullAllUserNews({ limit }) {
        if (!limit) {
            return await axios.get("/news");
        }
        return await axios.get(`/news?limit=${limit}`);
    },

    async registerNewsSeen({ group_id }) {
        return await axios.put(`/news/seen/${group_id}`, {})
    },
    async setCommentsSeen({ group_id }) {
        if (!group_id) return 0
        return await axios.put(`/group/${group_id}/news/comments/seen`);
    }

}
