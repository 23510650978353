import axios from 'axios'

export default {

    createUser(info) {
        return axios.post('/user', info)
    },

    createCompanyUser(info) {
        return axios.post('/users/company', info)
    },

    getUserInfo(user_id) {
        return axios.get(`/user/${user_id}`)
    },

    importUsers({ institution_id, user_data }) {
        return axios.post('/users/import/bulk', {
            institution_id : institution_id,
            user_data : user_data
        })
    },

    pullLoggedInUserInfo(token) {
        return axios.get('/user/token/info')
    },

    removeUser(user_id) {
        return axios.delete(`/user/${user_id}`)
    },

    /**
     * @desc Search users
     * @param {Object} data - Search user data
     */
    async searchUsers(data) {
        const {
            institution_id,
            group_id,
            only_admins = false,
            only_students = false,
            txt,
        } = data

        return await axios.post('/users/search', {
            group_id,
            institution_id,
            only_admins,
            only_students,
            txt,
        })
    },

    saveUser({ user_id, info }) {
        return axios.put(`/user/${user_id}`, info)
    },

    saveUserEmail({ user_id, email }) {
        return axios.put(`/user/email/${user_id}`, { email : email })
    },

    saveUserLanguage({ user_id, language }) {
        return axios.put(`/user/language/${user_id}`, { language : language })
    },

    setUserPassword({ password_old, password_new }) {
        return axios.put('/user/password', {
            password_old : password_old,
            password_new : password_new
        })
    },

    setUserProfilePicture({ media_data }) {
        return axios.post('/users/profile-picture', {
            media_data : media_data
        })
    },

    getPermissions() {
        return axios.get(`/permissions`)
    },

    setAccountType({ client_type, institution_id }) {
      return axios.put(`/institution/${institution_id}/client`, {client_type})
    },
    async getActiveUsersCount() {
        return await axios.get(`/institution/active-users?count`)
    },

    async getActiveUsers() {
        return await axios.get(`/institution/active-users`)
    },
    async removeFromInstitution({ userId, institutionId }) {
        if(!userId || !institutionId) return
        return await axios.delete(`/user/${userId}/institution/${institutionId}`)
    },
    async getInstitutionTeachers() {
        return await axios.get(`/institution/teachers`)
    },
    async setUserDefaultInstitution(institution_id) {
        if(!institution_id) return
        return await axios.post(`/institution/set-default`, {
            institution_id
        })
    },
    async getUserInstitutions() {
        return await axios.get('/institutions')
    },
    async getInstitutionInfo() {
        return await axios.get("/institution/info")
    }
}
