import Vue from 'vue'
import authAPI from '@/api/authAPI'
import auxApi from '@/api/auxAPI'

export const auxStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        classes: [],
        grade_scales : [],
        languages : [],
        levels : [],
        object_tags : {},
        object_tags_references : {},
        rte_plugins_loaded : 0,
        subjects: [],
        topics: [],
    },


    getters: {},


    actions : {

        async addTag({ commit, state }, { title }) {
            return await auxApi.addTag({ title : title })
        },

        /**
         * @desc Add new subject
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Subject data
         * @param {number} data.adaptive_class_id
         * @param {string} data.image_url
         * @param {number} data.language_id
         * @param {string} data.title
         * @return {Promise} response
         */
        async addSubject(vx, data) {
            const { commit } = vx
            const {
                adaptive_class_id,
                image_url,
                language_id,
                title,
            } = data

            const response = await auxApi.addSubject({
                adaptive_class_id: parseInt(adaptive_class_id),
                image_url,
                language_id: parseInt(language_id),
                title,
            })

            commit('ADD_SUBJECT', {
                newSubject: response.data,
            })

            return response
        },

        /**
         * @desc Add new topic
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Topic data
         * @param {number} data.adaptive_subject_id
         * @param {number} data.language_id
         * @param {string} data.title
         * @return {Promise} response
         */
        async addTopic(vx, data) {
            const { commit } = vx
            const { adaptive_subject_id, language_id, title } = data

            const response = await auxApi.addTopic({
                adaptive_subject_id: parseInt(adaptive_subject_id),
                language_id: parseInt(language_id),
                title,
            })

            commit('ADD_TOPIC', {
                newTopic: response.data,
            })

            return response
        },

        /**
         * @desc Get all classes
         * @param {Object} vx - Vuex specific functions
         * @return {Promise} response
         */
        async fetchAllClasses(vx) {
            const { commit } = vx

            const response = await auxApi.fetchAllClasses()

            commit('SET_CLASSES', {
                classes: response.data,
            })

            return response
        },

        /*
        * Function for fetching grade scales
        */
        async fetchGradeScales({ commit, state }) {

            if (state.grade_scales && state.grade_scales.length) {
                return state.grade_scales
            } else {
                const response = await auxApi.fetchGradeScales()
                commit('SET_GRADE_SCALES', {
                    grade_scales : response.data
                })
                return state.grade_scales
            }

        },

        async fetchAllLanguages({ commit, state }) {

            const response = await auxApi.fetchAllLanguages()

            commit('SET_LANGUAGES', {
                languages : response.data
            })

            return response
        },

        async fetchAllLevels({ commit, state }) {

            const response = await auxApi.fetchAllLevels()

            commit('SET_LEVELS', {
                levels : response.data
            })

            return response
        },

        /**
         * @desc Get all subjects
         * @param {Object} vx - Vuex specific functions
         * @return {Promise} response
         */
        async fetchAllSubjects(vx) {
            const { commit } = vx

            const response = await auxApi.fetchAllSubjects()

            commit('SET_SUBJECTS', {
                subjects: response.data,
            })

            return response
        },

        /**
         * @desc Get all topics
         * @param {Object} vx - Vuex specific functions
         * @return {Promise} response
         */
        async fetchAllTopics(vx) {
            const { commit } = vx

            const response = await auxApi.fetchAllTopics()

            commit('SET_TOPICS', {
                topics: response.data,
            })

            return response
        },

        async fetchObjectTags({ commit, state }, { search_query }) {
            return await auxApi.getTags(search_query)
        },


        async requestForgottenEmail({ commit, state }, { email }) {
            return await authAPI.requestForgottenEmail({
                email : email
            })
        },

        async registerRTEPluginsLoaded({ commit, state }) {
            commit('REGISTER_RTE_PLUGINS_LOADED')
        },

        /**
         * @desc Remove subject
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Subject data
         * @param {number} data.adaptive_subject_id
         * @return {Promise} response
         */
        async removeSubject(vx, data) {
            const { commit } = vx
            const { adaptive_subject_id } = data

            const response = await auxApi.removeSubject({
                adaptive_subject_id: parseInt(adaptive_subject_id),
            })

            commit('REMOVE_SUBJECT', {
                adaptive_subject_id: parseInt(adaptive_subject_id),
            })

            return response
        },

        /**
         * @desc Remove topic
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Topic data
         * @param {number} data.adaptive_topic_id
         * @return {Promise} response data
         */
        async removeTopic(vx, data) {
            const { commit } = vx
            const { adaptive_topic_id } = data

            const response = await auxApi.removeTopic({
                adaptive_topic_id: parseInt(adaptive_topic_id),
            })

            commit('REMOVE_TOPIC', {
                adaptive_topic_id: parseInt(adaptive_topic_id),
            })

            return response
        },

        /**
         * @desc Update subject
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Subject data
         * @param {number} data.adaptive_class_id
         * @param {number} data.adaptive_subject_id
         * @param {string} data.image_url
         * @param {number} data.language_id
         * @param {string} data.title
         * @return {Promise} response
         */
        async updateSubject({ commit }, data) {
            const {
                adaptive_class_id,
                adaptive_subject_id,
                image_url,
                language_id,
                title,
            } = data

            const response = await auxApi.updateSubject({
                adaptive_class_id: parseInt(adaptive_class_id),
                adaptive_subject_id: parseInt(adaptive_subject_id),
                image_url,
                language_id: parseInt(language_id),
                title,
            })

            commit('UPDATE_SUBJECT', {
                adaptive_subject_id: parseInt(adaptive_subject_id),
                updatedSubject: response.data,
            })

            return response
        },

        /**
         * @desc Update topic
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Topic data
         * @param {number} data.adaptive_subject_id
         * @param {number} data.adaptive_topic_id
         * @param {number} data.language_id
         * @param {string} data.title
         * @return {Promise} response data
         */
        async updateTopic({ commit }, data) {
            const {
                adaptive_subject_id,
                adaptive_topic_id,
                language_id,
                title,
            } = data

            const response = await auxApi.updateTopic({
                adaptive_subject_id: parseInt(adaptive_subject_id),
                adaptive_topic_id: parseInt(adaptive_topic_id),
                language_id: parseInt(language_id),
                title,
            })

            commit('UPDATE_TOPIC', {
                adaptive_topic_id: parseInt(adaptive_topic_id),
                updatedTopic: response.data,
            })

            return response
        },

        async validateConfirmToken({ commit, state }, token) {
            return await auxApi.validateConfirmToken(token)
        },

        async validateSignupToken({ commit, state }, token) {
            return await auxApi.validateSignupToken(token)
        },


    },


    mutations: {
        ADD_SUBJECT: (state, data) => {
            const { newSubject } = data

            state.subjects.push(newSubject)
        },

        ADD_TOPIC: (state, data) => {
            const { newTopic } = data

            state.topics.push(newTopic)
        },

        REGISTER_RTE_PLUGINS_LOADED: (state) => {
            Vue.set(state, 'rte_plugins_loaded', 1)
        },

        REMOVE_SUBJECT: (state, data) => {
            const { adaptive_subject_id } = data

            const subjectIndex = state.subjects.findIndex(subject => {
                return parseInt(subject.adaptive_subject_id) === parseInt(adaptive_subject_id)
            })

            if (subjectIndex !== -1) {
                Vue.delete(state.subjects, subjectIndex)
            }
        },

        REMOVE_TOPIC: (state, data) => {
            const { adaptive_topic_id } = data

            const topicIndex = state.topics.findIndex(topic => {
                return parseInt(topic.adaptive_topic_id) === parseInt(adaptive_topic_id)
            })

            if (topicIndex !== -1) {
                Vue.delete(state.topics, topicIndex)
            }
        },

        SET_CLASSES: (state, { classes }) => {
            Vue.set(state, 'classes', classes)
        },

        SET_GRADE_SCALES: (state, { grade_scales }) => {
            Vue.set(state, 'grade_scales', grade_scales)
        },

        SET_LANGUAGES: (state, { languages }) => {
            Vue.set(state, 'languages', languages)
        },

        SET_LEVELS: (state, { levels }) => {
            Vue.set(state, 'levels', levels)
        },

        SET_SUBJECTS: (state, data) => {
            const { subjects } = data

            Vue.set(state, 'subjects', subjects)
        },

        SET_TOPICS: (state, data) => {
            const { topics } = data

            Vue.set(state, 'topics', topics)
        },

        UPDATE_SUBJECT: (state, data) => {
            const { adaptive_subject_id, updatedSubject } = data

            const subjectIndex = state.subjects.findIndex(subject => {
                return parseInt(subject.adaptive_subject_id) === parseInt(adaptive_subject_id)
            })

            if (subjectIndex !== -1) {
                Vue.set(state.subjects, subjectIndex, updatedSubject)
            } else {
                state.subjects.push(updatedSubject)
            }
        },

        UPDATE_TOPIC: (state, data) => {
            const { adaptive_topic_id, updatedTopic } = data

            const topicIndex = state.topics.findIndex(topic => {
                return parseInt(topic.adaptive_topic_id) === parseInt(adaptive_topic_id)
            })

            if (topicIndex !== -1) {
                Vue.set(state.topics, topicIndex, updatedTopic)
            } else {
                state.topics.push(updatedTopic)
            }
        },
    },
}
