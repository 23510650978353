import axios from 'axios'

export default {

    async getGroupActivityAssignmentsMatrixData({ group_id, sub_group_id, course_id=0, lesson_id=0 }) {
        return await axios.get(`/activity/group/assignment-matrix/${group_id}/${sub_group_id}?course_id=${course_id}&lesson_id=${lesson_id}`)
    },

    async getGroupActivityQuizMatrixData({ group_id, sub_group_id, course_id=0, lesson_id=0}) {
        return await axios.get(`/activity/group/quiz-matrix/${group_id}/${sub_group_id}?course_id=${course_id}&lesson_id=${lesson_id}`)
    },

    async getGroupActivityCoursesProgressionData({ group_id, sub_group_id }) {
        return await axios.get(`/activity/group/courses/${group_id}/${sub_group_id}`)
    },

    async getGroupStatisticsQuizzesData({ group_id, sub_group_id }) {
        return await axios.get(`/statistics/group/quizzes/${group_id}/${sub_group_id}`)
    },

    async getUserAssignmentsBehindForGroup({ group_id }) {
        return await axios.get(`/statistics/group/assignments-behind/${group_id}`)
    },

    async getGroupActivityLessonsProgressionData({ group_id, course_id }) {
        return await axios.get(`/activity/group/${group_id}/courses/${course_id}/lessons`)

    },

    async getGroupActivityElementsProgressionData({ group_id, lesson_id }) {
        return await axios.get(`/activity/group/${group_id}/lessons/${lesson_id}/elements`)

    },


}
