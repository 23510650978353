<template>
    <div class="main-content-wrapper tw-w-full tw-min-h-screen tw-flex tw-flex-col main-gradient"
         :class="[isLoggedIn? 'lg:tw-bg-wild-sand':'main-gradient tw-h-full']">
        <dialogue></dialogue>
        <heartbeat></heartbeat>
        <video-chat-notification></video-chat-notification>
        <navbar
            @logout="logout"
            @launch-intercom="launchIntercom"
        ></navbar>
        <main
            class="tw-w-full tw-flex tw-flex-col tw-flex-1"
            :class="[isLoggedIn? 'lg:tw-bg-wild-sand':'main-gradient']"
        >
            <LangsBlock
                v-if="!isLoggedIn && !isLoading"
                class="tw-ml-auto tw-p-3"
            />
            <CircleLoader v-if="isLoading"/>
            <router-view v-if="!isLoading" @launch-intercom="launchIntercom" class="tw-flex tw-flex-col tw-flex-1"></router-view>
            <vue-snotify></vue-snotify>
            <img
                v-if="!isSignupType && !isLoggedIn && !isLoading"
                src="@/assets/img/owl-logo.svg"
                class="tw-mx-auto tw-my-5 lg:tw-hidden"
                style="width: 60px;"
            />
            <img
                v-if="!isSignupType && !isLoggedIn && !isLoading"
                src="@/assets/img/logo.svg"
                class="tw-p-3 tw-ml-auto tw-hidden lg:tw-block"
                style="width: 130px;"
            />
        </main>
        <LearnUToast/>
        <IntroductionSteps ref="introductionModal"/>
        <div v-if="isLoggedIn">
            <intercom-support
                ref="intercomSupportComponent"
                v-on:unreadCountChange="unreadCount => unreadCountChange(unreadCount)"
                class="intercom-support"
            >
            </intercom-support>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapState, mapActions} from 'vuex'

import auth from '../src/auth'

const heartbeat = () => import('@/components/misc/heartbeat')
const dialogue = () => import('@/components/misc/dialogue')

const navbarMain = () => import('@/components/navbar/navbarMain')
const videoChatNotification = () => import('@/components/chat/videoChatNotification')
const LangsBlock = () => import('@/components/navbar/LangsBlock')
const CircleLoader = () => import('@/components/misc/CircleLoader')
const IntercomSupport = () =>
    import('@/components/misc/intercomSupportComponent');
const LearnUToast = () => import('@/components/misc/LearnUToast')
const IntroductionSteps = () => import('@/components/dashboard/IntroductionSteps')
import debounce from 'debounce'

export default {
    components: {
        'navbar': navbarMain,
        'heartbeat': heartbeat,
        'dialogue': dialogue,
        'video-chat-notification': videoChatNotification,
        LangsBlock,
        IntercomSupport,
        CircleLoader,
        LearnUToast,
        IntroductionSteps
    },
    data() {
        return {
            isLoading: false,
            support_messages_unread : 0
        }
    },
    methods: {
        ...mapActions('userStore', [
            'pullLoggedInUserInfo',
            'clearLoggedInUserInfo'
        ]),
        ...mapActions('groupStore', [
            'setUserGroups',
            'setInstitutionData',
            'setInstitutionsSettings',
            'pullUserGroups'
        ]),
        ...mapActions('generalStore', ['setCurrentDevice']),

        launchIntercom() {
            if(!this.isLoggedIn) return 0
            this.$refs.intercomSupportComponent.launchClient()
        },
        unreadCountChange(unreadCount) {
            this.support_messages_unread = unreadCount
        },
        async logout() {
            this.isLoading = true
            localStorage.setItem('learnu_lang', this.userStore.user_settings.language)
            await auth.logout()
            await this.clearLoggedInUserInfo()
            // do not use router, because we need to page refresh
            document.location.href = '/'
        },
        async pullAppInfo() {
            let error = false
            if (!this.userStore.user) return 0
            this.isLoading = true
            const response = await this.pullLoggedInUserInfo(this.userStore.user.token)
                .catch(err => {
                    console.log(err)
                    error = true
                })

            this.setColors()

            if (error || !response.data || !response.data.user || !response.data.user.user_id) {
                return this.logout()
            }
            this.setLanguage()
            await this.pullUserGroups(true)
            this.isLoading = false
        },

        setColors() {

            if (!this.userStore.default_institution) return 0
            const client = this.userStore.default_institution.client
            if (client.accent_color) {
                document.documentElement.style.setProperty('--secondary', client.accent_color);
                document.documentElement.style.setProperty('--secondary-20', `${client.accent_color}33`);
            }
            if (client.accent_complementary) {
                document.documentElement.style.setProperty('--accent-text', client.accent_complementary);
            }
            if (client.primary_color) {
                document.documentElement.style.setProperty('--primary', client.primary_color);
                document.documentElement.style.setProperty('--primary-20', `${client.primary_color}33`);
                document.documentElement.style.setProperty('--primary-50', `${client.primary_color}80`);
            }
            if (client.primary_complementary) {
                document.documentElement.style.setProperty('--primary-text', client.primary_complementary);
            }
            if (client.second_accent_color) {
                document.documentElement.style.setProperty('--accent', client.second_accent_color);
                document.documentElement.style.setProperty('--accent-20', `${client.second_accent_color}33`);
            }
            if (client.third_accent_color) {
                document.documentElement.style.setProperty('--third-accent', client.third_accent_color);
            }
            if (client.corresponding_color) {
                document.documentElement.style.setProperty('--corresponding', client.corresponding_color);
            }
            if (client.fourth_corresponding_color) {
                document.documentElement.style.setProperty('--fourth-corresponding', client.fourth_corresponding_color);
            }
            if (client.corresponding_color) {
                document.documentElement.style.setProperty('--corresponding', client.corresponding_color);
            }
            if (client.third_corresponding_color) {
                document.documentElement.style.setProperty('--third-corresponding', client.third_corresponding_color);
            }
            if (client.second_corresponding_color) {
                document.documentElement.style.setProperty('--second-corresponding', client.second_corresponding_color);
            }
        },

        setLanguage(lang = '') {
            if (lang.length) {
                return Vue.set(this.$i18n, 'locale', lang)
            }
            // bind language to user language from store
            if (this.userStore.user_settings.language && this.userStore.user_settings.language.length) {
                Vue.set(this.$i18n, 'locale', this.userStore.user_settings.language)
            } else if (this.userStore.institutions_settings[this.getInstitutionId] && this.userStore.institutions_settings[this.getInstitutionId]['institution-settings-language'] && this.userStore.institutions_settings[this.getInstitutionId]['institution-settings-language'].length) {
                // if user pref not set, use institution default
                Vue.set(this.$i18n, 'locale', this.userStore.institutions_settings[this.getInstitutionId]['institution-settings-language'])
            } else {
                //if all else fails - go to danish (will be english in the future)
                Vue.set(this.$i18n, 'locale', 'en')
            }

        },

        // startTimeSpentInAppDataCollection() {
        //     this.$dataCollector.startTimeSpentInAppDataCollection({
        //         userId: +this.userStore.user.user_id,
        //         firstName: this.userStore.user.firstname || '',
        //         lastName: this.userStore.user.lastname || '',
        //     });
        // },
        //
        // stopTimeSpentInAppDataCollection() {
        //     this.$dataCollector.stopTimeSpentInAppDataCollection();
        // },
        checkProfileInfo() {
            if (!this.isLoading || !this.userStore.user) return 0
            if (this.isLoggedIn && (!this.userStore.user.firstname || !this.userStore.user.lastname)) {
                setTimeout(() => {
                    this.$refs['introductionModal'].openModal()
                }, 2000)
            }
        },
        handleResize(){
            if(window.innerWidth <= 767) this.setCurrentDevice('mobile')
            if(window.innerWidth <= 1023 && window.innerWidth > 767) this.setCurrentDevice('tablet')
            if(window.innerWidth > 1023) this.setCurrentDevice('laptop')
        },
        debounceResizeHandler: debounce(function(){
            this.handleResize()
        }, 0),
        // identifyTeacher(){
        //     try{
        //         if(!EncTracking || !this.userStore.user.is_teacher) return 0
        //         // console.log('track')
        //         EncTracking.identify({
        //             email: this.userStore.user.email,
        //             userId: this.userStore.user.user_id
        //         });
        //     }catch(error){
        //         console.log(error)
        //     }
        // },
    },
    computed: {
        ...mapState([
            'groupStore',
            'userStore',
        ]),
        isLoggedIn() {
            return this.userStore.isLoggedIn
        },
        isSignupType(){
            return this.$route.path === '/signup'
        },
        getInstitutionId() {
          if(!this.userStore.default_institution) return 0
          return this.userStore.default_institution.group_id
        }
    },
    watch: {
        isLoggedIn(val, oldVal) {
            // val === 1
            //     ? this.startTimeSpentInAppDataCollection()
            //     : this.stopTimeSpentInAppDataCollection();

            if (!this.isLoading) {
                if (val === 1) {
                    this.pullAppInfo()
                }
                if(!val){
                    this.$refs.intercomSupportComponent.closeClient()
                }
            }
            this.checkProfileInfo()
            // this.identifyTeacher()
        },
        $route(to, from) {
            this.checkProfileInfo()
        }
    },
    async created() {
        if (!auth.checkAuth()) return
        await this.pullAppInfo()
        if(this.userStore.user.is_teacher && this.$route.query['redirect'] && this.$route.query['redirect']==='subscriptions' && this.$route.query['plan_id']){
            document.location.href = `/profile/${this.userStore.user.user_id}/subscriptions?plan=${this.$route.query['plan_id']}`
        }
    },
    mounted() {
        // if (this.isLoggedIn === 1) {
            // this.startTimeSpentInAppDataCollection()
            // this.identifyTeacher()
        // }
        this.checkProfileInfo()
        window.addEventListener('resize', this.debounceResizeHandler)
        this.handleResize()
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.debounceResizeHandler)
    }
}
</script>
<style>
:root {
    --primary: #108D82;
    --primary-20: #108D8233;
    --primary-50: #108D8280;
    --primary-text: #ffffff;
    --secondary: #62DBB5;
    --accent-text: #ffffff;
    --secondary-20: #62DBB533;
    --accent: #3DB2A0;
    --accent-20: #3DB2A033;
    --third-accent: #EAFFF8;
    --corresponding: #47C4B1;
    --fourth-corresponding: #6BECC3;
    --third-corresponding: #3DBA93;
    --second-corresponding: #03675E;
}

html {
    font-size: 16px !important;
    font-family: Trenda;
    color: theme('colors.dark-text');
}

body {
    width: 100%;
    height: 100vh;
}

.app-container {
    height: fit-content;
}

body.prevent-scroll{
    overflow: unset !important;
}

.main-container {
    max-width: 1600px;
}

.inner-container {
    max-width: 1200px;
    margin: auto;
}

.content-container {
    margin: 16px;
}
@media screen and (min-width: 768px) {
    .content-container {
        margin: 20px 37px;
    }
}

@media screen and (min-width: 1024px) {
    .content-container {
        margin: 20px 50px;
    }
}
.main-gradient {
    background: linear-gradient(
        -45deg,
        theme('colors.primary') -5%,
        theme('colors.secondary') 100%,
        theme('colors.turquoise-blue') 20%
    );
}

div:focus {
    outline: none;
}

.background {
    background: theme('colors.background') !important;
}

.center-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.center-xy {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

html.modal-open {
    overflow: hidden;
}

.content-height {
    min-height: calc(100vh - 60px);
    height: fit-content;
}

.page-title {
    @apply tw-font-bodySemibold tw-text-xl tw-text-primary;
}
</style>
<style lang="stylus">
@import './stylus/main.styl';
</style>

<style lang="stylus">

body {
    --z-index-layer-max: 16777271;
    --z-index-layer-max-1: 16777270;
    --z-index-layer-min: 16777271;
}

.selected-chip {
    height: 32px !important;
    border: 2px solid var(--v-primary-base) !important;
}

</style>

<style lang="css">

/*
 *  IE FIX
*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .container {
        max-width: 1300px !important;
    }
}

a {
    text-decoration: none;
}

.activity-sticky-items-td {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
}

.badge-correct {
    display: block !important;
    position: static !important;
}

body .fc {
    overflow: auto;
}

.border--rounded {
    border-radius: 5px !important;
}

.button-icon {
    margin-left: 5px;
}

/*
 * CKEDITOR
*/
.cke_dialog_ui_button_ok {
    background: var(--v-accent-base) !important;
    color: #fff !important;
    border: 1px solid var(--v-accent-base) !important;
}

.cke_dialog_ui_input_text:focus, input.cke_dialog_ui_input_password:focus, textarea.cke_dialog_ui_input_textarea:focus {
    border: none !important;
    border-bottom: 1px solid var(--v-accent-base) !important;
}

input.cke_dialog_ui_input_text:hover, input.cke_dialog_ui_input_password:hover, textarea.cke_dialog_ui_input_textarea:hover {
    border: none !important;
    border-bottom: 1px solid var(--v-accent-base) !important;
}

a.cke_dialog_tab:focus {
    border: 1px solid #d1d1d1 !important;
    border-bottom-color: #fff !important;
}

select.cke_dialog_ui_input_select:focus {
    border: 1px solid #bcbcbc !important;
}

/*
 *  CKEDITOR END
*/

.v-chip.pointer > .v-chip__content {
    cursor: pointer !important;
}

.clearfix:before {
    clear: both;
}

/* TODO: Remove. Moved to Cloze components */
/* .cloze-answer-div del {
    color: red !important;
}

.cloze-answer-div ins {
    color: green !important;
}

.correct-answers__answer del {
    color: #F44336 !important;
}

.correct-answers__answer ins {
    color: #4CAF50 !important;
}

.cloze-input {
    border: 1px solid #333 !important;
    padding: 5px;
}

.cloze-input--correct {
    border-radius: 6px;
    border-width: 5px !important;
    border-color: #4CAF50 !important;
}

.cloze-input--wrong {
    border-radius: 6px;
    border-width: 5px !important;
    border-color: #F44336 !important;
} */

.expanded-stepper-2-step .v-timeline {
    padding-top: 0;
}

.expanded-stepper-2-step .v-timeline:before {
    height: 82% !important;
}

.expanded-stepper-3-step .v-timeline:before, .expanded-stepper-5-step .v-timeline:before {
    height: 97% !important;
}

.material-element-tile > .v-list__tile {
    height: 100% !important;
    min-height: 72px !important;
    padding: 16px 0 !important;
}

.material-element-tile:first-of-type .group-material-element-pipe {
    bottom: -1px;
    height: 50%;
    top: auto;
}

.material-element-tile:last-of-type .group-material-element-pipe {
    height: 50%;
}

.material-view__top-toolbar .v-toolbar__content {
    padding: 0 !important;
}

.v-toolbar--no-padding .v-toolbar__content {
    padding: 0 !important;
}

.default-cursor {
    cursor: default !important;
}

.dialog__content.dialog__content__active {
    z-index: 1001 !important;
}

.force-inline-flex, .force-inline-flex > div {
    display: inline-flex;
}

.fsp-picker__brand-container {
    display: none !important;
}
body.fsp-picker--no-scroll {
    overflow: unset;
}

.full-height {
    height: 100% !important;
}

.full-width {
    width: 100% !important;
}


@media only screen and (max-width: 992px) {
    .full-width-mobile {
        width: 100% !important;
    }
}

.greyed-out {
    color: rgba(0, 0, 0, 0.40) !important;
}

.greyed-out-bg {
    background-color: rgba(0, 0, 0, 0.01) !important;
}


.grey-thick-border {
    border: 2px solid rgba(0, 0, 0, 0.5) !important;
    border-radius: 5px !important;
}

.dark-greyed-out-bg {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.half-hr-element {
    width: 130px;
    margin-left: 10px;
    margin-right: 10px;
    border: 0.2px solid grey;

}

.header-app-icon .v-badge--overlap .v-badge__badge {
    top: -3px !important;
}

.high-tile > .v-list__tile {
    height: 60px;
}

.hover-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* Remove outermost scrollbar that would sometimes come forward */
/* html::-webkit-scrollbar {
    display: none;
} */

img.circle {
    border-radius: 50%;
}


#intercom-container .intercom-home-screen .intercom-link {
    opacity: 0 !important;
}

.list-icon {
    font-size: 20px;
}

.list-tile__active {
    background: rgba(0, 0, 0, 0.04);
}

.v-list__group__header__prepend-icon {
    color: #333 !important;
}

/* FIREFOX HACKS */
@-moz-document url-prefix() {

    .badge-correct .badge__badge > span {
        margin-top: -6px;
    }


    #navbar-logged-in .v-btn__content .v-badge__badge span {
        margin-top: -17px;
    }

    .awareness-card-avatar {
        max-width: 100%;
        height: 200px;
    }

    /* Error in firefox with froala audio element */
    div[fr-original-class="fr-audio-click-catcher"] {
        display: none !important;
    }

}

/* EDGE HACKS */
@supports (-ms-ime-align:auto) {

}

.modal-side-button .v-btn {
    height: 100% !important;
}

.moveable {
    cursor: move
}

.multiple-choice-content p {
    margin-bottom: 0;
}

.multiple-choice__answer-checkbox .v-input--selection-controls__input {
    margin-right: 0 !important;
}

.main-navigation-drawer {
    z-index: 100 !important;
}

/* Used in draggable components to enable empty list and easy the dropping */
.mr-draggable {
    min-height: 20px !important;
    width: 100%;
}

/*.main-navigation-drawer > .list .list__tile:hover {*/
/*background: rgba(0,0,0,0.12) !important;*/
/*}*/

nav .brand-logo {
    position: absolute;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
    white-space: nowrap;
}

.no-border {
    border: none
}

.no-hover {
    pointer-events: none;
}

.no-top-margin {
    margin-top: 0 !important;
}

.no-bottom-margin {
    margin-bottom: 0 !important;
}

.object-icon-status.assignment-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-assignment_greenyellow.svg?v=1');
}

.object-icon-status.file-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-file_greenyellow.svg');
}

.object-icon-status.flashcards-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-flashcards_greenyellow.svg');
}

.object-icon-status.interactive-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-interactive_greenyellow.svg');
}

.object-icon-status.link-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-link_greenyellow.svg');
}

.object-icon-status.questionnaire-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-questionnaire_greenyellow.svg');
}

.object-icon-status.quiz-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-quiz_greenyellow.svg');
}

.object-icon-status.text-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-book_open_greenyellow.svg?v=1');
}

.object-icon-status.timeline-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-timeline_greenyellow.svg');
}

.object-icon-status.video-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-video_greenyellow.svg');
}

.object-icon-status.board-node {
    background-image: url('https://images.edaptio.com/material-element-icons/edaptio_material_icon-wall_greenyellow.svg');
}

.pa0-toolbar > .v-toolbar__content {
    padding: 0 !important;
}

.pointer {
    cursor: pointer
}

.post-content p {
    margin-bottom: 2px;
}

/*
 * <RAW TEXT ELEMENT STYLES>
*/

.primary-link {
  color: theme('colors.primary-own') !important;
}
.secondary1-link {
  color: theme('colors.eucalyptus') !important;
}
.secondary2-link {
  color: theme('colors.accent-own') !important;
}
.black-link {
  color:  #020402 !important;
}
.gray-link {
  color: #868282 !important;
}
.dark-blue-link {
  color: #1E3888 !important;
}
.blue-link {
  color: #35A7FF !important;
}
.purple-link {
  color: #6E44FF !important;
}
.pink-link {
  color: #FF5964 !important;
}
.orange-link {
  color: #FF9466 !important;
}
.fr-popup{
    z-index: 75 !important;
}
.fr-popup.fr-upload-layer{
    display: none !important;
}
.fr-view table{
    margin: auto !important;
}
.fr-view table td,
.fr-view table th{
    padding: 5px 10px !important;
}
.fr-view blockquote{
    border-left: 2px solid #3DB2A0 !important;
    color: #3DB2A0 !important;
}
.fr-view blockquote > span{
    color: #3DB2A0 !important;
}

.fr-box.fr-basic .fr-element{
    color: theme('colors.dark-text') !important;
    font-family: Trenda !important;
}
.raw-text-element img.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
}
.raw-text-element img.fr-dib {
  margin: 5px auto;
  display: block;
  float: none;
}
.raw-text-element img.fr-dii.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}
.raw-text-element img.fr-dii.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}
.raw-text-element .fr-emoticon {
  background-size: cover;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  min-width: 20px;
  min-height: 20px;
  background-repeat: no-repeat;
}
.raw-text-element h1, .fr-wrapper h1 {
    font-size: 2.5em !important;
}

.raw-text-element h2, .fr-wrapper h2 {
    font-size: 2em !important;
}

.raw-text-element h3, .fr-wrapper h3 {
    font-size: 1.67em !important;
}

.raw-text-element h4, .fr-wrapper h4 {
    font-size: 1.5em !important;
}

.raw-text-element p {
    margin-bottom: 10px !important;
}

.raw-text-element .fr-iframe-click-catcher,
.fr-audio-click-catcher {
  display: none;
}

.raw-text-element table {
    margin-bottom: 20px !important;
    border-collapse: collapsec !important;
    max-width: 100% !important;
    margin: auto !important;
}
.raw-text-element table td,
.raw-text-element table th {
    padding: 5px 10px !important;
    border-width: 1px !important;
    border-color: #DDD !important;
}
.raw-text-element table th{
    background: #ececec;
}

.raw-text-element table tr {
    border-bottom: 1px solid #333 !important;
}

.raw-text-element table tr:first-of-type {
    border-top: 1px solid #333 !important;
}

.raw-text-element ul, .raw-text-element ol {
    padding-left: 40px !important;
    margin-bottom: 20px !important;
}

.raw-text-element ul li{
    list-style: circle;
}
.raw-text-element ol li{
    list-style: decimal;
}

.raw-text-element hr {
    color: theme('colors.dark-text');
    border-width: unset;
    border-top-width: 1px !important;
}

.raw-text-element a {
    font-weight: bold !important;
    color: theme('colors.primary-own');
}

.raw-text-element a:hover {
    text-decoration: underline !important;
}

.raw-text-element blockquote {
    border-width: unset;
    border-left: 2px solid #3DB2A0 !important;
    color: #3DB2A0 !important;
}
.raw-text-element blockquote >>> span{
    color: #3DB2A0 !important;
}

.raw-text-element .edaptio-media-video-element {
    max-height: 500px;
}

.raw-text-element table.clean-table tr, .raw-text-element table.clean-table td {
    border: none !important;
}

.raw-text-element img, .raw-text-element iframe {
    max-width: 100% !important;
}

/*
 * </RAW TEXT ELEMENT STYLES>
*/

.sheet-corners {
    border-radius: 2px;
}

.small-font-textfield .v-label, .small-font-textfield input {
    font-size: 14px !important;
}

.small-table th, .small-table td {
    padding: 0 5px !important;
}

.tab--active {
    opacity: 1 !important;
    background-color: var(--v-accent-base) !important;
    color: #FFFFFF !important;
}

/*
    ---------------- start ----------------
    LIST GROUP OVERRIDES FOR TREE-LIKE VIEW
 */

/* Remove border lines */
#tree-list .v-list__group:before,
#tree-list .v-list__group:after {
    height: 0 !important;
}

#tree-list .v-list__tile--link:hover {
    background: none !important;
}

#tree-list .v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
    transform: rotate(45deg) !important;
}

#tree-list .loading > .v-list__group__header .v-list__group__header__prepend-icon .v-icon {
    animation: spin 2s linear infinite !important;
}

#tree-list .v-list__group__header__prepend-icon {
    padding: 0 !important;
    min-width: 0 !important;
    margin-right: 4px !important;
    margin-left: 4px !important;
}

#tree-list .v-list__group__items--no-action .v-list__tile {
    padding-left: 56px !important;
}

#tree-list .v-list__tile {
    padding: 0 4px 0 0 !important;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/*
    LIST GROUP OVERRIDES FOR TREE-LIKE VIEW
    ----------------- end -----------------
 */

.text-truncate {
    /* FIX: Default line-height 1 causing titles to be cropped */
    line-height: 1.25 !important;
}

.theme--light.v-icon {
    color: rgba(0, 0, 0, 1);
}

.video-js {
    width: 100% !important;
    min-height: 100px !important;
}

.v-btn.accent > .v-btn__content, .v-btn.accent > .v-btn__content > i {
    color: var(--v-accentComplementary-base) !important;

}

.v-icon, .theme--light.v-icon {
    color: #333;
}

.v--modal-overlay {
    cursor: pointer;
}

.v--modal {
    cursor: default;
}

.v-date-picker-table {
    height: auto !important;
}

.v-sheet, .sheet-corners {
    border-radius: 5px;
}

.v-sheet-no-corners {
    border-radius: 0px !important;
}

@media only screen and (max-width: 960px) {
    .expanded-stepper .v-timeline--dense .v-timeline-item__body {
        max-width: calc(100% - 45px) !important;
    }
}

.v-toolbar, .no-box-shadow {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.VueCarousel-navigation-prev, .VueCarousel-navigation-next {
    padding: 0 !important;
    font-size: 20px;
}

.VueCarousel-navigation--disabled {
    display: none;
}

.VueCarousel-dot {
    margin-top: 0 !important;
}


.wrs_linksContainer {
    display: none !important;
}

.wirisinputcontrolslist input[type="radio"], .wirissyntaxvalues input {
    position: static !important;
    opacity: 1 !important;
    margin-right: 10px !important;
}

.wirisinputcontrolslist label, .wirissyntaxlabel label {
    cursor: pointer !important;
}

.wirissyntaxchar {
    margin-right: 20px !important;
}

.wirissyntaxvalues select {
    display: block !important;
}


input.wirisembeddedmathinput,
input.wirisembeddedauthoringfield {
    height: 16px !important;
    /*
    Set dinamically.
    width: 100px;
    background-position: 105px 3px;*/
    background-position-x: 83px !important;
}

img.wirisembeddedmathinput {
    background-position-x: 103px !important;
}


</style>
