import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/*
 *  SUBROUTES
*/
import adminRoutes from '@/router/subroutes/adminRoutes'
import studentAdministrationRoutes from '@/router/subroutes/studentAdministrationRoutes'
import groupRoutes from '@/router/subroutes/groupRoutes'
import profileRoutes from '@/router/subroutes/profileRoutes'


/*
 *   VIEWS
*/
const fourOThree = () => import('@/views/403')
const FourOFour = () => import('@/views/404')
const adminMainView = () => import('@/views/admin/adminMainView')
const conversationsView = () => import('@/views/messages/messagesView')
const dashboard = () => import(/* webpackPrefetch: true */'@/views/dashboard')
const frontpage = () => import('@/views/frontpage/frontpage')
const groupsMainView = () => import('@/views/groups/groupsMainView')
const groupsOverview = () => import(/* webpackPrefetch: true */'@/views/groups/groupsOverview')
const institutionFilesharingView = () => import('@/views/files/institutionFilesharingView')
const institutionNewsView = () => import('@/views/news/institutionNewsView.vue')
const videoChatNew = () => import('@/views/videoChat/videoChat.vue')
const inviteToInstitutionView = () => import('@/views/invite/inviteToInstitutionView')
const joinView = () => import(/* webpackPrefetch: true */'@/views/join/joinView')
const login = () => import(/* webpackPrefetch: true */'@/views/login/loginView')
const moodlelogin = () => import('@/views/login/moodleloginView')
const profileMainView = () => import('@/views/profile/profileMainView')
const redirectPage = ()=>import('@/views/redirectPage/redirectPage')
const resetPasswordView = () => import('@/views/login/resetPasswordView')
const searchMainView = () => import('@/views/search/searchMainView')
const signupConfirmView = () => import('@/views/sign-up/signupConfirmView')
const signupView = () => import(/* webpackPrefetch: true */'@/views/sign-up/signupView')
const sharedOverview = () => import('@/views/shared/sharedOverview')
const studentAdministrationOverview = () => import('@/views/studentadministration/studentAdministrationOverview')
const testView = () => import('@/views/testView')
const unilogin = () => import('@/views/login/uniloginVuew')
const videoChat = () => import('@/views/chat/videoChat')
const assignmentFeedbackMediaView = () => import(/* webpackPrefetch: true */'@/views/assignments/assignmentFeedbackMediaView')

// application routes
const routes = [
    {
        path: '/',
        component: frontpage,
        meta: { view : 'frontpage' }
    },
    {
        path: '/403',
        component: fourOThree,
        meta : { view : '403' }
    },
    {
        path: '/404',
        component: FourOFour,
        meta : { view : '404' }
    },
    {
        path: '/admin',
        component: adminMainView,
        children: adminRoutes,
        meta : { view : 'admin-main' }
    },
    {
        path: '/administration',
        component: studentAdministrationOverview,
        meta: { requiresAuth: true, view : 'student-administration-main' },
        children : studentAdministrationRoutes
    },
    {
        path: '/dashboard',
        component: dashboard,
        meta: { requiresAuth: true, view : 'dashboard' }
    },
    {
        path: '/email-redirect-bot',
        component: redirectPage,
        meta: { requiresAuth: true, view : 'redirect-page' }
    },
    {
        path: '/files',
        component: institutionFilesharingView,
        meta: { requiresAuth: true, view : 'institution-files' }
    },
    {
        path: '/groups',
        component: groupsOverview,
        meta: { requiresAuth: true, view: 'groups-overview' },
    },
    {
        path: '/groups/:group_id',
        component: groupsMainView,
        meta: { requiresAuth: true, view : 'groups-main' },
        children: groupRoutes
    },
    {
        path: '/groups/:group_id/assignment/:assignment_id/feedback/:feedback_id?/media/:media_id/view',
        component: assignmentFeedbackMediaView,
        props: route => ({
            group_id : parseInt(route.params.group_id),
            assignment_id : parseInt(route.params.assignment_id),
            feedback_id : route.params.feedback_id ? parseInt(route.params.feedback_id) : undefined,
            media_id : parseInt(route.params.media_id)
        }),
        meta: { requiresAuth: true, view : 'groups-assignment-feedback-media-view' },
    },
    {
        path: '/institution/invite',
        component: inviteToInstitutionView,
        meta: {
            requiresAuth: true,
            view: 'invite-to-institution',
        },
    },
    {
        path: '/join/:join_id?',
        component: joinView,
        meta: { view: 'join-view' },
    },
    {
        path: '/login/:error',
        component: login,
        meta : { view : 'login-error' }
    },
    {
        path: '/login',
        component: login,
        meta : { view : 'login' }
    },
    {
        path: '/messages/:conversation_id',
        component: conversationsView,
        meta: { requiresAuth: true, view : 'messages' },
    },
    {
        path: '/messages',
        component: conversationsView,
        meta: { requiresAuth: true, view : 'messages' },
    },
    {
        path: '/moodle-login/:token/:course_id',
        component: moodlelogin,
        meta : { view : 'moodle-login' }
    },
    {
        path: '/news',
        component: institutionNewsView,
        meta: { requiresAuth: true, view : 'institution-news' }
    },
    {
        path: '/videochat/:id',
        component: videoChatNew,
        meta: { requiresAuth: true, view : 'learnu' }
    },
    {
        path: '/profile/:user_id',
        component: profileMainView,
        meta: { requiresAuth: true, view : 'profile-main' },
        children: profileRoutes
    },
    {
        path: '/reset-password/:token',
        component: resetPasswordView,
        meta: { view : 'reset-password' },
    },
    {
        path: '/search',
        component: searchMainView,
        meta: { requiresAuth: true, view : 'search' },
    },
    {
        path: '/signup',
        component: signupView,
        meta : { view : 'signup' }
    },
    {
        path: '/signup/:type',
        component: signupView,
        meta : { view : 'signup' }
    },
    {
        path: '/signup/confirm/:token',
        component: signupConfirmView,
        meta : { view : 'signup confirm' }
    },
    {
        path: '/shared-with-me',
        component: sharedOverview,
        meta: { requiresAuth: true, view: 'shared-overview' },
    },
    {
        path: '/uni-login/:token',
        component: unilogin,
        meta : { view : 'uni-login' }
    },
    {
        path: '/video-chat/:id',
        component: videoChat,
        meta : {
            requiresAuth: true,
            view : 'video-chat'
        }
    },
    {
        path: '/video-chat/:id/join/:token',
        redirect: (to) => {
            return {
                path: `/video-chat/${to.params.id}`,
                meta: {
                    zebra: '123'
                }
            }
        }
    },
    {
        path: '/test',
        component: testView,
        meta: { requiresAuth: true }
    },
    // every other non-existing route go to 404
    {
        path: '*',
        redirect: '/404'
    }
]

// export router instance
export default new Router({
  mode: 'history',
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
})
