import Vue from 'vue'
import adminAPI from '@/api/adminAPI'
import userAPI from '@/api/userAPI'

export const adminStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        institution_payments : [],
        institutions : []
    },


    getters: {

    },


    actions : {


        async checkAdminStatus({ commit }) {
            return await adminAPI.checkAdminStatus()
        },

        async createNewInstitution({ commit }, data) {
            return await adminAPI.createNewInstitution(data)
        },

        async copyAdaptiveQuestionToTest({ commit }, data) {
            return await adminAPI.copyAdaptiveQuestionToTest(data)
        },

        async copyAdaptiveQuizToTest({ commit }, data) {
            return await adminAPI.copyAdaptiveQuizToTest(data)
        },

        async deleteInstitution({ commit }, { institution_id }) {
            return await adminAPI.deleteInstitution(institution_id)
        },

        async getAllInstitutions({ commit, state }, force_refresh=false) {

            if (!force_refresh && state.institutions && state.institutions.length) {
                return state.institutions
            }

            const response = await adminAPI.getAllInstitutions()
            const institutions = response.data
            commit('SET_INSTITUTIONS' , institutions)
            return response
        },

        async getInstitutionPayments({ commit, state }) {

            const response = await adminAPI.getInstitutionPayments()
            const data = response.data
            commit('SET_INSTITUTION_PAYMENTS_DATA' , data)
            return response.data

        },


        async importUsers({ commit }, { institution_id, user_data }) {
            return await userAPI.importUsers({
                institution_id : institution_id,
                user_data : user_data
            })
        },

        async searchUsers({ commit }, { institution_id, group_id, txt }) {
            return await userAPI.searchUsers({ institution_id, group_id, txt })
        },



    },


    mutations: {

        SET_INSTITUTION_PAYMENTS_DATA: (state, data) => {
            data.push({
                count_user: "501",
                group_id:"12387090",
                group_name: "Mathtutor class (wiris)",
                user_id: "14686"
            })
            Vue.set(state, 'institution_payments', data)
        },

        SET_INSTITUTIONS: (state, institutions) => {
            Vue.set(state, 'institutions', institutions)
        },

    }
}
