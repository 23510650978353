import Vue from 'vue'
import chatAPI from '@/api/chatAPI'

export const chatStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        messages: {},
    },


    getters: {},


    actions: {

        /**
         * Function for deleting a chat message
         * @param dx
         * @param data
         * @returns {Promise<number>}
         */
        async deleteChatMessage(dx, data) {
            const { commit, state } = dx
            const { message_id, channel } = data

            await chatAPI.deleteChatMessage({
                channel : channel,
                message_id : message_id
            })

            commit('REMOVE_MESSAGE_FROM_CHANNEL', data)

            return 1

        },

        /**
         * Function for loading newest chat messages
         * @param dx
         * @param data
         * @returns {Promise<*>}
         */
        async getChatMessages(dx, data) {
            const { commit, state } = dx
            const { channel, limit=50, nextOffset='' } = data

            const response = await chatAPI.getChatMessages(data)

            const messages = response.data.data

            commit('SET_CHANNEL_MESSAGES', {
                channel : channel,
                messages : messages
            })

            return response.data

        },

        /**
         * Function for loading more in pagination
         * @param dx
         * @param data
         * @returns {Promise<*>}
         */
        async getEarlierChatMessages(dx, data) {
            const { commit, state } = dx
            const { channel, limit=50, nextOffset='' } = data

            const response = await chatAPI.getChatMessages(data)

            const messages = response.data.data

            commit('PREPEND_CHANNEL_MESSAGES', {
                channel : channel,
                messages : messages
            })

            return response.data

        },

        /**
         * Function for sending a new message to a channel
         * @param dx
         * @param message_data
         * @returns {Promise<number>}
         */
        async sendChatMessage(dx, message_data) {
            const { commit, state } = dx
            const { channel, data } = message_data

            await chatAPI.sendChatMessage({
                channel : channel,
                data : data
            })

            // const new_data = response.data.data.attributes

            // commit('ADD_MESSAGE_TO_CHANNEL', new_data)

            return 1

        },

    },


    mutations: {

        ADD_MESSAGE_TO_CHANNEL: (state, data) => {

            const { channel, message } = data

            if (!state.messages[channel]) {
                Vue.set(state.messages, channel, [])
            }

            // if exists - update
            let found = false
            state.messages[channel].map( (local_message, index) => {
                if (message.id === local_message.id) {
                    Vue.set(state.messages[channel], index, message)
                    found = true
                }
            })

            // else insert
            if (!found) {
                state.messages[channel].push(message)
            }

        },

        REMOVE_MESSAGE_FROM_CHANNEL: (state, data) => {

            const { channel, message_id } = data

            if (!state.messages[channel]) {
                return 0
            }

            state.messages[channel].map( (message, index) => {
                if (message.id === message_id) {
                    Vue.delete(state.messages[channel], index)
                }
            })

            return 1
        },

        PREPEND_CHANNEL_MESSAGES: (state, data) => {

            const { channel, messages } = data

            let current_messages = state.messages[channel]

            messages.map( message =>  {
                current_messages.unshift(message)
            })

            Vue.set(state.messages, channel, current_messages)

            return 1
        },

        SET_CHANNEL_MESSAGES: (state, data) => {

            const { channel, messages } = data

            Vue.set(state.messages, channel, messages)

            return 1
        },

    }
}
