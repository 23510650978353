import consultationApi from '@/api/consultationAPI'

export const consultationStore = {
    namespaced: true,

    state: {
        consultations: [],
        consultation: null,
        participants: [],
        lastCreatedConsultation: null,
        lastUpdatedConsultation: null,
        lastOperationSucceed: null,
    },
    mutations: {
        setConsultations(state, consultations) {
            state.consultations = consultations
        },
        setConsultation(state, consultation) {
            state.consultation = consultation
        },
        setParticipants(state, participants) {
            state.participants = participants
        },
        setLastOperationSucceed(state, succeed) {
            state.lastOperationSucceed = succeed
        },
        unsetLastOperationSucceed(state) {
            state.lastOperationSucceed = null
        },
        setLastCreatedConsultation(state, consultation) {
            state.lastCreatedConsultation = consultation
        },
        unsetLastCreatedConsultation(state) {
            state.lastCreatedConsultation = null
        },
        setLastUpdatedConsultation(state, consultation) {
            state.lastUpdatedConsultation = consultation
        },
        unsetLastUpdatedConsultation(state) {
            state.lastUpdatedConsultation = null
        }
    },
    actions: {
        async loadConsultationsInGroup({commit}, groupId) {
            commit('setConsultations', [])
            commit('unsetLastOperationSucceed')

            try {
                const consultations = (await consultationApi.getConsultationsInGroup(groupId)).data

                commit('setConsultations', consultations)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('setLastOperationSucceed', false)
            }
        },
        async loadConsultation({commit}, id) {
            commit('setConsultation', null)
            commit('unsetLastOperationSucceed')

            try {
                const consultation = (await consultationApi.getConsultation(id)).data

                commit('setConsultation', consultation)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('setLastOperationSucceed', false)
            }
        },
        async createConsultation({commit}, data) {
            commit('unsetLastCreatedConsultation')
            commit('unsetLastOperationSucceed')

            try {
                const consultation = (await consultationApi.createConsultation(data)).data

                commit('setLastCreatedConsultation', consultation)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async updateConsultation({commit}, data) {
            commit('unsetLastUpdatedConsultation')
            commit('unsetLastOperationSucceed')

            if (typeof data.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                const consultation = (await consultationApi.updateConsultation(data.id, data)).data

                commit('setLastUpdatedConsultation', consultation)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async removeConsultation({commit}, data) {
            commit('unsetLastOperationSucceed')

            if (typeof data.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                await consultationApi.removeConsultation(data.id)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async loadConsultationParticipants({commit}, consultationData) {
            commit('unsetLastOperationSucceed')

            if (typeof consultationData.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                const participants = (await consultationApi.getConsultationParticipants(consultationData.id)).data

                commit('setParticipants', participants)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async addConsultationParticipant({commit}, consultationData, participantData) {
            commit('unsetLastOperationSucceed')

            if (typeof consultationData.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                const participant = (await consultationApi.addConsultationParticipant(consultationData.id, participantData)).data

                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
        async removeConsultationParticipant({commit}, consultationData, participantData) {
            commit('unsetLastOperationSucceed')

            if (typeof consultationData.id === 'undefined' || typeof participantData.id === 'undefined') {
                commit('unsetLastOperationSucceed', false)
                return
            }

            try {
                await consultationApi.removeConsultationParticipant(consultationData.id, participantData.id)
                commit('setLastOperationSucceed', true)
            } catch (error) {
                commit('unsetLastOperationSucceed', false)
            }
        },
    }
}
