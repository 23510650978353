import axios from 'axios'

export default {
    getConsultationsInGroup(groupId) {
        return axios.get(`/consultation?group_id=${groupId}`)
    },
    getConsultationsWithVideoChat(videoChatId) {
        return axios.get(`/consultation?video_chat_id=${videoChatId}`)
    },
    getConsultation(id) {
        return axios.get(`/consultation/${id}`)
    },
    getConsultationParticipants(id) {
        return axios.get(`/consultation/${id}/participants`)
    },
    createConsultation(data) {
        return axios.post('/consultation', data)
    },
    updateConsultation(id, data) {
        return axios.put(`/consultation/${id}`, data)
    },
    removeConsultation(id) {
        return axios.delete(`consultation/${id}`)
    },
    addConsultationParticipant(consultationId, participantData) {
        return axios.post(`/consultation/${consultationId}/participant`, participantData)
    },
    removeConsultationParticipant(consultationId, participantId) {
        return axios.post(`/consultation/${consultationId}/participant/${participantId}`)
    }
}
