import axios from 'axios'

export default {

    /**
     * @desc Create a news post for a group
     * @param {Object} post_data
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async createNewPost(post_data, isMockStudent = false) {
        // Handle real user
        if (!isMockStudent) return await axios.post('/post', post_data)

        const currentDate = Date.now()

        // Mock response
        return {
            data: {
                ...post_data,
                assignment_id: '0',
                comments: [],
                edit_date: '0',
                firstname: 'Student',
                group_material_element_id: '0',
                lastname: 'Testing',
                post_date: currentDate / 1000,
                post_id: currentDate,
                post_status: 'public',
                profile_picture_url: '',
                mock_identifier: true,
            },
        }
    },

    /**
     * @desc Edit a group news post
     * @param {number} post_id
     * @param {Object} post_data
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async editPost(post_id, post_data, isMockStudent = false) {
        if (!isMockStudent) return await axios.put(`/post/${post_id}`, post_data)

        // Mock response
        return {}
    },

    async pinPost({ post_id, pinned }) {

        return await axios.put(`/post/pinned/${post_id}`, {
            pinned : pinned
        })

    },

    /**
     * @desc Delete a news post from a group
     * @param {number} post_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async removePost(post_id, isMockStudent = false) {
        // Handle real user
        if (!isMockStudent) return await axios.delete(`/post/${post_id}`)

        // Mock response
        return {}
    },
}
