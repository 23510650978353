import Vue from 'vue'
import textApi from '@/api/rawtextAPI'

export const rawtextStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        total_time_spent : {}
    },


    getters: {},


    actions : {

        /*
        * Function for saving a raw text element
        */
        async createRawText({ commit, state }, { text, title, group_id }) {
            return await textApi.createRawText({ text, title, group_id })
        },

        /**
         * @desc Get raw text data
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Raw text data
         * @param {Object} data.raw_text_id
         * @return {Promise} response
         */
        async getRawTextData(vx, data) {
          const { commit, rootState, state } = vx
          const { raw_text_id } = data

          // Return local data if present
          if (
            state.total_time_spent
            && state.total_time_spent[raw_text_id]
            && parseInt(state.total_time_spent[raw_text_id]) > 0
          ) {
            return parseInt(state.total_time_spent[raw_text_id])
          }

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Get data
          const response = await textApi.getRawTextData({
            raw_text_id
          }, isMockStudent)

          // Pull total time
          const total_time_spent = (
            response
            && response.data
            && response.data.total_time_spent
            )
            ? parseInt(response.data.total_time_spent)
            : 0

          // Update time spent state
          commit('SET_TIME_SPENT_FOR_TEXT', {
            raw_text_id,
            total_time_spent,
          })

          return total_time_spent
        },

        /*
        * Function for saving a raw text element
        */
        async saveRawText({ commit, state }, { raw_text_id, text, title, group_id }) {
            return await textApi.saveRawText({ raw_text_id, text, title, group_id })
        },

        /**
         * @desc Store raw text data point
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Raw text data
         * @param {Object} data.raw_text_id
         * @param {Object} data.total_time_spent
         * @return {Promise} response
         */
        async storeRawTextDataPoint(vx, data) {
          const { commit, rootState } = vx
          const { raw_text_id, total_time_spent } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Store data point
          textApi.storeRawTextDataPoint({
            raw_text_id,
            total_time_spent
          }, isMockStudent)

          // Update data point in state
          commit('SET_TIME_SPENT_FOR_TEXT', {
              raw_text_id,
              total_time_spent: parseInt(total_time_spent)
          })

          return 1
        },

    },


    mutations: {

        SET_TIME_SPENT_FOR_TEXT: (state, { raw_text_id, total_time_spent }) => {
            Vue.set(state.total_time_spent, raw_text_id , total_time_spent)
        }


    }
}
