
const groupActivityAssignmentsView = () => import('@/views/groups/groupstatistics/groupActivityAssignmentsView')
const groupActivityCoursesView = () => import('@/views/groups/groupstatistics/groupActivityCoursesView')
const groupActivityQuizzesView = () => import('@/views/groups/groupstatistics/groupActivityQuizzesView')

export default  [
    {
        path: 'assignments',
        component: groupActivityAssignmentsView,
        meta: { requiresAuth: true, view : 'group-activity-assignments' }
    },
    {
        path: 'courses',
        component: groupActivityCoursesView,
        meta: { requiresAuth: true, view : 'group-activity-courses' }
    },
    {
        path: 'quizzes',
        component: groupActivityQuizzesView,
        meta: { requiresAuth: true, view : 'group-activity-quizzes' }
    },
]
