import axios from 'axios'

export default {

    createFolder({ group_id, parent_folder, name, status }) {
        return axios.post('/folders', {
            parent_folder : parent_folder,
            group_id : group_id,
            name : name,
            status : status
        })
    },

    getFolders({ group_id }) {
        return axios.get('/folders/'+group_id)
    },

    fetchFolderFiles({ folder_id, group_id }) {
        return axios.get('/folders/'+group_id+'/files/'+folder_id)
    },

    /**
     * @desc Move folder into parent folder
     * @param {Object} data - Folder data
     * @return {Promise} response
     */
    moveFolder(data) {
        const { folder_id, group_id, parent_folder } = data

        return axios.put(`/folders/move/${folder_id}`, {
            group_id: parseInt(group_id),
            parent_folder: parseInt(parent_folder),
        })
    },

    removeFolder(folder_id) {
        return axios.delete('/folders/'+folder_id)
    },

    saveFolder({ folder_id, group_id, title, status }) {
        return axios.put(`/folders/${folder_id}`, {
            group_id : group_id,
            title : title,
            status : status
        })
    },

    uploadFileToFolder({ folder_id, group_id, file_info }) {
        return axios.post('/folders/files', {
            folder_id : folder_id,
            group_id : group_id,
            file_info : file_info
        })
    }

}
