import axios from 'axios'

export default {
    async loadChats() {
        return await axios.get('/video-chat')
    },
    async loadChat(id) {
        return await axios.get(`/video-chat/${id}`)
    },
    async createChat(data) {
        return await axios.post('/video-chat', data)
    },
    async updateChat(id, data) {
        return await axios.put(`/video-chat/${id}`, data)
    },
    async loadMembers(chatId) {
        return await axios.get(`/video-chat/${chatId}/member`)
    },
    async loadMemberData(chatId) {
        return await axios.get(`/video-chat/${chatId}/member/me`)
    },
    async deleteChat(id) {
        return await axios.delete(`/video-chat/${id}`)
    },
    async startChat(chatId) {
        return await axios.post(`/video-chat/${chatId}/start`)
    },
    async stopChat(chatId) {
        return await axios.post(`/video-chat/${chatId}/stop`)
    },
    async joinChat(chatId) {
        return await axios.post(`/video-chat/${chatId}/join`)
    },
    async leaveChat(chatId) {
        return await axios.post(`/video-chat/${chatId}/leave`)
    },
    async addMember(chatId, data, quiet) {
        if (typeof quiet === 'undefined') {
            quiet = false
        }

        return await axios.post(`/video-chat/${chatId}/member`, {
            member: data,
            quiet: quiet
        })
    },
    async addMembers(chatId, data, quiet) {
        if (typeof quiet === 'undefined') {
            quiet = false
        }

        return await axios.post(`/video-chat/${chatId}/members`, {
            members: data,
            quiet: quiet
        })
    },
}
