import Vue from 'vue'
import timelineApi from '@/api/timelineAPI'

export const timelineStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        adaptive_object_type_id : 20,
        available_colors : [
            'cyan',
            'green',
            'pink',
            'amber',
            'orange',
            'blue',
            'red',
            'teal',
            'yellow'
        ],
        timelines : [],
    },


    getters: {
        getTimeLineFromId: (state) => (timeline_id) => {
            const timelines = state.timelines
            let data = {}
            timelines.map(timeline => {
                if (parseInt(timeline_id) === parseInt(timeline.timeline_id)) {
                    data = timeline
                }
            })
            return data
        },
    },


    actions : {

        async createTimeline(vx, data) {

            const { commit, state, getters } = vx
            const { group_id, timeline_data } = data

            const response = await timelineApi.createTimeline({
                group_id : group_id,
                timeline_data : timeline_data
            })

            commit('SET_TIMELINE', {
                new_timeline : response.data
            })

            return response.data

        },

        async editTimeline(vx, data) {

            const { commit, state, getters } = vx
            const { timeline_id, group_id, timeline_data } = data

            const response = await timelineApi.editTimeline({
                timeline_id : timeline_id,
                group_id : group_id,
                timeline_data : timeline_data
            })

            commit('SET_TIMELINE', {
                new_timeline : response.data
            })

        },

        async fetchTimelineData(vx, data) {

            const { commit, state, getters } = vx
            const { timeline_id, force_refresh } = data

            if (!force_refresh) {
                const timelineData = getters.getTimeLineFromId(timeline_id)
                if (timelineData && timelineData.timeline_id) {
                    return timelineData
                }
            }

            const response = await timelineApi.fetchTimelineData({
                timeline_id : timeline_id
            })

            commit('SET_TIMELINE', {
                new_timeline : response.data
            })

            return response.data
        },


    },


    mutations: {

        SET_TIMELINE: (state, { new_timeline }) => {

            let timelines = state.timelines

            let found_index = -1
            timelines.map( (timeline, index) => {
                if (parseInt(timeline.timeline_id) === parseInt(new_timeline.timeline_id)) {
                    found_index = index
                }
            })

            if (found_index > -1) {
                Vue.set(state.timelines, found_index, new_timeline)
            } else {
                state.timelines.push(new_timeline)
            }


        },

    }
}
