import axios from 'axios'

export default {

    async addToFlashCardsStats({ flashcard_id }) {
        return axios.put(`/flashcard/stats/add/${flashcard_id}`, {})
    },

    async createCollection({ data }) {
        return await axios.post('/flashcards/collections', data)
    },

    async createFlashCardsStudent({ data }) {
        return await axios.post('/flashcards/student', data)
    },

    async editCollection({ collection_id, data }) {
        return await axios.put(`/flashcards/collections/${collection_id}`, data)
    },

    async fetchCollectionFlashCards({ collection_id }) {
        return await axios.get(`/flashcards/collection/${collection_id}`)
    },

    async fetchAllGroupFlashcardCollections({ group_id }) {
        return await axios.get(`/flashcards/collections/group/${group_id}`)
    },

    async removeCollection({ collection_id }) {
        return await axios.delete(`/flashcards/collections/${collection_id}`)
    },



}
