import Vue from 'vue'
import profileApi from '@/api/profileAPI'

export const profileStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        user_group_assignments : {},
        user_group_courses : {},
        user_group_course_elements : {},
        user_group_materials : {},
        user_group_videos : {},
        user_groups : {},
        user_quizzes : {},
        user_quiz_tries : {},
        user_quiz_try_review : {}
    },


    getters: {},


    actions: {
      
        async removeProfilePicture({ commit, state }) {
            await profileApi.removeProfilePicture()
        },

        /**
         * Function for getting the tries the user has had in that quiz
         */
        async getQuizTries({ commit, state }, { quiz_id, user_id }) {

            if (state.user_quiz_tries[user_id] && state.user_quiz_tries[user_id][quiz_id]) {
                return state.user_quiz_tries[user_id][quiz_id]
            } else {
                const response = await profileApi.getQuizTries({
                    quiz_id : quiz_id,
                    user_id : user_id
                })
                commit("SET_USER_QUIZ_TRIES", {
                    quiz_id : quiz_id,
                    user_id : user_id,
                    tries : response.data
                })
                return response.data
            }
        },

        /**
         * Function for getting the questions review from a quiz or archived attempt
         */
        async getQuizTryReview({ commit, state }, { quiz_id, user_id, archived }) {

            if (state.user_quiz_try_review[user_id] && state.user_quiz_try_review[user_id][quiz_id] && state.user_quiz_try_review[user_id][quiz_id][archived]) {
                return state.user_quiz_try_review[user_id][quiz_id][archived]
            } else {
                const response = await profileApi.getQuizTryReview({
                    quiz_id : quiz_id,
                    user_id : user_id,
                    archived : archived
                })
                commit("SET_USER_QUIZ_TRY_REVIEW", {
                    quiz_id : quiz_id,
                    user_id : user_id,
                    archived : archived,
                    questions : response.data
                })
                return response.data
            }
        },


        /**
         * Function for getting the user courses for statistics
         */
        async getUserAssignments({ commit, state }, { group_id, user_id }) {

            if (state.user_group_assignments[group_id]) {
                return state.user_group_assignments[group_id]
            } else {
                const response = await profileApi.getUserAssignments({
                    group_id : group_id,
                    user_id : user_id
                })
                commit("SET_USER_ASSIGNMENTS", {
                    group_id : group_id,
                    user_id : user_id ,
                    assignments : response.data
                })
                return response.data
            }
        },

        /**
         * Function for getting the user courses for statistics
         */
        async getUserCourses({ commit, state }, { group_id }) {

            if (state.user_group_courses[group_id]) {
                return state.user_group_courses[group_id]
            } else {
                const response = await profileApi.getUserCourses({
                    group_id : group_id
                })
                commit("SET_USER_COURSES", {
                    group_id : group_id,
                    courses  : response.data
                })
                return response.data
            }
        },

        /**
         * Function for getting the user progression for a course by pulling all material elements and sum up
         */
        async getUserCoursesStatistics({ commit, state }, { group_id, user_id }) {

            if (state.user_group_course_elements[user_id] && state.user_group_course_elements[user_id][group_id]) {
                return state.user_group_course_elements[user_id][group_id]
            } else {
                const response = await profileApi.getUserCoursesStatistics({
                    group_id : group_id,
                    user_id : user_id
                }).catch( err => {
                    return []
                })
                commit("SET_USER_COURSE_ELEMENTS", {
                    user_id : user_id,
                    group_id : group_id,
                    elements : response.data
                })
                return response.data
            }
        },

        /**
         * Function for getting the user courses for statistics
         */
        async getUserGroupMaterials({ commit, state }, { group_id}) {

            if (state.user_group_materials[group_id]) {
                return state.user_group_materials[group_id]
            } else {
                const response = await profileApi.getUserGroupMaterials({
                    group_id : group_id
                })
                commit("SET_USER_GROUP_MATERIALS", {
                    group_id : group_id,
                    materials : response.data
                })
                return response.data
            }
        },

        /**
         * Function for getting the user courses for statistics
         */
        async getUserGroupMaterialElements({ commit, state }, { material_id, user_id }) {
            const response = await profileApi.getUserGroupMaterialElements({
                material_id : material_id,
                user_id : user_id
            })
            return response.data
        },


        /**
        * Function for getting all user groups to use in quizzes data
        */
        async getUserGroups({ commit, state }, { user_id }) {

            if (state.user_groups[user_id] && state.user_groups[user_id].length) {
                return state.user_groups[user_id]
            } else {
                const response = await profileApi.getUserGroups({
                    user_id : user_id
                })
                commit("SET_USER_GROUPS", {
                    user_id : user_id,
                    groups : response.data
                })
                return response.data
            }
        },

        /**
        * Function for getting data for quizzes in profile for user
        */
        async getUserQuizzes({ commit, state }, { group_id, user_id }) {

            if (state.user_quizzes[user_id] && state.user_quizzes[user_id][group_id] && state.user_quizzes[user_id][group_id].length) {
                return state.user_quizzes[group_id]
            } else {
                const response = await profileApi.getUserQuizzes({
                    group_id : group_id,
                    user_id : user_id
                })
                commit("SET_USER_QUIZZES", {
                    group_id : group_id,
                    user_id : user_id,
                    quizzes : response.data
                })
                return response.data
            }
        },

        /**
         * Function for getting the user courses for statistics
         */
        async getUserVideos({ commit, state }, { group_id, user_id }) {

            if (state.user_group_videos[user_id] && state.user_group_videos[user_id][group_id]) {
                return state.user_group_videos[user_id][group_id]
            } else {
                const response = await profileApi.getUserVideos({
                    group_id : group_id,
                    user_id : user_id
                })
                commit("SET_USER_VIDEOS", {
                    group_id : group_id,
                    user_id : user_id,
                    videos : response.data
                })
                return response.data
            }
        },



    },




    mutations: {

        SET_USER_ASSIGNMENTS: (state, { group_id, user_id, assignments } ) => {
            if (!state.user_group_assignments[user_id]) {
                Vue.set(state.user_group_assignments, user_id, {})
            }
            Vue.set(state.user_group_assignments[user_id], group_id, assignments)
        },

        SET_USER_COURSES: (state, { group_id, courses } ) => {
            Vue.set(state.user_group_courses, group_id, courses)
        },

        SET_USER_COURSE_ELEMENTS: (state, { user_id, group_id, elements } ) => {
            if (!state.user_group_course_elements[user_id]) {
                Vue.set(state.user_group_course_elements, user_id, {})
            }
            Vue.set(state.user_group_course_elements[user_id], group_id, elements)
        },

        SET_USER_GROUP_MATERIALS: (state, { group_id, materials } ) => {
            Vue.set(state.user_group_materials, group_id, materials)
        },

        SET_USER_GROUPS: (state, { user_id, groups }) => {
            if (!state.user_groups[user_id]) {
                Vue.set(state.user_groups, user_id, {})
            }
            Vue.set(state.user_groups, user_id, groups)
        },

        SET_USER_QUIZZES: (state, { group_id, user_id, quizzes }) => {
            if (!state.user_quizzes[user_id]) {
                Vue.set(state.user_quizzes, user_id, {})
            }
            if (!state.user_quizzes[user_id][group_id]) {
                Vue.set(state.user_quizzes[user_id], group_id, {})
            }
            Vue.set(state.user_quizzes[user_id], group_id, quizzes)
        },

        SET_USER_VIDEOS: (state, { group_id, user_id, videos }) => {
            if (!state.user_group_videos[user_id]) {
                Vue.set(state.user_group_videos, user_id, {})
            }
            if (!state.user_group_videos[user_id][group_id]) {
                Vue.set(state.user_group_videos[user_id], group_id, {})
            }
            Vue.set(state.user_group_videos[user_id], group_id, videos)
        },

        SET_USER_QUIZ_TRIES: (state, { quiz_id, user_id , tries}) => {
            if (!state.user_quiz_tries[user_id]) {
                Vue.set(state.user_quiz_tries, user_id, {})
            }
            if (!state.user_quiz_tries[user_id][quiz_id]) {
                Vue.set(state.user_quiz_tries[user_id], quiz_id, {})
            }
            Vue.set(state.user_quiz_tries[user_id], quiz_id, tries)
        },

        SET_USER_QUIZ_TRY_REVIEW: (state, { quiz_id, user_id, archived, questions}) => {
            if (!state.user_quiz_try_review[user_id]) {
                Vue.set(state.user_quiz_try_review, user_id, {})
            }
            if (!state.user_quiz_try_review[user_id][quiz_id]) {
                Vue.set(state.user_quiz_try_review[user_id], quiz_id, {})
            }
            if (!state.user_quiz_try_review[user_id][quiz_id][archived]) {
                Vue.set(state.user_quiz_try_review[user_id][quiz_id], archived, {})
            }
            Vue.set(state.user_quiz_try_review[user_id][quiz_id], archived, questions)
        },



    }
}
