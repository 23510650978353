
/*
    VIEWS
 */
const groupSettingsApps = () => import('@/views/groups/groupsettings/GroupSettingsApps')
const groupSettingsArchive = () => import('@/views/groups/groupsettings/groupSettingsArchiveOld')
const groupSettingsAssignments = () => import('@/views/groups/groupsettings/groupSettingsAssignmentsOld')
const groupSettingsCopy = () => import('@/views/groups/groupsettings/groupSettingsCopyOld')
const groupSettingsExamMode = () => import('@/views/groups/groupsettings/groupSettingsExamModeOld')
const groupSettingGeneral = () => import('@/views/groups/groupsettings/GroupSettingGeneral')
const groupSettingsNews = () => import('@/views/groups/groupsettings/groupSettingsNewsOld')
const groupSettingsSubgroups = () => import('@/views/groups/groupsettings/groupSettingsSubgroupsOld')

export default  [
    {
        path: 'apps',
        component: groupSettingsApps,
        meta: { requiresAuth: true, view : 'group-settings-apps' }
    },
    {
        path: 'archive',
        component: groupSettingsArchive,
        meta: { requiresAuth: true, view : 'group-settings-archive' }
    },
    {
        path: 'assignments',
        component: groupSettingsAssignments,
        meta: { requiresAuth: true, view : 'group-settings-assignments' }
    },
    {
        path: 'copy',
        component: groupSettingsCopy,
        meta: { requiresAuth: true, view : 'group-settings-copy' }
    },
    {
        path: 'exam-mode',
        component: groupSettingsExamMode,
        meta: { requiresAuth: true, view : 'group-settings-exam' }
    },
    {
        path: 'general',
        component: groupSettingGeneral,
        meta: { requiresAuth: true, view : 'group-settings-general' }
    },
    {
        path: 'news',
        component: groupSettingsNews,
        meta: { requiresAuth: true, view : 'group-settings-news' }
    },
    {
        path: 'subgroups',
        component: groupSettingsSubgroups,
        meta: { requiresAuth: true, view : 'group-settings-subgroups' }
    },
]
