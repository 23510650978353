import axios from 'axios'

export default {

  async createCourse({ group_id, title, hidden = false }) {
        return await axios.post('/courses', {
            group_id : group_id,
            title : title,
            hidden : hidden
        })
    },

    async fetchGroupCourses({ group_id, library_category_id=0 }) {
        return await axios.get(`/courses/group/${group_id}/${library_category_id}`)
    },

    async getCourseCompletions() {
        return await axios.get('/courses/completions')
    },

    async fetchCourseDependencies({ group_id }) {
        return await axios.get(`/courses/dependencies/${group_id}`)
    },

    async removeCourse({ course_id }) {
        return await axios.delete(`/courses/${course_id}`)
    },

    async removeMaterialFromCourse({ course_id, material_id }) {
        return await axios.delete(`/courses/${course_id}/materials/${material_id}`)
    },

    async saveCourse({ course_id, group_id, title, status, dependencies }) {
        return await axios.put(`/courses/${course_id}`, {
            group_id : group_id,
            title  : title,
            status : status,
            dependencies : dependencies
        })
    },

    async saveCourseDependencies({ dependencies }) {
        return await axios.post(`/courses/dependencies`, {
            dependencies : dependencies
        })
    },

    async saveCoursesSequence({ group_id, courses }) {
        return await axios.put(`/courses/sequence/${group_id}`, {
            group_id : group_id,
            courses  : courses
        })
    }

}
