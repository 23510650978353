import axios from 'axios'

export default {

    async fetchGroupLearningElementsPaginate({ group_id, adaptive_object_type_id, page, per_page, sort, query }) {
        const sortQuery = sort ? `&sort=${sort}` : ''
        const searchQuery = query ? `&query=${query}` : ''
        return await axios.get(`groups/${group_id}/element-type/${adaptive_object_type_id}/elements?page=${page}&per_page=${per_page}${sortQuery}${searchQuery}`)
    },

    async getLearningElementData({ group_id, adaptive_object_type_id, element_id }) {
        return await axios.get(`groups/${group_id}/element-type/${adaptive_object_type_id}/elements/${element_id}`)
    },
    
    async fetchGroupLearningElements({ group_id, adaptive_object_type_id }) {
        return await axios.get(`groups/${group_id}/element-type/${adaptive_object_type_id}/elements`)
    },

    async addMaterialToCourse({ group_id, course_id, material_id }) {
        return await axios.post('/courses/materials', {
            group_id : group_id,
            course_id : course_id,
            material_id : material_id
        })
    },

    async addNewMaterialElement({ group_id, material_id, adaptive_object_type_id, adaptive_object_id }) {
        return await axios.post('/materials/elements', {
            group_id : group_id,
            material_id : material_id,
            adaptive_object_type_id : adaptive_object_type_id,
            adaptive_object_id : adaptive_object_id
        })
    },

    async createMaterial({ group_id, title, status }) {
        return await axios.post('/materials', {
            group_id : group_id,
            title : title,
            status : status
        })
    },

    async fetchAllGroupMaterialElements({ group_id, reference_course_id=0 }) {
        return await axios.get(`/materials/elements/group/${group_id}/${reference_course_id}`)
    },

    async fetchGroupMaterials({ group_id, reference_course_id }) {
        return await axios.get(`/materials/group/${group_id}/${reference_course_id}`)
    },

    async fetchGroupMaterialElements({ material_id }) {
        return await axios.get(`/materials/elements/${material_id}`)
    },

    /**
     * @desc Register material as seen by user
     * @param {Object} data - Material data
     * @param {number} data.adaptive_object_type_id
     * @param {number} data.adaptive_object_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async registerMaterialElementSeen(data, isMockStudent = false) {
      const { adaptive_object_type_id, adaptive_object_id } = data

      // Handle real user
      if (!isMockStudent) {
        return await axios.post('/activity/seen', {
          adaptive_object_type_id,
          adaptive_object_id,
        })
      }

      // Mock response
      return {
        data: {
          read_date: Date.now() / 1000
        }
      }
    },

    /**
     * @desc Register material as understood by user
     * @param {Object} data - Material data
     * @param {number} data.adaptive_object_type_id
     * @param {number} data.adaptive_object_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async registerMaterialElementUnderstood(data, isMockStudent = false) {
      const { adaptive_object_type_id, adaptive_object_id } = data

      // Handle real user
      if (!isMockStudent) {
        return await axios.post('/activity/understood', {
          adaptive_object_type_id,
          adaptive_object_id,
        })
      }

      // Mock response
      return {
        data: {
          understood_date: Date.now() / 1000
        }
      }
    },

    async removeMaterial(material_id) {
        return await axios.delete('/materials/'+material_id)
    },

    async removeMaterialElement(material_element_id) {
        return await axios.delete(`/materials/elements/${material_element_id}`)
    },

    async saveMaterial({ material_id, group_id, title, status }) {
        return await axios.put(`/materials/${material_id}`, {
            group_id : group_id,
            title : title,
            status : status
        })
    },

    async saveMaterialElement({ element_id, group_id, material_id, adaptive_object_type_id, adaptive_object_id }) {
        return await axios.put(`/materials/elements/${element_id}`, {
            group_id : group_id,
            material_id : material_id,
            adaptive_object_type_id : adaptive_object_type_id,
            adaptive_object_id : adaptive_object_id
        })
    },

    async saveMaterialElements({ material_id, elements }) {
        return await axios.put(`/materials/elements/update/${material_id}`, {
            elements : elements
        })
    },

    async saveMaterialElementsSequence({ material_id, elements }) {
        return await axios.put(`/materials/elements/sequence/${material_id}`, {
            elements : elements
        })
    },

    async saveMaterialsSequence({ group_id, course_id, materials }) {
        return await axios.put(`/materials/sequence/${group_id}`, {
            materials : materials,
            course_id : course_id
        })
    },

    async setElementHidden({ hidden , element_id }) {
        return await axios.put(`/materials/element/hidden/${element_id}`, {
            hidden : hidden
        })
  },

    async moveLesson({ lesson_id, destination_course_id }) {
        if (!lesson_id || !destination_course_id) return
        return await axios.post(`/lesson/${lesson_id}/move`, {
                destination_course_id
            })
    },
    async moveActivity({ lesson_id, element_id, destination_lesson_id }) {
        if (!lesson_id || !element_id || !destination_lesson_id) return
        return await axios.post(`/lesson/${lesson_id}/element/${element_id}/move`, {
                destination_lesson_id
            })
    }


}
