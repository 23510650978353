class EdaptioDialogue {
    _id
    _manager
    _topic
    _incomingMessageHandler
    _muted
    _verbose
    _eventListener


    /**
     * Constructor
     * @param manager
     * @param topic
     * @param verbose
     */
    constructor(manager, topic, verbose) {
        this._id = this._newId(10)
        this._manager = manager
        this._topic = topic
        this._muted = false
        this._verbose = verbose
        this._incomingMessageHandler = []
    }

    /**
     * Get dialogue id
     * @returns {*}
     */
    id() {
        return this._id
    }

    /**
     * Get dialogue topic
     * @returns {*}
     */
    topic() {
        return this._topic
    }

    /**
     * Send message in this dialogue
     * @param message
     * @returns {*}
     */
    send(message) {
        return this
            ._manager
            .send(this._topic, message)
    }

    /**
     * Mute dialogue
     * Incoming messages handler will not be called
     */
    mute() {
        this._muted = true
    }

    /**
     * Unmute dialogue
     */
    unmute() {
        this._muted = false
    }

    /**
     * Set incoming message handler
     * @param callback
     * @returns {boolean}
     */
    onIncomingMessage(callback) {
        if (typeof callback !== 'function') {
            return false
        }

        this._incomingMessageHandler.push(callback)
    }

    /**
     * onmessage event handler.
     * You should not call this directly.
     */
    eventListener() {
        if (!this._eventListener) {
            const dialog = this

            this._eventListener = function (event) {
                const data = JSON.parse(event.data)
                if (data.topic === dialog._topic && dialog._incomingMessageHandler.length && !dialog._muted) {
                    dialog._incomingMessageHandler.forEach((handler) => handler(data.message))
                    dialog._log('New incoming message', data)
                }
            }
        }

        return this._eventListener
    }

    /**
     * Get new id
     * @param length
     * @returns {string}
     * @private
     */
    _newId(length) {
        const abc = 'abcdefghijklmnopqrstuvw0123456789'

        let result = ''
        for (let i = 0; i < length; i++) {
            let index = Math.floor(Math.random() * Math.floor(abc.length))
            result += String(abc[index])
        }

        return result
    }

    /**
     * Output data to console
     * @param message
     * @param data
     * @private
     */
    _log(message, data) {
        if (this._verbose) {
            console.log(`🗨 DIALOGUE ${this._topic}:` + message, data || {})
        }
    }
}

//TODO: Restore subscriptions and call "when ready" callbacks when stop\start
class EdaptioDialogueManager {
    _ws
    _url
    _verbose
    _userData
    _dialogues
    _stopped = true
    _reconnectAttempts = 0
    _maxReconnectAttempts = 3
    _reconnectAttemptTimeout = 60000
    _whenReadyCallbacks = []

    /**
     * Constructor
     * @param url
     * @param verbose
     */
    constructor(url, verbose) {
        this._url = url
        this._verbose = verbose || false
        this._dialogues = []
    }
    /**
     * Start dialogue manager
     */
    start() {
        if (this._stopped) {
            this._ws = new WebSocket(this._url)
            this._addDisconnectListeners()
            this._attachWhenReadyCallbacks()

            this._stopped = false

            this._log('Service started')
        }
    }

    /**
     * Stop dialogue manager
     */
    stop() {
        if (this._isWsOpened()) {
            this._ws.close()
        }

        this._ws = null
        this._stopped = true

        this._log('Service stopped')
    }

    getDialog(topic) {
        let dialogue = this._dialogues.find((d) => d.topic() === topic)

        if (dialogue) {
            return dialogue
        }
        return null
    }

    /**
     * Subscribe to dialogue
     * @param topic
     * @returns {EdaptioDialogue|boolean}
     */
    subscribe(topic) {

        if (!this._isWsOpened()) {
            return false
        }

        let dialogue = this._dialogues.find((d) => d.topic() === topic)

        if (dialogue) {
            return dialogue
        }
        try {
            this._ws.send(JSON.stringify({
                action: 'subscribe',
                topic: topic,
                userId: (this._userData.id) ? this._userData.id : null
            }))
        } catch (error) {
            return false
        }

        dialogue = new EdaptioDialogue(this, topic, this._verbose)

        this._dialogues.push(dialogue)
        this
            ._ws
            .addEventListener('message', dialogue.eventListener())

        this._log('Subscribed to dialogue', dialogue)

        return dialogue
    }

    /**
     * Unsubscribe from dialogue
     * @param dialogue
     * @returns {boolean}
     */
    unsubscribe(dialogue) {
        if (this._isWsOpened() && dialogue instanceof EdaptioDialogue && dialogue.topic()) {
            try {
                this._ws.send(JSON.stringify({
                    action: 'unsubscribe',
                    topic: dialogue.topic()
                }))
            } catch (error) {
                return false
            }

            this
                ._ws
                .removeEventListener('message', dialogue.eventListener())
            this._dialogues = this._dialogues.filter((_dialogue) => {
                return _dialogue.id() !== dialogue.id()
            })

            this._log('Unsubscribed from dialogue', dialogue)

            return true
        }

        return false
    }

      /**
     * Send message to topic
     * @param topic
     * @param message
     * @param options
     * @returns {boolean}
     */
    send(topic, message, options) {
        if (!this._isWsOpened()) {
            return false
        }

        const includeMe = options && typeof options.includeMe !== 'undefined'
            ? Boolean(options.includeMe)
            : false
        const sendToServices = options && typeof options.sendToServices !== 'undefined'
          ? Boolean(options.sendToServices)
          : false
        const sendOnlyToServices = options && typeof options.sendOnlyToServices !== 'undefined'
          ? Boolean(options.sendOnlyToServices)
          : false

        try {
            this._ws.send(JSON.stringify({
                action: 'post-message',
                topic: topic,
                message: message,
                includeMe: includeMe,
                sendToServices: sendToServices,
                sendOnlyToServices: sendOnlyToServices
            }))
        } catch (error) {
            this._log(error)
            return false
        }

        this._log(`Message sent to topic ${topic}`, message)
    }
    /**
     * Personalizes dialogue manager (bind it to some user)
     * @param userData
     */
    personalize(userData) {
        if (userData === 'guest') {
            this._userData = null
        } else if (userData.id) {
            this._userData = {
                id: userData.id,
                firstName: (typeof userData.firstName !== 'undefined') ? userData.firstName : '',
                lastName: (typeof userData.lastName !== 'undefined') ? userData.lastName : ''
            }
        }

        this._log('Dialogue manager personalized', userData)
    }

    /**
     * Add callback when WS is opened
     * @param callback
     */
    whenReady(callback) {
        if (typeof callback === 'function') {
            if (!this._isWsCreated()) {
                this._whenReadyCallbacks.push({
                    callback,
                    attached: false
                })
                return
            }

            this._whenReadyCallbacks.push({
                callback,
                attached: true
            })

            this._isWsOpened()
                ? callback()
                : this._ws.addEventListener('open', callback)
        }
    }

    /**
     * Recreate ws connection
     * @private
     */
    _reconnect() {
        this._reconnectAttempts++
        this._log(`Reconnecting...attempt ${this._reconnectAttempts}/${this._maxReconnectAttempts}`)

        this.stop()
        this.start()

        this.whenReady(() => {
            //Renew all topics subscriptions
            for (const dialogue of this._dialogues) {
                this._ws.send(JSON.stringify({
                    action: 'subscribe',
                    topic: dialogue.topic(),
                    userId: (this._userData.id) ? this._userData.id : null
                }))

                this
                    ._ws
                    .addEventListener('message', dialogue.eventListener())
            }
            //Reset reconnect attempts
            this._reconnectAttempts = 0
        })
    }

    /**
     * Add close and error listeners to ws to be able to reconnect in case or connection lost
     * @private
     */
    _addDisconnectListeners() {
        if (this._isWsCreated()) {
            this._ws.addEventListener('close', (event) => {
                //Reconnect only in case service was not stopped manually and not normal close
                if (!this._stopped && event.code !== 1000) {
                    if (this._reconnectAttempts < this._maxReconnectAttempts) {
                        setTimeout(() => this._reconnect(), this._reconnectAttemptTimeout)
                    }
                }
                this._log('WS closed', event)
            })
            this._ws.addEventListener('error', (event) => this._log('WS error', event))
        }
    }

    /**
     * Attach "when ready" callbacks
     * @private
     */
    _attachWhenReadyCallbacks() {
        if (this._isWsCreated()) {
            this._whenReadyCallbacks.forEach((callback) => {
                if (!callback.attached) {
                    //Maybe WS is already opened
                    this._isWsOpened()
                        ? callback()
                        : this._ws.addEventListener('open', callback.callback)

                    callback.attached = true
                }
            })
        }
    }

    /**
     * Ensure that ws object is created
     * @returns {boolean}
     * @private
     */
    _isWsCreated() {
        return this._ws instanceof WebSocket
    }

    /**
     * Ensure that websocket connection is open
     * @returns {boolean}
     * @private
     */
    _isWsOpened() {
        return this._isWsCreated() && this._ws.readyState === WebSocket.OPEN
    }

    /**
     * Output data to console
     * @param message
     * @param data
     * @private
     */
    _log(message, data) {
        if (this._verbose) {
            console.log('🗨 DIALOGUE MANAGER:' + message, data || {})
        }
    }

    /**
     * Destructor
     */
    destroy() {
        if (this._isWsOpened()) {
            for (const dialogue of this._dialogues) {
                this.unsubscribe(dialogue)
            }
        }

        this.stop()
        this._dialogues = []
        this._whenReadyCallbacks = []

        this._log('Service destroyed')
    }
}

export default {
    install(Vue, options) {
        Vue.prototype.$dialogueManager = new EdaptioDialogueManager(options.url, options.verbose)
    }
}
