import axios from 'axios'

export default {
    getCounsellors({query, institutionId, page, per_page, active = 0}) {
        let params = {}

        if (query) {
            params.query = query
        }

        if (institutionId) {
            params.institutionId = institutionId
        }

        if (page) {
            params.page = page
        }

        if (per_page) {
            params.per_page = per_page
        }
            
        params.active = active

        return axios.get(
            '/counsellors/list',
            {
                params: params
            }
        )
    },
    getCounsellorStudents({counsellorId}) {
        return axios.get(
            `/counsellors/${counsellorId}/students`
        )
    },
    getPossibleCounsellorStudents({counsellorId, query, page, per_page}) {
        let params = {}

        if (query) {
            params.query = query
        }

        if (page) {
            params.page = page
        }

        if (per_page) {
            params.per_page = per_page
        }

        return axios.get(
            `/counsellors/${counsellorId}/possible-students`,
            {
                params: params
            }
        )
    },
    assignUserCounsellor({ userId, counsellorUserId}) {
        return axios.post('/counsellors/assign', {
            userId: userId,
            counsellorUserId: counsellorUserId
        })
    },
    unAssignUserCounsellor({ userId, counsellorUserId}) {
        return axios.post('/counsellors/un-assign', {
            userId: userId,
            counsellorUserId: counsellorUserId
        })
    }
}
