import axios from 'axios'

export default {
    async setupPipelines(parameters) {
        /* Parameters should be array of objects */
        /*  [
                {
                    what: '',
                    language: '',
                    source: {
                        text: ''
                    },
                    output: ''
                }
            ]
        */
        if(!parameters || !parameters.length) return 0
        return await axios.post(`/pipelines`, {
                name: "ai",
                parameters
        })
    },
    async runPipelines(id) {
        if(!id) return 0
        return await axios.post(`/pipelines/${id}/run`)
    },
    async getResults(id) {
        if(!id) return 0
        return await axios.get(`/pipelines/${id}`)
    },
    async sendNotification({ pipeline_id, group_id, course_id, lesson_id }) {
        if(!pipeline_id || !group_id || !course_id || !lesson_id) return 0
        return await axios.post(`/pipelines/notification`, {
            pipeline_id,
            group_id,
            course_id,
            lesson_id
        })
    }
}