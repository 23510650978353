var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "main-content-wrapper tw-w-full tw-min-h-screen tw-flex tw-flex-col main-gradient",
      class: [_vm.isLoggedIn ? "lg:tw-bg-wild-sand" : "main-gradient tw-h-full"]
    },
    [
      _c("dialogue"),
      _vm._v(" "),
      _c("heartbeat"),
      _vm._v(" "),
      _c("video-chat-notification"),
      _vm._v(" "),
      _c("navbar", {
        on: { logout: _vm.logout, "launch-intercom": _vm.launchIntercom }
      }),
      _vm._v(" "),
      _c(
        "main",
        {
          staticClass: "tw-w-full tw-flex tw-flex-col tw-flex-1",
          class: [_vm.isLoggedIn ? "lg:tw-bg-wild-sand" : "main-gradient"]
        },
        [
          !_vm.isLoggedIn && !_vm.isLoading
            ? _c("LangsBlock", { staticClass: "tw-ml-auto tw-p-3" })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading ? _c("CircleLoader") : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("router-view", {
                staticClass: "tw-flex tw-flex-col tw-flex-1",
                on: { "launch-intercom": _vm.launchIntercom }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("vue-snotify"),
          _vm._v(" "),
          !_vm.isSignupType && !_vm.isLoggedIn && !_vm.isLoading
            ? _c("img", {
                staticClass: "tw-mx-auto tw-my-5 lg:tw-hidden",
                staticStyle: { width: "60px" },
                attrs: { src: require("@/assets/img/owl-logo.svg") }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isSignupType && !_vm.isLoggedIn && !_vm.isLoading
            ? _c("img", {
                staticClass: "tw-p-3 tw-ml-auto tw-hidden lg:tw-block",
                staticStyle: { width: "130px" },
                attrs: { src: require("@/assets/img/logo.svg") }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("LearnUToast"),
      _vm._v(" "),
      _c("IntroductionSteps", { ref: "introductionModal" }),
      _vm._v(" "),
      _vm.isLoggedIn
        ? _c(
            "div",
            [
              _c("intercom-support", {
                ref: "intercomSupportComponent",
                staticClass: "intercom-support",
                on: {
                  unreadCountChange: function(unreadCount) {
                    return _vm.unreadCountChange(unreadCount)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }