import axios from 'axios'

export default {

    async checkAdminStatus() {
        return await axios.get('/admin/validity-check')
    },

    async checkAdaptiveObjectCopyLog({ adaptive_object_type_id, adaptive_object_id, source_group_id, destination_group_id }) {
        return await axios.post('/adaptiveobject/copy/check', {
            adaptive_object_type_id : adaptive_object_type_id,
            adaptive_object_id : adaptive_object_id,
            source_group_id : source_group_id,
            destination_group_id : destination_group_id
        })
    },

    async copyAdaptiveObject({ adaptive_object_type_id, adaptive_object_id, source_group_id, destination_group_id, copy_to_lesson_id, copy_to_course_id, allow_sync=1 }) {
        return await axios.post('/adaptiveobject/copy', {
            adaptive_object_type_id,
            adaptive_object_id,
            source_group_id,
            destination_group_id,
            copy_to_course_id,
            copy_to_lesson_id,
            allow_sync : allow_sync
        })
  },

    async getAdaptiveElementsOfType({ adaptive_object_type_id, group_id }) {
        return await axios.get(`/adaptiveobject/elements/${adaptive_object_type_id}/${group_id}`)
    },

    async getAdaptiveTags({ adaptive_object_type_id, adaptive_object_id }){
        return await axios.get(`/adaptiveobject/tags/${adaptive_object_type_id}/${adaptive_object_id}`)
    },

    /**
     * @desc Get shared adaptive objects for the current user
     * @return {Promise} response
     */
    async getSharedAdaptiveObjects() {
        const response = await axios.get('/adaptiveobject/shared')

        return response
    },

    async linkAdaptiveObject({ adaptive_object_type_id, adaptive_object_id, destination_group_id, destination_course_id, destination_material_id}) {
        return await axios.post('/adaptiveobject/link', {
            adaptive_object_type_id : adaptive_object_type_id,
            adaptive_object_id : adaptive_object_id,
            destination_group_id : destination_group_id,
            destination_course_id : destination_course_id,
            destination_material_id : destination_material_id
        })
    },

    /**
     * @desc Share adaptive object with a set of users
     * @param {Object} data - Share data
     * @param {number} data.adaptive_object_type_id
     * @param {number} data.adaptive_object_id
     * @param {number} data.source_group_id,
     * @param {Array} data.users
     * @return {Promise} response
     */
    async shareAdaptiveObject(data) {
        const {
            adaptive_object_type_id,
            adaptive_object_id,
            source_group_id,
            users,
        } = data

        const response = await axios.post('/adaptiveobject/share', {
            adaptive_object_type_id: parseInt(adaptive_object_type_id),
            adaptive_object_id: parseInt(adaptive_object_id),
            source_group_id: parseInt(source_group_id),
            users: users,
        })

        return response
    }
}
