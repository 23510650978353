import Vue from 'vue'
import adaptiveTestAPI from '@/api/adaptiveTestAPI'

export const adaptiveTestStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        adaptive_tests : [],
        adaptive_tests_synced : {},
        questions: [],
    },


    getters: {
        getAdaptiveTestsByClassAndLevel: (state) => (class_id, level_id) => {
            class_id = parseInt(class_id)
            level_id = parseInt(level_id)
            return state.adaptive_tests.filter(test => {
                return (
                    (parseInt(test.adaptive_class_id) === class_id) &&
                    (parseInt(test.adaptive_level_id) === level_id)
                )
            }) || []
        },
        getAdaptiveTestsForSpecificSubject: (state) => (class_id, level_id, subject_id) => {
            class_id = parseInt(class_id)
            level_id = parseInt(level_id)
            subject_id = parseInt(subject_id)
            return state.adaptive_tests.filter(test => {
                if (!subject_id) {
                    return (
                        (parseInt(test.adaptive_class_id) === class_id) &&
                        (parseInt(test.adaptive_level_id) === level_id) &&
                        (parseInt(test.adaptive_topic_id) === 0)
                    )
                }

                return (
                    (parseInt(test.adaptive_class_id) === class_id) &&
                    (parseInt(test.adaptive_level_id) === level_id) &&
                    (parseInt(test.adaptive_subject_id) === subject_id)
                )

            }) || []
        },
        getAdaptiveTestById: (state) => (test_id) => {
            test_id = parseInt(test_id)
            return state.adaptive_tests.find(test => {
                return (parseInt(test.adaptive_test_id) === test_id)
            }) || {}
        },
    },


    actions : {
        /**
         * Delete adaptive question
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Adaptive question data
         * @param {number} data.question_id
         * @return {Promise} response
         */
        async deleteAdaptiveQuestion(vx, data) {
            const { commit } = vx
            const { question_id } = data

            const response = await adaptiveTestAPI.deleteAdaptiveQuestion({
                question_id: parseInt(question_id),
            })

            commit('DELETE_QUESTION', {
                question_id: parseInt(question_id),
            })

            return response
        },

        async getAdaptiveTestData({ commit, state }, { test_id }) {

            return await adaptiveTestAPI.getAdaptiveTestData({
                test_id : test_id
            })

        },

        async getAllAdaptiveTests({ commit, state }, { class_id=0, level_id=0, subject_id=0 }) {

            // if synced then don't go for API
            // if (state.adaptive_tests_synced[class_id] && state.adaptive_tests_synced[class_id][level_id]) {
            //     return []
            // }

            const response = await adaptiveTestAPI.getAllAdaptiveTests({
                class_id : class_id,
                level_id : level_id,
                subject_id : subject_id
            })

            commit('SET_ADAPTIVE_TESTS' , {
                adaptive_tests : response.data,
                class_id : class_id,
                level_id : level_id,
                subject_id : subject_id
            })

            return response.data
        },

        async getAllAdaptiveTestsFromTestId({ commit, state }, { test_id }) {

            const response = await adaptiveTestAPI.getAllAdaptiveTestsFromTestId({
                test_id : test_id,
            })

            const class_id = response.data[0].adaptive_class_id
            const level_id = response.data[0].adaptive_level_id

            commit('SET_ADAPTIVE_TESTS' , {
                adaptive_tests : response.data,
                class_id : class_id,
                level_id : level_id
            })

            return response.data
        },

        /**
         * Get adaptive test questions
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Adaptive questions data
         * @param {number} data.adaptive_class_id
         * @param {number} data.adaptive_level_id
         * @param {number} data.adaptive_subject_id
         * @param {number} data.adaptive_topic_id
         * @return {Promise} response
         */
        async getAdaptiveTestQuestions(vx, data) {
            const { commit } = vx
            const {
                adaptive_class_id,
                adaptive_level_id,
                adaptive_subject_id,
                adaptive_topic_id,
            } = data

            const response = await adaptiveTestAPI.getAdaptiveTestQuestions({
                adaptive_class_id: parseInt(adaptive_class_id),
                adaptive_level_id: parseInt(adaptive_level_id),
                adaptive_subject_id: parseInt(adaptive_subject_id),
                adaptive_topic_id: parseInt(adaptive_topic_id),
            })

            commit('SET_QUESTIONS', {
                questions: response.data,
            })

            return response
        },

        async getNextAdaptiveQuestion({ commit, state }, { test_id, difficulty }) {

            return await adaptiveTestAPI.getNextAdaptiveQuestion({
                test_id : test_id,
                difficulty : difficulty
            })

        },

        /**
         * Save adaptive question
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Adaptive question data
         * @param {number} data.question_id
        * @param {number} data.adaptive_class_id
        * @param {number} data.adaptive_grade_id
        * @param {number} data.adaptive_level_id
        * @param {number} data.adaptive_subject_id
        * @param {number} data.adaptive_topic_id
         * @return {Promise} response
         */
        async saveAdaptiveQuestion(vx, data) {
            const { commit } = vx
            const {
                question_id,
                adaptive_class_id,
                adaptive_grade_id,
                adaptive_level_id,
                adaptive_subject_id,
                adaptive_topic_id,
            } = data

            const response = await adaptiveTestAPI.saveAdaptiveQuestion({
                question_id: parseInt(question_id),
                adaptive_class_id: parseInt(adaptive_class_id),
                adaptive_grade_id: parseInt(adaptive_grade_id),
                adaptive_level_id: parseInt(adaptive_level_id),
                adaptive_subject_id: parseInt(adaptive_subject_id),
                adaptive_topic_id: parseInt(adaptive_topic_id),
            })

            commit('SAVE_QUESTION', {
                question_id: parseInt(question_id),
                savedQuestion: response.data,
            })

            return response
        },

        async saveAdaptiveTestResults( { commit, state }, { test_id, irt_score, latest_difficulty, standard_error, streak, avg_response_time, increment, num_questions }) {

            adaptiveTestAPI.saveAdaptiveTestResults({
                test_id : test_id,
                irt_score : irt_score,
                latest_difficulty : latest_difficulty,
                streak : streak,
                standard_error : standard_error,
                avg_response_time : avg_response_time,
                increment : increment
            })

            commit('UPDATE_ADAPTIVE_TEST' , {
                test_id : test_id,
                irt_score : irt_score,
                standard_error : standard_error,
                streak : streak,
                num_questions : num_questions
            })

            return 1

        },

        async saveAndValidateAdaptiveQuestionAnswer({ commit, state }, { question_data }) {
            return await adaptiveTestAPI.saveAndValidateAdaptiveQuestionAnswer(question_data)
        },

    },


    mutations: {
        DELETE_QUESTION: (state, data) => {
            const { question_id } = data

            const questionIndex = state.questions.findIndex(question => {
                return parseInt(question.question_id) === parseInt(question_id)
            })

            if (questionIndex !== -1) {
                Vue.delete(state.questions, questionIndex)
            }
        },

        SET_ADAPTIVE_TESTS: (state, { adaptive_tests, class_id, level_id, subject_id=0 }) => {

            Vue.set(state, 'adaptive_tests', adaptive_tests)

            // if (!state.adaptive_tests[subject_id]) {
            //     Vue.set(state.adaptive_tests_synced, class_id, {})
            // }
            //
            // Vue.set(state.adaptive_tests, subject_id, adaptive_tests)

            if (!state.adaptive_tests_synced[class_id]) {
                Vue.set(state.adaptive_tests_synced, class_id, {})
            }

            Vue.set(state.adaptive_tests_synced[class_id], level_id, 1)
        },

        UPDATE_ADAPTIVE_TEST: (state, { test_id, irt_score, standard_error, num_questions, streak }) => {

            state.adaptive_tests.map( (test, index) => {
                if (parseInt(test.adaptive_test_id) === parseInt(test_id)) {
                    Vue.set(state.adaptive_tests[index], 'theta', irt_score)
                    Vue.set(state.adaptive_tests[index], 'SE', standard_error)
                    Vue.set(state.adaptive_tests[index], 'num_questions', num_questions)
                    Vue.set(state.adaptive_tests[index], 'streak', streak)

                }
            })

        },

        SAVE_QUESTION: (state, data) => {
            const { question_id, savedQuestion } = data

            const questionIndex = state.questions.findIndex(question => {
                return parseInt(question.question_id) === parseInt(question_id)
            })

            if (questionIndex !== -1) {
                Vue.set(state.questions, questionIndex, savedQuestion)
            } else {
                state.questions.push(savedQuestion)
            }
        },

        SET_QUESTIONS: (state, data) => {
            const { questions } = data

            Vue.set(state, 'questions', questions)
        },
    },
}
