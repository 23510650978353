import axios from 'axios'

export default {

    async checkAdminStatus() {
        return await axios.get('/admin/validity-check')
    },

    async copyAdaptiveQuestionToTest(data) {
        return await axios.post('/adaptive-test/admin/question/copy', data)
    },

    async copyAdaptiveQuizToTest(data) {
        return await axios.post('/adaptive-test/admin/quiz/copy', data)
    },

    async createNewInstitution(data) {
        return await axios.post('/admin/institutions', data)
    },

    async deleteInstitution(institution_id) {
        return await axios.delete(`/admin/institutions/${institution_id}`)
    },

    async getAllInstitutions() {
        return await axios.get('/admin/institutions')
    },

    async getInstitutionPayments() {
        return await axios.get('/admin/institution/payments')
    },


}
