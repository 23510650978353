    import Vue from 'vue'
    import administrationAPI from '@/api/administrationAPI'
    import groupAPI from '@/api/groupAPI'

    export const administrationStore = {
        // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
        namespaced: true,

        state: {
            institutionSettings: null
        },

        actions : {

            async createAssignmentsInBulkForGroup({ commit }, { title='',group_id, start_date, num_assignments, recurring_week_day }) {

                return await administrationAPI.createAssignmentsInBulkForGroup({
                title,
                group_id : group_id,
                start_date : start_date,
                num_assignments : num_assignments,
                recurring_week_day : recurring_week_day,
            })

            },

            async createGroup ({ commit }, { info, members }) {

              const response = await groupAPI.createGroup(info.group_name)

              await groupAPI.addMembers({
                  group_id : response.data.group_id,
                  members: members
              })

              return response
            },

            async createInstitutionUser({ commit }, { institution_id, firstname, lastname, email, password, permission_group_id }) {
              return await administrationAPI.createInstitutionUser({
                  institution_id : institution_id,
                  firstname : firstname,
                  lastname : lastname,
                  email : email,
                  password : password,
                  permission_group_id : permission_group_id
              })
            },

            async createGroupAssigmment ({ commit }, { title, type, due_date, group_id }) {

              return await administrationAPI.createGroupAssigmment({
                  title : title,
                  type : type,
                  due_date : due_date,
                  group_id : group_id
              })
            },

            async createInstitutionHoliday({ commit }, { title, start, end }) {
              return await administrationAPI.createInstitutionHoliday({
                  title : title,
                  start : start,
                  end : end
              })
            },

            async createInstitutionPermissions({ commit }, { data }) {
              return await administrationAPI.createInstitutionPermissions({
                  data : data
              })
            },

            async fetchInstitutionHolidays({ commit }) {
              return await administrationAPI.fetchInstitutionHolidays()
            },

            async getExamGroups({ commit, state }) {
              return await administrationAPI.getExamGroups()
            },

            async getInstitutionPermissionGroups({ commit }, { institution_id }) {
              return await administrationAPI.getInstitutionPermissionGroups({
                  institution_id : institution_id
              })
            },

            async getInstitutionPermissions({ commit }) {
              return await administrationAPI.getInstitutionPermissions()
            },

            async getInstitutionSettings({ commit }) {
              let response = await administrationAPI.getInstitutionSettings()
              commit('SET_INSTITUTION_SETTINGS', response.data)
            },

            async getInstitutionStatisticsAssignmentsBehind({ commit }) {
              return await administrationAPI.getInstitutionStatisticsAssignmentsBehind()
            },

            async getInstitutionStatisticsMissingAssignments({ commit }) {
              return await administrationAPI.getInstitutionStatisticsMissingAssignments()
            },

            async getUserAssignmentPlan({ commit }, { user_id, group_id }) {
              return await administrationAPI.getUserAssignmentPlan({
                  user_id : user_id,
                  group_id : group_id
              })
            },

            async logInAs({ commit }, { user_id, log_back_to_previous = 0 } ) {
              return await administrationAPI.logInAs({
                  user_id : user_id,
                  log_back_to_previous : log_back_to_previous
              })
            },

            async markStudentAsSelfStudy( { commit }, { user_id, group_id }) {
                return await administrationAPI.markStudentAsSelfStudy({
                    user_id : user_id,
                    group_id : group_id
                })
            },

            async recalculateAssignmentsPlanForUser( { commit }, { group_id, user_id }) {
              return await administrationAPI.recalculateAssignmentsPlanForUser({
                  group_id : group_id,
                  user_id : user_id
              })
            },

            async recalculateAssignmentsPlansForGroup( { commit }, { group_id }) {
              return await administrationAPI.recalculateAssignmentsPlansForGroup({
                  group_id : group_id
              })
            },

            async removeInstitutionHoliday( { commit }, { schedule_id }) {
              return await administrationAPI.removeInstitutionHoliday({
                  schedule_id : schedule_id,
              })
            },

            async saveGroup ({ commit }, { group_id, info, members }) {
              const name_response = groupAPI.saveGroupName({
                  group_id : group_id,
                  name : info.group_name
              })
              const add_response = groupAPI.addMembers({
                  group_id : group_id,
                  members: members
              })

              return Promise.all([
                  name_response,
                  add_response
              ])

            },

            async saveInstitutionHoliday({ commit }, { schedule_id, title, start, end }) {
              return await administrationAPI.saveInstitutionHoliday({
                  schedule_id : schedule_id,
                  title : title,
                  start : start,
                  end : end
              })
            },

            async saveInstitutionPermissions ({ commit }, { permission_group_id, data }) {
              return await administrationAPI.saveInstitutionPermissions({
                  permission_group_id : permission_group_id,
                  data : data
              })
            },

            async saveInstitutionSettings({ commit }, { settings }) {
              return await administrationAPI.saveInstitutionSettings(settings)
            },

            async saveMoodleRelation({ commit }, { moodle_course_id, replacement_group_id, create_sub_group, is_main_group }) {
              return await administrationAPI.saveMoodleRelation({
                  moodle_course_id : moodle_course_id,
                  replacement_group_id : replacement_group_id,
                  create_sub_group : create_sub_group,
                  is_main_group : is_main_group
              })
            },

            async saveUMSRelation({ commit }, { uni_group_id, replacement_group_id }) {
              return await administrationAPI.saveUMSRelation({
                  uni_group_id : uni_group_id,
                  replacement_group_id : replacement_group_id
              })
            },

            async searchGroup({ commit }, { institution_id, txt, page, per_page, filter, filter_value }) {
              return await groupAPI.searchGroup({ institution_id, txt, page, per_page, filter, filter_value })
            },

            async searchMoodleGroups({ commit }, { search_query }) {
              return await administrationAPI.searchMoodleGroups({
                  search_query : search_query
              })
            },

            async searchUMSGroups({ commit }, { search_query }) {
              return await administrationAPI.searchUMSGroups({
                  search_query : search_query
              })
            }

        },
        mutations: {
            // SET_LOGGED_IN_USER: (state, flag) => {
            //    Vue.set(state, 'isLoggedIn', flag)
            // },
            SET_INSTITUTION_SETTINGS(state, settings) {
                state.institutionSettings = settings
            }
        },
    }
