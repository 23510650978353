import Vue from 'vue'
import notificationAPI from '@/api/notificationAPI'


export const notificationStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        notifications: [],
    },


    getters: {

    },


    actions : {

        /**
         * Function for getting all notifications
         */
        async getNotifications({ commit, state }) {
            const response = await notificationAPI.getNotifications()
            const notifications = response.data
            commit('SET_NOTIFICATIONS', {
                notifications : notifications
            })
            return 1
        },

        /**
         * @desc Mark all notifications as read
         * @param {Object} vx - Vuex specific functions
         */
        async markAllNotificationRead(vx) {
            const { commit } = vx

            await notificationAPI.markAllNotificationRead()
            
            commit('MARK_ALL_NOTIFICATIONS_READ')

            return 1
        },

        async markAllNotificationSeen({ commit, state }) {
            notificationAPI.markAllNotificationSeen()
            commit('MARK_ALL_NOTIFICATIONS_SEEN')
            return 1
        },

        async markNotificationRead({ commit, state }, notification_id) {
            const response = await notificationAPI.markNotificationRead(notification_id)
            const time = response.data
            commit('MARK_NOTIFICATION_READ', {
                notification_id : notification_id,
                time : time
            })
            return response
        },

    },


    mutations: {

      MARK_ALL_NOTIFICATIONS_READ: (state) => {
          const time = Date.now()

          const updatedState = state.notifications.map(notification => {
              notification.read_date = time

              return notification
          })

          Vue.set(state, 'notifications', updatedState)
      },

        MARK_ALL_NOTIFICATIONS_SEEN: (state) => {
            const time = new Date().getTime()
            for (let notification of state.notifications) {
                let index = state.notifications.indexOf(notification)
                Vue.set(state.notifications[index], 'seen_date' , time)
            }
        },

        MARK_NOTIFICATION_READ: (state, { notification_id, time }) => {
            for (let notification of state.notifications) {
                if (parseInt(notification.notification_id) === parseInt(notification_id)) {
                    let index = state.notifications.indexOf(notification)
                    Vue.set(state.notifications[index], 'read_date' , time)
                }
            }
        },

        SET_NOTIFICATIONS: (state, { notifications }) => {
            Vue.set(state, 'notifications', notifications)
        },

    }
}
