import Vue from 'vue'
import libraryAPI from '@/api/libraryAPI'

export const libraryStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        entries: []
    },


    getters: {

    },


    actions : {

        async getLibraryMap({ commit, state }) {
            if (state.entries.length) {
                return state.entries
            }
            const response = await libraryAPI.getLibraryMap()
            commit("SET_LIBRARY_ENTRIES", {
                entries : response.data
            })
            return response
        },

    },


    mutations: {

        SET_LIBRARY_ENTRIES: (state, { entries }) => {
            Vue.set(state, 'entries', entries)
        },

    }
}
