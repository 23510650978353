
/*
    VIEWS
 */
const groupStatisticsAssignmentsView = () => import('@/views/groups/groupstatistics/groupStatisticsAssignmentsView')
const groupStatisticsCoursesView = () => import('@/views/groups/groupstatistics/groupStatisticsCoursesView')
const groupStatisticsQuizzesView = () => import('@/views/groups/groupstatistics/groupStatisticsQuizzesView')

export default  [
    {
        path: 'assignments',
        component: groupStatisticsAssignmentsView,
        meta: { requiresAuth: true, view : 'group-statistics-assignments' }
    },
    {
        path: 'courses',
        component: groupStatisticsCoursesView,
        meta: { requiresAuth: true, view : 'group-statistics-courses' }
    },
    {
        path: 'quizzes',
        component: groupStatisticsQuizzesView,
        meta: { requiresAuth: true, view : 'group-statistics-quizzes' }
    },
]
