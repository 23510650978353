import axios from 'axios'

export default {

    createNewExternalLink(data) {
        return axios.post('/external-links', data)
    },

    saveExternalLink({ link_id, data }) {
        return axios.put('/external-links/'+link_id, data)
    },
      
    removeExternalLink(adaptive_object_id) {
      return axios.delete(`/external-links/${adaptive_object_id}`)
    }


}
