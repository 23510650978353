import axios from 'axios'

export default {

    getQuizTries({ quiz_id, user_id }) {
        return axios.get(`/profile/quizzes/tries/${quiz_id}/${user_id}`)
    },

    getQuizTryReview({ quiz_id, user_id, archived }) {
        return axios.get(`/profile/quizzes/tries/feedback/${quiz_id}/${user_id}/${archived}`)
    },

    getUserAssignments({ group_id, user_id }) {
        return axios.get(`/profile/assignments/${group_id}/user/${user_id}`)
    },

    getUserCourses({ group_id }) {
        return axios.get(`/profile/courses/${group_id}`)
    },

    getUserCoursesStatistics({ user_id, group_id }) {
        return axios.get(`/profile/courses/statistics/${group_id}/${user_id}`)
    },

    getUserGroupMaterials({ group_id }) {
        return axios.get(`/profile/materials/group/${group_id}`)
    },

    getUserGroupMaterialElements({ material_id, user_id }) {
        return axios.get(`/profile/materials/elements/${material_id}/${user_id}`)
    },


    getUserGroups({ user_id }) {
        return axios.get(`/profile/user/groups/${user_id}`)
    },

    getUserQuizzes({ group_id, user_id }) {
        return axios.get(`/profile/quizzes/${group_id}/${user_id}`)
    },

    getUserVideos({ group_id, user_id }) {
        return axios.get(`/profile/videos/${group_id}/${user_id}`)
    },

    removeProfilePicture() {
        return axios.delete('/users/profile-picture')
    }

}
