import axios from 'axios'

export default {

    async getNotifications() {
        return await axios.get('/notifications')
    },

    /**
     * @desc Mark all notifications as read
     * @return {Promise} response
     */
    async markAllNotificationRead() {
        return await axios.post('/notifications/read')
    },

    async markAllNotificationSeen() {
        return await axios.post('/notifications/seen')
    },

    async markNotificationRead(notification_id) {
        return await axios.put(`/notifications/read/${notification_id}`)
    }

}
