import axios from 'axios'

const base_URL = process.env.CHAT_API_BASE_URL

export default {

    async deleteChatMessage({ channel, message_id }) {
        return await axios.delete(`${base_URL}/apps/edaptio-1/channels/${channel}/messages/${message_id}`)
    },

    async getChatMessages(data) {
        const { channel, limit=50, nextOffset='' } = data
        const offset = (nextOffset && nextOffset.length) ? `&offset=${nextOffset}` : ''
        return await axios.get(`${base_URL}/apps/edaptio-1/channels/${channel}/messages?limit=${limit}${offset}&sort=desc`)
    },

    async sendChatMessage({ channel, data }) {

        const message_data = {
            data : {
                type : 'message',
                attributes : data
            }
        }

        return await axios.post(`${base_URL}/apps/edaptio-1/channels/${channel}/messages`, message_data)
    },

}
