import subscrimtionsAPI from '@/api/subscriptionsAPI'
import Vue from 'vue'

export const subscriptionsStore = {
    namespaced: true,

    state: {
        basicPlan: {
            id: 'learnu-basic',
            attributes: {
                features: [
                    'Up to 30 active learners',
                    '2GB storage limit',
                    'Unlimited classes, courses & lessons',
                    'Activities, quizzes & assignments',
                    'Video conferencing & messaging',
                    'Class tracking & insights',
                    'Email support'
                ],
                name: 'Basic'
            }
        },
        institutionPlan:  {
            id: 'learnu-institution',
            attributes: {
                features: [
                    'Unlimited active learners',
                    'Unlimited storage',
                    'Unlimited classes, courses & lessons',
                    'Activities, quizzes & assignments',
                    'Video conferencing & messaging',
                    'Class tracking & insights',
                    'Dedicated support',
                    'Custom integrations',
                    'Custom DPA',
                    'White labeling'
                ],
                name: 'Institution'
            }
        },
        products: [],
        paymentMethod: null,
        subscription: null,
        billingHistory: [],
        futureSubscription: null
    },

    actions: {
        async getProducts({ state, commit }) {
            try {
                const response = await subscrimtionsAPI.getProducts()
                if (response && response.data) {
                    commit('SET_PROUDUCTS', response.data.data)
                }
            } catch (error) {
                console.log(error)
            }

        },
        async savePaymentMethod({ state, commit }, {paymentMethodId}) {
            const response = await subscrimtionsAPI.savePaymentMethod(paymentMethodId)
            if (!response || !response.data || !response.data.data || !response.data.data.attributes) return 0
            commit('SET_PAYMENT_METHOD', response.data.data)
            return response.data.data.id
        },

        async createSubscription({ state, commit }, { product, price, paymentMethod }) {
            const response = await subscrimtionsAPI.createSubscription(
                {
                    product,
                    price,
                    paymentMethod
                }
            )
            if (!response || !response.data || !response.data.data || !response.data.data.attributes) return 0
            if (response.data.data.attributes.status === 'active') {
                commit('SET_SUBSCRIPTION_DATA', response.data.data)
            }
            return response.data
        },

        async getSubscription({ state, commit }) {
            try {
                const response = await subscrimtionsAPI.getActiveSubscriptions()
                if (!response || !response.data || !response.data.data) return 0
                const subscriptions = response.data.data
                if (subscriptions.length) {
                    if (subscriptions.length > 1) {
                        const subscription = subscriptions.sort((a, b) => b.attributes.created - a.attributes.created).find(item => item.attributes.status === 'active')
                        if(subscription) commit('SET_SUBSCRIPTION_DATA', subscription)
                    } else {
                        commit('SET_SUBSCRIPTION_DATA', response.data.data[0])
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },

        async getPaymentMethod({ state, commit }) {
            try {
                const response = await subscrimtionsAPI.getActivePaymentMethods()
                if (!response || !response.data || !response.data.data) return 0
                let paymentMethods = response.data.data
                if (paymentMethods.length) {
                    paymentMethods = paymentMethods.filter(item => item.attributes.app === 'learnu')
                    if(!paymentMethods.length) return
                    if (paymentMethods.length > 1) {
                        const paymentMethod = paymentMethods.sort((a, b) => b.attributes.created - a.attributes.created).find(item => item.attributes.status === 'active')
                        if(paymentMethod) commit('SET_PAYMENT_METHOD', paymentMethod)
                    } else {
                        commit('SET_PAYMENT_METHOD', paymentMethods[0])
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async getBillingHistory({ state, commit }) {
            try {
                const response = await subscrimtionsAPI.getBillingHistory()
                if (!response || !response.data || !response.data.data) return 0
                
                commit('SET_BILLING_HISTORY', response.data.data)
            } catch (error) {
                console.log(error)
            }
        },
        async getFutureSubscriptions({ state, commit }) {
            try {
                const response = await subscrimtionsAPI.getFutureSubscriptions()
                if (!response || !response.data || !response.data.data) return 0
                
                commit('SET_FUTURE_SUBSCRIPTION', response.data.data[0])
            } catch (error) {
                console.log(error)
            }
        },
    },

    mutations: {
        SET_BILLING_HISTORY: (state, data) => {
            state.billingHistory = data
        },
        SET_PROUDUCTS: (state, data) => {
            state.products = data
        },
        SET_PAYMENT_METHOD: (state, data) => {
            state.paymentMethod = data
        },
        SET_SUBSCRIPTION_DATA: (state, data) => {
            state.subscription = data
        },
        SET_FUTURE_SUBSCRIPTION: (state, data) => {
            state.futureSubscription = data
        }
    }
}
