
/*
 *  SUBROUTES
*/
import adminInstitutionRoutes from '@/router/subroutes/adminInstitutionRoutes'

/*
 *   VIEWS
*/

const adminCopyView = () => import('@/views/admin/adminCopyView')
const adminDashboard = () => import('@/views/admin/adminDashboard')
const adminInstitutionPaymentsView = () => import('@/views/admin/adminInstitutionPaymentsView')
const adminInstitutionsMainView = () => import('@/views/admin/institutions/adminInstitutionsMainView')
const adminInstitutionsOverview = () => import('@/views/admin/adminInstitutionsOverview')
const adminLogin = () => import('@/views/admin/adminLogin')

export default [
    {
        path: 'copy',
        component: adminCopyView,
        meta: { requiresAuth: true, view : 'admin-copy' }
    },
    {
        path: 'dashboard',
        component: adminDashboard,
        meta: { requiresAuth: true, view : 'admin-dashboard' }
    },
    {
        path: 'institution-payments',
        component: adminInstitutionPaymentsView,
        meta: { requiresAuth: true, view : 'admin-institution-payments' }
    },
    {
        path: 'institutions',
        component: adminInstitutionsOverview,
        meta: { requiresAuth: true, view : 'admin-institutions' }
    },
    {
        path: 'institutions/:institution_id',
        component: adminInstitutionsMainView,
        meta: { requiresAuth: true, view : 'admin-institution' },
        children: adminInstitutionRoutes
    },
    {
        path: 'login',
        component: adminLogin,
        meta : { view : 'admin-login' }
    },
]
