import videoChatApi from '@/api/videoChatAPI'
import consultationAPI from '@/api/consultationAPI'

export const videoChatStore = {
    namespaced: true,

    state: {
        me: null,
        chats: [],
        activeChat: null,
        activeChatAttachedConsultations: [],
        invites: [],
        lastLoadedChat: null,
        lastCreatedChat: null,
        lastUpdatedChat: null
    },
    mutations: {
        addChat(state, chat) {
            state.chats.push(chat)
        },
        clearChats(state) {
            state.chats = []
        },
        setActiveChat(state, chatId) {
            for (let chat of state.chats) {
                if (+chat.id === +chatId) {
                    state.activeChat = chat
                    return
                }
            }

            state.activeChat = null
        },
        setActiveIdentity(state, identity) {
            state.me = identity
        },
        removeChat(state, chatId) {
            let chatIndex = -1
            for (let index in state.chats) {
                if (+state.chats[index].id === +chatId) {
                    chatIndex = index
                    break;
                }
            }

            if (chatIndex !== -1) {
                state.chats.splice(chatIndex, 1)
            }
        },
        unsetActiveChat(state) {
            state.activeChat = null
            state.me = null
        },
        addInvite(state, chat) {
            state.invites.push({
                chat: chat,
                joinUrl: `/video-chat/${chat.id}`
            })
        },
        removeInvite(state, chat) {
            const chatId = (typeof chat === 'object') ? chat.id : parseInt(chat)

            let chatIndex = -1
            for (let index in state.invites) {
                if (state.invites[index].chat.id === chatId) {
                    chatIndex = index
                    break
                }
            }

            if (chatIndex !== -1) {
                state.invites.splice(chatIndex, 1)
            }
        },
        setLastLoadedChat(state, chat) {
            state.lastLoadedChat = chat
        },
        setLastCreatedChat(state, chat) {
            state.lastCreatedChat = chat
        },
        setLastUpdatedChat(state, chat) {
            state.lastUpdatedChat = chat
        },
        setActiveChatAttachedConsultations(state, consultations) {
            state.activeChatAttachedConsultations = consultations
        }
    },
    actions: {
        async loadChats({commit}) {
            commit('clearChats')

            const chats = (await videoChatApi.loadChats()).data

            for (const chat of chats) {
                commit('addChat', chat)
            }
        },
        async loadChat({commit}, id) {
            const chat = (await videoChatApi.loadChat(id)).data

            commit('setLastLoadedChat', chat)
        },
        async createChat({commit}, data) {
            commit('setLastCreatedChat', null)

            const response = await videoChatApi.createChat(data)
            const chat = response.data

            commit('addChat', chat)
            commit('setLastCreatedChat', chat)

            return response
        },
        async updateChat({commit}, data) {
            commit('setLastUpdatedChat', null)

            if (typeof data.id === 'undefined') {
                return
            }

            const chat = (await videoChatApi.updateChat(data.id, data)).data

            commit('setLastUpdatedChat', chat)
        },
        async activateChat({dispatch, commit, state}, chatId) {
            await dispatch('loadChats')
            const identity = (await videoChatApi.loadMemberData(chatId)).data
            const consultations = (await consultationAPI.getConsultationsWithVideoChat(chatId)).data

            commit('setActiveChat', chatId)
            commit('setActiveIdentity', identity)
            commit('setActiveChatAttachedConsultations', consultations)
        },
        async deleteChat({commit, state}, chatId) {
            await videoChatApi.deleteChat(chatId)

            commit('removeChat', chatId)
            if (state.activeChat && +state.activeChat.id === +chatId) {
                commit('unsetActiveChat')
            }
        },
        async startChat({dispatch, commit}, chatId) {
            await videoChatApi.startChat(chatId)
            //dispatch('joinChat', chatId)
        },
        async stopChat({commit, state}, chatId) {
            await videoChatApi.stopChat(chatId)
        },
        async joinChat({commit, state}, chatId) {
            await videoChatApi.joinChat(chatId)
        },
        async leaveChat({commit, state}, chatId) {
            await videoChatApi.leaveChat(chatId)
        },
    }
}
