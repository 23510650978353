import Vue from 'vue'
import questionnaireApi from '@/api/questionnaireAPI'

export const questionnaireStore = {
  // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
  namespaced: true,

  state: {
      questionnaires : {},
      questionnaires_synced : {},
      questionnaire_result_data : {}
  },


  getters: {

      // getLoggedInUserInfo: state => {
      //   return state.user
      // }
  },


    actions : {

        clearQuestionnaireStore({ commit, state }, { group_id }) {
            commit('CLEAR_STORE', {
                group_id : group_id
            })
            return 1
        },

        /**
         * Function for saving and edit on a existing questionnaire
         */
        async createQuestionnaire({ commit, state }, { group_id, questionnaire_data }) {

            const response = await questionnaireApi.createQuestionnaire(questionnaire_data)

            commit('ADD_NEW_QUESTIONNAIRE', {
                group_id : group_id,
                questionnaire_data : response.data,
            })

            return response
        },

        /**
         * Function for saving and edit on a existing questionnaire
         */
        async editQuestionnaire({ commit, state }, { questionnaire_id, group_id, questionnaire_data }) {

            const response = await questionnaireApi.saveQuestionnaire(questionnaire_id, questionnaire_data)

            commit('SET_QUESTIONNAIRE_DATA', {
                questionnaire_id : questionnaire_id,
                group_id : group_id,
                questionnaire_data : response.data
            })

        },

        /**
        * Function for fetching questionnaires for group
        */
        async fetchAllGroupQuestionnaires({ commit, state }, group_id) {
            if (state.questionnaires_synced[group_id]) {
                return state.questionnaires[group_id]
            } else {

                const response = await questionnaireApi.fetchAllGroupQuestionnaires(group_id)
              const questionnaires = response.data

                commit('SET_GROUP_QUESTIONNAIRES', {
                    group_id : group_id,
                    questionnaires : questionnaires
                })

                commit('SET_QUESTIONNAIRES_SYNCED', {
                    group_id : group_id,
                    flag: 1
                })

            }

        },


        /**
         * Function for fetching questionnaire data for use in testing
         */
        async fetchQuestionnaireData({ commit, state }, { questionnaire_id, group_id }) {

            const response = await questionnaireApi.fetchQuestionnaireData(questionnaire_id)

            commit('SET_QUESTIONNAIRE_DATA', {
                questionnaire_id : questionnaire_id,
                group_id : group_id,
                questionnaire_data : response.data
            })

            return response
        },


        async fetchQuestionnaireResultData({ commit, state }, { questionnaire_id }) {

            const response = await questionnaireApi.fetchQuestionnaireResultData(questionnaire_id)

            commit('SET_QUESTIONNAIRE_RESULT_DATA', {
                questionnaire_id : questionnaire_id,
                result_data : response.data
            })

            return response
        },


        /**
         * @desc Register questionnaire end
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Questionnaire data
         * @param {number} data.questionnaire_id
         * @param {number} data.group_id
         * @return {Promise} response
         */
        async registerQuestionnaireEnded(vx, data) {
          const { commit, rootState } = vx
          const { questionnaire_id, group_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false
          
          // Register questionnaire end
          const response = await questionnaireApi.registerQuestionnaireEnded({
            questionnaire_id,
          }, isMockStudent)

          // Pull end time
          const end_time = response.data.questionnaire_ended

          // Update questionnaire state
          commit('REGISTER_QUESTIONNAIRE_ENDED', {
            questionnaire_id,
            group_id,
            end_time,
          })

          return response

        },

        /**
         * @desc Register questionnaire start
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Questionnaire data
         * @param {number} data.questionnaire_id
         * @param {number} data.group_id
         * @return {Promise} response
         */
        async registerQuestionnaireStarted(vx, data) {
          const { commit, rootState } = vx
          const { questionnaire_id, group_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Register questionnaire start
          const response = await questionnaireApi.registerQuestionnaireStarted({
            questionnaire_id,
          }, isMockStudent)

          // Pull start time
          const start_time = response.data.questionnaire_started

          // Update questionnaire state
          commit('REGISTER_QUESTIONNAIRE_STARTED', {
            questionnaire_id,
            group_id,
            start_time,
          })

          return response

        },

        /**
         * Function for fetching questionnaires for group
         */
        async removeQuestionnaire({ commit, state }, { questionnaire_id, group_id }) {

            const response = await questionnaireApi.removeQuestionnaire(questionnaire_id)
            commit('REMOVE_QUESTIONNAIRE', { questionnaire_id, group_id })
            return response

        },

        /**
         * @desc Save user questionnaire answer
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Questionnaire data
         * @param {number} data.questionnaire_id
         * @param {number} data.group_id
         * @param {number} data.question_index
         * @param {Object} data.question_data
         * @return {Promise} response
         */
        async saveUserQuestionnaireAnswer(vx, data) {
          const { commit, rootState} = vx
          const { questionnaire_id, group_id, question_index, question_data } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Save user answer
          const response = await questionnaireApi.saveUserQuestionnaireAnswer({
            question_data
          }, isMockStudent)

          // Update questionnaire state
          commit('SET_QUESTIONNAIRE_QUESTION_DATA', {
            question_data: response.data,
            questionnaire_id,
            group_id,
            question_index,
          })

          return response
        },

    },


  mutations: {


        ADD_NEW_QUESTIONNAIRE: (state, { group_id, questionnaire_data }) => {

            if (!state.questionnaires[group_id]) {
                Vue.set(state.questionnaires, group_id, [])
            }

            state.questionnaires[group_id].push(questionnaire_data)
        },

        CLEAR_STORE: (state, { group_id }) => {
            Vue.set(state.questionnaires, group_id, [])
            Vue.set(state.questions, group_id, [])
            Vue.set(state.questions_synced, group_id, 0)
            Vue.set(state.questionnaires_synced, group_id, 0)
        },

        REGISTER_QUESTIONNAIRE_ENDED: (state, { questionnaire_id, group_id, end_time }) => {
            const questionnaires = state.questionnaires[group_id]
            if (questionnaires) {
                questionnaires.map( (questionnaire,index) => {
                    if (parseInt(questionnaire['questionnaire_id']) === parseInt(questionnaire_id)) {
                        Vue.set(state.questionnaires[group_id][index], 'questionnaire_ended', end_time)
                    }
                })
            }
        },

        REGISTER_QUESTIONNAIRE_STARTED: (state, { questionnaire_id, group_id, start_time }) => {
            const questionnaires = state.questionnaires[group_id]
            if (questionnaires) {
                questionnaires.map( (questionnaire,index) => {
                    if (parseInt(questionnaire['questionnaire_id']) === parseInt(questionnaire_id)) {
                        Vue.set(state.questionnaires[group_id][index], 'questionnaire_started', start_time)
                    }
                })
            }
        },

        REMOVE_QUESTIONNAIRE: (state, { questionnaire_id, group_id }) => {
            const questionnaires = state.questionnaires[group_id]
            if (questionnaires) {
                questionnaires.map( (questionnaire,index) => {
                    if (parseInt(questionnaire['questionnaire_id']) === parseInt(questionnaire_id)) {
                        Vue.delete(state.questionnaires[group_id], index)
                    }
                })
            }
        },


    SET_GROUP_QUESTIONNAIRES: (state, { group_id, questionnaires }) => {
            Vue.set(state.questionnaires, group_id, questionnaires)
        },


        SET_QUESTIONNAIRES_SYNCED: (state, { group_id, flag }) => {
            Vue.set(state.questionnaires_synced, group_id, flag)
        },


        SET_QUESTIONNAIRE_DATA: (state, { questionnaire_id, group_id, questionnaire_data }) => {
            if (!state.questionnaires[group_id]) {
                Vue.set(state.questionnaires, group_id, [])
                state.questionnaires[group_id].push(questionnaire_data)
            } else {

              if (state.questionnaires[group_id] && state.questionnaires[group_id].length) {
                    state.questionnaires[group_id].map((questionnaire, index) => {
                        if (parseInt(questionnaire['questionnaire_id']) === parseInt(questionnaire_id)) {
                            if (questionnaire_data.questions) {
                                questionnaire_data.questions.map( question => {
                                    question.question_id = parseInt(question.question_id)
                                    if (question.answers) {
                                        question.answers.map( answer => {
                                            answer['answer_id'] = parseInt(answer['answer_id'])
                                            answer['is_chosen'] = parseInt(answer['is_chosen'])
                                            answer['sort_order'] = parseInt(answer['sort_order'])
                                        })
                                    }
                                })
                            }
                            Vue.set(state.questionnaires[group_id], index, questionnaire_data)
                        }
                    })
                if(!state.questionnaires[group_id].find(questionnaire => parseInt(questionnaire['questionnaire_id']) === parseInt(questionnaire_id))){
                  state.questionnaires[group_id].push(questionnaire_data)
                }
                  
              }
            }

        },

        SET_QUESTIONNAIRE_QUESTION_DATA: (state, { questionnaire_id, group_id, question_index, question_data }) => {

            if (state.questionnaires[group_id] && state.questionnaires[group_id].length) {
                state.questionnaires[group_id].map((questionnaire, index) => {
                    if (parseInt(questionnaire['questionnaire_id']) === parseInt(questionnaire_id)) {
                        if (state.questionnaires[group_id][index]['questions'] && state.questionnaires[group_id][index]['questions'].length) {
                            if (question_data.answers) {
                                question_data.answers.map( answer => {
                                    answer['answer_id'] = parseInt(answer['answer_id'])
                                    answer['is_chosen'] = parseInt(answer['is_chosen'])
                                    answer['sort_order'] = parseInt(answer['sort_order'])
                                })
                            }
                            question_data['answer_id'] = parseInt(question_data['answer_id'])
                            question_data['is_chosen'] = parseInt(question_data['is_chosen'])
                            question_data['sort_order'] = parseInt(question_data['sort_order'])
                            Vue.set(state.questionnaires[group_id][index]['questions'], question_index, question_data)
                        }
                    }
                })
            }

        },

        SET_QUESTIONNAIRE_RESULT_DATA: (state, { questionnaire_id, result_data }) => {

            Vue.set(state.questionnaire_result_data, questionnaire_id, result_data)

        },


    }
}
