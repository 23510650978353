import axios from 'axios'

export default {

    async correctAssignmentDate({ assignment_id, date }) {
        return await axios.put(`/assignment/correct/date/${assignment_id}`, {
            date : date
        })
    },

    async correctAssignmentPlanDate({ assignment_id, user_id, date, calculate_after=0 }) {
        return await axios.put(`/assignment/assignment-plan/${assignment_id}`, {
            date : date,
            user_id : user_id,
            calculate_after : calculate_after
        })
    },

    async createAssignment({ assignment_data }) {
        return await axios.post('/assignment', assignment_data)
    },

    async createExternalHandin({ feedback_data }) {
        return await axios.post('/assignment/external', feedback_data)
    },

    async editAssignment({ assignment_id, assignment_data }) {
        return await axios.put(`/assignment/${assignment_id}`, assignment_data)
    },


    /**
     * @desc Edit assignment answer 
     * @param {Object} data - Assignment data
     * @param {number} data.post_id
     * @param {Object} data.assignment_data
     * @param {boolean} isMockStudent 
     * @return {Promise} response
     */
    async editAssignmentAnswer(data, isMockStudent = false) {
      const { post_id, assignment_data } = data

      // Handle real user
      if (!isMockStudent) return await axios.put(`/post/${post_id}`, assignment_data)

      // Mock response
      return {}
    },

    async fetchAllGroupAssignments(group_id) {
        return await axios.get(`/assignments/group/${group_id}`)
    },

    async getAllUserAssignments() {
        return await axios.get(`/assignments/user/all`)
    },

    async getAllPastFeedbackFromUserAssignments({ group_id, user_id }) {
        return await axios.get(`/assignments/feedback/past/${group_id}/${user_id}`)
    },

    /**
     * @desc Get assignment answer
     * @param {Object} data - Assignment data
     * @param {number} data.user_id
     * @param {number} data.assignment_id
     * @param {boolean} isMockStudent 
     */
    async getAssignmentAnswer(data, isMockStudent = false) {
      const { user_id, assignment_id } = data

      // Handle real user
      if (!isMockStudent) {
        return await axios.get(`/assignments/answer/${assignment_id}/user/${user_id}`)
      }

      // Mock response
      return {}
    },

    async getAssignmentData({ assignment_id}) {
        return await axios.get(`/assignments/${assignment_id}`)
    },

    async getAssignmentsWithoutFeedback(){
        return await axios.get('/assignments/feedback/missing')
    },

    async pullAllTurninData({ assignment_id, group_id }) {
        return await axios.get(`/assignments/turnins/${assignment_id}/${group_id}`)
    },

    async registerFeedbackSeen({ feedback_id }) {
        return await axios.put(`/feedback/seen/${feedback_id}`, {})
    },

    async removeAssignment(assignment_id) {
        return await axios.delete(`/assignment/${assignment_id}`)
    },


    /**
     * @desc Remove assignment answer
     * @param {Object} data - Assignment data
     * @param {number} data.post_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async removeAssignmentAnswer(data, isMockStudent = false) {
      const { post_id } = data

      // Handle real user
      if (!isMockStudent) return await axios.delete(`/post/${post_id}`)

      // Mock response
      return {}
    },

    async removeFeedback(feedback_post_id) {
        return await axios.delete(`/assignment/feedback/${feedback_post_id}`)
    },

    async sendUnReleasedTurninFeedback({ assignment_id, feedback_post_id }) {
        return await axios.post(`/assignments/feedback/release`, {
             assignment_id : assignment_id,
            feedback_post_id : feedback_post_id
        })
    },

    /**
     * @desc Submit assignment answer
     * @param {Object} data - Assignment data
     * @param {Object} data.assignment_data
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async submitAssignmentAnswer(data, isMockStudent = false) {
      const { assignment_data } = data

      // Handle real user
      if (!isMockStudent) return await axios.post('/post', assignment_data)

      // Mock response
      return {}
    }


}
