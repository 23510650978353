import counsellorApi from '@/api/counsellorAPI'
import Vue from 'vue'

export const counsellorStore = {
    namespaced: true,

    state: {
        activeCounsellors: [],
        activeCounsellorsPagination: {
            page: 1,
            per_page: 8,
            total_pages: 1,
            query: ''
        },
        counsellors: [],
        counsellorsPagination: {
            page: 1,
            per_page: 6,
            total_pages: 1,
            query: ''
        },
        counsellorSearchQuery: '',
        counsellorId: null,
        counsellorStudents: [],
        studentsPossibleToAssign: [],
        studentsPossibleToAssignPagination: {
            page: 1,
            per_page: 6,
            total_pages: 1,
            query: ''
        },
        loadingCounsellors: false,
        loadingCounsellorStudents: false,
        loadingStudentPossibleToAssign: false,
    },
    mutations: {
        /**
         * Super mutation. Just to avoid writing lots of code
         * @param state
         * @param payload
         */
        mutate(state, payload) {
            if (Object.keys(state).indexOf(payload.what) > -1 && typeof payload.value !== 'undefined') {
                state[payload.what] = payload.value
            }
        },
        SET_PAGINATION_QUERY(state, { query, type }) {
            if (query) {
              Vue.set(state[type], 'query', query)
            } else {
              Vue.set(state[type], 'query', '')
            }
        },
    },
    actions: {
        /**
         * Load active counsellors
         * @param commit
         * @param state
         * @param query
         * @param limit
         * @returns {Promise<void>}
         */
        async loadActiveCounsellors({commit, state}, {query, page, per_page, active = 1}) {
            try {
                const searchResults = (await counsellorApi.getCounsellors({
                    query,
                    active,
                    page,
                    per_page
                }))
                if (searchResults && searchResults.data) {
                  commit('mutate', { what: 'activeCounsellorsPagination', value: searchResults.data.pagination })
                  commit('SET_PAGINATION_QUERY', { query: searchResults.data.query.query, type: 'activeCounsellorsPagination' })
                }

                if (searchResults.data && searchResults.data.data instanceof Array && searchResults.data.data.length > 0) {
                    commit('mutate', { what: 'activeCounsellors', value: searchResults.data.data })
                } else {
                    commit('mutate', { what: 'activeCounsellors', value: [] })
                }
            } catch (error) {
                commit('mutate', { what: 'activeCounsellors', value: [] })
            }
        },
        /**
         * Load counsellors
         * @param commit
         * @param state
         * @param query
         * @param offset
         * @param limit
         * @returns {Promise<void>}
         */
        async loadCounsellors({commit, state}, {query, page, per_page, active = 0}) {

            commit('mutate', { what: 'loadingCounsellors', value: true })

            try {
                const searchResults = (await counsellorApi.getCounsellors({
                    query: query,
                    page,
                    per_page,
                    active
                }))
              
                if (searchResults && searchResults.data) {
                  commit('mutate', { what: 'counsellorsPagination', value: searchResults.data.pagination })
                  commit('SET_PAGINATION_QUERY', { query: searchResults.data.query.query, type: 'counsellorsPagination' })
                }

                if (searchResults.data && searchResults.data.data instanceof Array && searchResults.data.data.length > 0) {
                    commit('mutate', { what: 'counsellors', value: searchResults.data.data })
                } else {
                    commit('mutate', { what: 'counsellors', value: [] })
                }
            } catch (error) {
                commit('mutate', { what: 'counsellors', value: [] })
            } finally {
                commit('mutate', { what: 'loadingCounsellors', value: false })
            }
        },
        /**
         * Load counsellor students
         * @param commit
         * @param state
         * @param counsellorId
         * @returns {Promise<void>}
         */
        async loadCounsellorStudents({ commit, state }, { counsellorId }) {
            commit('mutate', {  what: 'counsellorId', value: counsellorId })

            commit('mutate', {  what: 'loadingCounsellorStudents', value: true })

            try {
                const searchResults = await counsellorApi.getCounsellorStudents({
                    counsellorId: state.counsellorId
                })

                commit('mutate', {
                    what: 'counsellorStudents',
                    value: searchResults.data instanceof Array ? searchResults.data : []
                })
            } catch (error) {
                commit('mutate', { what: 'counsellorStudents', value: [] })
            } finally {
                commit('mutate', {  what: 'loadingCounsellorStudents', value: false })
            }
        },
        /**
         * Load possible counsellor students
         * @param commit
         * @param state
         * @param counsellorId
         * @param query
         * @param offset
         * @param limit
         * @returns {Promise<void>}
         */
        async loadStudentsPossibleToAssign({commit, state}, {counsellorId, query, page, per_page}) {
            commit('mutate', { what: 'counsellorId', value: counsellorId })

            commit('mutate', { what: 'loadingStudentPossibleToAssign', value: true })

            try {
                const searchResults = (await counsellorApi.getPossibleCounsellorStudents({
                    counsellorId: state.counsellorId,
                    query,
                    page, 
                    per_page
                }))
              
                if (searchResults && searchResults.data) {
                    commit('mutate', { what: 'studentsPossibleToAssignPagination', value: searchResults.data.pagination })
                    commit('SET_PAGINATION_QUERY', { query: searchResults.data.query.query, type: 'studentsPossibleToAssignPagination' })
                }

                if (searchResults.data && searchResults.data.data instanceof Array && searchResults.data.data.length > 0) {
                    commit('mutate', { what: 'studentsPossibleToAssign', value: searchResults.data.data })
                } else {
                    commit('mutate', { what: 'studentsPossibleToAssign', value: [] })
                }
            } catch (error) {
                commit('mutate', { what: 'studentsPossibleToAssign', value: [] })
            } finally {
                commit('mutate', { what: 'loadingStudentPossibleToAssign', value: false })
            }
        },
        /**
         * Assign student to counsellor
         * @param _context
         * @param counsellorId
         * @param studentId
         * @returns {*|AxiosPromise<any>}
         */
        assignCounsellor(_context, {counsellorId, studentId}) {
            return counsellorApi.assignUserCounsellor({
                counsellorUserId: counsellorId,
                userId: studentId
            })
        },
        /**
         * Unassign student from counsellor
         * @param context
         * @param counsellorId
         * @param studentId
         * @returns {*|AxiosPromise<any>}
         */
        unAssignCounsellor(context, {counsellorId, studentId}) {
            return counsellorApi.unAssignUserCounsellor({
                counsellorUserId: counsellorId,
                userId: studentId
            })
        }
    }
}
