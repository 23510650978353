import Vue from 'vue'
import groupApi from '@/api/groupAPI'

export const groupStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        apps : {},
        dashboard_widgets: {},
        dropout_members : [],
        class_notebook_links : {},
        current_group_id : 0,
        info : {},
        information : [],
        institution_settings : {},
        institution: {},
        isMockStudent: false,
        isStudentView: false,
        members : {},
        selectedApp : '',
        group_settings : {},
        queriedGroups: [],
        groupsQueryData: {},
        subGroups : {},
        userGroups : [], // all user member groups
    },


    getters: {
        // getLoggedInUserInfo: state => {
        //   return state.user
        // }
    },


    actions : {

        async addNewGroupMembers({ commit, state }, { group_id, members }) {

            const response = await groupApi.addMembers({
                group_id : group_id,
                members : members
            })

            const new_members = response.data

            commit('SET_GROUP_MEMBERS', {
                group_id : group_id,
                members : new_members
            })

            return response
        },
        
      async inviteUsersByEmail({ commit, state }, users) {
        users.forEach(user => {
                if (user.userEmail && user.destinationId) {
                    groupApi.inviteUserByEmail(user)
                }
            })
        },

        /**
         * Function for archiving a group
         */
        async archiveGroup({ commit, state }, { group_id, for_all } ) {
            const response = await groupApi.archiveGroup({ group_id, for_all })
            commit('ARCHIVE_GROUP', {
                group_id : group_id
            })
            return response
        },

        /**
         * Function for checking whether an app is hidden or not in the group - used for access control
         */
        checkAppHidden({ commit, state }, { group_id, app_description }) {
            let found = false
            if (!state.apps || !state.apps[group_id]) {
                return false
            }
            state.apps[group_id].map( app => {
                if (app.description === app_description && parseInt(app.visible) === 0) {
                    found = true
                }
            })
            return found
        },

        async checkExamMode({ commit, state }) {
            return await groupApi.checkExamMode()
        },

        /**
         * Function for demoting an admin to member
         */
        async demoteMember({ commit, state }, { group_id, user_id }) {
            const response = await groupApi.demoteMember(group_id, user_id)
            commit('DEMOTE_MEMBER', {
                group_id : group_id,
                user_id : user_id
            })
            return response
        },

        async fetchDropoutMembers({ commit, state }, { group_id }) {

            if (state.dropout_members[group_id] && state.dropout_members[group_id].length) {
                return state.dropout_members[group_id]
            }

            const response = await groupApi.fetchDropoutMembers({
                group_id : group_id
            })

            commit('SET_DROPOUT_MEMBERS', {
                group_id : group_id,
                dropouts : response.data
            })

            return response
        },

        async fetchGroupClassNotebookLink({ commit, state }, { group_id }) {

            if (state.class_notebook_links[group_id] && state.class_notebook_links[group_id].length > 5) {
                return state.class_notebook_links[group_id]
            }

            const response = await groupApi.fetchGroupClassNotebookLink({
                group_id : group_id
            })

            const link = response.data.link

            commit('SET_CLASS_NOTEBOOK', {
                group_id : group_id,
                link : link
            })

            return link
        },

        /**
         * Function for removing a member from a group
         */
        async removeMember({ commit, state }, { group_id, user_id }) {

            const response = await groupApi.removeMember(group_id, user_id)
            commit('REMOVE_MEMBER', {
                group_id : group_id,
                user_id : user_id
            })
            return response

        },

        /**
         * function for content for the group information app
         */
        async pullDashboardWidgets({ commit, state }, { group_id }) {

            const response = await groupApi.pullDashboardWidgets({
                group_id : group_id
            })

            commit('SET_GROUP_DASHBOARD_WIDGETS', {
                group_id : group_id,
                data : response.data
            })

            return response

        },

        /**
        * function for pulling group info and apps
        */
        async pullGroupInfo({ commit, state }, group_id) {
            if (state.info[group_id] && state.info[group_id]['group_name'] && state.info[group_id]['group_name'].length && state.apps[group_id] && state.apps[group_id].length) {

                commit('SET_GROUP_LAST_VISITED', {
                    group_id : group_id,
                    last_visited : new Date().getTime()
                })

                return {
                    apps : state.apps[group_id]
                }

            } else {

                const response = await groupApi.pullGroupApps(group_id)

                if (!response || !response.data) {
                    return 0
                }

                const apps = response.data.apps
                let info = response.data.info
                const settings = response.data.settings
                const institution = response.data.institution

                info['material_group_info'] = response.data.material_group_info

                commit('SET_GROUP_INFO', {
                    group_id : group_id,
                    info : info
                })

                commit('SET_GROUP_APPS', {
                    group_id : group_id,
                    apps : apps
                })

                commit('SET_GROUP_SETTINGS', {
                    group_id : group_id,
                    settings : settings
                })

                commit('SET_GROUP_INSTITUTION', {
                    group_id : group_id,
                    institution : institution
                })

                commit('SET_GROUP_LAST_VISITED', {
                    group_id : group_id,
                    last_visited : new Date().getTime()
                })

                return response.data
            }

        },

        /**
         * function for content for the group information app
         */
        async pullGroupInformation({ commit, state }, group_id) {
            if (group_id === undefined ||group_id == null) {
                return 1
            }

            const response = await groupApi.pullGroupInformation(group_id)
            commit('SET_GROUP_INFORMATION', response.data)

            return response
        },

        async pullGroupMembers({ commit, state }, group_id) {

            if (state.members.length && state.members[group_id].length) {
                return state.members[group_id]
            } else {
                const response = await groupApi.pullGroupMembers(group_id)
                const members = response.data
                commit('SET_GROUP_MEMBERS', {
                    group_id : group_id,
                    members : members
                })
                return members
            }

        },

        /**
         * Function for pulling all groups the user is a member of.
         */
        async pullSubGroupMembers({ commit, state }, sub_group_id) {
            // check if set
            if (state.userGroups.length) {
                return state.userGroups
            } else {
                const response = await groupApi.pullUserGroups()
                commit('SET_USER_GROUPS', response.data)
            }

        },

        /**
         * Function for pulling all groups the user is a member of.
         */
        async pullSubGroups({ commit, state }, { group_id }) {
            // check if set
            if (state.subGroups[group_id] && state.subGroups[group_id].length) {
                return state.subGroups[group_id]
            } else {
                const response = await groupApi.pullSubGroups(group_id)
                commit('SET_SUB_GROUPS', {
                    group_id : group_id,
                    sub_groups : response.data
                })
            }

        },

        /**
        * Function for pulling all groups the user is a member of.
        */
        async pullUserGroups({ commit, state }, includeCourses) {
            const response = await groupApi.pullUserGroups(includeCourses)
            commit('SET_USER_GROUPS', response.data)
            return response.data

        },

        /**
         * Function for promoting a member to admin
         */
        async promoteMember({ commit, state }, { group_id, user_id }) {
            const response = await groupApi.promoteMember(group_id, user_id)
            commit('PROMOTE_MEMBER', {
                group_id : group_id,
                user_id : user_id
            })
            return response
        },

        async removeSubGroupFromGroup({ commit, state }, { group_id, sub_group_id }) {

            const response = await groupApi.removeSubGroupFromGroup({
                group_id : group_id,
                sub_group_id : sub_group_id
            })

            commit('REMOVE_SUB_GROUP', {
                group_id : group_id,
                sub_group_id : sub_group_id
            })

            return response
        },

        /**
         * Function for saving the group apps and sort order
         */
        async saveApps({ commit, state }, { group_id, apps, formatted_apps }) {


            commit('SET_GROUP_APPS', {
                group_id : group_id,
                apps : apps
            })

            const api_response = await groupApi.saveApps({
                group_id : group_id,
                apps : formatted_apps
            })

            commit('SET_GROUP_APPS', {
                group_id : group_id,
                apps : api_response.data
            })

            return api_response

        },

        async saveClassNotebook({ commit, state }, { group_id, link }) {

            const api_response = await groupApi.saveClassNotebook({
                group_id : group_id,
                link : link
            })

            commit('SET_CLASS_NOTEBOOK', {
                group_id : group_id,
                link : link
            })

            return api_response

        },

        async saveDashboardWidgets({ commit, state }, { group_id, widgets }) {

            const api_response = await groupApi.saveDashboardWidgets({
                group_id : group_id,
                widgets : widgets
            })

            const new_widgets = api_response.data

            commit('SET_GROUP_DASHBOARD_WIDGETS', {
                group_id : group_id,
                data : new_widgets
            })

            return api_response

        },

        /**
         * Add\Update group information
         * @param commit
         * @param state
         * @param group_id
         * @param group_information_id
         * @param title
         * @param text
         * @returns {Promise<{data: null, success: boolean}>}
         */
        async saveGroupInformation({ commit, state }, { group_id, group_information_id, title, text }) {
            try {
                let resp

                if (group_information_id === null) {
                    //Assume we add new group information
                    resp = await groupApi.addGroupInformation(group_id, title, text)
                    commit('ADD_INFORMATION', resp.data)
                } else {
                    resp = await groupApi.updateGroupInformation(group_id, group_information_id, title, text)
                    commit('UPDATE_INFORMATION', resp.data)
                }

                return {
                    success: true,
                    data: resp.data ? resp.data : null
                }
            } catch (e) {
                return {
                    success: false,
                    data: e.data ? e.data : null
                }
            }
        },

        /**
         * Delete group information
         * @param commit
         * @param state
         * @param group_id
         * @param group_information_id
         * @returns {Promise<{data: null, success: boolean}>}
         */
        async removeGroupInformation({ commit, state }, { group_id, group_information_id}) {
            try {

              const resp = await groupApi.removeGroupInformation(group_id, group_information_id)
              commit('REMOVE_INFORMATION', group_information_id)

                return {
                    success: true,
                    data: resp.data ? resp.data : null
                }

            } catch (e) {
                return {
                    success: false,
                    data: e.data ? e.data : null
                }
            }
        },

        async resetUserDeadlineCompletion({ commit, state }, group_id) {
            return await groupApi.resetUserDeadlineCompletion({
                group_id : group_id
            })
        },



        async saveGroupClassAndLanguage({ commit, state }, { group_id, class_id, default_language_id }) {

            let error = false
            const response = await groupApi.saveGroupClassAndLanguage({
                group_id : group_id,
                class_id : class_id,
                default_language_id : default_language_id
            }).catch( err => {
                error = true
                return 0
            })

            if (error || !response || !response.data) {
                return 0
            }

            commit('SET_GROUP_CLASS_AND_LANGUAGE', {
                group_id : group_id,
                class_id : class_id,
                language_data : response.data
            })

            return 1

        },

        async saveGroupName({ commit, state }, { group_id, name }) {

            await groupApi.saveGroupName({
                group_id : group_id,
                name : name
            })

            commit('SET_GROUP_NAME', {
                group_id : group_id,
                name : name
            })

            return 1

        },

        async saveGroupSetting({ commit }, { group_id, setting, value }) {

            await groupApi.saveGroupSetting({
                group_id : group_id,
                setting : setting,
                value : value
            })

            commit('SET_GROUP_SETTINGS', {
                group_id : group_id,
                settings : [{
                    setting : setting,
                    value : value
                }]
            })

            return 1
        },

        async setWidgetsOrder({ commit }, { group_id, widgets }) {

            // await groupApi.saveGroupSetting({
            //     group_id : group_id,
            //     setting : setting,
            //     value : (value ? 1 : 0)
            // })

            commit('SET_GROUP_DASHBOARD_WIDGETS', {
                group_id : group_id,
                data : widgets
            })

            return 1
        },

        resetSearchGroupsResults({ commit }) {
            commit('SET_QUERIED_GROUPS', {groups: []})
            commit('SET_GROUPS_QUERY_DATA', {})
        },

        /**
         * Function for searching for a group
         */
        async searchGroup({ commit }, { institution_id, txt, page, per_page, filter, filter_value }) {
            const response = await groupApi.searchGroup({
                institution_id,
                txt,
                page,
                per_page,
                filter,
                filter_value
            })

            commit('SET_QUERIED_GROUPS', {
                groups: response.data.data,
            })

            commit('SET_GROUPS_QUERY_DATA', response.data.query || {})

            return response
        },

        /**
         * @desc Structure/Destructure mock student
         * @param {Object} vx - Vuex specific functions
         * @param {boolean} isMockStudent - Mock student
         */
        async setMockStudent(vx, isMockStudent) {
          const { commit, dispatch, rootState, state } = vx

          const group_id = state.current_group_id

          // Deep copy helper func
          const deepCopy = object_slash_array => JSON.parse(JSON.stringify(object_slash_array))

          // ------- GROUP NEWS ------- //

          // Pull group news state
          const newsRef = rootState.newsStore.news[group_id]
          const groupNews = !!newsRef && deepCopy(newsRef)

          if (groupNews && groupNews.length) {
            // Remove mocked comments
            const posts = groupNews.map(post => {
              post.comments = post.comments.filter(comment => !comment.mock_identifier) || []
              return post
            })

            // Remove mocked posts
            const news = posts.filter(post => !post.mock_identifier) || []

            // Update news state
            commit('newsStore/SET_GROUP_NEWS', {
              group_id,
              news,
            }, { root: true })
          }

          // ---- GROUP MATERIALS ---- //

          // Pull group material state
          const materalsRef = rootState.materialStore.material_elements[group_id]
          const groupMaterials = !!materalsRef && deepCopy(materalsRef)

          if (groupMaterials && groupMaterials.length) {
            // Reset elements
            const elements = groupMaterials.map(material => {
              material.read_date = null
              material.understood_date = null
              return material
            })

            // Update material state
            commit('materialStore/SET_GROUP_MATERIAL_ELEMENTS', {
              reference_course_id: 0,
              group_id,
              elements,
            }, { root: true })
          }

          // ---- GROUP CONFERENCES ---- //

          // Pull group conference state
          const conferenceRef = rootState.conferenceStore.posts[group_id]
          const groupConferences = !!conferenceRef && deepCopy(conferenceRef)

          if (groupConferences && groupConferences.length) {
            // Remove mocked answers
            const conferences = groupConferences.map(conference => {
              conference.answers = conference.answers.filter(answer => !answer.mock_identifier) || []
              return conference
            })

            // Remove mocked conferences
            const posts = conferences.filter(conference => !conference.mock_identifier) || []

            // Update conference state
            commit('conferenceStore/SET_CONFERENCE_POSTS', {
              group_id,
              posts,
            }, { root: true })
          }

          // ------ GROUP VIDEOS ------ //

          // Pull group video state
          const videoRef = rootState.mediaStore.course_videos[group_id]
          const groupVideos = !!videoRef && deepCopy(videoRef)

          if (groupVideos && groupVideos.length) {
            // Reset videos
            const videos = groupVideos.map(video => {
              video.max_watch_time = 0
              return video
            })

            // Update video state
            commit('mediaStore/SET_COURSE_VIDEOS', {
              group_id,
              videos,
            }, { root: true })
          }

          // Set mock student state
          commit('SET_MOCK_STUDENT', isMockStudent)
          return 1
        },

        /**
         * Function for settings institution data
         */
        async setInstitutionData({ commit, state }, { data }) {
            commit('SET_INSTITUTION_DATA', data)
            return 1
        },

        /**
         * Function for settings institution settings
         */
        async setInstitutionsSettings({ commit, state }, { settings }) {
            commit('SET_INSTITUTION_SETTINGS', settings)
            return 1
        },


        /**
         * Function for setting the user groups directly - used when logging in
         */
        async setUserGroups({ commit, state }, { groups }) {
            if (groups.length) {
                commit('SET_USER_GROUPS', groups)
            }
            return 1
        },


        /**
        * Function for setting the currently selected app in the view
        */
        async setSelectedApp({ commit }, selectedApp) {
            commit('SET_GROUP_SELECTED_APP', selectedApp)
            return 1
        },

        /**
         * @desc Set isStudentView
         * @param {boolean} isStudentView - View group as student
         */
        setStudentView({ commit }, isStudentView) {
          commit('SET_STUDENT_VIEW', isStudentView)
          return 1
        },

        /**
         * Function for unarchiving a group
         */
        async unarchiveGroup({ commit, state }, group_id) {
            const response = await  groupApi.unarchiveGroup(group_id)
            commit('UNARCHIVE_GROUP', {
                group_id : group_id
            })
            return response
        },

        async saveGroupInformationSequence({ commit, state }, { group_id, information_ids }) {
            // dont wait since we want it to appear like it is happening fast

          const newSequenceInformation = information_ids.map((element, index) => {
            const data = element
            data.sequence = index + 1
            return data
          })
          groupApi.saveGroupInformationSequence(group_id, newSequenceInformation)
            commit('SET_GROUP_INFORMATION', newSequenceInformation)
        }
    },


    mutations: {

        ARCHIVE_GROUP: (state, { group_id }) => {
            if (state.info[group_id]) {
                Vue.set(state.info[group_id], 'archived', 1)
            }
            if (state.userGroups) {
                state.userGroups.map((group, index) => {
                    if (parseInt(group.group_id) === parseInt(group_id)) {
                        Vue.set(state.userGroups[index], 'archived', 1)
                    }
                })
            }
        },

        DEMOTE_MEMBER: (state, {group_id, user_id}) => {
            if (state.members[group_id]) {
                state.members[group_id].map((member, index) => {
                    if (parseInt(member.user_id) === parseInt(user_id)) {
                        Vue.set(state.members[group_id][index], 'is_admin', 0)
                    }
                })
            }
        },

        PROMOTE_MEMBER: (state, {group_id, user_id}) => {
            let members = state.members[group_id]
            for (let member of members) {
                if (parseInt(member.user_id) === parseInt(user_id)) {
                    let index = members.indexOf(member)
                    Vue.set(state.members[group_id][index], 'is_admin', 1)
                }
            }
        },

        REMOVE_MEMBER: (state, {group_id, user_id}) => {
            let members = state.members[group_id]
            for (let member of members) {
                if (parseInt(member.user_id) === parseInt(user_id)) {
                    let index = members.indexOf(member)
                    Vue.delete(state.members[group_id], index)
                }

            }
        },

        REMOVE_SUB_GROUP: (state, { group_id, sub_group_id }) => {
            if (!state.subGroups[group_id]) {
                return 0
            }
            sub_group_id = parseInt(sub_group_id)
            state.subGroups[group_id].map( (sub_group, index) => {
                if (parseInt(sub_group['group_id']) === sub_group_id) {
                    Vue.delete(state.subGroups[group_id], index)
                }
            })
        },

        SET_CLASS_NOTEBOOK: (state, { group_id, link }) => {
            Vue.set(state.class_notebook_links, parseInt(group_id), link)
        },

        SET_GROUP_APPS: (state, { group_id, apps }) => {

            let _apps = [...apps]
            _apps.map( (app,index) => {
                _apps[index]['activated'] = parseInt(app.activated)
                const visible = (parseInt(app.activated)) ? parseInt(app.visible) : 1
                _apps[index]['visible'] = visible
            })

            Vue.set(state.apps, group_id, _apps)
        },

        SET_DROPOUT_MEMBERS: (state, { group_id, dropouts }) => {

            Vue.set(state.dropout_members, group_id, dropouts)

        },

        SET_QUERIED_GROUPS: (state, data) => {
            const { groups } = data

            Vue.set(state, 'queriedGroups', groups)
        },

        SET_USER_GROUPS: (state, groups) => {

            if (groups) {
                groups.map((group, index) => {
                    groups[index]['group_id'] = parseInt(group.group_id)
                })
            }

            Vue.set(state, 'userGroups', groups)

        },

        SET_GROUP_CLASS_AND_LANGUAGE: (state, { group_id, class_id, language_data }) => {

            state.userGroups.map( (group, index) => {
                if (parseInt(group.group_id) === parseInt(group_id)) {
                    Vue.set(state.userGroups[index], 'adaptive_class_id', parseInt(class_id))
                    Vue.set(state.userGroups[index], 'language_id', parseInt(language_data.language_id))
                    Vue.set(state.userGroups[index], 'language_key', language_data.language_key)
                    Vue.set(state.userGroups[index], 'language_key_short', language_data.language_key_short)
                    Vue.set(state.userGroups[index], 'speech_library_key', language_data.speech_library_key)
                }
            })

        },

        SET_GROUP_DASHBOARD_WIDGETS: (state, { group_id, data }) => {
            // set int on all numbers
            if (data && data.length) {
                data.map(widget => {
                    widget.x = parseInt(widget.x)
                    widget.y = parseInt(widget.y)
                    widget.h = parseInt(widget.h)
                    widget.w = parseInt(widget.w)
                    widget.i = parseInt(widget.sequence)
                })

                Vue.set(state.dashboard_widgets, group_id, data)
            } else {
              Vue.set(state.dashboard_widgets, group_id, [])
            }

        },

        SET_GROUP_INFO: (state, { group_id, info }) => {
            Vue.set(state.info, group_id, info)
            Vue.set(state, 'current_group_id', group_id)
        },

        SET_GROUP_LAST_VISITED: (state, { group_id, last_visited }) => {
            if (state.userGroups) {
                state.userGroups.map((group, index) => {
                    if (parseInt(group.group_id) === parseInt(group_id)) {
                        Vue.set(state.userGroups[index], 'last_visited', last_visited)
                    }
                })
            }
        },

        SET_GROUP_INFORMATION: (state, information) => {
            state.information = information
        },
        ADD_INFORMATION: (state, information) => {
          state.information.push(information)
        },
        UPDATE_INFORMATION: (state, information) => {
          const index = state.information.findIndex(item => parseInt(item.id) === parseInt(information.id))
          if (index !== -1) {
            Vue.set(state.information, index, information)
          }
        },
        REMOVE_INFORMATION: (state, id) => {
          const index = state.information.findIndex(item => parseInt(item.id) === parseInt(id))
          if (index !== -1) {
            state.information.splice(index,1)
          }
        },

        SET_GROUP_MEMBERS: (state, { group_id, members }) => {
            Vue.set(state.members, group_id, members)
        },

        SET_GROUP_NAME: (state, { group_id, name }) => {
            group_id = parseInt(group_id)
            if (state.info[group_id]) {
                Vue.set(state.info[group_id], 'group_name', name)
            }
            if (state.userGroups) {
                state.userGroups.map((group, index) => {
                    if (parseInt(group.group_id) === group_id) {
                        Vue.set(state.userGroups[index], 'group_name', name)
                    }
                })
            }
        },

        SET_GROUP_SELECTED_APP: (state, selectedApp) => {
            Vue.set(state, 'selectedApp', selectedApp)
        },

        SET_GROUP_SETTINGS: (state, { group_id, settings }) => {

            if (!state.group_settings[group_id]) {
                Vue.set(state.group_settings, group_id, {})
            }

            if (settings && settings.length) {
                settings.map( (setting, index) => {
                    Vue.set(state.group_settings[group_id], setting.setting, setting.value)
                })
            }

        },

        SET_INSTITUTION_SETTINGS: (state, institution_settings) => {
            for (let setting of institution_settings) {
                Vue.set(state.institution_settings, setting.name, setting.value)
            }
        },

        SET_INSTITUTION_DATA: (state, data) => {
            Vue.set(state, 'institution_data', data)
        },

        SET_MOCK_STUDENT: (state, isMockStudent) => {
          Vue.set(state, 'isMockStudent', isMockStudent)
        },

        SET_STUDENT_VIEW: (state, isStudentView) => {
          Vue.set(state, 'isStudentView', isStudentView)
        },

        SET_SUB_GROUP_MEMBERS: (state, { group_id, sub_group_id, members }) => {
            if (state.subGroups[group_id]) {
                state.subGroups[group_id].map( (sub_group, index) => {
                    if (parseInt(sub_group.group_id) === parseInt(sub_group_id)) {
                        Vue.set(state.subGroups[group_id][index], 'members', members)
                    }
                })
            }
        },

        SET_SUB_GROUPS: (state, { group_id, sub_groups }) => {
            if (!state.subGroups[group_id]) {
                Vue.set(state.subGroups, group_id, [])
            }
            let arr = [...sub_groups]
            arr.map( (sub_group, index) => {
                if (sub_group['moodle_sub_group_id'] && parseInt(sub_group['moodle_sub_group_id'])) {
                    arr[index]['group_name'] = `${sub_group['group_name']} (moodle_id:${sub_group['moodle_sub_group_id']})`
                } else if (sub_group['course_name_short'] && sub_group['course_name_short'].length) {
                    arr[index]['group_name'] = `${sub_group['group_name']} (${sub_group['course_name_short']})`
                }
            })
            Vue.set(state.subGroups, group_id, arr)
        },

        UNARCHIVE_GROUP: (state, { group_id }) => {
            if (state.info[group_id]) {
                Vue.set(state.info[group_id], 'archived', 0)
            }
            if (state.userGroups) {
                state.userGroups.map((group, index ) => {
                    if (parseInt(group.group_id) === parseInt(group_id)) {
                        Vue.set(state.userGroups[index], 'archived', 0)
                    }
                })
            }
        },

        SET_GROUP_INSTITUTION: (state, { group_id, institution }) => {
            Vue.set(state.institution, group_id, institution)
        },

        SET_GROUPS_QUERY_DATA: (state, queryData) => {
            state.groupsQueryData = queryData
        },
    }
}
