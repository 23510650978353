import Vue from 'vue'
import adaptiveObjectAPI from '@/api/adaptiveObjectAPI'

export const adaptiveObjectStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        icons: {
            1 : 'mdi-square-edit-outline',
            2 : 'mdi-checkbox-marked-outline',
            3 : 'mdi-book-open-page-variant',
            4 : 'fa-cubes',
            5 : {
                'audio' : 'mdi-headphones',
                'default' : 'mdi-file',
                'image' : 'mdi-image-outline',
                'vimeo' : 'mdi-vimeo',
                'video' : 'mdi-play',
                'youtube' : 'mdi-youtube'
            },
            6 : 'mdi-graphql',
            7: 'mdi-help-circle-outline',
            8 : 'mdi-folder',
            10 : 'mdi-view-list',
            11 : 'home',
            12 : 'mdi-link-variant',
            13 : 'mdi-bullhorn-outline',
            14 : 'mdi-card-bulleted-outline',
            15 : 'mdi-cards-outline',
            20 : 'mdi-timeline-text-outline',
            22 : 'mdi-view-dashboard-variant'
        },
        sharedAdaptiveObjects: [],
        titles: {
            1 : 'Submission',
            2 : 'Quiz',
            3 : 'Text',
            4 : 'Lesson',
            5 : {
                'audio' : 'Audio',
                'default' : 'File',
                'image' : 'Image',
                'vimeo' : 'Vimeo',
                'video' : 'Video',
                'youtube' : 'Youtube'
            },
            6 : 'Geogebra',
            7 : 'Question',
            8 : 'Folder',
            10 : 'Course',
            11 : 'Group',
            12 : 'External link',
            13 : 'Questionnaire',
            14 : 'Flashcard',
            15 : 'Flashcards',
            20 : 'Timeline',
            22 : 'Board'
      },
      elementsTypes: {
        'Content': [
          {
            title: 'Text',
            adaptiveObjectId: 3,
            img: 'text',
            mediaType: null,
          },
          {
            title: 'File',
            adaptiveObjectId: 5,
            img: 'file',
            mediaType: 'file',
          },
          {
            title: 'Youtube',
            adaptiveObjectId: 5,
            img: 'youtube',
            mediaType: 'youtube',
          },
          {
            title: 'Vimeo',
            adaptiveObjectId: 5,
            img: 'vimeo',
            mediaType: 'vimeo',
          },
          {
            title: 'Video',
            adaptiveObjectId: 5,
            img: 'video',
            mediaType: 'video',
          },
          {
            title: 'Audio',
            adaptiveObjectId: 5,
            img: 'audio',
            mediaType: 'audio',
          },
          {
            title: 'Image',
            adaptiveObjectId: 5,
            img: 'image',
            mediaType: 'image',
          },
          {
            title: 'External link',
            adaptiveObjectId: 12,
            img: 'link',
            mediaType: null,
          }
        ],
        'Assessments': [
          {
            title: 'Quiz',
            adaptiveObjectId: 2,
            img: 'quiz',
            mediaType: null,
          },
          {
            title: 'Submission',
            adaptiveObjectId: 1,
            img: 'submission',
            mediaType: null,
          },
          {
            title: 'Questionnaire',
            adaptiveObjectId: 13,
            img: 'questionnaire',
            mediaType: null,
          }
        ],
        'Graphical': [
          {
            title: 'Timeline',
            adaptiveObjectId: 20,
            img: 'timeline',
            mediaType: null,
          }
        ],
        'Interactive': [
          {
            title: 'Flashcards',
            adaptiveObjectId: 15,
            img: 'flashcards',
            mediaType: null,
          },
          {
            title: 'Geogebra',
            adaptiveObjectId: 6,
            img: 'geogebra',
            mediaType: null,
          }
        ],
        'Co-creation': [
          {
            title: 'Pinboard',
            adaptiveObjectId: 22,
            img: 'pinboard',
            mediaType: null,
          }
        ]
      },
      elementTypesIcons: {
        1 : 'submission',
        2 : 'quiz',
        3 : 'text',
        5 : {
            'audio': 'audio',
            'default': 'file',
            'image': 'image',
            'vimeo': 'vimeo',
            'video': 'video',
            'youtube': 'youtube'
        },
        6 : 'geogebra',
        12 : 'link',
        13 : 'questionnaire',
        15 : 'flashcards',
        20 : 'timeline',
        22 : 'pinboard'
      },

      learningelements: [
        {
          value: 1,
          text: 'Submissions',
          name: 'submission',
          visible: 1
        },
        {
          value: 2,
          text: 'Quizzes',
          name: 'quiz',
          visible: 1
        },
        {
          value: 3,
          text: 'Text',
          name: 'text',
          visible: 1
        },
        {
          value: 5,
          text: 'Media',
          name: 'media',
          visible: 1
        },
        {
          value: 6,
          text: 'Geogebra',
          name: 'geogebra',
          visible: 1
        },
        {
          value: 7,
          text: 'Quiz questions',
          name: 'quiz-question',
          visible: 0
        },
        {
          value: 12,
          text: 'Links',
          name: 'link',
          visible: 1
        },
        {
          value: 13,
          text: 'Questionnaires',
          name: 'questionnaire',
          visible: 1
        },
        {
          value: 15,
          text: 'Flashcards',
          name: 'flashcards',
          visible: 1
        },
        {
          value: 20,
          text: 'Timelines',
          name: 'timeline',
          visible: 1
        },
        {
          value: 22,
          text: 'Pinboards',
          name: 'pinboard',
          visible: 1
        },
      ]

    },


    getters: {
        getAdaptiveIconFromId: (state) => (adaptive_object_id, mdi = true) => {
          if (mdi) {
            return state.icons[adaptive_object_id]
          } else {
            return state.elementTypesIcons[adaptive_object_id]
          }
        },
        getAdaptiveTitleFromId: (state) => (adaptive_object_id) => {
          return state.titles[adaptive_object_id] || ''
        },
        getAdaptiveObjectMediaTypeFromId: (state) => (adaptive_object_id) => {
            let found = false;

            for (const section of Object.keys(state.elementsTypes)) {
                for (const obj of state.elementsTypes[section]) {
                    if (obj.adaptiveObjectId === +adaptive_object_id) {
                        return obj.mediaType
                    }
                }
            }

            return null
        }
    },


    actions : {

        async checkAdaptiveObjectCopyLog({ commit }, { adaptive_object_type_id, adaptive_object_id, source_group_id, destination_group_id }) {
            return await adaptiveObjectAPI.checkAdaptiveObjectCopyLog({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id,
                source_group_id : source_group_id,
                destination_group_id : destination_group_id
            })
        },

        async copyAdaptiveObject({ commit }, { adaptive_object_type_id, adaptive_object_id, source_group_id, destination_group_id, copy_to_course_id, copy_to_lesson_id, allow_sync=1 }) {
            return await adaptiveObjectAPI.copyAdaptiveObject({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id,
                source_group_id: source_group_id,
                copy_to_course_id,
                copy_to_lesson_id,
                destination_group_id : destination_group_id,
                allow_sync : allow_sync
            })
        },

        async getAdaptiveTags({ commit }, { adaptive_object_type_id, adaptive_object_id }) {
            return await adaptiveObjectAPI.getAdaptiveTags({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id
            })
        },

        /**
         * @desc Get shared adaptive objects for the current user
         * @return {Promise} response
         */
        async getSharedAdaptiveObjects(vx) {
            const { commit } = vx

            const response = await adaptiveObjectAPI.getSharedAdaptiveObjects()

            commit('SET_SHARED_ADAPTIVE_OBJECTS', {
                adaptiveObjects: response.data,
            })

            return response
        },

        async linkAdaptiveObject({ commit, state }, { adaptive_object_type_id, adaptive_object_id, destination_group_id, destination_course_id = 0, destination_material_id = 0 }) {
            return await adaptiveObjectAPI.linkAdaptiveObject({
                adaptive_object_type_id : adaptive_object_type_id,
                adaptive_object_id : adaptive_object_id,
                destination_group_id : destination_group_id,
                destination_course_id : destination_course_id,
                destination_material_id : destination_material_id
            })
        },

        /**
         * @desc Share adaptive object with a set of users
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Share data
         * @param {number} data.adaptiveObjectTypeId
         * @param {number} data.adaptiveObjectId
         * @param {number} data.sourceGroupId
         * @param {Array} data.users
         * @return {Promise} response
         */
        async shareAdaptiveObject(vx, data) {
            const {} = vx
            const {
                adaptiveObjectTypeId,
                adaptiveObjectId,
                sourceGroupId,
                users,
            } = data

            let userIds = []

            // Pull only user ids
            users.forEach(user => {
                userIds.push(parseInt(user.user_id))

                return 1
            })

            const response = await adaptiveObjectAPI.shareAdaptiveObject({
                adaptive_object_type_id: parseInt(adaptiveObjectTypeId),
                adaptive_object_id: parseInt(adaptiveObjectId),
                source_group_id: parseInt(sourceGroupId),
                users: userIds,
            })

            return response
        },
    },


    mutations: {
        SET_SHARED_ADAPTIVE_OBJECTS: (state, data) => {
            const { adaptiveObjects } = data

            Vue.set(state, 'sharedAdaptiveObjects', adaptiveObjects)

            return 1
        }
    }
}
