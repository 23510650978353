
/*
    VIEWS
 */
const adminInstitutionsGroupsView = () => import('@/views/admin/institutions/adminInstitutionsGroupsView')
const adminInstitutionsImportUsersView = () => import('@/views/admin/institutions/adminInstitutionsImportUsersView')
const adminInstitutionsPermissionsView = () => import('@/views/admin/institutions/adminInstitutionsPermissionsView')
const adminInstitutionsSettingsView = () => import('@/views/admin/institutions/adminInstitutionsSettingsView')
const adminInstitutionsUsersView = () => import('@/views/admin/institutions/adminInstitutionsUsersView')

export default [
    {
        path: 'users',
        component: adminInstitutionsUsersView,
        meta: { requiresAuth: true, view : 'admin-institution-users' },
    },
    {
        path: 'groups',
        component: adminInstitutionsGroupsView,
        meta: { requiresAuth: true, view : 'admin-institution-groups' },
    },
    {
        path: 'import-users',
        component: adminInstitutionsImportUsersView,
        meta: { requiresAuth: true, view : 'admin-institution-import-users' },
    },
    {
        path: 'permissions',
        component: adminInstitutionsPermissionsView,
        meta: { requiresAuth: true, view : 'admin-institution-permissions' },
    },
    {
        path: 'settings',
        component: adminInstitutionsSettingsView,
        meta: { requiresAuth: true, view : 'admin-institution-settings' },
    },
]
