import axios from 'axios'

export default {

    async addTag({ title }) {
        return await axios.post('/aux/tags', {
            title : title
        })
    },

    /**
     * @desc Add new subject
     * @param {Object} data - Subject data
     * @param {number} data.adaptive_class_id
     * @param {string} data.image_url
     * @param {number} data.language_id
     * @param {string} data.title
     * @return {Promise} response
     */
    async addSubject(data) {
        const {
            adaptive_class_id,
            image_url,
            language_id,
            title,
        } = data

        return await axios.post('/aux/subjects', {
            adaptive_class_id,
            image_url,
            language_id,
            title,
        })
    },

    /**
     * @desc Add new topic
     * @param {Object} data - Topic data
     * @param {number} data.adaptive_subject_id
     * @param {number} data.language_id
     * @param {string} data.title
     * @return {Promise} response
     */
    async addTopic(data) {
        const { adaptive_subject_id, language_id, title } = data

        return await axios.post('/aux/topics', {
            adaptive_subject_id,
            language_id,
            title,
        })
    },

    async fetchGradeScales() {
        return await axios.get('/aux/grade-scales')
    },

    /**
     * @desc Get all classes
     * @return {Promise} response
     */
    async fetchAllClasses() {
        return await axios.get('/aux/classes')
    },

    async fetchAllLanguages() {
        return await axios.get('/aux/languages')
    },

    async fetchAllLevels() {
        return await axios.get('/aux/levels')
    },

    /**
     * @desc Get all subjects
     * @return {Promise} response
     */
    async fetchAllSubjects() {
        return await axios.get('/aux/subjects')
    },

    /**
     * @desc Get all topics
     * @return {Promise} response
     */
    async fetchAllTopics() {
        return await axios.get('/aux/topics')
    },

    async getTags(search_query) {
        return await axios.get(`/aux/tags/${search_query}`)
    },

    /**
     * @desc Remove subject
     * @param {Object} data - Subject data
     * @param {number} data.adaptive_subject_id
     * @return {Promise} response
     */
    async removeSubject(data) {
        const { adaptive_subject_id } = data

        return await axios.delete(`/aux/subjects/${adaptive_subject_id}`)
    },

    /**
     * @desc Remove topic
     * @param {Object} data - Topic data
     * @param {number} data.adaptive_topic_id
     * @return {Promise} response
     */
    async removeTopic(data) {
        const { adaptive_topic_id } = data

        return await axios.delete(`/aux/topics/${adaptive_topic_id}`)
    },

    /**
     * @desc Update subject
     * @param {Object} data - Subject data
     * @param {number} data.adaptive_class_id
     * @param {number} data.adaptive_subject_id
     * @param {string} data.image_url
     * @param {number} data.language_id
     * @param {string} data.title
     * @return {Promise} response
     */
    async updateSubject(data) {
        const {
            adaptive_class_id,
            adaptive_subject_id,
            image_url,
            language_id,
            title
        } = data

        return await axios.put(`/aux/subjects/${adaptive_subject_id}`, {
            adaptive_class_id,
            image_url,
            language_id,
            title,
        })
    },

    /**
     * @desc Update topic
     * @param {Object} data - Topic data
     * @param {number} data.adaptive_subject_id
     * @param {number} data.adaptive_topic_id
     * @param {number} data.language_id
     * @param {string} data.title
     * @return {Promise} response
     */
    async updateTopic(data) {
        const {
            adaptive_subject_id,
            adaptive_topic_id,
            language_id,
            title
        } = data

        return await axios.put(`/aux/topics/${adaptive_topic_id}`, {
            adaptive_subject_id,
            language_id,
            title,
        })
    },

    async validateConfirmToken(token) {
        return await axios.get(`/auth/user/confirm-token/validate/${token}`)
    },

    async validateSignupToken(token) {
        return await axios.get(`/auth/signup/confirm-token/validate/${token}`)
    },


}
