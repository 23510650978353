import Vue from 'vue'
import mediaAPI from '@/api/mediaAPI'

export const mediaStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        media_statistics : {},
        vimeo_video_statistics: {},
        youtube_video_statistics: {},
        course_videos: {}
    },


    getters: {

    },


    actions : {

        async createNewVimeo({ commit, state },  data) {
            data['type'] = 'vimeo'
            data['mimetype'] = 'vimeo'
            return await mediaAPI.storeNewFile(data)
        },


        async createNewYoutube({ commit, state },  data) {
            data['type'] = 'youtube'
            data['mimetype'] = 'youtube'
            return await mediaAPI.storeNewFile(data)
        },

        async getCourseVideos({ commit, state },  { group_id }) {

            if (state.course_videos[group_id] && state.course_videos[group_id].length) {
                return state.course_videos[group_id]
            } else {
                const response = await mediaAPI.getCourseVideos(group_id)
                commit('SET_COURSE_VIDEOS', {
                    group_id : group_id,
                    videos : response.data
                })
                return response
            }
        },

        async loadVideoAudioProgression({ commit, state }, { media_id }) {

            if (state.media_statistics[media_id]) {
                return state.media_statistics[media_id]
            } else {

                const response = await mediaAPI.loadVideoAudioProgression({
                    media_id : media_id
                })

                commit('SET_MEDIA_STATISTICS', {
                    media_id : media_id,
                    statistics : response.data
                })

                return state.media_statistics[media_id]
            }

        },

        async loadVimeoVideoProgression({ commit, state }, { video_id }) {

            if (state.vimeo_video_statistics[video_id]) {
                return state.vimeo_video_statistics[video_id]
            } else {

                const response = await mediaAPI.loadVimeoVideoProgression({
                    video_id : video_id
                })

                commit('SET_VIMEO_VIDEO_STATISTICS', {
                    video_id : video_id,
                    statistics : response.data
                })

                return state.vimeo_video_statistics[video_id]
            }

        },

        async loadYoutubeVideoProgression({ commit, state }, { video_id }) {

            if (state.youtube_video_statistics[video_id]) {
                return state.youtube_video_statistics[video_id]
            } else {

                const response = await mediaAPI.loadYoutubeVideoProgression({ video_id : video_id })

                commit('SET_YOUTUBE_VIDEO_STATISTICS', {
                    video_id : video_id,
                    statistics : response.data
                })

                return state.youtube_video_statistics[video_id]
            }

        },

        async saveMedia({ commit, state }, { media_id , data }) {
            return await mediaAPI.saveMedia({
                media_id : media_id,
                data : data
            })
        },

        /**
         * Save media progression
         */
        async saveVideoAudioProgression({ commit, state }, { media_id, latest_watch_time, duration, max_watch_time, total_watch_time }) {

            const response = await mediaAPI.saveVideoAudioProgression({
                media_id,
                latest_watch_time,
                duration,
                max_watch_time,
                total_watch_time
            })

            commit('SET_MEDIA_STATISTICS', {
                media_id : media_id,
                statistics : response.data
            })

            return response
        },

        /**
         * Save vimeo progression
         */
        async saveVimeoVideoProgression({ commit, state }, { video_id, latest_watch_time, duration, max_watch_time, total_watch_time }) {

            const response = await mediaAPI.saveVimeoVideoProgression({
                video_id : video_id,
                latest_watch_time : latest_watch_time,
                duration : duration,
                max_watch_time : max_watch_time,
                total_watch_time : total_watch_time
            })

            commit('SET_VIMEO_VIDEO_STATISTICS', {
                video_id : video_id,
                statistics : response.data
            })

            return response
        },

        /**
         * Save youtube progression
         */
        async saveYoutubeVideoProgression({ commit, state }, { video_id, latest_watch_time, duration, max_watch_time, total_watch_time }) {

            const response = await mediaAPI.saveYoutubeVideoProgression({
                video_id,
                latest_watch_time,
                duration,
                max_watch_time,
                total_watch_time
            })

            commit('SET_YOUTUBE_VIDEO_STATISTICS', {
                video_id : video_id,
                statistics : response.data
            })

            return response
        },

        async updateCourseVideoProgression({ commit, state }, { group_id, media_id, latest_watch_time, duration, max_watch_time, total_watch_time } ) {
            commit('UPDATE_COURSE_VIDEO_PROGRESSION', {
                group_id,
                media_id,
                latest_watch_time,
                duration,
                max_watch_time,
                total_watch_time
            })
            return 1
        }


    },


    mutations: {


        SET_COURSE_VIDEOS: (state, { group_id, videos }) => {
            Vue.set(state.course_videos, group_id, videos)
        },

        SET_MEDIA_STATISTICS: (state, { media_id, statistics }) => {
            Vue.set(state.media_statistics, media_id, statistics)
        },

        SET_VIMEO_VIDEO_STATISTICS: (state, { video_id, statistics }) => {
            Vue.set(state.vimeo_video_statistics, video_id, statistics)
        },

        SET_YOUTUBE_VIDEO_STATISTICS: (state, { video_id, statistics }) => {
            Vue.set(state.youtube_video_statistics, video_id, statistics)
        },

        UPDATE_COURSE_VIDEO_PROGRESSION: (state, { group_id, media_id, latest_watch_time, duration, max_watch_time, total_watch_time }) => {
            if (!state.course_videos || !state.course_videos[group_id] || !state.course_videos[group_id].length) {
                return 0
            }
            state.course_videos[group_id].map( (video, index) => {
                if (parseInt(video.media_id) === parseInt(media_id)) {
                    Vue.set(state.course_videos[group_id][index], 'duration', duration)
                    Vue.set(state.course_videos[group_id][index], 'latest_watch_time', latest_watch_time)
                    Vue.set(state.course_videos[group_id][index], 'max_watch_time', max_watch_time)
                    Vue.set(state.course_videos[group_id][index], 'total_watch_time', total_watch_time)
                }
            })
        },

    }
}
