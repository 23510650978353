/*
    VIEWS
 */
const assignmentPlansWrapperView = () => import('@/views/studentadministration/assignmentPlansWrapperView')
const studentAdministrationExamView = () => import('@/views/studentadministration/studentAdministrationExamView')
const studentAdministrationGroups = () => import('@/views/studentadministration/studentAdministrationGroups')
const studentAdministrationMoodle = () => import('@/views/studentadministration/studentAdministrationMoodle')
const studentAdministrationPermissions = () => import('@/views/studentadministration/studentAdministrationPermissions')
const studentAdministrationSettings = () => import('@/views/studentadministration/studentAdministrationSettings')
const studentAdministrationStatistics = () => import('@/views/studentadministration/studentAdministrationStatistics')
const studentAdministrationUMS = () => import('@/views/studentadministration/studentAdministrationUMS')
const studentAdministrationUsers = () => import('@/views/studentadministration/studentAdministrationUsers')
const studentAdministrationCounsellors = () => import('@/views/studentadministration/studentAdministrationCounsellors')

export default [
    {
        path: 'assignment-plans',
        component: assignmentPlansWrapperView,
        meta: { requiresAuth: true, view : 'student-administration-assignment-plans' },
    },
    {
        path: 'exam',
        component: studentAdministrationExamView,
        meta: { requiresAuth: true, view : 'student-administration-exam' },
    },
    {
        path: 'groups',
        component: studentAdministrationGroups,
        meta: { requiresAuth: true, view : 'student-administration-groups' },
    },
    {
        path: 'moodle',
        component: studentAdministrationMoodle,
        meta: { requiresAuth: true, view : 'student-administration-moodle' },
    },
    {
        path: 'permissions',
        component: studentAdministrationPermissions,
        meta: { requiresAuth: true, view : 'student-administration-permissions' },
    },
    {
        path: 'settings',
        component: studentAdministrationSettings,
        meta: { requiresAuth: true, view : 'student-administration-settings' },
    },
    {
        path: 'statistics',
        component: studentAdministrationStatistics,
        meta: { requiresAuth: true, view : 'student-administration-statistics' },
    },
    {
        path: 'UMS',
        component: studentAdministrationUMS,
        meta: { requiresAuth: true, view : 'student-administration-UMS' },
    },
    {
        path: 'users',
        component: studentAdministrationUsers,
        meta: { requiresAuth: true, view : 'student-administration-users' },
    },
    {
        path: 'counsellors',
        component: studentAdministrationCounsellors,
        meta: {
            requiresAuth: true,
            view : 'student-administration-counsellors',
            requiresInstitutionSetting: {
                name: 'allow-counsellors',
                value: '1',
                pathIfFail: '/403'
            }
        },
    },
]
