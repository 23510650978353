import Vue from 'vue'
import messagesAPI from '@/api/messagesAPI'

export const messagesStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        conversations: [],
        conversations_synced : 0,
        conversation_messages : {}
    },


    getters: {

    },


  actions: {
      
        async getConversationAttachments({ commit, state }, { conversation_id }) {
            if(!conversation_id) return 0
            const response = await messagesAPI.getConversationAttachments({ conversation_id })
            if (response && response.data) {
              commit('SET_CONVERSATION_ATTACHMENTS', {
                conversation_id,
                attachments: response.data
              })
            }
        },

        async archiveConversation({ commit, state }, { conversation_id }) {

            const response = await messagesAPI.archiveConversation({
                conversation_id : conversation_id
            })

            commit('REMOVE_CONVERSATION', {
                conversation_id : conversation_id
            })

            return response
        },


        /**
         * Function for creating a new conversation
         */
        async createNewConversation({ commit, state }, { title, groups, members }) {

            const response = await messagesAPI.createNewConversation({
                title : title,
                groups : groups,
                members : members
            })

            commit('ADD_NEW_CONVERSATION', {
                conversation : response.data
            })

            return response
        },

        /**
         * Function for updating conversation
         */
        async updateConversation({ commit, state }, { id, title, groups, members, talk_room_id }) {
            const data = {
                  id: id,
                  title : title,
                  groups : groups,
                  members: members,
            }
            if(talk_room_id) data.talk_room_id = talk_room_id
            const response = await messagesAPI.updateConversation(data)

            commit('REPLACE_CONVERSATION', {
                conversation : response.data
            })

            return response
        },

        /**
         * Function for getting all group folders
         */
        async getConversationMessages({ commit, state }, { conversation_id }) {

            // if (state.conversation_messages[conversation_id] && state.conversation_messages[conversation_id].length) {
            //     return state.conversation_messages[conversation_id]
            // } else {
            const response = await messagesAPI.getConversationMessages({
                conversation_id : conversation_id
            })
            commit('SET_CONVERSATION_MESSAGES', {
                conversation_id : conversation_id,
                messages : response.data
            })
            return response
            // }
        },

        /**
         * Function for getting all conversations
         */
        async getConversations({ commit, state }) {

            // if (state.conversations_synced) {
            //     return state.conversations
            // } else {
            const response = await messagesAPI.getConversations()
            commit('SET_CONVERSATIONS', {
                conversations : response.data
            })
            return response
            // }
        },

        /**
         * Function for getting all group folders
         */
        async markConversationSeen({ commit, state }, { conversation_id }) {

            const response = await messagesAPI.markConversationRead({
                conversation_id : conversation_id
            })

            commit('SET_CONVERSATION_SEEN', {
                conversation_id : conversation_id
            })

            return response
        },

        async markConversationUnseen({ commit, state }, { conversation_id }) {

            const response = await messagesAPI.markConversationUnread({
                conversation_id : conversation_id
            })

            commit('SET_CONVERSATION_UNSEEN', {
                conversation_id : conversation_id
            })

            return response
        },

        async registerNewMessage({ commit, state }, { conversation_id, message_data, conversation_data }) {

            if (state.conversation_messages[conversation_id] && state.conversation_messages[conversation_id].length) {
                commit('SET_CONVERSATION_UNSEEN', { conversation_id : conversation_id })
                commit('ADD_NEW_MESSAGE', { conversation_id : conversation_id, message_data : message_data })
            } else {
                commit('ADD_NEW_CONVERSATION', { conversation : conversation_data })
            }
            return 1
        },

        async sendNewMessage({ commit, dispatch, state }, { conversation_id, message_data, fetch_conversation_attachments = false, update_conversations = false }) {

            const response = await messagesAPI.sendNewMessage({
                conversation_id : conversation_id,
                message_data : message_data
            })

            const new_message_data = response.data
            commit('ADD_NEW_MESSAGE', {
                conversation_id : conversation_id,
                message_data : new_message_data
            })
            commit('UPDATE_CONVERSATION_LAST_MESSAGE', { message: new_message_data })

            commit('PURGE_SEEN_BY_FOR_CONVERSATION', {
                conversation_id : conversation_id
            })
          
            if (fetch_conversation_attachments && message_data.attachments && message_data.attachments.length) {
                dispatch('getConversationAttachments',{conversation_id})
            }
            return new_message_data

        },

        async removeMessage({commit, dispatch, state}, {conversation_id, message_id, delete_completely = true, fetch_conversation_attachments=false}) {
            const response = await messagesAPI.removeMessage({
                conversation_id,
                message_id
            })
          const messages = state.conversation_messages[conversation_id]
          if (messages) {
            const message = messages.find(item => parseInt(item.message_id) === parseInt(message_id))
            if (message && message.attachments && message.attachments.length) {
                if (fetch_conversation_attachments) {
                    dispatch('getConversationAttachments',{conversation_id})
                }
            }
          }
          commit('REMOVE_MESSAGE', {
            conversation_id,
            message_id,
            delete_completely,
            message: response.data
          })
        },

        async removeConversationMember({ commit, state }, { conversation_id, user_id }) {
            const response = await messagesAPI.removeConversationMember({
                conversation_id : conversation_id,
                user_id : user_id
            })

            commit('REMOVE_CONVERSATION_MEMBER', {
                conversation_id : conversation_id,
                user_id : user_id,   
            })

            return response
        },


        // Update message
        async updateMessage({ commit, state }, { conversation_id, message_id, message_data, fetch_conversation_attachments=false }) {
            const response = await messagesAPI.updateMessage({
                conversation_id,
                message_id,
                message_data
            })

            const updated_message = response.data

            commit('UPDATE_MESSAGE', {
                conversation_id,
                message_id,
                updated_message,
            })
          if (fetch_conversation_attachments && message_data.attachments && message_data.attachments.length) {
                dispatch('getConversationAttachments',{conversation_id})
            }

            return updated_message
        },


    },


    mutations: {
        UPDATE_CONVERSATION_LAST_MESSAGE: (state, { message }) => {
            if(!message) return 0
            const index = state.conversations.findIndex(conversation => conversation.conversation_id === message.conversation_id)
            if (index !== -1) {
                Vue.set(state.conversations, index, {...state.conversations[index], ...{latest_message: message}})
            }
        },
        ADD_NEW_CONVERSATION: (state, { conversation }) => {

            Vue.set(state.conversation_messages, conversation.conversation_id, [])
            state.conversations.push(conversation)

        },
        SET_CONVERSATION_ATTACHMENTS: (state, { conversation_id, attachments }) => { 
          const index = state.conversations.findIndex(conversation => parseInt(conversation.conversation_id) === parseInt(conversation_id))
          if (index !== -1) {
            const conversation = state.conversations[index]
            conversation.attachments = attachments
            Vue.set(state.conversations, index, conversation)
          }
        },

        REPLACE_CONVERSATION: (state, { conversation }) => {

            let index = null
            for (let i = 0; i < state.conversations.length; i++) {
                if (conversation.id === state.conversations[i].id) {
                    index = i
                    break
                }
            }

            if (index !== null) {
                state.conversations.splice(index, 1)
            }

            state.conversations.push(conversation)

        },

        ADD_NEW_MESSAGE: (state, { conversation_id, message_data }) => {
            if (!state.conversation_messages[conversation_id] && !state.conversation_messages[conversation_id].length) {
                Vue.set(state.conversation_messages, conversation_id, [])
            }

            state.conversation_messages[conversation_id].push(message_data)

        },

        PURGE_SEEN_BY_FOR_CONVERSATION: (state, { conversation_id }) => {
            if (state.conversations && state.conversations.length) {
                state.conversations.map( (conversation, index) => {
                    if (parseInt(conversation.conversation_id) === parseInt(conversation_id)) {
                        if (conversation.members && conversation.members.length) {
                            conversation.members.map( (member, index) => {
                                Vue.set(conversation.members[index], 'conversation_read', 0)
                            })
                        }
                    }
                })
            }
        },

        REMOVE_CONVERSATION: (state, { conversation_id }) => {
            if (state.conversations && state.conversations.length) {
                state.conversations.map( (conversation, index) => {
                    if (parseInt(conversation.conversation_id) === parseInt(conversation_id)) {
                        Vue.delete(state.conversations, index)
                    }
                })
            }
        },

        REMOVE_CONVERSATION_MEMBER: (state, {conversation_id, user_id}) => {
            if (state.conversations && state.conversations.length) {
                state.conversations.map( (conversation, index) => {
                    if (parseInt(conversation.conversation_id) === parseInt(conversation_id)) {
                        if (conversation.members && conversation.members.length) {
                            conversation.members.map( (member, index) => {
                                if (parseInt(member.user_id) === parseInt(user_id)) {
                                    Vue.delete(conversation.members, index)
                                }
                            })       
                        }
                    }
                })
            }
        },

        SET_CONVERSATION_MESSAGES: (state, { conversation_id, messages }) => {
            Vue.set(state.conversation_messages, conversation_id, messages)
        },

        SET_CONVERSATIONS: (state, { conversations }) => {
            Vue.set(state, 'conversations', conversations)
            Vue.set(state, 'conversations_synced', 1)
        },

        SET_CONVERSATION_SEEN: (state, { conversation_id }) => {
            for (let conversation of state.conversations) {
                if (parseInt(conversation.conversation_id) === parseInt(conversation_id)) {
                    let index = state.conversations.indexOf(conversation)
                    let time = new Date().getTime()
                    Vue.set(state.conversations[index], 'conversation_read', time)
                    break
                }
            }

        },
        SET_CONVERSATION_UNSEEN: (state, { conversation_id }) => {
            for (let conversation of state.conversations) {
                if (parseInt(conversation.conversation_id) === parseInt(conversation_id)) {
                    let index = state.conversations.indexOf(conversation)
                    Vue.set(state.conversations[index], 'conversation_read', 0)
                    break
                }
            }

        },

        // Update message
        UPDATE_MESSAGE: (state, { conversation_id, message_id, updated_message}) => {
          // Find message index
          const index = state.conversation_messages[conversation_id].findIndex(message => (
            message.message_id === message_id
          ))

          // Set updated message
          Vue.set(state.conversation_messages[conversation_id], index, updated_message)
        },

        REMOVE_MESSAGE: (state, { conversation_id, message_id, delete_completely, message}) => {
            conversation_id = +conversation_id
            message_id = +message_id
            // Find message index
            const index = state.conversation_messages[conversation_id].findIndex(message => (
                +message.message_id === message_id
            ))

          if (index !== -1) {
                if (delete_completely) {
                  Vue.delete(state.conversation_messages[conversation_id], index)
                } else {
                  Vue.set(state.conversation_messages[conversation_id], index, message)
                }
                
            }
        },
        MARK_MESSAGE_AS_DELETED: (state, { message_id, conversation_id }) => {
            const index = state.conversation_messages[conversation_id].findIndex(message => (
                parseInt(message.message_id) === parseInt(message_id)
            ))
            if (index !== -1) {
              const message = state.conversation_messages[conversation_id][index]
              Vue.set(state.conversation_messages[conversation_id], index, { ...message, ...{ deleted: '1', message_text: '', attachments: [], reply_to_message_id: '0' } })
            }
        }

    }
}
