import axios from 'axios'

export default {
    getWebinarsInGroup(groupId) {
        return axios.get(`/webinar?group_id=${groupId}`)
    },
    getWebinar(webinarId) {
        return axios.get(`/webinar/${webinarId}`)
    },
    createWebinar(data) {
        return axios.post('/webinar', data)
    },
    startWebinarBroadcast(webinarId) {
        return axios.post(`/webinar/${webinarId}/start-broadcast`)
    },
    stopWebinarBroadcast(webinarId) {
        return axios.post(`/webinar/${webinarId}/stop-broadcast`)
    },
    joinWebinarBroadcast(webinarId) {
        return axios.post(`/webinar/${webinarId}/join-broadcast`)
    }
}
