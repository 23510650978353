import axios from "axios";

const talkApiBaseUrl = process.env.TALK_API_BASE_URL;

export default {
    async getProvider(id) {
        return (await axios.get(`${talkApiBaseUrl}/providers/${id}`)).data;
    },
    async getRooms(filters) {
        // eslint-disable-next-line no-underscore-dangle
        const _filters = filters || {};

        let queryString = "";
        Object.keys(_filters).forEach(filter => {
            queryString += `filter[${filter}]=${encodeURIComponent(
                _filters[filter]
            )}`;
        });

        const url =
            queryString !== ""
                ? `${talkApiBaseUrl}/rooms?${queryString}`
                : `${talkApiBaseUrl}/rooms`;

        return (await axios.get(url)).data;
    },
    async getRoom(id) {
        return (await axios.get(`${talkApiBaseUrl}/rooms/${id}`)).data;
    },
    async roomsJoin(id) {
        return (await axios.post(`${talkApiBaseUrl}/rooms/join`, {
            data: {
                type: "room-join-request",
                attributes: {
                    room: id,
                    toLobby: true
                }
            }
        })).data;
    },
    async updateRoom(id, attributes) {
        return (await axios.patch(`${talkApiBaseUrl}/rooms/${id}`, {
            data: {
                type: "room",
                attributes
            }
        })).data;
    },
    deleteRoom(id) {
        return axios.delete(`${talkApiBaseUrl}/rooms/${id}`);
    },
    async createRoom(attributes) {
        return (await axios.post(`${talkApiBaseUrl}/rooms`, {
            data: {
                type: "room",
                attributes
            }
        })).data;
    },
    async joinRoom(id, user_name, audio, video, user_id) {
        return (await axios.post(`${talkApiBaseUrl}/rooms/join`, {
            data: {
                type: "room-join-request",
                attributes: {
                    room: id,
                    ...(user_name || audio || video
                        ? {
                              providerData: {
                                  ...(user_name
                                      ? { user_name: user_name }
                                      : {}),
                                  ...(audio !== null
                                      ? { start_audio_off: audio }
                                      : {}),
                                  ...(video !== null
                                      ? { start_video_off: video }
                                      : {}),
                                  user_id
                              }
                          }
                        : {})
                }
            }
        })).data;
    }
};
