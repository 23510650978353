import Vue from 'vue'
import flashcardsAPI from '@/api/flashcardsAPI'


export const flashcardsStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        collections: {},
        collections_synced : 0,
        flashcards: {}
    },


    getters: {},


    actions: {


        clearFlashcardsStore({commit, state}, {group_id}) {
            commit('CLEAR_STORE', {
                group_id: group_id
            })
            return 1
        },

        async addToFlashCardsStats({commit, state}, { flashcard_id }) {

            flashcardsAPI.addToFlashCardsStats({
                flashcard_id : flashcard_id
            })

            return 1

        },

        async createCollection({commit, state}, {group_id, collection_data, allow_students_add}) {

            const response = await flashcardsAPI.createCollection({
                data: collection_data
            })

            commit('ADD_COLLECTION', {
                group_id: group_id,
                data: response.data
            })

            // always reset flashcards
            commit('RESET_FLASHCARDS')

            return response.data

        },

        async createFlashcardStudent({ commit, state }, data) {
            const response = await flashcardsAPI.createFlashCardsStudent({
                data: data
            })

            const { collection_id } = {...data}
            commit('ADD_FLASHCARDS', {
                collection_id: collection_id,
                data: response.data
            })

            return response.data
        },

        async editCollection({commit, state}, {collection_id, group_id, collection_data}) {

            const response = await flashcardsAPI.editCollection({
                collection_id: collection_id,
                data: collection_data
            })

            commit('EDIT_COLLECTION', {
                collection_id: collection_id,
                group_id: group_id,
                data: response.data
            })

            // always reset flashcards
            commit('RESET_FLASHCARDS')

            return response.data

        },

        /**
         * Function for getting all collections in a group
         */
        async fetchAllGroupFlashcardCollections({commit, state}, {group_id}) {

            if (state.collections_synced) {
                return state.collections[group_id]
            }

            const response = await flashcardsAPI.fetchAllGroupFlashcardCollections({
                group_id: group_id
            })

            commit('SET_COLLECTIONS', {
                group_id: group_id,
                collections: response.data
            })

            return response.data

        },

        /**
         * Function for saving the group apps and sort order
         */
        async fetchCollectionFlashCards({commit, state}, {collection_id}) {

            if (state.flashcards[collection_id] && state.flashcards[collection_id].length) {
                return state.flashcards[collection_id]
            }

            const response = await flashcardsAPI.fetchCollectionFlashCards({
                collection_id: collection_id
            })

            commit('SET_COLLECTION_FLASHCARDS', {
                collection_id: collection_id,
                flashcards: response.data
            })

            return response.data

        },


        async removeCollection({commit, state}, { group_id, collection_id }) {

            await flashcardsAPI.removeCollection({
                collection_id : collection_id
            })

            commit('REMOVE_COLLECTION', {
                group_id: group_id,
                collection_id : collection_id
            })

            return 1

        },

    },


    mutations: {

        ADD_COLLECTION: (state, {group_id, data}) => {

            if (!state.collections[group_id]) {
                Vue.set(state.collections, group_id, [])
            }

            state.collections[group_id].push(data)

        },

        CLEAR_STORE: (state, {group_id}) => {
            Vue.set(state.collections, group_id, [])
            Vue.set(state, 'flashcards', {})
        },

        EDIT_COLLECTION: (state, { collection_id, group_id, data }) => {

            if (!state.collections[group_id]) {
                Vue.set(state.collections, group_id, [])
            }

            if (state.collections[group_id]) {
                state.collections[group_id].map((collection, index) => {
                    if (parseInt(collection.collection_id) === collection_id) {
                        Vue.set(state.collections[group_id], index, data)
                    }
                })
            }

        },

        RESET_FLASHCARDS: (state) => {
            Vue.set(state, 'flashcards', {})
        },

        REMOVE_COLLECTION: (state, { collection_id, group_id }) => {

            if (state.collections[group_id]) {
                state.collections[group_id].map( (collection, index) => {
                    if (parseInt(collection.collection_id) === collection_id) {
                        Vue.delete(state.collections[group_id], index)
                    }
                })
            }

            Vue.delete(state.flashcards, collection_id)

        },

        SET_COLLECTION_FLASHCARDS: (state, {collection_id, flashcards}) => {
            Vue.set(state.flashcards, collection_id, flashcards)
        },

        SET_COLLECTIONS: (state, {group_id, collections}) => {
            Vue.set(state.collections, group_id, collections)
            Vue.set(state, 'collections_synced', 1)
        },

        ADD_FLASHCARDS: (state, { collection_id, data }) => {
            state.flashcards[collection_id].push(data)
        }

    }
}
