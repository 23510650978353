import axios from 'axios'

export default {

    async createQuestion(question_data) {
        return await axios.post('/questions', question_data)
    },

    async createQuiz(quiz_data) {
        return await axios.post('/quizzes', quiz_data)
    },

    async fetchAllGroupQuizzes(group_id) {
        return await axios.get(`/quizzes/group/${group_id}`)
    },

    async getAllGroupQuestions(group_id, options) {
        const page = options.page;
        const per_page = options.per_page;
        const query = options.query;

        let url = `/questions/group/${group_id}?`

        if (query) {
            url += `query=${encodeURIComponent(query)}&`
        }

        if (page && per_page) {
            url += `page=${page}&per_page=${per_page}&`
        }

        if (url.indexOf('?') === url.length - 1) {
            url = url.substr(0, url.length - 1)
        }

        if (url.lastIndexOf('&') === url.length - 1) {
            url = url.substr(0, url.length - 1)
        }

        return await axios.get(url)
    },

    async getNextAdaptiveQuestion({ quiz_id, difficulty}) {
        return await axios.get(`/quizzes/adaptive/next/${quiz_id}/${difficulty}`)
    },

    async fetchQuestion(question_id) {
        return await axios.get(`/questions/${question_id}`)
    },

    async fetchQuestionForTest(question_id) {
        return await axios.get(`/questions/test/${question_id}`)
    },

    async fetchQuestionStatisticsData({ question_id }) {
        return await axios.get(`/questions/statistics/${question_id}`)
    },

    async fetchQuiz(data) {
        const { editMode = false, quizId } = data
        return await axios.get(`/quiz/${quizId}?edit_mode=${editMode}`)
    },

    async fetchQuizData(quiz_id) {
        return await axios.get(`/quiz/${quiz_id}`)
    },

    async fetchQuizDataForAdaptiveTest({ quiz_id }) {
        return await axios.get(`/quiz/test/adaptive/${quiz_id}`)
    },

    async fetchQuizDataForTest(quiz_id) {
        return await axios.get(`/quiz/test/${quiz_id}`)
    },

    async fetchQuizQuestions({ quiz_id }) {
        return await axios.get(`/quizzes/questions/${quiz_id}`)
    },

    async fetchQuizStatisticsData({ quiz_id }) {
        return await axios.get(`/quizzes/statistics/${quiz_id}`)
    },

    async getAdaptiveTestData({ quiz_id }) {
        return await axios.get(`/adaptive-test/data/${quiz_id}`)
    },

    /**
     * @desc Register a quiz has started
     * @param {Object} data - Quiz data
     * @param {number} data.quiz_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async registerQuizStarted(data, isMockStudent = false) {
      const { quiz_id } = data

      if (!isMockStudent) {
        return await axios.post('/quizzes/start', {
          quiz_id
        })
      }

      return {
        data: {
          quiz_started: Date.now() / 1000,
        },
      }
    },

    /**
     * @desc Register a quiz has ended
     * @param {Object} data - Quiz data
     * @param {number} data.quiz_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async registerQuizEnded(data, isMockStudent = false) {
      const { quiz_id } = data

      if (!isMockStudent) {
        return await axios.post('/quizzes/end', {
          quiz_id,
        })
      }

      return {
        data: {
          quiz_ended: Date.now() / 1000,
        },
      }
    },

    async removeQuestion(question_id) {
        return await axios.delete(`/question/${question_id}`)
    },

    async removeQuiz(quiz_id) {
        return await axios.delete(`/quizzes/${quiz_id}`)
    },

    async repeatQuizForUser(data) {
        return await axios.post('/quizzes/repeat', data)
    },

    async saveAndValidateAnswer(question_data) {
        return await axios.post('/questions/evaluate', question_data)
    },

    async saveQuestion(question_id, question_data) {
        return await axios.put(`/questions/${question_id}`, question_data)
    },

    async saveQuiz(quiz_id, quiz_data) {
        return await axios.put(`/quizzes/${quiz_id}`, quiz_data)
    },

    async searchQuestions(data) {
        const {
            exclude_question_ids = [],
            group_id = 0,
            limit = 0,
            page = 0,
            query = ''
        } = data

        const baseUrl = '/questions/search'
        const params = [
            `q=${encodeURIComponent(query)}`,
            `group_id=${group_id}`,
            `limit=${limit}`,
            `page=${page}`,
            `exclude_question_ids=${
                encodeURIComponent(
                    JSON.stringify(exclude_question_ids)
                )
            }`
        ]

        return await axios.get(`${baseUrl}?${params.join('&')}`)
    },
}
