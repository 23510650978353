import axios from 'axios'

export default {
    async getLearnUToken() {
        return await axios.post(`${process.env.AUTH_SERVICE_URL}/token`, {
            
            data: {
                type: "token",
                attributes: {
                    learnuToken: localStorage.getItem('id_token'),
                    shortLived: true
                }
            }

        })
    },
    async getProducts() {
        return await axios.get(`${process.env.PRODUCT_SERVICE_URL}/products?filter[app]=learnu&filter[paymentProcessor]=stripe-learnu`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
            }
        })
    },

    async getStripeKey() {
        return await axios.get(`${process.env.PRODUCT_SERVICE_URL}/payment-processors/stripe-learnu`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
            }
        })
    },

    async savePaymentMethod(id) {
        if(!id) return 0
        return await axios.post(`${process.env.PRODUCT_SERVICE_URL}/payment-methods`,
            {
                data: {
                    type: "payment-method",
                    attributes: {
                        app: "learnu",
                        paymentProcessor: "stripe-learnu",
                        dataForPaymentProcessor: {
                            stripePaymentMethodId: id,
                            createStripeSetupIntent: true
                        }
                    }
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                },
                
            }
        )
    },

    async createSubscription({ product, price, paymentMethod }) {
        if(!product || !price || !paymentMethod) return 0
        return await axios.post(`${process.env.PRODUCT_SERVICE_URL}/subscriptions`,
            {
                data: {
                    type: "subscription",
                    attributes: {
                        paymentProcessor: "stripe-learnu",
                        product,
                        price,
                        paymentMethod
                    }
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                }
            }
        )
    },

    async getActiveSubscriptions() {
        return await axios.get(`${process.env.PRODUCT_SERVICE_URL}/subscriptions?filter[status]=active&filter[app]=learnu`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
            }
        })
    },

    async getActivePaymentMethods() {
        return await axios.get(`${process.env.PRODUCT_SERVICE_URL}/payment-methods?filter[status]=active`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                },
            }
        )
    },

    async unsubscribe(subscribsionId) {
        if(!subscribsionId) return
        return await axios.post(`${process.env.PRODUCT_SERVICE_URL}/subscriptions/cancel`,
            {
                "data":
                {
                    "type": "subscription-cancel-request",
                    "attributes":
                    {
                        "subscription": subscribsionId
                    }
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                }
            }
        )
    },

    async cancelSubscriptionAnswer(message) {
        if (!message) return ''
        return await axios.post(`/subscription-cancel-poll`,
            {
                message
            }
        )
    },

    async migrateToSubscription({ subscription, product, price, paymentMethod }) {
        if(!subscription || !product || !price || !paymentMethod) return 0
        return await axios.post(`${process.env.PRODUCT_SERVICE_URL}/subscriptions/migrate`,
            {
                'data':
                {
                    type: 'migrate-subscription-request',
                    attributes:
                    {
                        subscription,
                        product,
                        price,
                        paymentMethod
                    }
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                }
            }
        )
    },
    async getBillingHistory() {
        return await axios.get(`${process.env.PRODUCT_SERVICE_URL}/payment-journal/entries?view=learnu&filter[app]=learnu&filter[paymentProcessor]=stripe-learnu`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                }
            }
        )
    },
    async getFutureSubscriptions() {
        return await axios.get(`${process.env.PRODUCT_SERVICE_URL}/future-subscriptions`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                }
            }
        )
    },
    async deactivatePaymentMethod(paymentMethod) {
        if(!paymentMethod) return
        return await axios.post(`${process.env.PRODUCT_SERVICE_URL}/payment-methods/disable`,
            {
                data: {
                    type: "payment-method-disable-request",
                    attributes: {
                        paymentMethod
                    }
                }
            },
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
                }
            }
        )
    },
}