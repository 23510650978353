/*
  LANGUAGE FILE FOR DANISH LANG
 */

export default {
    "€0.60/month for each additional learner over the prepaid amount":
        "€0,60/måned for hver studerende over det forudbetalte beløb",
    "€1.20/month for each additional learner over the prepaid amount":
        "€1,20/måned for hver studerende over det forudbetalte beløb",
    "2GB storage limit": "2GB lagerplads",
    "10GB storage limit": "10GB lagerplads",
    "30GB storage limit": "30GB lagerplads",
    "3-digit code placed on the back side of the card, in the signature area":
        "3-cifret kode placeret på bagsiden af dit kort, ved signaturen.",
    Abandon: "Afbryd",
    "Abandon copying to this group": "Afbryd kopiering til dette hold",
    "Account already linked": "Konto eksisterer allerede i systemet",
    "Accept terms": "Accepter betingelser",
    "Account linked": "Konto linket",
    "Access denied!": "Adgang nægtet!",
    "Account succesfully linked with": "Konto succesfuldt linket med",
    "Account type": "Kontotype",
    Actions: "Muligheder",
    Active: "Aktiv",
    "Active learners": "Aktive studerende",
    "Active learners above the plan in this month":
        "Aktive studerende mere end betalt for denne måned",
    "Active learners in this month": "Aktive studerende i denne måned",
    "Active learners on plan": "Aktive studerende på plan",
    Activity: "Aktivitet",
    Activities: "Aktiviteter",
    "Activities, quizzes & assignments": "Aktiviteter, quizzer og opgaver",
    "Adaptive settings": "Adaptive indstillinger",
    "Adaptive test": "Adaptiv test",
    "Adaptive tests": "Adaptive tests",
    Add: "Tilføj",
    "Additional group": "Sekundært hold",
    "Add a document": "Tilføj et dokument",
    "Add a file": "Tilføj fil",
    "Add a new learner or co-teacher": "Tilføj ny elev eller med-underviser",
    "Add activities": "Tilføj aktiviteter",
    "Add additional group": "Tilføj sekundært hold",
    "Add answer": "Tilføj svar",
    "Add assignment": "Tilføj opgave",
    "Add attachment": "Tilføj fil",
    "Add audio": "Tilføj lyd",
    "Add board": "Tilføj opslagstavle",
    "Add board item": "Tilføj opslag",
    "Add category": "Tilføj Kategori",
    "Add collection": "Tilføj kollektion",
    "Add conversation name": "Tilføj navn på samtale",
    "Add course": "Tilføj forløb",
    "Add dashboard widget": "Tilføj dashboard widget",
    "Add description": "Tilføj beskrivelse",
    "Add discussion topic": "Tilføj debatemne",
    "Add event": "Tilføj event",
    "Add existing activities": "Tilføj eksisterende aktivitet",
    "Add existing material": "Tilføj eksisterende lektion",
    "Add expression": "Tilføj udtryk",
    "Add external link": "Tilføj eksternt link",
    "Add extra item": "Tilføj ekstra element",
    "Add feedback": "Tilføj feedback",
    "Add file": "Tilføj fil",
    "Add files/folders": "Tilføj filer/mapper",
    "Add first learning element to this course":
        "Tilføj din første læringsaktivitet til dette hold",
    "Add flashcard": "Tilføj flashkort",
    "Add folder": "Tilføj mappe",
    "Add geogebra": "Tilføj Geogebra",
    "Add image": "Tilføj billede",
    "Add information": "Tilføj information",
    "Add item": "Tilføj element",
    "Add label": "Tilføj etiket",
    "Add labels to the image": "Tilføj etiketter til billedet",
    "Add learning activities": "Tilføj læringsaktiviteter",
    "Add learning activity": "Tilføj læringsaktivitet",
    "Add lesson": "Tilføj lektion",
    "Add lessons to your course": "Tilføj lektioner til dit forløb",
    "Add image label": "Tilføj billedetikette",
    "Add match": "Tilføj match",
    "Add match set": "Tilføj match sæt",
    "Add material": "Tilføj lektion",
    "Add member": "Tilføj medlem",
    "Add members": "Tilføj medlemmer",
    "Add members manually to the class below":
        "Tilføj manuelt medlemmer til dette hold nedenfor",
    "Add members to this class": "Tilføj medlemmer til dette hold",
    "Add teachers to your plan": "Tilføj undervisere til dit abonnement",
    "Add participants": "Tilføj medlemmer",
    "Add new course": "Tilføj nyt forløb",
    "Add new dependency": "Tilføj ny forudsætning",
    "Add new flashcard": "Tilføj nyt flashkort",
    "Add new institution": "Tilføj ny institution",
    "Add new item": "Tilføj ny aktivitet",
    "Add new learning element": "Tilføj ny læringsaktivitet",
    "Add new match option": "Tilføj ny match mulighed",
    "Add new material": "Tilføj ny lektion",
    "Add new question": "Tilføj nyt spørgsmål",
    "Add new tag": "Tilføj nyt emne",
    "Add news": "Tilføj nyhed",
    "Add numeric variable": "Tilføj en numerisk variabel",
    "Add only tags that do not exist yet. Make sure you have tried to search for the tag before adding.":
        "Tilføj venligst kun emner som endnu ikke eksisterer i systemet. Sørg for at prøve at søge grundigt før du tilføjer.",
    "Add question": "Tilføj spørgsmål",
    "Add questions": "Tilføj spørgsmål",
    "Add question from questionbank": "Tilføj spørgsmål fra spørgsmålsbanken",
    "Add questionnaire": "Tilføj spørgeskema",
    "Add quiz": "Tilføj quiz",
    "Add one learning element at a time. Save changes in order to continue creating.":
        "Tilføj én læringsaktivitet af gangen. Gem ændringerne for at forsætte med at bygge.",
    "Add submission example": "Tilføj afleveringseksempel",
    "Add text": "Tilføj tekst",
    "Add text label": "Tilføj tekstetikette",
    "Add timeline": "Tilføj tidslinje",
    "Add to existing activity": "Tilføj til eksisterende aktivitet",
    "Add to word list": "Tilføj til ordliste",
    "Add video": "Tilføj video",
    "Add Vimeo video": "Tilføj Vimeo video",
    "Add youtube video": "Tilføj youtube video",
    "Add your first document that will be shared with your class":
        "Tilføj din første fil til fildelingen med holdet",
    Added: "Tilføjet",
    "Add groups": "Tilføj hold",
    "Add new answer possibility": "Tilføj ny svarmulighed",
    "Add new element": "Tilføj ny aktivitet",
    "Add new expression": "Tilføj nyt udtryk",
    "Add new nummeric variable": "Tilføj ny nummerisk variabel",
    "Add title": "Tilføj titel",
    "Add vacation/holiday": "Tilføj ferie/helligdag",
    "Add word": "Tilføj ord",
    "Add words to the word list. Any number of words from the list can be chosen at random.":
        "Tilføj ord til ordlisten. Ethvert ord fra listen kan blive tilfældigt udvalgt.",
    "Adjust your settings and start a call":
        "Tilpas dine indstillinger og start opkaldet",
    Admin: "Underviser",
    Admins: "Undervisere",
    Administration: "Administration",
    "Advanced integrations": "Udvidede integrationer",
    "Advanced settings": "Udvidede indstillinger",
    Afrikaans: "Afrikaans",
    Albanian: "Albansk",
    All: "Alle",
    "All changes were saved": "Alle ændringer blev gemt",
    "All classes, courses and content will be transferred, and nothing will be lost.":
        "Alle hold, forløb og indhold vil blive overført, og intet vil gå tabt.",
    "All communication suspended.": "Al kommunikation er suspenderet.",
    "All correct answers must be chosen": "Alle korrekte svar skal vælges",
    "All correct answers must be selected": "Alle korrekte svar skal vælges",
    "All file types": "Alle filtyper",
    "All topics": "Alle delemner",
    "All variables must have one hashtag in the beginning and lower threshhold must be lower than the upper threshhold":
        "Alle variable skal have et hashtag i begyndelsen og den nedre værdi må ikke være højere end den øvre.",
    "Allow counsellors": "Tillad vejledere",
    "Allow direct user creation in the group":
        "Tillad direkte brugeroprettelse på holdet",
    "Allow groups": "Tillad gruppeaflevering",
    "Allow code join": 'Slå "tilmed medlemmer med kode" til',
    "Allow late submission": "Tillad aflevering efter frist",
    "Allow late turnin": "Tillad aflevering efter frist",
    "Allow questions on material elements":
        "Tillad spørgsmål på læringsaktiviteter",
    "Allow groups code join": "Tillad holdtilmelding med link",
    "All participants from your class can access this call. If you want to invite others, send them a link.":
        "Alle medlemmer af dit hold kan tilgå dette opkald. Hvis du vil have andre ind, så send dem linket til opkaldet.",
    "Allow peers": "Tillad at se andres afleveringer",
    "Allow students to see results": "Tillad studerende at se resultater",
    "Allowed file types": "Tilladte filtyper",
    "Already a user?": "Allerede en bruger?",
    "Already have an account?": "Har du allerede en bruger?",
    Amount: "Beløb",
    "An error occurred": "Det opstod en fejl",
    "An error occurred in the quiz. Please refresh your browser window, and if the error persists, contact the support.":
        "Der opstod en fejl med at hente quizzen. Venligst genindlæs browservinduet, og hvis fejlen fortsætter så kontakt supporten.",
    "Ancient history": "Oldtidskundskab",
    Answer: "Svar",
    "Answer's feedback": "Svarets feedback",
    "Answer date": "Svar dato",
    "Answer {number} more": "Svar på {number} mere",
    "Answer evaluation criteria": "Evalueringskriterie på svar",
    "Answer possibilities": "Svarmuligheder",
    "Answer possibility": "Svarmulighed",
    "Answer the question to continue the video":
        "Svar på spørgsmålet for at fortsætte videoen",
    Answers: "Svar",
    "Answers must have a unique match option assigned":
        "Alle svarmuligheder skal have en unik match-mulighed tilknyttet",
    "Answers not in category": "Svar uden for kategori",
    "Answers will be shown after quiz is completed":
        "Svarene vil blive vist, når quizzen er afsluttet",
    "Appears on": "Optræder på",
    Apps: "Apps",
    Arabic: "Arabisk",
    Archive: "Arkivér",
    "Archive class": "Arkivér hold",
    "Archive class for all members": "Arkivér holdet for alle medlemmer",
    "Archive for all": "Arkivér for alle",
    "Archive for you": "Arkivér for kun dig",
    Archived: "Arkiveret",
    "Archived groups": "Arkiverede hold",
    "Are you a student or a teacher?": "Er du studerende eller en underviser?",
    "Are you sure?": "Er du sikker?",
    "Are you sure you have checked the list thoroughly? Remember you can search it too!":
        "Er du sikker på du har tjekket listen ordentligt igennem? Husk du også kan søge i den!",
    "Are you sure you want to archive this conversation?":
        "Er du sikker på du vil arkivere denne samtale?",
    "Are you sure you want to archive this group?":
        "Er du sikker på du vil arkivere dette hold?",
    "Are you sure you want to archive this group for all members?":
        "Er du sikker på du vil arkivere dette hold for alle medlemmer?",
    "Are you sure you want to delete this?":
        "Er du sikker på du vil slette dette?",
    "Are you sure you want to delete this assignment?":
        "Er du sikker på du vil slette denne opgave?",
    "Are you sure you want to delete this collection?":
        "Er du sikker på du vil slette denne kollektion?",
    "Are you sure you want to delete this feedback?":
        "Er du sikker på du vil slette dette feedback?",
    "Are you sure you want to delete this file?":
        "Er du sikker på du vil slette denne fil?",
    "Are you sure you want to delete this folder?":
        "Er du sikker på du vil slette denne mappe? Dette fjerner også alle undermapper og filer.",
    "Are you sure you want to delete this Geogebra element?":
        "Er du sikker på du vil slette denne Geogebra aktivitet?",
    "Are you sure you want to delete this group?":
        "Er du sikker på du vil slette dette hold?",
    "Are you sure you want to delete this group information?":
        "Er du sikker på du vil fjerne denne information?",
    "Are you sure you want to delete this post?":
        "Er du sikker på du vil slette dette opslag?",
    "Are you sure you want to delete this question?":
        "Er du sikker på du vil slette dette spørgsmål?",
    "Are you sure you want to delete this quiz?":
        "Er du sikker på du vil slette denne quiz?",
    "Are you sure you want to delete this user?":
        "Er du sikker på du vil slette denne bruger?",
    "Are you sure you want to delete this widget?":
        "Er du sikker på du vil slette denne widget?",
    "Are you sure you want to discard this learning activity?":
        "Er du sikker på, at du ønsker at kassere denne læringsaktivitet?",
    "Are you sure you want to leave the meeting?":
        "Er du sikker på du vil forlade mødet?",
    "Are you sure you want to mark student as a self-study? This will move all assignments years into the future.":
        "Er du sikker på du vil markere denne studerende som selvstuderende? Dette vil flytte alle afleveringerne mange år frem i tiden.",
    "Are you sure you want to open learner view without saving the changes?":
        "Er du sikker på du vil skifte til brugervisning, uden at gemme dine ændringer?",
    "Are you sure you want to recalculate assignment plan?":
        "Er du sikker på du vil genberegne opgaveplanen?",
    "Are you sure you want to remove from your plan?":
        "Er du sikker på du vil fjerne {insertion} fra din plan?" /* Translate with param */,
    "Are you sure you want to remove this answer?":
        "Er du sikker på du vil fjerne dette svar?",
    "Are you sure you want to remove this course?":
        "Er du sikker på du vil fjerne dette forløb?",
    "Are you sure you want to remove this label?":
        "Er du sikker på du vil fjerne denne etikette?",
    "Are you sure you want to remove this learning element?":
        "Er du sikker på du vil fjerne denne læringsaktivitet?",
    "Are you sure you want to remove this lesson?":
        "Er du sikker på du vil fjerne denne lektion?",
    "Are you sure you want to remove this member?":
        "Er du sikker på du vil fjerne dette medlem?",
    "Are you sure you want to remove this match set?":
        "Er du sikker på du vil fjerne dette match sæt?",
    "Are you sure you want to remove this material?":
        "Er du sikker på du vil fjerne denne lektion?",
    "Are you sure you want to remove this question?":
        "Er du sikker på du vil fjerne dette spørgsmål?",
    "Are you sure you want to remove this subject?":
        "Er du sikker på du vil fjerne dette emne?",
    "Are you sure you want to remove this topic?":
        "Er du sikker på du vil fjerne dette underemne?",
    "Are you sure you want to remove this variable?":
        "Er du sikker på du vil fjerne denne variabel?",
    "Are you sure you want to remove this user?":
        "Er du sikker på du vil fjerne denne bruger?",
    "Are you sure you want to remove this widget?":
        "Er du sikker på du vil fjerne denne widget?",
    "Are you sure you want to remove this word?":
        "Er du sikker på du vil fjerne dette ord?",
    "Are you sure you want to start a video call?":
        "Er du sikker på du gerne vil starte et videokald?",
    "Are you sure you want to start a video call with this user?":
        "Er du sikker på du vil starte et video kald med denne bruger?",
    "Are you sure you want to submit this questionnaire?":
        "Er du sikker på du vil aflevere dette spørgeskema?",
    "Are you sure you want to turn in this quiz?":
        "Er du sikker på du vil aflevere denne quiz?",
    "Are you sure you want to unarchive this group?":
        "Er du sikker på du vil fjerne dette hold fra arkiveret?",
    "Are you sure you want to unlink your account from":
        "Er du sikker på du vil frakoble din konto fra",
    "Are you sure you want to unsubscribe?":
        "Er du sikker på du vil opsige dit abonnement?",
    "Are you unsure? Click the button below to read a guide on this":
        "Er du usikker? Klik på knappen nedenfor, for at læse en guide om emnet.",
    Armenian: "Armensk",
    Arrows: "Pile",
    "As a teacher, you are automatically always in training mode, and we will not store your answers, but you can test the quiz.":
        "Som underviser er du automatisk i træning på quizzen. Det betyder at vi ikke gemmer dine svar så du kan teste den.",
    "Ask to join": "Anmod om adgang",
    "Ask your students to go to": "Bed dine studerende om at gå til",
    Assessments: "Bedømmelser",
    Assign: "Tildel",
    "Assign category": "Tildel kategori",
    "Assign learners to counsellors": "Fordel brugere til vejledere",
    "Assign student": "Tildel studerende",
    "Assign student to": "Tildel studerende til",
    "Assign task to Olly": "Tildel opgave til Olly",
    Assignment: "Aflevering",
    assignment_objects: "Aflevering",
    assignments: "Afleveringer",
    "Assignments behind": "Afleveringer bagud",
    "Assignments completion percentage": "Aflevering fuldførelsesprocent",
    "Assignment answer": "Afleveret opgave",
    "Assignment created": "Aflevering oprettet",
    "Assignment handin timeline": "Tidslinje for opgaveaflevering",
    "Assignment handins/feedback statistics":
        "Opgaveafleverings/feedback statistik",
    "Assignment plans": "Afleveringsplaner",
    "Assignment rejected": "Aflevering afvist",
    "Assignment removed": "Aflevering fjernet",
    "Assignment saved": "Aflevering gemt",
    "Assignment statistics": "Afleveringsstatistik",
    "Assignment text": "Afleveringstekst",
    "Assignment title": "Afleveringstitel",
    "Assignment turnins missing feedback": "Afleveringer uden feedback",
    "Assignment turnin overview": "Opgaveafleveringsoverblik",
    "At least one correct answer chosen":
        "Mindst ét af de korrekte svar er valgt",
    "Attach media": "Vedhæft fil",
    "Attach to feedback": "Gem på feedback",
    Attachments: "Vedhæftede filer",
    Attachment: "Vedhæftet fil",
    Attempt: "Forsøg",
    "Average on teams": "Gennemsnit på hold",
    "Average quiz answer percentage for group":
        "Gennemsnitlig svarprocent for quizzer på holdet",
    "Average user completion": "Gennemsnitlig brugerfuldførelse",
    Audio: "Lyd",
    "Audio is on": "Lyd tændt",
    Australian: "Australsk",
    "Awaiting feedback": "Afventer feedback",
    Back: "Tilbage",
    "Back to all news": "Tilbage til alle nyheder",
    "Back to courses": "Tilbage til forløb",
    "Back to course overview": "Tilbage til forløbsoverblik",
    "Back to dashboard": "Tilbage til dashboard",
    "Back to debate list": "Tilbage til debatlisten",
    "Back to groups": "Tilbage til hold",
    "Back to information list": "Tilbage til informationsliste",
    "Back to lessons overview": "Tilbage til lektionsoversigten",
    "Back to overview": "Tilbage til oversigt",
    "Back to the dashboard": "Tilbage til overblikket",
    "Back to quizzes": "Tilbage til quizzer",
    Bank: "Bank",
    Basic: "Basic",
    "Basic & advanced integrations": "Basis & advancerede integrationer",
    "Basic integrations": "Basis integrationer",
    "Below are download links to the most popular browsers, in case you do not have them installed":
        "Nedenfor er links til de mest populære browsere, i tilfælde af at du ikke har dem installeret endnu.",
    Billing: "Abonnement",
    "Billing history": "Faktureringshistorik",
    Biology: "Biologi",
    Black: "Sort",
    Blue: "Blå",
    Board: "Opslagstavle",
    Bosnian: "Bosnisk",
    "Brazilian Portuguese": "Portugisisk (Brazilien)",
    "Build structured blocks of text to inform your students on exams procedures, tell them about yourself, or add a course description.":
        "Byg strukturerende blokke af tekster for at informere dine brugere om eksamensprocedurer, fortæl om dig selv eller tilføj en indholdsbeskrivelse.",
    "Business economics": "Erhvervsøkonomi",
    "By closing this modal you discard all learning activities prepared by Olly":
        "Hvis du lukker dette vindue, kasserer du alle læringsaktiviteter, der er forberedt af Olly",
    "By creating an account I accept Terms and Conditions and Edaptio's default Notification settings":
        "Ved at oprette en bruger accepterer jeg {termsLink} og Edaptio's generelle {notifLink}" /* Translate with params like in en_Us */,
    "By deleting the last element you discard the whole learning activity":
        "Ved at slette det sidste {name} sletter du hele læringsaktiviteten" /* Translate with param */,
    "By signing up you accept our": "Ved at oprette dig, accepterer du vores",
    "By unsubscribing you will continue to have plan access and be charged monthly for active learners above count until date. After this period you will only be able to keep 2GB of storage and 15 active learners.":
        "Ved at afmelde dig vil du fortsat have {plan}-adgang og blive opkrævet månedligt for aktive elever over {learners} indtil {validUntil}. Efter denne periode vil du kun beholde 2 GB lagerplads og max 15 aktive elever." /* Translate with params */,
    "By unsubscribing you will be downgraded to Basic Plan at the end of this billing cycle. After this period you will only be able to keep 2GB of storage and 15 active learners.":
        "Ved at afmelde dig vil du blive nedgraderet til Basic planen i slutningen af denne faktureringscyklus. Efter denne periode vil du kun kunne beholde 2 GB lagerplads og 15 aktive elever.",
    Ca: "Ca",
    Calculate: "Beregn",
    "Calculate this expression": "Udregn dette udtryk",
    Camera: "Kamera",
    "Camera off": "Kamera af",
    "Camera on": "Kamera på",
    Cancel: "Annuller",
    "Cancel call": "Afbryd opkald",
    "Case sensitive": "Skal skelne imellem store og små bogstaver",
    "Card details": "Kortoplysninger",
    "Card number": "Kortnummer",
    "Cardholder name": "Navn på kort",
    Catalan: "Katalansk",
    Categories: "Kategorier",
    Category: "Kategori",
    "Category items": "Kategori elementer",
    "changed the feedback for the assignment":
        "ændrede sit feedback til afleveringen",
    Changelog: "Changelog",
    "Change card": "Skift kort",
    "Change group": "Udskift hold",
    "Change image": "Udskift billede",
    "Change institution": "Skift institution",
    "Change moodle relation": "Skift moodle relation",
    "Change password": "Skift kodeord",
    "Change payment method": "Skift betalingsmetode",
    "Change plan": "Skift plan",
    "Change plan selection": "Skift planvalg",
    "Change UMS relation": "Skift UMS relation",
    characters: "tegn",
    chat: "Chat",
    Chat: "Chat",
    Checkbox: "Afkrydsningsfelt",
    "Check schedule of a specific class or class member":
        "Se skema for et specifit hold eller medlem",
    "Check your inbox!": "Tjek din indbakke!",
    Chemestry: "Kemi",
    "Change submission date for user":
        "Giv brugeren en ny afleveringsdato for denne opgave",
    Chinese: "Kinesisk",
    "Co-creation": "Samskabelse",
    "Connect Facebook": "Forbind Facebook",
    "Connect Microsoft": "Forbind Microsoft",
    "Connect Google": "Forbind Google",
    "Connect Unilogin": "Forbind Unilogin",
    "Connect your account": "Forbind din bruger",
    Choose: "Vælg",
    "Choose another element": "Vælg en anden aktivitet",
    "Choose another group": "Vælg et andet hold",
    "Choose assignment": "Vælg opgave",
    "Choose assignment from group": "Vælg opgave fra hold",
    "Choose between seeing quizzes the user has passed or completed.":
        "Vælg imellem at se quizzer som en bruger har bestået eller fuldført.",
    "Choose class": "Vælg fag",
    "Choose collection": "Vælg kollektion",
    "Choose collection from group": "Vælg kollektion fra hold",
    "Choose column representation": "Vælg kolonne repræsentation",
    "Choose correct match option": "Vælg den korrekte match mulighed",
    "Choose course": "Vælg forløb",
    "Choose CSV file": "Vælg CSV fil",
    "Choose date": "Vælg dato",
    "Choose element": "Vælg aktivitet",
    "Choose element type": "Vælg aktivitets type",
    "Choose from filesharing": "Vælg fra fildeling",
    "Choose files": "Vælg filer",
    "Choose from existing materials": "Vælg fra eksisterende lektioner",
    "Choose gender": "Vælg køn",
    "Choose Geogebra": "Vælg Geogebra",
    "Choose Geogebra from group": "Vælg Geogebra fra hold",
    "Choose grade": "Vælg karakter",
    "Choose group": "Vælg hold",
    "Choose institution": "Vælg institution",
    "Choose material": "Vælg lektion",
    "Choose members": "Vælg medlemmer",
    "Choose language": "Vælg sprog",
    "Choose learning element": "Vælg læringsaktivitet",
    "Choose lesson": "Vælg lektion",
    "Choose new file": "Vælg ny fil",
    "Choose questionnaire": "Vælg spørgeskema",
    "Choose questionnaire from group": "Vælg spørgeskema fra hold",
    "Choose quiz": "Vælg quiz",
    "Choose quiz from group": "Vælg quiz fra hold",
    "Choose random words": "Vælg tilfældige ord",
    "Choose subgroup": "Vælg underhold",
    "Choose time": "Vælg tid",
    "Choose the default language of the group":
        "Vælg standard sprog dette hold undervises i (bruges til at læse op)",
    "Choose the strategy with which Olly should create activity":
        "Vælg den strategi, som Olly skal lave {name} med" /* Translate with param */,
    "Choose type": "Vælg type",
    "Choose user": "Vælg bruger",
    "Choose weekday": "Vælg ugeday",
    "Choose what this group is teaching": "Vælg hvad dette hold underviser i",
    Chosen: "Valgt",
    "Chosen file": "Valgt fil",
    "Chosen group": "Valgt hold",
    "Chosen monthly plan summary":
        "Du opgraderer til {name} Plan. Planen fornyes automatisk hver måned, indtil den annulleres." /* Translate with params */,
    "Chosen user": "Valgt bruger",
    "Chosen yearly plan summary":
        "Du opgraderer til 1 års {name}-adgang og sparer 25 % ved at foretage en engangsbetaling. Planen fornyes automatisk årligt, indtil den annulleres." /* Translate with params */,
    class: "hold",
    Class: "Hold",
    "Class admins": "Undervisere",
    "Class code": "Holdkode",
    "class dashboard": "Holdside",
    "Class tracking & insights": "Holdstatistikker og indsigter",
    Classes: "Hold",
    Classic: "Klassisk",
    "Class members": "Medlemmer",
    "Class notebook link": "Link til klassenotesbog",
    "Class not found": "Hold ikke fundet",
    "Class subject": "Holdets fag",
    Clear: "Ryd",
    "Click enter to submit": "Klik enter for at tilføje",
    "Click here to add a new learning element or edit the selected element":
        "Klik her for at tilføje nye læringsaktiviteter eller redigere i den valgte aktivitet",
    "Click here to add existing material, create a new material or change the current material order":
        "Klik her for at tilføje eksisterende lektioner, oprette en ny lektion eller ændre rækkefølgen på de eksisterende lektioner",
    "Click here to create a new assignment":
        "Klik her for at oprette en ny opgave",
    "Click here to create a new conference post":
        "Klik her for at oprette en ny tråd eller et nyt spørgsmål",
    "Click here to create a new conversation with a student or a group":
        "Klik her for at oprette en ny samtale med en bruger eller et hold",
    "Click here to create a new course or change the current course order":
        "Klik her for at oprette et nyt forløb, eller ændre rækkefølgen på de eksisterende forløb",
    "Click here to create a new Geogebra element":
        "Klik her for at oprette et ny Geogebra aktivitet",
    "Click here to create a new material":
        "Klik her for at oprette en ny lektion",
    "Click here to create a new news post":
        "Klik her for at oprette en ny nyhed på holdet",
    "Click here to create a new quiz": "Klik her for at oprette en ny quiz",
    "Click here to create a new question":
        "Klik her for at oprette et nyt spørgsmål",
    "Click here to edit the group information text":
        "Klik her for at redigere gruppe informationsteksten",
    "Click here to go to your class notebook":
        "Klik her for at gå til din klassenotesbog",
    "Click left/right arrows to go to next/previous card":
        "Klik på venstre/højre pilene for at gå til næste/forrige kort",
    "Click on the link to activate your account. Don’t see it? Check your spam folder too :)":
        "Klik på linket for at aktivere din konto. Kan du ikke se det? Tjek også din spam-mappe :)",
    "Click on the answer to the left, and then a match option to the right, in order to make a connection.":
        "Klik på svaret til venstre, og derefter matchmuligheden til højre, for at oprette en forbindelse.",
    "Click on the card to see the back": "Klik på kortet for at se bagsiden",
    "Click on the card to see the front": "Klik på kortet for at se forsiden",
    "Click the add learning element button in the menu on the left":
        "Klik på tilføj læringsaktivitet i menuen i venstre side",
    "Click the create button to get started":
        "Klik på opret knappen for at komme igang",
    "Click the big button in the lower right corner to get started":
        "Klik på den store knap i nedre højre hjørne for at komme igang",
    "Click the button above to begin":
        "Klik på knappen ovenfor, for at begynde",
    "Click the button to add the first learning element":
        "Klik på knappen for at tilføje den første læringsaktivitet",
    "Click the create button on the left side to get started":
        "Klik på opret knappen i venstre side for at komme igang",
    "Click this button and click one of the sub-buttons to create a new folder or upload a new file":
        "Klik denne knap, og så klik en af de mindre knapper for at oprette en ny mappe eller tilføje en ny fil.",
    "Click this button to open the menu with your groups on all pages":
        "Klik på knappen for at åbne menuen med alle dine hold på alle sider",
    "Click to start edit": "Klik for at redigere",
    "Click up/down arrows to flip card":
        "Klik på op/ned pilene for at flippe kortet",
    Close: "Luk",
    "Close modal and create": "Luk vindue og opret",
    cloze: "Fill in the blank (Cloze)",
    code: "kode",
    Collapse: "Fold sammen",
    Color: "Farve",
    "Color can't be empty.": "Farve kan ikke være tom.",
    Comment: "Kommentar",
    "Comment created": "Kommentar oprettet",
    "Comment on news": "Kommentér på nyheder",
    "Comment removed": "Kommentar fjernet",
    Comments: "Kommentarer",
    Company: "Firma",
    "Compare plans": "Sammenlign planer",
    Completed: "Fuldført",
    "Completed consultations": "Afholdte konsultationer",
    "Completion advisor": "Gennemførselsvejleder",
    Confirm: "Bekræft",
    "Contact support": "Kontakt support",
    "Contact support@edaptio.com for more info":
        "Kontakt support@edaptio.com for mere info",
    "Contact us": "Kontakt os",
    Content: "Indhold",
    Continue: "Fortsæt",
    "Continue where you left off": "Fortsæt hvor du kom fra",
    conference: "Forum",
    "Conference thread": "Forumtråd",
    consultations: "Konsultationer",
    Consultations: "Konsultationer",
    Conversations: "Samtaler",
    "Conversation creation": "Opret samtale",
    "Conversation name": "Samtalenavn",
    "Conversation members": "Medlemmer af samtalen",
    "Collapse all": "Fold alle sammen",
    Collection: "Kollektion",
    Collections: "Kollektioner",
    "Complete registration": "Fuldfør registrering",
    Copied: "Kopieret",
    "Copied to clipboard": "Kopieret til udklipsholder",
    Copy: "Kopier",
    "Copy/paste your text source here. Olly will use this as context for generating content":
        "Kopier/indsæt din tekstkilde her. Olly vil bruge dette som kontekst til at generere indhold",
    "Copy activity": "Kopier læringsaktivitet",
    "Copy answer to the feedback field": "Kopier svar til feedback felt",
    "Copy class": "Kopier hold",
    "Copy course": "Kopier forløb",
    "Copy course and bypass sync": "Kopier forløb og synkronisér ikke",
    "Copy course and sync": "Kopier forløb og synkronisér",
    "Copy element": "Kopier aktivitet",
    "Copy element and bypass sync": "Kopier aktivitet og synkronisér ikke",
    "Copy element and sync": "Kopier aktivitet og synkronisér",
    "Copy element to other group": "Kopier aktivitet til et andet hold",
    "Copy element to these groups?": "Kopier aktivitet til disse hold?",
    "Copy element to this group?": "Kopier aktivitet til dette hold",
    "Copy existing question": "Kopier eksisterende spørgsmål",
    "Copy file": "Kopier fil",
    "Copy file url to clipboard": "Kopier filens webadresse til udklipsholder",
    "Copy group": "Kopier hold",
    "Copy groups": "Kopier hold",
    "Copy here": "Kopier hertil",
    "Copy lesson": "Kopier lektion",
    "Copy lesson and bypass sync": "Kopier lektion og synkronisér ikke",
    "Copy lesson and sync": "Kopier lektion og synkronisér",
    "Copy link": "Kopier link",
    "Copy new adaptive test": "Kopier new adaptiv test",
    "Copy of": "Kopi af",
    "Copy submission": "Kopier opgave",
    "Copy to clipboard": "Kopier til udklipsholder",
    "Copy to other groups": "Kopier aktivitet til andre hold",
    Corrected: "Rettet",
    Correct: "Korrekt",
    "Correct answer": "Korrekt svar",
    "Correct answer(s)": "Korrekt(e) svar",
    "Correct assignment": "Ret opgave",
    "Correct using DirectEdit": "Ret med DirectEdit",
    "Corresponding answer": "Tilhørende svar",
    course: "forløb",
    Course: "Forløb",
    "Courses are the top building block of content":
        "Forløb er øverste byggesten i dit læringsindhold",
    "Course copied": "Forløb kopieret",
    Counsellor: "Vejleder",
    Counsellors: "Vejledere",
    "Counsellors list": "Vejleder liste",
    "Course created": "Forløb oprettet",
    "Course moved": "Forløb flyttet",
    "Course name": "Forløbsnavn",
    "Course progression": "Forløbsprogression",
    "Course removed": "Forløb fjernet",
    "Course statistics": "Forløbsstatistik",
    "Course title": "Forløbstitel",
    courses: "Forløb",
    Courses: "Forløb",
    "Courses are comparable to chapters in a book, and contain several lessons to provide at logical structure for the class content. Make sure to create a course for all of your major subjects of the class.":
        "Forløb kan sammenlignes med kapitler i en bog, og indeholder lektioner for at gruppere læringsindhold. Giv en logisk struktur til holdets indhold! Sørg for at oprette forløb til alle store emner på dit hold.",
    Create: "Opret",
    "Create account using": "Opret bruger med",
    "Create another": "Opret endnu en",
    "Create a class": "Opret et hold",
    "Create a course": "Opret et forløb",
    "Create a lesson": "Opret en lektion",
    "Create a lesson by inserting its name. You can hide this lesson so students will not see it.":
        "Opret en lektion ved at skrive en titel. Du kan skjule denne lektion, så eleverne ikke kan se den.",
    "Create a new discussion and get opinions from other members":
        "Opret en ny debat og få meninger fra andre medlemmer",
    "Create an account": "Opret en bruger",
    "Create assignment": "Opret opgave",
    "Create assignments in bulk": "Opret afleveringer i bunker",
    "Create and release feedback": "Opret og frigiv feedback",
    "Create and show": "Opret og vis",
    "Create board item": "Tilføj opslag",
    "Create class": "Opret hold",
    "Create collection": "Opret kollektion",
    "Create consultation": "Opret konsultation",
    "Create course": "Opret forløb",
    "Create discussion": "Opret debat",
    "Create feedback": "Opret feedback",
    "Create fields and drag them to the desired location on the image. You can switch between image and text. Adjust the 'matches with' field to group together fields, that can be placed in the same places in the picture.":
        "Opret felter og træk dem til det rigtige sted på billedet. Du kan vælge imellem billeder og tekst. Justér 'Matcher med' feltet for at gruppere felter der gerne må ombyttes på billedet.",
    "Create flashcards": "Opret flashkort",
    "Create folder": "Opret mappe",
    "Create image label": "Opret billedetikette",
    "Create geogebra": "Opret Geogebra aktivitet",
    "Create Geogebra element": "Opret Geogebra aktivitet",
    "Create group": "Opret hold",
    "Create lesson": "Opret lektion",
    "Create link": "Opret link aktivitet",
    "Create media": "Opret mediaet",
    "Create new activity": "Opret ny aktivitet",
    "Create new conversation": "Opret ny samtale",
    "Create new course": "Opret nyt forløb",
    "Create new discussion": "Opret ny debat",
    "Create new group": "Opret nyt hold",
    "Create new material": "Opret ny lektion",
    "Create new question": "Opret nyt spørgsmål",
    "Create news post": "Opret nyhed",
    "Create now": "Opret nu",
    "Create conference post": "Opret forum tråd/spørgsmål",
    "Create question": "Opret spørgsmål",
    "Create questionnaire": "Opret spørgeskema",
    "Create quiz": "Opret quiz",
    "Create quiz question": "Opret quizspørgsmål",
    "Create question and show": "Opret spørgsmål og vis",
    "Create quiz and show": "Opret quiz og vis",
    "Create permission group": "Opret rolle",
    "Create pinboard": "Opret opslagstavle",
    "Create post": "Opret opslag",
    "Create sub group": "Opret underhold",
    "Create subject": "Opret emne",
    "Create submission": "Opret opgaveaflevering",
    "Create text": "Opret tekst",
    "Create text label": "Opret tekstetikette",
    "Create timeline": "Opret tidslinje",
    "Create topic": "Opret underemne",
    "Create user": "Opret bruger",
    "Create widget": "Opret widget",
    "Create your account": "Opret din bruger",
    "Create your first class": "Opret dit første hold",
    "Create your first class and invite students":
        "Opret dit første hold og inviter brugere",
    "Create your first message": "Opret din første besked",
    Created: "Oprettet",
    "Created learning activities": "Oprettede læringsaktiviteter",
    "created the assignment": "oprettede afleveringen",
    Croatian: "Kroatisk",
    "Current plan": "Nuværende plan",
    "Currently unavailable": "Ikke tilgængelig lige nu",
    "Custom DPA": "Tilpasset DPA",
    "Custom pricing": "Tilpasset pris",
    "Custom integrations": "Tilpassede integrationer",
    CVC: "CVC",
    Czech: "Tjekkisk",
    Danish: "Dansk",
    Dansk: "Dansk",
    "Dark blue": "Mørkeblå",
    dashboard: "Overblik",
    Dashboard: "Overblik",
    "dashboard-new-teacher-greeting":
        "Du har endnu ikke nogen aktive hold på edaptio. <br/> Klik nedenfor for at oprette dit første hold.",
    "Data display": "Datavisning",
    Date: "Dato",
    "Date saved": "Dato gemt",
    "Dedicated support": "Dedikeret support",
    "Default language": "Standard sprog",
    Definition: "Definition",
    "Delete group information": "Fjern information",
    Delete: "Slet",
    "Delete match": "Fjern match",
    "Delete match set": "Fjern matchsæt",
    "Delete pinboard item?": "Slet opslag?",
    "Delete submission": "Slet aflevering",
    "Depending on course(s)": "Afhænger af forløb",
    "Depending on following courses": "Afhænger af følgende forløb",
    Description: "Beskrivelse",
    "Description too long": "Beskrivelser for lang",
    "Descriptive text": "Beskrivelsestekst",
    Design: "Design",
    Deutsch: "Tysk",
    Dialog: "Dialog",
    Difficulty: "Sværhedsgrad",
    "Direct edit": "Direct edit",
    "Disable assignment links in assignments app":
        "Slå links fra i afleveringer-app (brug kun som oversigt).",
    "Disable video": "Slå video fra",
    "Disable audio": "Slå lyd fra",
    Discard: "Kassér",
    discussions: "Debatter",
    Discussions: "Debatter",
    "Display news from a specific class by selecting one below":
        "Vis nyheder fra et bestemt hold ved at vælge et nedenfor",
    "Dive deeper by choosing course and lesson.":
        "Dyk dybere ned ved at vælge forløb og lektion.",
    Document: "Dokument",
    Documents: "Dokumenter",
    Done: "Færdig",
    Download: "Hent",
    "Download as csv": "Download som csv",
    "Download attachment": "Download fil",
    "Download original file": "Hent original fil",
    "Download zip": "Hent zip fil",
    "Do not create sub group": "Opret ikke underhold",
    "Do not join": "Tilmeld mig ikke",
    "Do not have an account?": "Har du ikke en bruger?",
    "Do not use deadline": "Brug ikke afleveringsdato",
    "Do you have any other tasks for your friend Olly, the virtual teaching assistant?":
        "Har du andre opgaver til din ven Olly, den virtuelle undervisningsassistent?",
    "Do you know the answer?": "Kender du svaret?",
    "Do you need help?": "Har du brug for hjælp?",
    "Do you really want to leave? you have unsaved changes!":
        "Er du sikker på du vil forlade siden? Du har ting du ikke har gemt!",
    "Do you want to abandon the process?":
        "Har du lyst til at afslutte processen?",
    "Do you want to continue, but only copy new elements?":
        "Har du lyst til at fortsætte, men kun kopiere nye aktiviteter?",
    "Do you want to delete this answer?": "Vil du gerne slette dette svar?",
    "Do you want to delete this consultation?":
        "Vil du gerne slette denne konsultation?",
    "Do you want to delete this message for all members?":
        "Er du sikker på du vil slette denne besked for alle medlemmer af samtalen?",
    "Do you want to enable camera after joining call?":
        "Vil du starte dit kamera når du kommer ind i opkaldet?",
    "Do you want to end this call for all participants or just leave this conversation?":
        "Vil du afslutte opkaldet for alle medlemmer, eller bare forlade opkaldet?",
    "Do you want to evenly distribute the rest of the users assignments afterwards?":
        "Vil du automatisk omfordele fremtidige afleveringer jævnt for brugeren efter?",
    "Do you want to remove": "Vil du fjerne",
    "Do you want to remove member from this call?":
        "Vil du fjerne {insertion} fra dette opkald?",
    "Do you want to you want to leave current call?":
        "Vil du forlade dette opkald?",
    "Do you want to you want to leave current webinar?":
        "Vil du forlade dette webinar?",
    "Do you want to synchronize elements that have already been copied?":
        "Har du lyst til at synkronisere aktiviteter, som allerede er blevet kopieret før?",
    "Do you want to test out some of our math materials?":
        "Har du lyst til at prøve et udpluk af vores materialer til matematik?",
    "Do you wish to join the institution": "Vil du tilmelde dig institutionen",
    "Do you wish to share this with everyone?":
        "Kunne du tænke dig at dele dette med alle?",
    Downgrade: "Nedgradér",
    "Drag and drop answers to the dashed boxes with the categories":
        "Træk og slip svarene til de stiplede felter med kategorierne",
    "Drag and drop labels into this box to create alternative (wrong) labels":
        "Træk og slip etiketter ind i denne boks for at oprette alternative (forkerte) etiketter",
    "Drag and drop on image": "træk og slip på billede",
    "Drag and drop options to the dashed boxes with the answers":
        "Drag'n'drop etiketter til de stiplede bokse med svarene",
    "Drag and drop to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students":
        "Drag'n'drop for at ændre rækkefølgen af dine apps og aktiver/deaktiver dem ved at slå knappen til/fra. Du kan også skjule apps for dine brugere",
    Drama: "Dramatik",
    "Drop new learning element here": "Træk nyt læringslement her",
    "Dropout members": "Udmeldte medlemmer",
    "Dropped out": "Udmeldt",
    Due_date: "Afleveringsdato",
    "Due date": "Afleveringsdato",
    "Due date required": "Afleveringsdato påkrævet",
    Duplicate: "Duplikér",
    "Duplicate assignment": "Duplikér opgave",
    "Duplicate flashcard": "Duplikér flashkort",
    "Duplicate learning activity": "Duplikering af læringsaktivitet",
    "Duplicate question": "Duplikér spørgsmål",
    Dutch: "Hollandsk",
    Economics: "Økonomi",
    "Edaptio group": "Edaptio hold",
    "Edaptio primary": "Edaptio primær",
    "Edaptio secondary": "Edaptio sekundær",
    Edit: "Rediger",
    "Edit assignment": "Rediger opgave",
    "Edit audio": "Redigér lyd",
    "Edit payment method": "Rediger betalingsmetode",
    "Edit pinboard": "Rediger opslagstavle",
    "Edit board item": "Rediger opslag",
    "Edit collection": "Rediger kollektion",
    "Edit consultation": "Rediger konsultation",
    "Edit course": "Rediger forløb",
    "Edit courses order": "Rediger rækkefølge af forløb",
    "Edit discussion": "Rediger debat",
    "Edit external link": "Redigér eksternt link",
    "Edit feedback": "Rediger feedback",
    "Edit file": "Redigér fil",
    "Edit flashcard": "Redigér flashkort",
    "Edit flashcards": "Redigér flashkort",
    "Edit Geogebra element": "Rediger Geogebra aktivitet",
    "edit-group": "Holdindstillinger",
    "Edit group": "Rediger hold",
    "Edit group information": "Rediger gruppeinformation",
    "Edit group name": "Rediger holdnavn",
    "Edit holiday": "Rediger ferie/helligdag",
    "Edit image": "Redigér billede",
    "Edit image label": "Rediger billedetikette",
    "Edit learning element": "Rediger læringsaktivitet",
    "Edit material": "Redigér lektion",
    "Edit materials order": "Rediger rækkefølge af lektioner",
    "Edit message": "Redigér besked",
    "Edit news post": "Redigér nyhedsopslag",
    "Edit notebook": "Redigér notesbog",
    "Edit permission group": "Rediger rolle",
    "Edit post": "Rediger opslag",
    "Edit question": "Rediger spørgsmål",
    "Edit questionnaire": "Rediger spørgeskema",
    "Edit questions": "Rediger spørgsmål",
    "Edit quiz": "Rediger quiz",
    "Edit one learning element at a time. Save changes in order to continue editing.":
        "Rediger ét læringslement af gangen. Gem dine ændringer for at redigere en ny aktivitet.",
    "Edit timeline": "Rediger tidslinje",
    "Edit selected learning element": "Rediger den valgte læringsaktivitet",
    "Edit selected user": "Rediger valgte bruger",
    "Edit subject": "Rediger emne",
    "Edit tests": "Rediger tests",
    "Edit text": "Redigér tekst",
    "Edit text label": "Rediger tekstetikette",
    "Edit topic": "Rediger underemne",
    "Edit user": "Rediger bruger",
    Element: "Element",
    "Element already in lesson": "Aktiviteten er allerede på lektionen",
    "Element moved": "Læringsaktivitet flyttet",
    Elements: "aktiviteter",
    "Edit comment": "Redigér kommentar",
    "Edit video": "Redigér video",
    "Edit vimeo": "Redigér vimeo",
    "Edit youtube": "Redigér youtube",
    Embedded: "Indlejret",
    "Enable camera": "Slå kamera til",
    "Enable video": "Slå video til",
    "Enable audio": "Slå lyd til",
    Email: "Email",
    email: "Email",
    "Email, chat & onboarding support": "Email, chat & onboarding support",
    "Email & chat support": "Email & chat support",
    "Email / Username": "Email / brugernavn",
    "Email already exists": "Email eksisterer allerede i systemet",
    "Email support": "Email support",
    "End chat": "Afslut chat",
    "End for all": "Afslut for alle",
    English: "Engelsk",
    "Enter class code": "Indtast holdets kode",
    "Enter colleague email": "Skriv kollegas email",
    "Enter your email address and we will send you a new temporary password":
        "Skriv din email-adresse og vi sender dig et nyt midlertidigt kodeord",
    "Enter your new payment details": "Indtast dine nye betalingsoplysninger",
    "Enter your nickname to join this call":
        "Skriv dit kaldenavn for at joine opkaldet",
    "Enter your payment details": "Indsæt dine betalingsdata",
    Error: "Fejl",
    "Error occurred while trying to load your text. Please contact us to resolve this issue or simply re-enter text.":
        "Der skete en fejl da vi prøvede at indlæse din tekst. Kontakt supporten hvis det bliver ved med at ske, og så fixer vi det.",
    "Error occurred during video chat. Please contact us to resolve this issue or try to restart video chat.":
        "Fejl ved etablering af forbindelse. Venligst kontakt supporten eller prøv at genstarte videoopkaldet.",
    "Error occurred while try sign up":
        "Det skete en fejl ved oprettelse af bruger",
    "Error: time must be in HH:MM:SS format":
        "Fejl: tid skal være TT:MM:SS format",
    Esperanto: "Esperanto",
    "Evaluate all words": "Evaluér alle ord",
    "Even more storage": "Endnu mere lagerplads",
    Event: "Begivenhed",
    Events: "Begivenheder",
    Exam: "Eksamen",
    "exam mode": "eksamenstilstand",
    Exams: "Eksamener",
    "Exam mode": "Eksamineringstilstand",
    "Exam mode limits the students communication features for an entire team during an exam. Can be turned on/off at any time, and works right away.":
        "Eksamineringstilstand begrænser brugernes kommunikationsmuligheder på Edaptio, så man kan holde eksamener. Funktionen kan blive slået fra/til til enhver tid og virker øjeblikkeligt.",
    "Exit fullscreen": "Luk fuld skærm",
    Expand: "Fold ud",
    "Expand all": "Fold all ud",
    "Expiration date of your ongoing subscription plan":
        "Udløbsdatoen for din igangværende abonnementsplan",
    "Expiry date": "Udløbsdato",
    Expressions: "Udtryk",
    "External link": "Eksternt link",
    "External service forgotten error":
        "Du kan ikke anmode om nyt kodeord på denne institution, da i anvender et eksternt system til at logge jer ind. Kontakt administrationen på din institution.",
    "External service not linked":
        "Ekstern service ikke er linket til nogen bruger",
    "External user id": "Eksternt bruger-id",
    "Extra items (uncategorised)": "Ekstra elementer (ikke kategoriseret)",
    "Failed to activate video chat. Please contact us.":
        "Video chat aktivering fejlede. Kontakt venligst supporten. ",
    "Failed to delete information": "Kunne ikke fjerne information",
    "Failed to join video chat. Maybe you re not a participant of video chat. Try to ask to join":
        "Der skete en fejl med at tilgå videochatten. Maybe er du ikke medlem af videochatten. På at bede om adgang. ",
    "Failed to lock\\unlock board": "Fejl ved lås\\lås op af opslagstavle",
    "Failed to lock\\unlock board item": "Fejl ved lås\\lås op af opslag",
    "Failed to remove board item": "Fejl ved fjern opslag",
    "Failed to save board item": "Fejl ved gem opslag",
    "Failed to save information": "Kunne ikke gemme information",
    "Failed to start video chat. Please contact us.":
        "Vi kunne ikke starte videochatten. Kontakt venligst support.",
    "Failed to subscribe to board updates. Try to refresh board":
        "Fejl ved oprettelse af forbindelse til opslagstavle-opdateringer. Prøv at genopfriske opslagstavle.",
    "Failed to subscribe to conference updates. Try to refresh conference":
        "Fejl ved oprettelse af forbindelse til forum-opdateringer. Prøv at genopfriske forum.",
    "Failed to subscribe to news updates. Try to refresh news":
        "Fejl ved oprettelse af forbindelse til nyheder-opdateringer. Prøv at genopfriske nyheder.",
    "Failed to subscribe to page updates. Try to refresh the page":
        "Fejl ved oprettelse af forbindelse til side-opdateringer. Prøv at genopfriske side.",
    "Failed to subscribe to post answers updates. Try to refresh post answers":
        "Fejl ved oprettelse af forbindelse til opslag-svar-opdateringer. Prøv at genopfriske opslag svar.",
    FAQ: "Ofte stillede spørgsmål",
    Feedback: "Feedback",
    "Feedback created": "Feedback oprettet",
    "Feedback for this quiz try": "Feedback til dette quiz forsøg",
    "Feedback from past assignments": "Feedback fra tidligere afleveringer",
    "Feedback with this answer": "Feedback ved denne svarmulighed",
    "Feedback for answer": "Feedback til svar",
    "Feedback on this answer": "Feedback til dette svar",
    "Feedback released": "Feedback frigivet",
    "Feedback saved": "Feedback gemt",
    Female: "Kvinde",
    Fields: "Felter",
    File: "Fil",
    Files: "Filer",
    "File added": "Fil tilføjet",
    "File removed": "Fil fjernet",
    "File saved": "Fil gemt",
    "File path": "Filsti",
    "File too big, please upgrade your account to increase file size limit. (Trial limit:":
        "Filen er for stor. Du kan opgradere din Edaptio konto hvis du vil oploade større filer. (Nuværende begrænsning: ",
    "Files added": "Filer tilføjet",
    file_sharing: "Fildeling",
    "File sharing": "Fildeling",
    "Fill it in to log in": "Skriv det her for at logge ind",
    Filter: "Filtrér",
    "Fill in the blank": "Fill in the blank",
    "Fill in the blank (Cloze)": "Fill in the blank (Cloze)",
    "Find members by email": "Find medlemmer via mail",
    Finish: "Færdig",
    Finnish: "Finsk",
    Firstname: "Fornavn",
    "First handin": "Første aflevering",
    "Firstname should not be empty": "Fornavn kan ikke være tomt",
    "Fixed submission date": "Fast afleveringsdato",
    "Flashcards collection": "Flashcards samling",
    "Flashcards collection creation": "Opret flashcards",
    "Flashcards collection edit": "Redigér flashcards",
    "Flashcards tips":
        "Brug {param1} for at skifte kort og {param2} til at vende kortet" /* Write with params like in en_EN.js */,
    "Flexible submission date": "Fleksibel afleveringsdato",
    Float: "Decimaltal",
    Folders: "Mapper",
    "Folder added": "Mappe tilføjet",
    "Folder removed": "Mappe fjernet",
    "Folder saved": "Folder gemt",
    "Following invites will be sent via email":
        "Følgende medlemmer via blive inviteret via mail",
    For: "Til",
    "Forgot password?": "Glemt kodeord?",
    "Forgotten password": "Glemt kodeord",
    "for clarification.": "til afklaring",
    "For the best experience, we recommend switching to a computer.":
        "For at få den bedste oplevelse anbefaler vi, at du skifter til en computer.",
    Fraction: "Brøk",
    Fractions: "Brøker",
    Flashcard: "Flashkort",
    flashcards: "Flashkort",
    Flashcards: "Flashkort",
    Free: "Gratis",
    French: "Fransk",
    From: "Fra",
    "From the date your plan will switch to plan":
        "Fra {dato} skifter din plan til {plan}" /* Translate with params */,
    "from this call?": "fra dette opkald?",
    Front: "Forside",
    Full: "Fuld",
    Fullscreen: "Fuld skærm",
    "gave feedback for the assignment": "gav feedback til afleveringen",
    Gender: "Køn",
    General: "Generelt",
    "General chat": "Generel chat",
    "General feedback": "Generel tilbagemelding",
    "General question types": "Generelle spørgsmålstyper",
    "General settings": "Generelle indstillinger",
    geogebra: "Geogebra",
    Geogebra: "Geogebra",
    "Geogebra creation": "Opret Geogebra",
    "Geogebra edit": "Rediger Geogebra",
    "Geogebra element": "Geogebra element",
    "Geogebra element removed": "Geogebra element fjernet",
    "Geogebra id": "Geogebra id",
    "Geogebra link or ID": "Geogebra link eller ID",
    Geography: "Geografi",
    German: "Tysk",
    "Get help from Olly": "Få hjælp af Olly",
    "Get started": "Kom igang",
    "Get started using Edaptio for free": "Kom gratis igang med Edaptio",
    "Get started with Edaptio": "Kom igang med Edaptio",
    "Get this code/URL from your teacher.":
        "Få denne kode/URL fra din underviser",
    "Get submission as zip": "Hent afleveringer som zip",
    "Go back to what you have been doing and get a notification when Olly is done":
        "Gå tilbage til det, du har været i gang med, og få en notifikation, når Olly er færdig",
    "Go back to where you came from":
        "Gå tilbage til det sted, hvor du kom fra",
    "Go to group": "Gå til hold",
    "Go to guide": "Gå til guide",
    "Go to link": "Gå til link",
    "Go to the team": "Gå til holdet",
    "Go to the lesson view to add learning elements!":
        "Gå til lektionen for at tilføje læringsaktiviteter!",
    "Good afternoon": "Godeftermiddag,",
    "Good evening": "Godaften,",
    "Good morning": "Godmorgen,",
    Google: "Google",
    "Google linked": "Google linked",
    Grade: "Karakter",
    "Grade scale": "Karakterskala",
    "grade_scale.danish": "Dansk 7-trins skala",
    "grade_scale.letters": "Bogstaver A-F",
    "grade_scale.numbers": "Tal 1-6",
    "grade_scale.romanDTU": "Romertal I-IV",
    Graded: "Rettet",
    Graphical: "Grafisk",
    "Gray": "Grå",
    Greek: "Græsk",
    Group: "Hold",
    "Group with": "Gruppér med",
    group_courses: "Forløb",
    groups: "Hold",
    "Groups in exam mode": "Hold i eksamenstilstand",
    "group-information": "Information",
    "group-statistics": "Holdstatistik",
    "Group archived": "Hold arkiveret",
    "Group created": "Hold oprettet",
    "Group hand-in allowed": "Gruppeaflevering tilladt",
    "Group name": "Holdnavn",
    "Group news": "Holdnyheder",
    "Group saved": "Hold gemt",
    "Group type": "Holdtype",
    "Group unarchived": "Hold fjernet fra arkiv",
    Half: "Halv",
    "Hatian Creole": "Hatian Creole",
    "Hand down": "Hånd ned",
    "Hand up": "Hånd op",
    "Handed in": "Afleveret",
    "Handed in after deadline": "Afleveret efter deadline",
    "has a hand up": "har en hånd oppe",
    "has started a video-chat. Click here to attend:":
        "har startet en video-chat. Klik her for at deltage:",
    "Have you received a class code from your teacher? Then click the “join now” button and become a part of your first class.":
        'Har du fået en kode af din underviser? Så klik på "tilmeld dig nu" og bliv en del af holdet',
    Help: "Hjælp",
    "Help center": "Hjælpecenter",
    "Here isn't added courses yet": "Ingen forløb fundet",
    "Here you can copy all your class contents to another class. Remember that elements you have previously copied will only be synchronized, and not copied again.":
        "Her kan du kopiere al indhold på dit hold til et andet hold. Husk at aktiviteter som du har kopieret før, ikke vil blive kopieret igen, men derimod synkroniseret.",
    "Here you can put groups in exam mode. Exam mode means that all users in the particular group can not use any communication tools on the platform. E.g messages, news and conference":
        "Her kan du sætte hold i eksamenstilstand. Eksamenstilstand betyder, at alle brugere på pågældende hold ikke kan bruge nogen kommunikationsværktøjer på platformen. Så som beskeder, nyheder eller debat.",
    "Hey, I am busy at the moment!": "Hej, jeg har travlt i øjeblikket!",
    "Hey! I am Olly, the virtual teaching assistant!":
        "Hej! Jeg er Olly, den virtuelle undervisningsassistent!",
    "Hey, Olly has your content ready for review":
        "Hej, Olly har dit indhold klar til gennemsyn",
    "Hey! Olly failed to create learning activities from of the uploaded source":
        "Hey! Olly kunne ikke oprette læringsaktiviteter fra den uploadede ressource",
    "Hey Edaptio, I am interested in a tailored offer for you platform. My contact information is:":
        "Hej Edaptio, jeg er interesseret i et skræddersyet tilbud til din platform. Mine kontaktoplysninger er:",
    "HH:MM:SS": "TT:MM:SS",
    "Hidden lesson": "Skjult lektion",
    "Hide element": "Skjul element",
    "Hide menu": "Skjul menu",
    "Hide/show side menu": "Skjul/vis sidemenu",
    "Hide non due date assignments": "Skjult afleveringer uden frist",
    Hidden: "Skjult",
    "Hidden assignment": "Skjult opgave",
    "Hidden course": "Skjult forløb",
    "Hidden folder": "Skjult mappe",
    "Hidden from students": "Skjult for brugere",
    "Hidden material": "Skjult lektion",
    "Hidden submission": "Skjult aflevering",
    Hindi: "Hindi",
    History: "Historie",
    Home: "Hjem",
    "How do i log in?": "Hvordan logger jeg ind?",
    "How do you want to create your activity?":
        "Hvordan ønsker du at oprette {name}?" /* Translate with param */,
    "How to get started?": "Hvordan kommer jeg igang?",
    Hungarian: "Ungarsk",
    Icelandic: "Islandsk",
    Identifier: "Identifier",
    "I am using another platform": "Jeg bruger en anden platform",
    "I agree": "Jeg accepterer",
    "I agree to the Terms and Conditions and default Notifications settings":
        "Jeg accepterer {termsLink} og de generelle {notifLink}" /* Translate with params like in en_US file */,
    "I can help you create content for your lessons. Just follow the steps and put me to work!":
        "Jeg kan hjælpe dig med at oprette læringsindhold til dine lektioner. Følg blot trinene, og sæt mig i gang!",
    "I don’t find the platform valuable": "Jeg fandt ikke værdi i platformen",
    "I have read and understood this text":
        "Jeg har læst og forstået denne tekst",
    "If unchecked, the user will be presented with all words form the word list.":
        "Hvis ikke valgt, vil brugere blive præsenteret for alle ordene i ordlisten.",
    "If you have been sent here by your teacher, then you already have a login. So make sure to ask them how to login and for your credentials.":
        "Hvis du er blevet sendt herhen af din underviser, så har du allerede et login. Spørg din underviser efter dine oplysninger.",
    'If you have downloaded your assignments via the "download as zip" button, then you can upload the feedback files directly here. We will automatically generate feedback and attach the files. Make sure you only upload files you have not uploaded before, and only for submissions you have not given feedback to already.':
        'Hvis du har hentet afleveringerne via "download som zip" knappen, så kan du uploade din feedback samlet her. Vi sørger for automatisk at generere feedback og vedhæfte filerne. Sørg for kun at uploade filer, som du ikke har uploadet før, og kun til afleveringer som du ikke allerede har rettet.',
    "If you're supposed to have access to this page, try refreshing.":
        "Hvis det er meningen at du skal have adgang til denne side, så prøv at genindlæse browservinduet.",
    "I'm sure": "Jeg er sikker",
    Image: "Billede",
    image: "Billede",
    "Image dimensions": "Billeddimensioner",
    "Image height": "Billedhøjde",
    "Image width": "Billedbredde",
    "image-drag-and-drop": "Træk og slip på billede",
    Import: "Importer",
    "Import data": "Importer data",
    "Import existing question": "Importér spørgsmål",
    "Import users": "Importer brugere",
    "Import wiris instance": "Importer et wiris spørgsmål",
    "In a test, all feedback is received at the end of the quiz.":
        "I en test får brugeren al feedback i slutningen af quizzen.",
    "Includes 10 active learners, €0.80/month for each additional learner":
        "Inkluderer 10 aktive studerende, 0,80 €/måned for hver ekstra studerende",
    "Includes 10 active learners, €1.60/month for each additional learner":
        "Inkluderer 10 aktive studerende, 1,60 €/måned for hver ekstra studerende",
    "Incorrect file name": "Forkert filnavn",
    Indonesian: "Indonesisk",
    information: "Information",
    Information: "Information",
    "Information deleted": "Information fjernet",
    "Information saved": "Information gemt",
    "Information title": "Titel",
    Innovation: "Innovation",
    "Input not email": "Input er ikke en email",
    "Input too short": "Input for kort",
    "Input valid date and time - or skip the deadline":
        "Tast korrekt dato og tid - eller vælg ikke at bruge deadline på denne opgave",
    "Input the class name and click create. You can always add members and co-teachers later under the members tab.":
        'Skriv holdnavnet og klik på opret. Du kan altid tilføje medlemmer og andre undervisere senere under "medlemmer" fanen.',
    "Insert a code received from your teacher in order to join the class":
        "Skriv holdkoden som du har modtaget fra din underviser for at tilmelde dig holdet.",
    "Insert a text below": "Indsæt en tekst nedenfor",
    "Insert a word or phrase you want learners to hear.":
        "Indsæt et ord eller en sætning, som du ønsker, at eleverne skal høre.",
    "Insert your personal information and set up the platform’s language. You will be able to edit it later in the profile’s settings.":
        "Indsæt din personlige information og sæt dit foretrukne sprog. Du kan lave det om senere inde i din profil",
    Institution: "Institution",
    "Institution/company name": "Navn på institution/virksomhed",
    "Institution groups": "Institutionens hold",
    "Institution payments": "Betaling og forbrug",
    Institutions: "Institutioner",
    "Institution language": "Institutionssprog",
    "Institution settings": "Institutionsindstillinger",
    Integer: "Heltal",
    Interactive: "Interaktive",
    interactive_objects: "Geogebra",
    "Interactive exercise": "Interaktiv øvelse",
    "International economy": "International økonomi",
    "Intranet": "Intranet",
    Invalid: "Ugyldig",
    "Invalid class code": "Ugyldig holdkode",
    "Invalid form": "Ugyldig data sendt til server",
    "Invalid: Must be at least 1": "Ugydig: skal være minimum 1",
    "Invalid: Must be at most": "Ugydig: må højst være",
    "Invalid: Must be between 0 - 100": "Ugydig: Skal være imellem 0 og 100",
    Invite: "Inviter",
    "Invite colleagues": "Invitér kolleger",
    "Invite learners": "Invitér brugere",
    "Invite members": "Inviter medlemmer",
    "Invite members to your plan": "Inviter medlemmer til din plan",
    "Invite more": "Invitér flere",
    "Invite new learners sharing one of following":
        "Inviter nye elever via en af følgende",
    "Invite Participants": "Inviter medlemmer",
    "Invite participants via link": "Inviter medlemmer via link",
    "Invite plan members via email": "Inviter medlemmer via email",
    "Invited by": "Inviteret af",
    "Invites have been sent to your colleagues. They will be added to your institution as soon as they click the link in the email and complete the registration.":
        "Invitationerne er blevet sendt til dine kolleger. De vil blive tilføjet så snart de har gennemført registreringen på Edaptio.",
    "Invite(s) sent.": "Invitation(er) sendt",
    Italian: "Italiensk",
    Item: "Element",
    "It seems that there are no active elements in this class":
        "Det ser ud til at der ikke er nogen aktive {insertion} på dette hold",
    Japanese: "Japansk",
    Join: "Tilmeld",
    "Join class": "Tilmeld dig holdet",
    Joined: "Joinede",
    "Join call": "Deltag i opkald",
    "Join institution": "Join institution",
    "Join now": "Tilmeld dig her",
    "Join your class": "Tilmeld dig",
    "Join your first class": "Tilmeld dig dit første hold",
    "Join with a code": "Tilmeld dig med kode",
    "Join with code not allowed":
        "Tilmelding med kode er ikke tilladt for dette hold",
    "Keep training": "Bliv ved med at træne",
    Keyword: "Nøgleord",
    "Knowledge bank": "Vidensbank",
    Korean: "Koreansk",
    label: "etikette",
    "Label group": "Etiketgruppe",
    "Labels not on image": "Etikette som ikke er på billedet",
    Language: "Sprog",
    "lang.da": "Dansk",
    "lang.en": "Engelsk",
    "Language saved": "Sprog gemt",
    "Language selection": "Vælg sprog",
    Lastname: "Efternavn",
    "Lastname should not be empty": "Efternavn kan ikke være tomt",
    "Last answered": "Seneste besvarelse",
    "Last modified": "Sidste aktivitet",
    Later: "Senere",
    "Latest activity": "Seneste aktivitet",
    "Latest answers": "Seneste svar",
    "Latest handin": "Seneste aflevering",
    "Latest sync": "Seneste synkronisering",
    "Latest try": "Seneste forsøg",
    Latin: "Latinsk",
    Latvian: "Lettisk",
    "Learning activities": "Læringsaktiviteter",
    learning_elements: "Læringsaktiviteter",
    "Learning activities added": "Læringsaktiviteter tilføjet",
    "Learning activities created by Olly are displayed below. You can keep, edit or discard each of them.":
        "Læringsaktiviteter skabt af Olly vises nedenfor. Du kan beholde, redigere eller kassere hver af dem.",
    "Learning activities to create": "Læringsaktiviteter til oprettelse",
    "Learning elements": "Læringsaktiviteter",
    "Learning element created": "Læringsaktivitet oprettet",
    "Learning element moved": "Læringsaktivitet flyttet",
    "Learning element removed": "Læringsaktivitet fjernet",
    "Learning element saved": "Læringsaktivitet gemt",
    "Learn more": "Lær mere",
    Leave: "Forlad",
    "Leave current video chat": "Forlad nuværende videoopkald",
    "Leave current webinar": "Forlad dette webinar",
    "Left us": "Forlod os",
    lesson: "Lektion",
    Lesson: "Lektion",
    "Lesson copied": "Lektion kopieret",
    "Lesson moved": "Lektion flyttet",
    "Lesson name": "Lektionsnavn",
    "Lesson title": "Lektionstitel",
    Lessonbank: "Lektionsbank",
    lessons: "Lektioner",
    Lessons: "Lektioner",
    "let all in": "lad alle komme ind",
    "Let us know why you are leaving:": "Fortæl of hvorfor du forlader os:",
    "Let’s begin!": "Lad os gå i gang!",
    Level: "Niveau",
    Levels: "Niveauer",
    Library: "Bibliotek",
    link: "link",
    Link: "Link",
    "Link copied!": "Link kopieret!",
    "Link course to group": "Link forløb til hold",
    "Link element to group": "Link læringsaktivitet til hold",
    "Link file to group": "Link fil til hold",
    "Link learning element to group": "Link læringsaktivitet til hold",
    "Link lesson to group": "Link lektion til hold",
    "Link text": "Link tekst",
    "Link to group": "Link til hold",
    "Link to join to video chat": "Link til adgang til video chat",
    Links: "Link",
    "List of participants": "Medlemsliste",
    "List of selected attachments": "Valgte filer til aflevering",
    "Load all comments": "Hent alle kommentarer",
    "Load earlier": "Hent tidligere",
    "Load external quiz": "Indlæs ekstern quiz",
    "Load flashcards’ preview": "Hent & se flashcards",
    "Load more": "Hent mere",
    "Load pinboard items": "Hent opslag",
    "Load student submissions": "Indlæs afleveringsoversigt",
    Loading: "Indlæser",
    "Lock board": "Lås opslagstavle",
    "Lock board item": "Lås opslag",
    "Locked course. You have not yet completed the prerequisite course(s) for this course.":
        "Låst forløb. Du har endnu ikke fuldført det/de forløb som dette forløb afhænger af.",
    Login: "Log ind",
    "Login as": "Log ind som",
    "Login failed with the following message":
        "Log ind fejlede med følgende meddelelse",
    Logout: "Log ud",
    "Log in as": "Log på som",
    "Login with": "Log ind med",
    "Log in with email": "Log ind med mail",
    "Log in with Facebook": "Log ind med Facebook",
    "Log in with Google": "Log ind med Google",
    "Log in with Unilogin": "Log ind med Unilogin",
    "Log in with Microsoft": "Log ind med Microsoft",
    "Looking for a specific user? Search below.":
        "Leder du efter en bestemt bruger? Søg nedenfor.",
    "Looking to log in? Click here": "Leder du efter log ind? Klik her",
    "Lower border": "Nedre grænse",
    "Made by": "Lavet af",
    Male: "Mand",
    "Manage apps": "Tilføj/fjern apps",
    "Manage board item": "Administrér opslag",
    "Manage groups": "Administrer hold",
    "Manage institutions": "Administrer institutioner",
    "Manage moodle": "Administrer moodle",
    "Manage permissions": "Administrer tilladelser",
    "Manage users": "Administrer brugere",
    Mandatory: "Obligatorisk",
    "Maple TA": "Maple TA",
    Macedonian: "Makedonsk",
    "Mark all as read": "Markér alle som læst",
    "Mark completed": "Markér fuldført",
    "Mark as unread": "Markér som ulæst",
    "Mark student as self-study": "Markér som selvstuderende",
    "Mark unread": "Markér ulæst",
    "Marked as read": "Markeret som læst",
    match: "Match",
    Match: "Match",
    "Match case insensitive": "Skal IKKE skelne imellem store og små bogstaver",
    "Match case sensitive": "Skal skelne imellem store og små bogstaver",
    "Match options": "Match muligheder",
    "Match set": "Match sæt",
    "Match sets": "Match sæt",
    "Match with arrows": "Match med pile",
    "Matching cloze tag": "Matchende tekst hashtag",
    "Matching with": "Matcher med",
    "match-wiris": "Wiris match",
    material: "Lektion",
    material_objects: "Lektion",
    materials: "Lektioner",
    "Material added": "Lektion tilføjet",
    "Material created": "Lektion oprettet",
    "Material deleted": "Lektion slettet",
    "Material group": "Materialehold",
    "Material removed": "Lektion fjernet",
    MathHero: "MatematikTutor",
    Mathematics: "Matematik",
    media: "medie",
    Media: "Medie",
    Member: "Medlem",
    Members: "Medlemmer",
    members: "Medlemmer",
    "Members can comment on news": "Medlemmer kan kommentere nyheder",
    "Members can post news": "Medlemmer kan slå nyheder op",
    "Members on plan": "Medlemmer på planen",
    Menu: "Menu",
    "Message marked as unread": "Meddelelse markeret som ulæst",
    Messages: "Beskeder",
    Microphone: "Mikrofon",
    Microsoft: "Microsoft",
    "Microsoft linked": "Microsoft linked",
    min: "min",
    "Minimum count characters":
        "Minimum {count} tegn" /* Translate with param count */,
    "Minimum number of assignments behind": "Minimum antal afleveringer bagud",
    "Minimum number of questions": "Minimum antal spørgsmål",
    "Miro Mind Maps": "Miro Mind Map",
    "Missing feedback": "Manglende feedback",
    Moderator: "Moderator",
    Moldavian: "Moldovisk",
    Montenegrin: "Montenegrinsk",
    Monthly: "Månedlig",
    "Monthly payment": "Månedlig betaling",
    "Monthly payment per active learner":
        "Månedlig betaling pr. aktive studerende",
    "months-full-array": [
        "Januar",
        "Februar",
        "Marts",
        "April",
        "Maj",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "December"
    ],
    "months-short-array": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Maj",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec"
    ],
    Moodle: "Moodle",
    "Moodle group": "Moodle hold",
    More: "Mere",
    "More active learners": "Flere aktive elever",
    "More settings": "Flere indstillinger",
    "More support options": "Flere supportmuligheder",
    "Most apps have a central action button - look for it in the same place as this one":
        "Det fleste apps har en central handlingsknap - kig efter den samme sted som denne",
    Move: "Flyt",
    "Move activity": "Flyt aktivitet",
    "Move all classes to exam mode": "Flyt alle klasser til eksamenstilstand",
    "Move here": "Flyt hertil",
    "Move lesson": "Flyt lektion",
    "Move to folder": "Flyt til anden mappe",
    ms_class_notebook: "Klassenotesbog",
    "Multiple choice": "Multiple choice",
    "multiple-choice": "Multiple choice",
    "multi-choice-wiris": "Wiris multiple choice",
    "Multiple choice questions": "Multiple choice spørgsmål",
    "Multiple choice settings": "Multiple choice indstillinger",
    Music: "Musik",
    "Must be a valid secure link (https)":
        "Skal være et validt sikkert link (https)",
    "Must be between": "Skal være imellem",
    Mute: "Slå lyd fra",
    Muted: "Lyd slukket",
    "Muted mic": "Mic af",
    "mute all": "mute alle",
    "My institution is not on the list": "Min institution er ikke på listen",
    Name: "Navn",
    "Name saved": "Navn gemt",
    "Need help?": "Brug for hjælp?",
    "Need more flexible options? Upgrade your plan today":
        "Brug for mere fleksible muligheder? Opgrader din plan idag!",
    Never: "Aldrig",
    "New assignment": "Ny opgave",
    "New consultation": "Ny konsultation",
    "New email": "Ny email",
    "New field": "Nyt felt",
    "New flashcard creation": "Opret nyt flashcard",
    "New Geogebra element": "Nyt Geogebra element",
    "New image": "Nyt billede",
    "New individual due date": "Ny individuel afleveringsdato",
    "New information": "Ny information",
    "New learning activity has been created":
        "Der er blevet oprettet en ny læringsaktivitet",
    "New learning element": "Ny læringsaktivitet",
    "New learning element added": "Ny læringsaktivitet tilføjet",
    "New message": "Ny besked",
    "New Post": "Nyt opslag",
    "New question": "Nyt spørgsmål",
    "New quiz": "Ny quiz",
    "New password": "Nyt kodeord",
    "New pinboard item": "Nyt opslag",
    "New relation": "Ny relation",
    "New word": "Nyt ord",
    news: "Nyheder",
    News: "Nyheder",
    "News post creation": "Opret nyhed",
    "New password has been successfully sent via email":
        "Et nyt kodeord er blevet sendt via email",
    Next: "Næste",
    "Next assignment": "Næste aflevering",
    "Next assignment answer without feedback": "Næste aflevering uden feedback",
    "Next course": "Næste forløb",
    "Next lesson": "Næste lektion",
    "Next material": "Næste lektion",
    "Next month": "Næste måned",
    "Next payment scheduled for": "Næste betaling forfalder",
    "Next question": "Næste spørgsmål",
    "Next submission": "Næste aflevering",
    "Next submission without feedback": "Næste aflevering uden feedback",
    "Next topic": "Næste delemne",
    "Nice, you created a quiz question!":
        "Flot, du har lavet et quiz-spørgsmål!",
    No: "Nej",
    "No admins yet": "Ingen undervisere endnu",
    "No answers": "Ingen svar",
    "No assignments found": "Ingen afleveringer fundet",
    "No collections found": "Ingen kollektioner fundet",
    "No completed consultations": "Ingen færdiggjorte konsultationer",
    "No consultations to display": "Ingen konsultationer at vise",
    "No conversations": "Ingen samtaler",
    "No counsellors": "Ingen vejledere",
    "No course": "Uden for forløb",
    "No courses found": "Ingen forløb fundet",
    "No credit card required": "Intet kreditkort påkrævet",
    "No data to display":
        "Ingen {name} at vise" /* Translate with param like in en_US */,
    "No description": "Ingen beskrivelse",
    "No elements in this lesson": "Ingen aktiviteter i denne lektion",
    "No files": "Ingen filer",
    "No files or folders yet": "Ingen filer eller mapper endnu",
    "No files to export":
        "Ingen filer at eksportere. Har dine brugere afleveret endnu?",
    "No Geogebra elements found": "Ingen Geogebra aktiviteter fundet",
    "No group information created": "Ingen information oprettet",
    "No groups": "Ingen hold",
    "No groups found": "Ingen hold fundet",
    "No information": "Ingen information",
    "No learning elements found": "Ingen læringsaktiviteter fundet",
    "No materials found": "Ingen lektioner fundet",
    "No members": "Ingen medlemmer",
    "No members yet": "Ingen medlemmer endnu",
    "No messages found": "Ingen beskeder endnu",
    "No messages to display": "Ingen beskeder at vise",
    "No messages yet": "Ingen beskeder endnu",
    "No news found for this group": "Ingen nyheder på dette hold endnu",
    "No notifications": "Ingen notifikationer",
    "No, only visible for my institution":
        "Nej, kun tilgængeligt for min institution",
    "No participants": "Ingen deltagere",
    "No people found": "Ingen brugere fundet",
    "No people found. Keep typing a full email to invite people.":
        "Ingen personer fundet. Bliv ved med at taste emailen for at invite personer",
    "No permissions found": "Ingen tilladelser fundet",
    "No permission to access your microphone or\\and camera you (or your companions) may experience some problems.":
        "Du har ikke givet tilladelse til at vi må bruge microfon og/eller video. Dette kan give problemer for dig selv eller dine gæster i video-kaldet.",
    "No posts found": "Ingen opslag fundet",
    "No questions": "Ingen spørgsmål",
    "No questions found": "Ingen spørgsmål fundet",
    "No questionnaires found": "Ingen spørgeskemaer fundet",
    "No quizzes found": "Ingen quizzer fundet",
    "No results": "Ingen resultater",
    "No results yet": "Ingen resultater endnu",
    "No subjects": "Ingen emner",
    "No submission date": "Ingen afleveringsdato",
    "No text": "Ingen tekst",
    "No title": "Ingen titel",
    "No topic": "Intet underemne",
    "No topics": "Ingen underemner",
    "No students": "Ingen studerende",
    "No tries yet": "Ingen forsøg endnu",
    "No upcoming consultations": "Ingen kommende konsultationer",
    "No videos found": "Ingen videoer fundet",
    "Non-profit": "Non-profit",
    None: "Ingen",
    "Nope, thanks!": "Niks, tak!",
    Norwegian: "Norsk",
    Notifications: "Notifikationer",
    "Notify me": "Giv mig besked",
    "Not assigned to course": "Ikke tildelt til forløb",
    "Not enough data": "Ikke nok data",
    "Not active": "Ikke aktiv",
    "Not added": "Ikke tilføjet",
    "Not chosen": "Ikke valgt",
    "Not correct": "Ikke korrekt",
    "Not completed": "Ikke fuldført",
    "Not graded": "Ikke rettet",
    "Not passed": "Ikke bestået",
    "Not released": "Ikke frigivet",
    "Not submitted": "Ikke afleveret",
    "Not valid video chat session provided. Please restart video call normally Page reload will not help.":
        "Dette er ikke en valid video chat session. Venligst genstart video chat. Det hjælper ikke at genindlæse.",
    "Note that your new subscription plan will be activated on the date":
        "Bemærk, at dit nye abonnement vil blive aktiveret {dato}" /* Translate with param */,
    "Nothing has been shared with you yet":
        "Intet er blevet delt med dig endnu",
    "Notification settings": "Notifikationsindstillinger",
    Now: "Nu",
    "now and share the class activity with your students":
        "nu, og del aktiviteten med dine elever",
    "Number of active learners": "Antal aktive studerende",
    "Number of assignments": "Antal afleveringer",
    "Number of assignments missing feedback":
        "Antal afleveringer som mangler at blive rettet",
    "Number of attempts": "Antal forsøg",
    "Number of decimals": "Antal decimaler",
    "Number of flashcards": "Antal flashkort",
    "Number of lessons": "Antal lektioner",
    "Number of missing": "Antal manglende",
    "Number of questions": "Antal spørgsmål",
    "Number of votes": "Antal stemmer",
    "Number of words": "Antal ord",
    "Number type": "Nummertype",
    "Numeric variables": "Nummeriske variable",
    "Oh no! Something went wrong.": "Åh nej! Noget gik galt.",
    OK: "OK",
    Okay: "Okay",
    Of: "Af",
    of: "af",
    "Of all questions are answered correctly":
        "Af alle spørgsmål bliver korrekt besvaret",
    Off: "Fra",
    "Old password": "Nuværende kodeord",
    "Olly has (finally) finished the task":
        "Olly er (endelig) færdig med opgaven",
    "Olly is working hard to create your content":
        "Olly arbejder hårdt på at oprette dit indhold",
    "Olly will use this to create learning activities from.":
        "Olly vil bruge denne til at lave læringsaktiviteter fra.",
    On: "Til",
    "Only open answer for now": "Kun åbne svar for nu",
    "Oops something went wrong. Maybe you didn't grant permissions to access your camera or microphone.":
        "Ups! Noget gik galt. Tjek at du har givet tilladelse til kamera og mikrofon. ",
    "Oops something went wrong. Please try again.":
        "Ups! Noget gik galt. Prøv igen. ",
    Open: "Åben",
    "Open answer": "Åbent svar",
    "Open external link": "Åben eksternt link",
    "Open link": "Åben link",
    "Open video chat": "Åben video chat",
    "Open turnin": "Åben besvarelse",
    "open-answer": "Åbent svar",
    Option: "Mulighed",
    Optional: "Valgfrit",
    "Optional settings": "Valgfrie indstillinger",
    Options: "Valgmuligheder",
    or: "eller",
    "Or continue using your phone or tablet with limited functionality.":
        "Eller du kan fortsætte med at bruge din telefon eller tablet med begrænset funktionalitet.",
    Orange: "Orange",
    "Order saved": "Rækkefølge gemt",
    Organize: "Organisér",
    Other: "Andet",
    "Other Edaptio products": "Andre Edaptio produkter",
    "Other login options": "Andre loginmuligheder",
    "Other reason": "Anden årsag",
    "Oups! This page does not exist!": "Ups! Denne side eksisterer ikke!",
    Overview: "Overblik",
    "Paid monthly": "Betalt månedligt",
    "Paid yearly": "Betalt årligt",
    Page: "Side",
    Pages: "Sider",
    Participants: "Deltagere",
    Passed: "Bestået",
    "Passed or completed": "Bestået eller fuldført",
    Password: "Kodeord",
    "Password saved": "Kodeord gemt",
    "Passwords must match": "Kodeordene skal være ens",
    "Pass percentage": "Beståelsesprocent",
    "Past consultations": "Tidligere konsultationer",
    "Paste wiris XML": "Indsæt wiris kode",
    Pay: "Betal",
    "Payment details": "Betalingsoplysninger",
    "per active learner": "per aktive studerende",
    "per active learner per month": "per aktive studerende per måned",
    "per month": "per måned",
    "Per month paid yearly": "Per måned betalt årligt",
    Percentage: "Procent",
    "Percentage or fractions": "Procent eller brøk",
    Permission: "Tilladelse",
    Permissions: "Tilladelser",
    "Permission group": "Rolle",
    "Permission groups": "Roller",
    "permissions.admin_bank": "Administrator i banken",
    "permissions.admin_groups_classes":
        "Administrer hold og klasser for hele institutionen",
    "permissions.admin_institution_settings":
        "Administrer generelle instillinger for institutionen",
    "permissions.admin_moodle_administration":
        "Administrer MOODLE for institutionen",
    "permissions.admin_permissions":
        "Administrer tildeling af rettigheder for hele institutionen",
    "permissions.admin_sign_in_as_other": "Log på som anden bruger",
    "permissions.admin_stats": "Tilgå statistikker for hele institutionen",
    // "permissions.auto_admin_in_groups" : "Automatisk underviser på hold, som brugeren inviteres til",
    "permissions.can_view_teacher_activity": "Kan se underviser-statistikker",
    "permissions.change_language": "Skift sprog",
    "permissions.change_profile_picture": "Skift profilbillede",
    "permissions.edit_module_assignments":
        "Administratorrettigheder til app: afleveringer",
    "permissions.edit_module_conferences":
        "Administratorrettigheder til app: Konferencer",
    "permissions.edit_module_consultations":
        "Administratorrettigheder til app: Konsultationer",
    "permissions.editor_bank": "Redaktør i banken",
    "permissions.edit_module_file_sharing":
        "Administratorrettigheder til app: Fildeling",
    "permissions.edit_module_information":
        "Administratorrettigheder til app: Information",
    "permissions.edit_module_quizzes":
        "Administratorrettigheder til app: Quizzer",
    "permissions.edit_module_materials":
        "Administratorrettigheder til app: lektioner",
    "permissions.edit_module_interactive":
        "Administratorrettigheder til app: Geogebra",
    "permissions.edit_module_paths": "Administratorrettigheder til app: Forløb",
    "permissions.edit_module_news": "Administratorrettigheder til app: Nyheder",
    "permissions.edit_module_videos":
        "Administratorrettigheder til app: Videoer",
    "permissions.edit_module_homework_aid":
        "Administratorrettigheder til app: Videohjælp",
    // "permissions.message_groups_classes" : "Send beskeder til hele hold og klasser",
    "permissions.profile_assignments": "Se afleveringer for anden bruger",
    "permissions.profile_quizzes": "Se quizzer for anden bruger",
    "permissions.profile_paths": "Se forløb for anden bruger",
    "permissions.profile_stats": "Se statistikker for anden bruger",
    // "permissions.search_entire_institution" : "Søg på alle hold, afleveringer og brugere på hele institutionen",
    // "permissions.see_own_assignments" : "Se afleveringer for egen bruger",
    // "permissions.see_own_stats" : "Se statistikker for egen bruger",
    // "permissions.send_reminders" : "Send påmindelser til brugere",
    "permissions.view_bank": "Adgang til banken",
    // "permissions.view_module_assignments" : "Brugerrettigheder til app: afleveringer",
    // "permissions.view_module_conferences" : "Brugerrettigheder til app: Konferencer",
    // "permissions.view_module_file_sharing" : "Brugerrettigheder til app: Fildeling",
    // "permissions.view_module_information" : "Brugerrettigheder til app: Information",
    // "permissions.view_module_quizzes" : "Brugerrettigheder til app: Quizzer",
    // "permissions.view_module_paths" : "Brugerrettigheder til app: Forløb",
    // "permissions.view_module_news" : "Brugerrettigheder til app: Nyheder",
    // "permissions.view_module_materials" : "Brugerrettigheder til app: lektioner",
    // "permissions.view_module_interactive" : "Brugerrettigheder til app: Geogebra",
    // "permissions.view_module_videos" : "Brugerrettigheder til app: Videoer",
    "permissions.user_edit_own_group": "Admin in own group",
    "Personal groups": "Personlige hold",
    "Personal onboarding": "Personlig onboarding",
    Philosophy: "Filosofi",
    Physics: "Fysik",
    "Pick date": "Vælg dato",
    "Pick due date": "Vælg afleveringsdato",
    "Pick due time": "Vælg afleveringstid",
    "Pick from file sharing": "Vælg fra fildeling",
    "Pick new background image": "Vælg nyt baggrundsbillede",
    "Pick time": "Vælg tid",
    "Pick the same date if it is just a single holiday":
        "Vælg den samme dato hvis det er en enkelt helligdag",
    Pin: "Fastgør",
    "Pin post": "Fastgør opslag",
    "Pin side menu": "Fastgør sidemenu",
    pinboard: "opslagstavle",
    Pinboard: "Opslagstavle",
    "Pinboard creation": "Opret opslagstavle",
    "Pinboard edit": "Rediger opslagstavle",
    "Pinboard text": "Opslagstext",
    Pinboards: "Opslagstavler",
    Pink: "Pink",
    Pinned: "Fastgjort",
    Phone: "Telefon",
    Plan: "Plan",
    "Plan will be canceled from the date":
        "Planen vil være opsagt fra den {dato}" /* Translate with param */,
    "Please fix all errors and try again":
        "Venligst ret alle fejl og prøv igen",
    "Please let me finish the previously assigned task before you assign me a new one.":
        "Lad mig venligst afslutte den opgave, jeg tidligere har fået tildelt, før du giver mig en ny opgave.",
    "Please select a user": "Venligst vælg en bruger",
    "Please select the language of the text in order to get the most accurate results":
        "Vælg venligst tekstens sprog for at få de mest præcise resultater",
    "Please solve the errors before adding this question":
        "Løs venligst fejlene, før du tilføjer dette spørgsmål",
    "Please use any other browser for browsing our platform.":
        "Venligst brug en hvilken som helst anden browser til at tilgå vores platform.",
    "Verify your email": "Bekræft din email",
    "Please wait until the call owner lets you in":
        "Vent venligst til du bliver lukket ind i opkaldet",
    Polish: "Polsk",
    "Pool size": "Pool størrelse",
    Portuguese: "Portugisisk",
    Possibilities: "Muligheder",
    Post: "Slå op",
    "Post comment": "Kommentér",
    "Post created": "Opslag oprettet",
    "Post removed": "Opslag fjernet",
    "Post saved": "Opslag gemt",
    Practiced: "Trænet",
    "Practice again": "Træn igen",
    "Practice quiz": "Kan træne quiz",
    "Pre-paid active learners number for this plan":
        "Forudbetalt antal aktive studerende for denne plan",
    Premium: "Premium",
    PremiumMonthlyLearnU: "Premium",
    PremiumYearlyLearnU: "Premium",
    Presentation: "Præsentation",
    "Press enter to add new field, and backspace to remove":
        "Tryk enter for at tilføje nyt felt, eller backspace for at slette",
    "Preview data": "Vis data",
    "Preview past feedback": "Vis tidligere feedback",
    "Preview question": "Vis spørgsmål",
    Previous: "Forrige",
    "Previous month": "Forrige måned",
    "Previous topic": "Forrige delemne",
    Proceed: "Fortsæt",
    Profile: "Profil",
    "Profile information": "Profilinformation",
    "Profile information change": "Rediger profilinformation",
    Progress: "Progression",
    Progression: "Progression",
    "Provide more details": "Angiv flere detaljer",
    "Provided email and password do not match. Please try again.":
        "Email og kodeord matcher ikke. Prøv igen.",
    Psychology: "Psykologi",
    "Pull the rows to edit the sort order of the apps and activate/deactivate the app using the lever. You can also hide apps from the students":
        "Træk i ikonerne for at ændre rækkefølgen af apps, og aktiver/deaktiver apps ved at slå den første knap til. Du kan også skjule apps for dine brugere ved at indstille på knappen længst til højre.",
    "Purple": "Lilla",
    "Put labels in the same group to make them interchangeable.":
        "Sæt etiketter i den samme gruppe for at gøre dem udskiftelige.",
    Quarter: "Kvart",
    Question: "Spørgsmål",
    "Question & answers": "Spørgsmål & svar",
    "Question and answers": "Spørgsmål og svar",
    "Question feedback": "Spørgsmålsfeedback",
    "Question title": "Spørgsmålstitel",
    questionnaire: "spørgeskema",
    Questionnaire: "Spørgeskema",
    questionnaires: "Spørgeskemaer",
    Questionnaires: "Spørgeskemaer",
    Questions: "Spørgsmål",
    "Question bank": "Spørgsmålsbank",
    "Question overview": "Spørgsmålsoversigt",
    "Question statistics": "Spørgsmålsstatistik",
    "Question type": "Spørgsmålstype",
    "Questions from the bank": "Spørgsmål fra banken",
    "Questions in order": "Spørgsmål i rækkefølge",
    "Questions must have at least one correct answer and cannot be empty":
        "Spørgsmål skal have minimum et korrekt svar og må ikke være tomme",
    Queue: "Kø",
    Quiz: "Quiz",
    quiz: "quiz",
    quiz_objects: "Quiz",
    "quiz-question": "quizspørgsmål",
    quiz_question_objects: "Quizspørgsmål",
    "Quiz completion percentage": "Quiz fuldførelsesprocent",
    "Quiz matrix": "Quiz matrix",
    "Quiz mode": "Quiz funktion",
    "Quiz name": "Quiz navn",
    "Quiz question creation": "Opret quizspørgsmål",
    "Quiz question editing": "Redigér quizspørgsmål",
    "Quiz question": "Quizspørgsmål",
    "Quiz questions": "Quizspørgsmål",
    "Quiz statistics": "Quizstatistik",
    "Quiz status": "Quiz status",
    "Quiz title": "Quiz-titel",
    "Quiz type": "Quiz type",
    Quizzes: "Quizzer",
    quizzes: "Quizzer",
    "Quizzes status": "Status på quizzer",
    "Radio buttons": "Radio knapper",
    "raised a hand": "rækker hånden op",
    "Random pool size": "Antal tilfældige spørgsmål",
    "Random questions": "Tilfældige spørgsmål",
    React: "Reaktion",
    "Read messages": "Læste",
    "Read more": "Læs mere",
    "Reading time": "Læsetid",
    "Recalculate assignment plan for user": "Genberegn opgaveplan for bruger",
    "Recalculate assignment plans for group": "Genberegn opgaveplaner for hold",
    "Recalculate cloze options": "Hiv tekst-variable igen",
    Reconnect: "Genopret forbindelse",
    "Refresh board": "Genopfrisk opslagstavle",
    "Refresh the page if the changes do not appear":
        "Genindlæs siden hvis ændringerne ikke kommer frem",
    Register: "Registrér",
    "Register handin": "Registrér aflevering",
    "Register handin manually": "Registrér aflevering manuelt",
    "Register student submission": "Registrer aflevering af studerende",
    Rejected: "Afvist",
    "Reject submission": "Afvis aflevering",
    Released: "Frigivet",
    "Release feedback": "Frigiv feedback",
    Religion: "Religion",
    "Remember to link this quiz to a Maple TA assignment after you save it. If you fail to do this, users will encounter a dead link.":
        "Husk at linke denne quiz til en opgave i Maple TA efter du opretter den. Hvis du ikke gør det, vil dine brugere møde et dødt link. ",
    Remove: "Fjern",
    "Remove admin": "Fjern admin",
    "Remove answer": "Fjern svar",
    "Remove assignment": "Fjern opgave",
    "Remove attachment": "Fjern vedhæftet fil",
    "Remove audio": "Fjern lyd",
    "Remove board": "Fjern opslagstavle",
    "Remove collection": "Fjern kollektion",
    "Remove course": "Fjern forløb",
    "Remove external link": "Fjern eksternt link",
    "Remove feedback": "Fjern tilbagemelding",
    "Remove file": "Fjern fil",
    "Remove flashcard": "Fjern flashkort",
    "Remove flashcards": "Fjern flashkort",
    "Remove folder": "Fjern mappe",
    "Remove from material": "Fjern fra lektion",
    "Remove geogebra": "Fjern geogebra",
    "Remove Geogebra element": "Fjern Geogebra element",
    "Remove group": "Fjern hold",
    "Remove image": "Fjern billede",
    "Remove match set": "Fjern match sæt",
    "Remove material": "Fjern lektion",
    "Remove member": "Fjern medlem",
    "Remove label": "Fjern etikette",
    "Remove learning element": "Fjern læringsaktivitet",
    "Remove picture": "Fjern billede",
    "Remove post": "Fjern opslag",
    "Remove subject": "Fjern emne",
    "Remove text": "Fjern tekst",
    "Remove topic": "Fjern underemne",
    "Remove question": "Fjern spørgsmål",
    "Remove questionnaire": "Fjern spørgeskema",
    "Remove quiz": "Fjern quiz",
    "Remove timeline": "Fjern tidslinje",
    "Remove user": "Fjern bruger",
    "Remove user from the conversation": "Fjern bruger fra samtale",
    "Remove vacation/holiday": "Fjern helligdag/ferie",
    "Remove video": "Fjern video",
    "Remove vimeo": "Fjern vimeo",
    "Remove your Facebook account": "Fjern din Facebook bruger",
    "Remove your Google account": "Fjern din Google bruger",
    "Remove youtube": "Fjern youtube",
    "Remove variable": "Fjern variabel",
    "Remove word": "Fjern ord",
    Rename: "Omdøb",
    "Reorder elements": "Omorganiser læringsaktiviteter",
    Repeatable: "Gentagelig",
    "Repeatable quiz": "Gentagelig quiz",
    "Repeat email": "Gentag email",
    "Repeat new password": "Gentag nyt kodeord",
    "Repeat quiz": "Gentag quiz",
    "Repeat password": "Gentag kodeord",
    "Replace picture": "Udskift billede",
    "replied to": "svarede",
    Replies: "Svar",
    "Reply to": "Svar til",
    "Reset password": "Sæt nyt kodeord",
    "Reset completion deadlines": "Genstart gennemførselsdeadlines",
    Required: "Påkrævet",
    "Required pass percentage": "Påkrævet beståelsesprocent",
    "Requires at least": "Kræver mindst",
    Result: "Resultat",
    Results: "Resultater",
    Retry: "Prøv igen",
    "Retry payment": "Prøv at betale igen",
    "Return to dashboard": "Tilbage til dashboard",
    Review: "Gennemgå",
    "Review results": "Gennemgå resultaterne",
    Role: "Rolle",
    Romanian: "Romansk",
    Russian: "Russisk",
    Save: "Gem",
    "Save (discount)": "Spar",
    "Save and attach to feedback": "Gem og vedhæft på feedback",
    "Save and release feedback": "Gem og frigiv feedback",
    "Save and show": "Gem og vis",
    "Save assignment": "Gem opgave",
    "Save audio file": "Gem lydfil",
    "Save changes": "Gem ændringer",
    "Save collection": "Gem kollektion",
    "Save external link": "Gem eksternt link",
    "Save feedback": "Gem feedback",
    "Save file": "Gem fil",
    "Save flashcards collection": "Gem flashcards",
    "Save geogebra": "Gem geogebra",
    "Save image": "Gem billede",
    "Save in filesystem": "Gem i fildeling",
    "Save order": "Gem rækkefølge",
    "Save question and show": "Gem spørgsmål og vis",
    "Save question": "Gem spørgsmål",
    "Save questionnaire": "Gem spørgeskema",
    "Save quiz": "Gem quiz",
    "Save quiz and show": "Gem quiz og vis",
    "Save video": "Gem video",
    "Save Vimeo video": "Gem Vimeo video",
    "Save payment details": "Gem betalingsoplysninger",
    "Save subject": "Gem emne",
    "Save submission": "Gem aflevering",
    "Save text": "Gem tekst",
    "Save timeline": "Gem tidslinje",
    "Save to": "Gem til",
    "Save to root folder": "Gem i grundfolder",
    "Save topic": "Gem underemne",
    "Save widget": "Gem widget",
    "Save your quiz": "Gem din quiz",
    "Save youtube video": "Gem youtube video",
    Saved: "Gemt",
    Science: "Naturvidenskab",
    School: "Skole",
    Search: "Søg",
    "Search all students": "Søg alle studerende",
    "Search counsellor": "Søg vejleder",
    "Search for users, groups..": "Søg efter hold, brugere..",
    "Search group": "Søg hold",
    "Search members": "Søg medlemmer",
    "Search person": "Søg person",
    "Search question": "Søg spørgsmål",
    "Search results": "Søgeresultater",
    "Search student": "Søg studerende",
    "Search students in the class": "Søg kun studerende på hold",
    "Search user": "Søg bruger",
    "Search youtube": "Søg Youtube",
    sec: "sek",
    "Security warning": "Sikkerhedsadvarsel",
    "See all": "Se alle",
    "See all news": "Se alle nyheder",
    "See past feedback": "Se tidligere feedback",
    "See members on plan": "Se medlemmer på plan",
    "See your feedback below": "Se din feedback nedenfor",
    Seen: "Set",
    "Seen by all": "Set af alle",
    "Seen by student": "Set af studerende",
    "Select a folder": "Vælg en folder",
    "Select a media type that you want to create":
        "Vælg hvilket medie du gerne vil oprette",
    "Select a month": "Vælg en måned",
    "Select a question type": "Vælg en spørgsmålstype",
    "Select a widget type you want to add":
        "Vælg den type widget du gerne vil tilføje",
    "Select a year": "Vælg et år",
    "Select activity type": "Vælg aktivitetstype",
    "Select attachments": "Vælg filer",
    "Select file type": "Vælg filtyper",
    "Select from list": "Vælg fra liste",
    "Select submission type": "Vælg afleveringstype",
    "Select subscription": "Vælg plan",
    "Select plan": "Vælg plan",
    "Select tags": "Vælg emner",
    "Select the language of the text source": "Vælg tekstens sprog",
    "Select the learning activities you want to create from the text":
        "Vælg de læringsaktiviteter, du ønsker at oprette ud fra teksten",
    "Selected class": "Valgte hold",
    "Selected counsellor's students": "Valgte vejleders studerende",
    "Selected groups": "Valgte hold",
    "Selected members": "Valgte medlemmer",
    Send: "Send",
    "Send a message to start the chat": "Send en besked for at starte chatten",
    "Send copy to colleagues": "Send kopi til kolleger",
    "Send element to these colleagues?": "Send element til disse kolleger?",
    "Send element to this colleague?": "Send element til denne kollega?",
    "Send Invites": "Send invitationer",
    "Send message": "Send besked",
    "Send messages": "Send beskeder",
    "Send new password": "Send nyt kodeord",
    "Send unreleased feedback": "Send ikke-frigivet feedback",
    Sentence: "Sætning",
    Serbian: "Serbisk",
    "Serbo-Croatian": "Serbo-kroatisk",
    "Set or change an individual due date":
        "Indstil eller rediger en individuel afleveringsdato",
    Setting: "Indstilling",
    Settings: "Indstillinger",
    "Settings saved": "Indstillinger gemt",
    Setup: "Opsætning",
    "Sign up": "Tilmeld dig",
    "Sign up as user type":
        "Opret bruger som {userType}" /* Translate with param */,
    "Sign up with email": "Tilmeld dig med email",
    "Sign up with Facebook": "Tilmeld dig med Facebook",
    "Sign up with Google": "Tilmeld dig med Google",
    "Sign up with Unilogin": "Tilmeld dig med Unilogin",
    "Sign up with Microsoft": "Tilmeld dig med Microsoft",
    "SimpleMath multiple choice": "SimpleMath multiple choice",
    "Simplemath settings": "Simplemath indstillinger",
    "SimpleMath settings": "SimpleMath indstillinger",
    "SimpleMath short answer": "SimpleMath kortsvar",
    "simple-math-short-answer": "SimpleMath kortsvar",
    "simple-math-multiple-choice": "SimpleMath multiple choice",
    Size: "Størrelse",
    Share: "Del",
    "Share class": "Del hold",
    "Share course": "Del forløb",
    "Share element": "Del element",
    "Share file": "Del fil",
    "Share folder": "Del mappe",
    "Share material": "Del lektion",
    "Share screen": "Del skærm",
    "Share with colleagues": "Del med kolleger",
    shared: "Delt",
    "shared the assignment": "Delte opgaven",
    "shared the course": "Delte forløbet",
    "shared the flashcard": "Delte flashkortet",
    "shared the flashcard collection": "Delte flashkort kollektionen",
    "shared the folder": "Delte folderen",
    "shared the geogebra": "Delte geogebra aktiviteten",
    "shared the group": "Delte holdet",
    "shared the lesson": "Delte lektionen",
    "shared the link": "Delte linket",
    "shared the media": "Delte mediaet",
    "shared the text": "Delte teksten",
    "shared the question": "Delte spørgsmålet",
    "shared the questionnaire": "Delte spørgsskemaet",
    "shared the quiz": "Delte quizzen",
    "shared the timeline": "Delte tidslinjen",
    "Shared with me": "Delt med mig",
    "Short answer": "Kortsvar",
    "Short answer settings": "Kortsvarindstillinger",
    "short-answer": "Kortsvar",
    "short-answer-wiris": "Wiris kortsvar",
    Show: "Vis",
    "Show all": "Vis alle",
    "Show element": "Vis aktivitet",
    "Show info": "Vis info",
    "Show less": "Vis mindre",
    "Show more": "Vis mere",
    "Skip first line": "Spring første linje over",
    "Skip over elements that have already been copied":
        "Spring aktiviteter over som allerede er blevet kopieret",
    Slovak: "Slovakisk",
    Socialstudies: "Samfundsfag",
    "Some elements have already been copied to these groups.":
        "Nogle aktiviteter er allerede blevet kopieret til disse hold",
    "Some elements have already been copied to this group.":
        "Nogle aktiviteter er allerede blevet kopieret til dette hold",
    "Some of the questions failed to be copied":
        "Nogle af spørgsmålene blev ikke kopieret",
    "Sorry, we can not connect your account. Please try again later.":
        "Beklager, vi kan ikke linke din bruger på dette tidspunkt. Prøv venligst senere.",
    "Sorry, but you do not have permission to access this page":
        "Beklager, men du har ikke tilladelse til at tilgå denne side.",
    "Sorry to see you go": "Ked af at se dig gå",
    Source: "Kilde",
    "Source successfully uploaded": "Teksten er gemt og klar",
    Spanish: "Spansk",
    "Spanish Latin American": "Spansk (Latin Amerika)",
    Speakers: "Højtalere",
    "Specify the difficulty of the question. This is your subjective assessment compared to the rest of the content.":
        "Sæt sværhedsgraden på dette spørgsmål. Dette er din subjektive vurdering sammenlignet med resten af indholdet på dette niveau.",
    Spell: "Stav",
    "spell-speech": "Stav ord",
    "Spell word": "Stav ord",
    Standard: "Standard",
    StandardMonthlyLearnU: "Standard",
    StandardYearlyLearnU: "Standard",
    "Standard DPA": "Standard DPA",
    Start: "Start",
    "Start broadcasting": "Begynd at udsende video",
    "Start by building your content with courses":
        "Start med forløb til at bygge din læringsstruktur",
    "Start by adding learning elements listed on the left by pressing a plus button or dragging and dropping them below.":
        "Start med at tilføje læringsaktiviteter fra listen til venstre ved at klikke på plusset, eller trække dem ind hvor du gerne vil placere dem. ",
    "Start exam mode": "Start eksamenstilstand",
    "Start making great learning content, by verifying your email.":
        "Verificer din email, og begynd at bygge fantastiske læringsmaterialer!",
    "Start new discussion": "Start ny debat",
    "Start quiz": "Start quiz",
    "Start reading": "Start oplæsning",
    "Start video chat": "Start video chat",
    Statistics: "Statistik",
    Status: "Status",
    "STEM questions": "STEM spørgsmål",
    "Stop broadcasting": "Stop videoudsendelse",
    "Stop exam mode": "Stop eksamenstilstand",
    "Stop reading": "Stop oplæsning",
    "Stop sharing": "Stop deling",
    "Structure course content using lessons":
        "Strukturer dit læringsindhold med lektioner",
    Student: "Studerende",
    student: "studerende",
    "Student submission registered by teacher":
        "Studerendes aflevering registreret af underviser",
    students: "studerende",
    Students: "Studerende",
    "Student administration": "Administration",
    "Student handed in offline":
        "Opgaveaflevering registreret manuelt af underviser",
    "Student has not handed in this submission yet.":
        "Brugeren har ikke afleveret denne afleveringsopgave endnu.",
    "Student hours": "Elevtimer",
    "Student hours help indicate to your students how much time they should spend on the submission":
        "Elevtimer hjælper dine studerende med at indikere hvor meget tid de skal bruge på en aflevering",
    "Student is not part of any class yet":
        "Brugeren er ikke medlem af nogen hold endnu",
    "Students can add flashcards": "Studerende kan tilføje flashkort",
    "Students can see results": "Brugere kan se resultater",
    "Students list": "Studerende liste",
    "Student / student average": "Bruger / gennemsnit af bruger",
    "Student Testing": "Studerende test",
    "Student submission will be registered when the feedback is saved":
        "Studerendes aflevering vil blive registreret, når feedbacken gemmes",
    "Student view": "Brugervisning",
    Subgroups: "Underhold",
    Subject: "Emne",
    Subjects: "Emner",
    Submission: "Aflevering",
    submission: "aflevering",
    "Submission example": "Afleveringseksempel",
    "Submission creation": "Afleveringsoprettelse",
    "Submission information": "Afleveringsinformation",
    "Submission missing feedback": "Afleveringer som mangler feedback",
    "Submission plan": "Afleveringsplan",
    "Submission title": "Afleveringstitel",
    "Submission type": "Afleveringstype",
    Submissions: "Afleveringer",
    Submit: "Indsend",
    "Submit answer & continue": "Angiv svar & fortsæt",
    "Submit questionnaire": "Aflevér spørgeskema",
    Submitted: "Afleveret",
    Subscription: "Abonnement",
    "Subscription type": "Abonnementstype",
    Success: "Succes",
    "Support mail": "Support e-mail",
    "Sure, lets go!": "Klart, lad os fortsætte!",
    Surname: "Efternavn",
    Swahili: "Swahili",
    "Swap out": "Udskift",
    Swedish: "Svensk",
    Switch: "Udskift",
    "Switch additional group": "Udskift sekundært hold",
    "Switch back to main user": "Skift tilbage til oprindelige bruger",
    "Switch devices now to start adding learning activities into your lessons.":
        "Skift enheder nu for at begynde at tilføje læringsaktiviteter i dine lektioner.",
    "Switch main group": "Udskift primært hold",
    "Switch to add these activities:":
        "Skift for at tilføje disse aktiviteter:",
    Sync: "Synkronisér",
    "Synchronize elements that have already been copied":
        "Synkronisér aktiviteter som allerede er blevet kopieret",
    "Synchronize hashtags manually": "Synkronisér hashtags manuelt",
    Tags: "Emner",
    Tamil: "Tamil",
    teacher: "underviser",
    Teacher: "Underviser",
    "Teacher view": "Vis som underviser",
    Teachers: "Undervisere",
    "Teachers on plan": "Undervisere på planen",
    Term: "Udtryk",
    "Terms & definitions": "Termer & definitioner",
    "Terms and Conditions": "Brugsbetingelser",
    Test: "Test",
    "Test answer": "Test svar",
    text: "Tekst",
    Text: "Tekst",
    "Text can't be empty and should be shorter then 255 characters.":
        "Tekst kan ikke være tom og skal være mindre end 255 karakterer.",
    "Text copied": "Tekst kopieret",
    "Text on label": "Tekst på etikette",
    "Text title": "Tekst titel",
    Time: "Tid",
    timeline: "tidslinje",
    Timeline: "Tidslinje",
    "Time in HH:MM:SS": "Tid i TT:MM:SS",
    "Time to complete course (days)": "Tid til at gennemføre holdet (dage)",
    Timelines: "Tidslinjer",
    Timestamp: "Tidsstempel",
    Tip: "Tip",
    Title: "Titel",
    "Title required": "Titel er påkrævet",
    "Title too long": "Titel for lang",
    Thai: "Thailandsk",
    "Thanks for your answer": "Tak for dit svar",
    "Thank you for your interest in Edaptio.":
        "Tak for din interesse i Edaptio.",
    "Thank you for your understanding": "Tak for din forståelse",
    "That was all I got for you this time":
        "Det var alt, hvad jeg havde til dig denne gang",
    "The amount of random questions each learner receives":
        "Antallet af tilfældige spørgsmål, som hver lærende modtager",
    "The assignment is closed for handin": "Opgaven er lukket for aflevering",
    "The call owner denied your request to join":
        "Ejeren af videokaldet afviste din anmodning om at joine",
    "The corrections have been attached to the feedback":
        "Retterelserne er vedhæftet din tilbagemelding",
    "The elements have been added to the existing learning activity":
        "Elementerne er blevet tilføjet til den eksisterende læringsaktivitet",
    "The Geogebra element has been created":
        "Geogebra aktiviteten blev oprettet",
    "The Geogebra element has been saved": "Geogebra aktiviteten blev gemt",
    "The group is currently in exam mode. All communication suspended.":
        "Holdet er i øjeblikket i eksamenstilstand. Al kommunikation er suspenderet.",
    "The question has been created": "Spørgsmålet blev oprettet",
    "The question has been saved": "Spørgsmålet blev gemt",
    "The quiz has been created": "Quizzen blev oprettet",
    "The quiz has been saved": "Quizzen blev gemt",
    "The message was succesfully deleted": "Besked er nu slettet",
    "The price is too high": "Prisen er for høj",
    "The system cannot auto-correct open answer questions, but your teacher can see your answer.":
        "Systemet kan ikke automatisk rette åbne spørgsmål, men din underviser kan se dit svar.",
    "The system rejected the request with the following message":
        "Systemet afviste operationen med følgende besked",
    "The user has received an email with all the necessary information to get started":
        "Brugeren har modtaget en mail med alle de relevante informationer til at komme i gang.",
    "The user will write an open ended text here":
        "Brugeren vil skrive et åbent svar her",
    "There are no answers posted. Add a first answer to this post.":
        "Der er ingen svar på dette opslag endnu. Vær den første til at svare.",
    "There are no existing data to display. Start by creating one direct":
        "Der er ikke nogen {name} at vise. Du kan oprette en ny i sidemenuen",
    "There are no items to display": "Der er intet at vise her",
    "There are no learning elements in this category to display. Start by creating one":
        "Der er ingen læringsaktiviteter at vise i denne kategori. Start med at oprette et!",
    "There are no lessons to display. Start by creating one":
        "Ingen lektioner fundet. Start med at oprette en",
    "There are no consultations created. Start by creating one":
        "Du har ikke oprettet en konsultation endnu. Start ved at oprette en",
    "There are no submissions to display. Start by creating one":
        "Der er ingen afleveringer at vise. Start med at oprette en ny",
    "There are unsaved changes": "Der er ændringer som ikke er gemt",
    "There is currently an error with the WIRIS plugin, which is why we have removed it. We are working on resolving it.":
        "Der er i øjeblikket en fejl med WIRIS pluginet, og vi har fjernet det. Vi arbejder hårdt på at få det løst.",
    "This course is a reference from another group, and can therefore not be edited here.":
        "Dette forløb er en reference til et andet sted, og kan derfor ikke rettes her.",
    "This course is depending on the other courses. Students can not start it until they complete the other.":
        "Dette forløb er afhængigt af andre forløb. Brugere kan ikke starte dette forløb før de færddiggør de andre forløb.",
    "This course has been copied to this group before.":
        "Dette forløb er blevet kopieret til dette hold før.",
    "This element has been copied to this group before.":
        "Dette element er blevet kopieret til dette hold før.",
    "This lesson has been copied to this group before.":
        "Denne lektion er blevet kopieret til dette hold før.",
    "This group does not have a class notebook yet. Contact your teacher in order to fix this.":
        "Dette hold har ikke en klassenotesbog endnu. Kontakt din underviser for at fikse det.",
    "this guide": "denne guide",
    "This is a beta feature, and errors can occur":
        "Dette er en eksperimentel funktion, og fejl kan opstå.",
    "This is a collection of all the videos from your courses":
        "Dette er en samling af alle videoerne fra dine forløb",
    "This is the number of learners not covered in your pre-paid plan. These will be charged separately according to our pricing plan.":
        "Dette er antallet af brugere uden for din forudbetalte plan. Dette antal bliver faktureret seperat ud fra vores pristabel.",
    "This is an open answer question. This means that you have to reflect on the question, and you have to write an answer to complete it.":
        "Dette er et 'åbent svar' spørgsmål. Det betyder, at du skal reflektere og svare med dine egne ord, og du skal skrive noget for at fuldføre det.",
    "This is an overview of the students progression through the videos in the courses/materials.":
        "Dette er et overblik over brugerens progression igennem videoerne i forløbene/lektionerne.",
    "This lesson is a reference from another group, and can therefore not be edited here.":
        "Denne lektion er en reference til et andet sted, og kan derfor ikke rettes her.",
    "This lesson is empty.": "Denne lektion er tom.",
    "This message was deleted": "Besked er nu slettet",
    "This object is empty": "{name} er tom" /* Translate with param */,
    "This quiz type requires an active subscription to Maple TA, which you need to obtain yourself.":
        "Denne quiztype kræver en aktiv licens til Maple TA's software, som du selv/din institution står for at anskaffe.",
    "This widget imports all of your courses automatically":
        "Denne widget importerer automatisk alle dine forløb i rækkefølge",
    "This will hold news from your classes":
        "Her vil der komme nyheder på tværs af dine hold",
    "Three quarter": "Trekvart",
    to: "til",
    To: "Til",
    Today: "Idag",
    "Toggle fullscreen mode": "Gå til fuldskærm",
    Top: "Top",
    Topic: "Underemne",
    Topics: "Underemner",
    "to join your class.": "for at tilmelde sig holdet",
    "To top": "Til top",
    Total: "Total",
    "Toggle fullscreen": "Skift fuldskærm",
    "Total number of assignment turnins": "Samlet antal af opgavebesvarelser",
    Trainable: "Muligt at træne",
    "Train mathematics": "Træn matematik",
    Translate: "Oversæt",
    "Translate word": "Oversæt ord",
    Tries: "Forsøg",
    Try: "Forsøg",
    "Try again": "Prøv igen",
    "Try Edaptio": "Prøv Edaptio",
    "Try now": "Prøv nu",
    "Try to adjust the text": "Prøv at justere din tekst",
    Turkish: "Tyrkisk",
    "Turnin overview": "Afleveringsoversigt",
    "Turn in quiz": "Aflever quiz",
    "Turn on/off exam mode": "Slå eksamenstilstand til/fra",
    "Turn video off": "Slå video fra",
    "Turn video on": "Slå video til",
    Type: "Type",
    "Type in a definition": "Indsæt definition",
    "Type in a question": "Skriv et spørgsmål",
    "Type in a term": "Indsæt udtryk",
    "Type in an answer": "Skriv et svar",
    "Type in your comment": "Skriv din kommentar",
    "Type in your message": "Skriv besked her",
    "Type of billing": "Faktureringstype",
    "Type something": "Skriv noget",
    "UK English": "Engelsk (UK)",
    UMS: "UMS",
    "UMS group": "UMS hold",
    Unarchive: "Fjern fra arkiv",
    "Unarchive group": "Fjern hold fra arkiv",
    "Unarchive for all": "Fjern fra arkiv for alle",
    "Unarchive for you": "Fjern fra arkiv for kun dig",
    Unassign: "Fjern tildeling",
    Unilogin: "Unilogin",
    "Unilogin username": "Unilogin brugernavn",
    Unknown: "Ukendt",
    "Unlimited active learners": "Ubegrænset antal aktive brugere",
    "Unlimited classes, courses & lessons":
        "Ubegrænset hold, forløb og lektioner",
    "Unlimited co-teachers": "Ubegrænset antal medundervisere",
    "Unlimited storage": "Ubegrænset lagerplads",
    "Unlock board": "Lås opslagstavle op",
    "Unlock board item": "Lås opslag op",
    Unmute: "Slå lyd til",
    Unmuted: "Mic på",
    Unpin: "Frigør",
    "Unpin post": "Frigør opslag",
    "Unpin side menu": "Gør sidemenu flydende",
    "Unread messages": "Ulæste",
    Unsubscribe: "Opsig",
    "Up to 100 active learners": "Op til 100 aktive studerende",
    "Up to 250 active learners": "Op til 250 aktive studerende",
    "Upcoming consultations": "Kommende konsultationer",
    "Update profile picture": "Opdater profilbillede",
    "Update and log in": "Opdatér og log in",
    "updated the assignment": "opdaterede afleveringen",
    Upgrade: "Opgrader",
    "Upgrade plan": "Opgrader",
    "Upgrade your plan": "Opgrader din plan",
    "Upgrade your plan to increase the storage space":
        "Opgrader dit abonnement for at få mere lagerplads",
    Upload: "Upload",
    "Upload an example submission that yours students will see after they receive your feedback for the submission":
        "Upload et eksempel på en besvarelse, som dine elever automatisk kan se efter du har givet feedback",
    "Upload feedback": "Upload feedback",
    "Upload new file": "Upload ny fil",
    Uploads: "Uploaded",
    "Upper border": "Øvre grænse",
    "Use 0 if you want to disable it":
        "Brug 0 hvis du vil slå beståelsesprocent fra",
    "Use in group": "Brug på hold",
    "Use course in group": "Brug forløb på hold",
    "Use hashtags before the words in the text that need to be filled in.":
        "Brug hashtags {insertion} før de ord i teksten, der skal udfyldes." /* Translate with param */,
    "Use hashtags to indicate word insertions":
        "Brug hashtags til at oprette felter til indsættelse af ord.",
    "Use learning element in group": "Brug læringsobjekt på hold",
    "Use lessons to divide your courses into smaller bites, that are easier for users to digest. Lessons are packages of learning elements that provide context to the learning the user has to obtain.":
        "Brug lektioner til at inddele dine forløb i mindre, mere spiselige bidder. Lektioner er pakker af læringslemeneter, som giver kontekst til den læring som brugeren skal igennem.",
    "Use material in group": "Brug lektion på hold",
    "Use the arrow keys to switch and flip the cards":
        "Brug pilepasterne på tastaturet til at skifte- og vende flashkort",
    "Use the feedback, improve your assignment and hand in again":
        "Brug feedbacken, finpuds opgaven og aflever igen.",
    "Use this in a group": "Brug dette på et hold",
    User: "Bruger",
    "User assignments behind": "Brugere som er bagud med afleveringer",
    "User assignment progression throughout courses":
        "Brugerprogression i afleveringer opdelt i forløb",
    "User created": "Bruger oprettet",
    "User creation": "Brugeroprettelse",
    "User is presented with a single random word":
        "Brugeren bliver præsenteret med et enkelt tilfældigt ord",
    "User is presented with all words":
        "Brugeren bliver præsenteret med alle ord fra listen",
    "User name": "Brugernavn",
    "User progression": "Brugerprogression",
    "User quiz progression": "Brugerprogression i quizzer",
    "User deleted": "Bruger slettet",
    "User saved": "Bruger gemt",
    "User was removed from conversation":
        "{user_name} blev fjernet fra samtalen {conversation_name}" /* Translate with params like in en_US file */,
    Users: "Brugere",
    "Users not yet started": "Brugere som ikke er startet endnu",
    "Users under average completion":
        "Brugere der ligger under gennemsnittet for holdet",
    Username: "Brugernavn",
    "US English": "Engelsk (US)",
    Validating: "Validerer",
    Value: "Værdi",
    "video-conference": "Videohjælp",
    Video: "Video",
    "Video chat": "Video chat",
    "Video chat does not exist or it's already finished.":
        "Video chatten kunne ikke findes.",
    "Video conferencing & messaging": "Videokonferencer og beskeder",
    "Video ID/link": "Video ID/link",
    "Video id required": "Video id påkrævet",
    "Video is off": "Video slukket",
    "Video is on": "Video tændt",
    videos: "Videoer",
    "Video preview": "Video forhåndsvisning",
    "Video quiz": "Video quiz",
    Vietnamese: "Vietnamesisk",
    View: "Vis",
    "Viewing as a student": "Viser som en studerende",
    "Viewing as a teacher": "Viser som en underviser",
    "View messages": "Vis meddelelser ",
    Vimeo: "Vimeo",
    "Vimeo video ID/Link": "Vimeo video ID/Link",
    "Visible to students": "Synlig for brugere",
    "Visual arts": "Billedkunst",
    "Voice gender": "Køn på stemme",
    "wants to join": "vil gerne ind",
    "wants to join video chat": "vil gerne deltage i video chat",
    webinars: "webinar",
    weekdays: {
        Sunday: "Søndag",
        Monday: "Mandag",
        Tuesday: "Tirsdag",
        Wednesday: "Onsdag",
        Thursday: "Torsdag",
        Friday: "Fredag",
        Saturday: "Lørdag"
    },
    "Weekdays-full-array": [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag"
    ],
    "Weekdays-short-array": ["Søn", "Man", "Tirs", "Ons", "Tors", "Fre", "Lør"],
    Welsh: "Walisisk",
    "We are preparing your file for embed, make sure to refresh the page in a little while. This should take no more than 2 minutes.":
        "Vi er ved at forberede din fil til indlejring. Genindlæs siden om et lille stykke tid for at se resultatet. Dette bør ikke tage mere end 2 minutter.",
    "We can not find any data to display. Wait for your teacher to add some.":
        "Vi kan ikke finde nogen {insertion} at vise. Vent på at underviseren tilføjer det." /* Translate with param like in en_US file */,
    "We can not find any data to display. Wait for your teacher to create submission.":
        "Vi kan ikke finde nogen {insertion} at vise. Vent på at din underviser tilføjer det." /* Translate with param like in en_US file */,
    "We can not find any data to display. Wait for your teacher to upload some.":
        "Vi kan ikke finde nogen {insertion} at vise. Vent på at underviseren uploader det." /* Translate with param like in en_US file */,
    "We can not find any lesson in course. Wait for your teacher to add some.":
        "Vi kan ikke finde nogen lektioner i '{insertion}' forløbet. Vent på at din underviser opretter dem." /* Translate with param */,
    "We can see, that you are using Internet Explorer to browse the web. This is an old, outdated and insecure browser, which is why we have chosen not to support it.":
        "Vi kan se, at du bruger Internet Explorer til at gå på internettet. Dette er en gammel og usikker browser, som vi har valgt ikke længere at supportere. ",
    "We have sent an email to the provided email address, if it exists in the system. Remember to check your spam filter.":
        "Vi har sendt en email til mail adressen hvis de eksisterer i vores system. Husk at tjekke dit spam filter.",
    "We have sent you a temporary password via email":
        "Vi har sendt dig et midlertidigt kodeord via email",
    "We sent a verification link to":
        "Vi har sendt et bekræftelseslink til {email}",
    "Welcome to": "Velkommen til",
    "What are you?": "Hvad er du?",
    "What do you want to create?": "Hvad ønsker du at skabe?",
    "What educational level?": "Hvilket klassetrin?",
    "What media should the users turn in?":
        "Hvilken type medie skal afleveres?",
    "What skills level?": "Hvilket niveau?",
    "What type of assignment?": "Hvilken type opgave?",
    "What would you like to learn about?": "Hvad vil du gerne lære om?",
    "When is the assignment due?": "Hvornår skal opgaven afleveres?",
    "Who are you?": "Hvem er du?",
    "WIRIS match": "WIRIS match",
    "WIRIS multiple choice": "WIRIS multiple choice",
    "WIRIS short answer": "WIRIS kortsvar",
    "Write your answer": "Skriv svar",
    "Write comment": "Skriv kommentar",
    "Write description": "Skriv beskrivelse",
    "Write here": "Skriv her",
    "Write message": "Skriv besked",
    "Write the code from the marked area below in the text field, to confirm deletion":
        "Skriv koden fra det markerede område ind i tekstfeltet, for at bekræfte sletningen",
    "Write what you hear": "Skriv hvad du hører",
    "Wrong email": "Forkert email",
    "Wrong username/password": "Forkert brugernavn/kodeord",
    "Year/date/title": "År/data/titel",
    Yearly: "Årlig",
    "yearly total": "årligt totalt",
    "Yearly payment": "Årlig betaling",
    Yes: "Ja",
    "Yes, archive this group": "Ja, arkivér dette hold",
    "Yes, delete this assignment": "Ja, slet denne opgave",
    "Yes, delete this collection": "Ja, slet denne kollektion",
    "Yes, delete this feedback": "Ja, slet dette feedback",
    "Yes, delete this file": "Ja, slet denne fil",
    "Yes, delete this folder": "Ja, slet denne mappe",
    "Yes, delete this Geogebra element": "Ja, slet denne Geogebra aktivitet",
    "Yes, delete this group": "Ja, slet dette hold",
    "Yes, delete this group information": "Ja, slet denne information",
    "Yes, delete this post": "Ja, slet dette opslag",
    "Yes, delete this quiz": "Ja, slet denne quiz",
    "Yes, delete this question": "Ja, slet dette spørgsmål",
    "Yes, delete this user": "Ja, slet denne bruger",
    "Yes, everyone can use this": "Ja, alle må bruge dette",
    "Yes, turn in this questionnaire": "Ja, aflever dette spørgeskema",
    "Yes, turn in this quiz": "Ja, aflever denne quiz",
    "Yes, remove this course": "Ja, fjern dette forløb",
    "Yes, remove this learning element": "Ja, fjern denne læringsaktivitet",
    "Yes, remove this material": "Ja, fjern denne lektion",
    "Yes, remove this member": "Ja, fjern dette medlem",
    "Yes, start video call": "Ja, start video kald",
    Yesterday: "Igår",
    You: "Du",
    "You have finished this quiz! You got percent correct.":
        "Du har fuldført quizzen! Du fik {insertion} korrekt.",
    "Your assignment plan": "Din afleveringsplan",
    "Your assignment turnin": "Din opgaveaflevering",
    "Your classes": "Dine hold",
    "Your class has multiple sub-groups. Choose which one to view for.":
        "Dit hold har et eller flere underhold. Vælg hvilket et du vil se nedenfor.",
    "Your files have been uploaded and matched.":
        "Dine filer er blevet uploaded og matched med feedback.",
    "Your payment details": "Dine betalingsoplysninger",
    "Your plan": "Din plan",
    "Your streak": "Din streak",
    "Your subscription payment failed.": "Din betaling gik ikke igennem",
    "Your subscription plan": "Dit abonnement",
    Youtube: "Youtube",
    "Youtube search": "Søg YouTube",
    "Youtube video": "Youtube video",
    "YouTube video ID": "YouTube video ID",
    "You answered correct": "Du svarede korrekt",
    "You are currently in exam mode.": "Du er i øjeblikket i {insertion}.",
    "You are currently in exam mode. All communication suspended.":
        "Du er i øjeblikket i eksamenstilstand. Al kommunikation er suspenderet.",
    "You are in a waiting room": "Du er i venteværelset",
    "You are in training mode. Your answers are not recorded, and you get instant feedback. Start the quiz to complete it.":
        "Du er i træningsdelen af quizzen og dine svar bliver ikke gemt. Start quizzen for at gennemføre den.",
    "You are subscribing to": "Du er ved at tilmelde dig",
    "You are up!": "Det er din tur!",
    "You are using Internet Explorer to browse. We do not guarantee support for this, and some areas of the page may not work. We encourage you to switch to any other browser.":
        "Du bruger Internet Explorer til at tilgå vores side. Vi garanterer ikke support for denne browser, og dele af siden kan potentielt ikke virke. Vi anbefaler at skifte til en hvilken som helst anden browser.",
    "You can find the course in your group":
        "Du kan finde forløbet på dit hold",
    "You can find the element on the material":
        "Du kan finde læringsaktiviteten på lektionen",
    "You can find the material on the course":
        "Du kan finde lektionen på forløbet",
    "This can take up to a few minutes, so feel free to grab a cup of coffee or practice your juggling skills while you wait":
        "Dette kan tage op til et par minutter, så tag gerne en kop kaffe eller øv dig på din jonglering, mens du venter.",
    "You can not access your assignments here in this group. This will serve as an overview only.":
        "Du kan ikke tilgå dine afleveringer denne vej, på dette hold. Denne app vil kun være et overblik her.",
    "You can not enter the program this way.":
        "Du kan ikke komme ind denne vej.",
    "You can not have a larger question pool than number of questions attached":
        "Du kan ikke have en større spørgsmålspulje end antal spørgsmål tilknyttet",
    "You can't join this call": "Du kan ikke joine dette kald",
    "You can only add admins here": "Du kan kun tilføje undervisere her",
    "You can reject the assignment and force the student to hand in again":
        "Du kan afvise afleveringen og opfordre brugeren til at aflevere igen",
    "You can see this guide to get started":
        "Du kan se denne guide for at komme igang",
    "You did not choose all the correct answers":
        "Du valgte ikke alle de korrekte svar",
    "You do not have much time left for this assignment":
        "Du har ikke meget tid tilbage til denne opgave",
    "You have a good amount of time left for this assignment":
        "Du har fint med tid til denne opgave",
    "You have already handed this assignment in.":
        "Du har allerede afleveret denne opgave.",
    "You have answered all the questions in this topic. Keep training or go to the next topic.":
        "Du har svaret på alle spørgsmål i dette underemne. Bliv ved med at træne eller gå til næste underemne.",
    "You have assigned duplicate rows to represent the same value":
        "Du har angivet at flere rækker repræsenterer den samme værdi",
    "You have been invited to this class via email":
        "Du er bliver inviteret til dette hold via email",
    "You have created a new course": "Du har oprettet et nyt forløb",
    "You have created a new lesson": "Du har oprettet et nyt lektion",
    "You have missed the due date, or the assignment has been rejected":
        "Du har misset afleveringsdatoen for denne aflevering, eller den er blevet afvist.",
    "You have no messages yet, create your first message to talk to your friends":
        "Du har ingen beskeder endnu, opret din første besked for at tale med dine venner",
    "You have not yet completed the course(s) on which this course depends on":
        "Du har endnu ikke færdiggjort de forløb som dette forløb afhænger af",
    "You have run out of storage": "Du er løbet tør for lagerplads",
    "You have to have atleast one question for your quiz":
        "Du skal have mindst ét spørgsmål tilknyttet til din quiz",
    "You must add either a group or a person to the conversation":
        "Du skal enten tilføje mindst et hold eller en person",
    "You must choose minimum one answer": "Du skal vælge minimum ét svar her",
    "You must choose more than one answer":
        "Du skal vælge mere end ét svar her",
    "You must choose only one answer": "Du skal kun vælge ét svar her",
    "You only need to choose one answer": "Du skal kun vælge ét svar her",
    "You will automatically be added as admin in the group upon creation":
        "Du vil automatisk blive tilføjet som underviser på holdet når du opretter.",
    "You will need a computer for this part to work smoothly!":
        "Du skal bruge en computer, for at denne del kan fungere problemfrit!",
    "Your answer": "Dit svar",
    "Your answer(s)": "Dine svar",
    "Your answer percentage": "Din svarprocent",
    "Your answer was not correct": "Dit svar var ikke korrekt",
    "Your class library is empty, create your first class and start using Learnu":
        "Du har ingen hold på LearnU endnu. Opret dit første hold for at starte med at bruge LearnU",
    "Your groups": "Dine hold",
    "Your level": "Dit niveau",
    "Your nickname": "Dit kaldenavn",
    "Your submission": "Din opgaveaflevering",
    "Your token is not valid or expired. Try again.":
        "Dit  link er ikke godkendt, eller udløbet. Prøv igen.",
    "Want more features and discounts? Request a quote for your entire school or organization today.":
        "Vil du have mere funktionalitet og rabat? Kontakt os idag for en skræddersyet plan til din organisation.",
    "was archived by": "blev arkiveret af",
    "Waiting list": "Venteliste",
    "Waiting for others to arrive": "Venter på at andre ankommer",
    "Watch out": "Pas på",
    "We can not find any course in class. Your teacher should add some courses soon.":
        "Vi kunne ikke finde nogen forløb på holdet {className} endnu. Vent på at din underviser tilføjer forløb." /* Set param like in en_US */,
    "We can not find any *** to display. Wait for your teacher to create ***.":
        "Vi kunne ikke finde nogen {learningElement} på holdet endnu. Vent på at din underviser tilføjer {learningElement}." /* Set param like in en_US */,
    "We could not find matching information to the typed search phrase":
        "Vi kunne ikke finde oplysninger, der svarede til den indtastede søgefrase",
    "We could not log you in via uni-login. This was either because you are not in the system yet, or because the school does not have access to the system.":
        "Vi kunne ikke logge dig ind via uni-login. Dette er enten fordi du ikke er blevet synkroniseret endnu, eller også har din skole ikke adgang til systemet.",
    "What element would you like want to add?":
        "Hvilken aktivitet vil du gerne tilføje?",
    "White labeling": "White labeling",
    widget: "widget",
    "Wiris settings": "Wiris-indstillinger",
    Word: "Ord",
    Words: "Ord",
    "Words list": "Ordliste",
    "Wrong password": "Forkert kodeord"
};
