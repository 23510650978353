import Vue from 'vue'
import newsApi from '@/api/newsAPI'
import postApi from '@/api/postAPI'

export const newsStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        news: {},
        news_seen: {},
        user_news: [] // all news for frontpage
    },


    getters: {

        // getLoggedInUserInfo: state => {
        //   return state.user
        // }
    },


    actions: {

        /**
         * @desc Create a news post for a group
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Data to create post
         * @param {number} data.group_id
         * @param {string} data.post_text
         * @param {number} data.user_id
         * @param {Array} data.attachments
         * @return {Promise} response
         */
        async createNewPost(vx, data) {
            const {commit, rootState, state} = vx
            const {
                attachments,
                group_id,
                post_text,
                user_id,
            } = data

            const post_data = {
                attachments,
                group_id,
                parent_post: 0,
                post_text,
                user_id,
            }

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Create post
            const response = await postApi.createNewPost(post_data, isMockStudent)

            // Add post to state
            commit('ADD_NEWS_POST', {
                group_id,
                new_post: response.data,
            })

            return response
        },

        /**
         * @desc Delete a news post from a group
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Data to delete post
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @return {Promise} response
         */
        async deleteNewsPost(vx, data) {
            const {commit, rootState} = vx
            const {group_id, post_id} = data

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Delete post
            const response = await postApi.removePost(post_id, isMockStudent)

            // Remove post from state
            commit('DELETE_NEWS_POST', {
                group_id,
                post_id,
            })

            return response
        },

        /**
         * @desc Delete a news post comment from a group
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Data to delete comment
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @param {number} data.comment_id
         * @return {Promise} response
         */
        async deleteNewsPostComment(vx, data) {
            const {commit, rootState} = vx
            const {group_id, post_id, comment_id} = data

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Delete comment
            const response = await postApi.removePost(comment_id, isMockStudent)

            // Remove comment from state
            commit('DELETE_NEWS_POST_COMMENT', {
                group_id,
                post_id,
                comment_id,
            })

            return response
        },

        /**
         * @desc Edit a group news post
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Data to edit post
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @param {number} data.post_data
         * @return {Promise} response
         */
        async editPost(vx, data) {
            const {commit, rootState} = vx
            const {group_id, post_id, post_data} = data

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Edit post
            const response = await postApi.editPost(post_id, post_data, isMockStudent)

            // Update post in state
            commit('EDIT_NEWS_POST', {
                group_id,
                post_id,
                post_data,
            })

            return response
        },

        async pinPost({commit, state}, { post_id, group_id, pinned }) {
            const response = await postApi.pinPost({
                post_id: post_id,
                pinned : pinned
            })

            commit('SET_POST_PINNED', {
                post_id: post_id,
                group_id : group_id,
                pinned : pinned
            })

            return response

        },

        /**
         * @desc Edit a group news post
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Data to create comment
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @param {number} data.comment_text
         * @param {number} data.user_id
         * @return {Promise} response
         */
        async postNewComment(vx, data) {
            const {commit, rootState} = vx
            const {group_id, post_id, comment_text, user_id, group_news = true} = data

            const comment_data = {
                post_text: comment_text,
                parent_post: post_id,
                user_id,
                group_id,
            }

            // Check if user is a mock student
            const isMockStudent = rootState.groupStore.isMockStudent || false

            // Create comment
            const response = await postApi.createNewPost(comment_data, isMockStudent)

            // Add comment to state
            commit("ADD_NEWS_POST_COMMENT", {
                group_id,
                post_id,
                new_comment: response.data,
                group_news
            });

            return response
        },
        
        async editPostComment(vx, data) {
            const {commit} = vx
            const {comment_id, post_id, group_id, comment_text, user_id, group_news = true } = data
            const comment_data = {
                post_text: comment_text,
                parent_post: post_id,
                group_id,
                user_id
            }
            await postApi.editPost(comment_id, comment_data)
            commit("EDIT_POST_COMMENT", {
                group_id,
                post_id,
                comment_id,
                comment_text,
                group_news
            });
        },
        


        /**
         * Function for pulling group news
         */
        async pullAllGroupNews({commit, state}, {group_id}) {
            if (state.news[group_id] && state.news[group_id].length) {
                return state.news[group_id]
            } else {
                const response = await newsApi.fetchAllGroupNews(group_id)
                commit('SET_GROUP_NEWS', {
                    group_id: group_id,
                    news: response.data
                })
                return response
            }
        },

        /**
         * Function for pulling all news from a user for the front page
         */
        async pullAllUserNews({commit, state}, data) {
            const response = await newsApi.pullAllUserNews({ limit: data ? data.limit : null })
            commit('SET_USER_NEWS', {
                news: response.data
            })
            return response
        },

        async registerNewsSeen({commit, state}, {group_id}) {

            await newsApi.registerNewsSeen({
                group_id: group_id
            })

            const time = new Date().getTime()
            commit('REGISTER_NEWS_SEEN', {
                group_id: group_id,
                time: time
            })

            return 1
        },

        async setNewsSeen({commit, state}, {group_id, time}) {
            commit('REGISTER_NEWS_SEEN', {
                group_id: group_id,
                time: time
            })
        }

    },


    mutations: {

        ADD_NEWS_POST: (state, {group_id, new_post}) => {
            if (!state.news[group_id]) {
                state.news[group_id] = []
            }
            state.news[group_id].unshift(new_post)

            state.user_news.unshift(new_post)

        },

        EDIT_POST_COMMENT: (state, { group_id, post_id, comment_id, comment_text, group_news }) => {
            if (group_news) {
                if (!state.news[group_id]) return;
                const postIndex = state.news[
                    group_id
                ].findIndex(post => post.post_id === post_id);
                if (postIndex !== -1) {
                    const commentIndex = state.news[group_id][
                        postIndex
                    ].comments.findIndex(
                        comment =>
                            comment.post_id === comment_id
                    );
                    if (commentIndex !== -1) {
                        Vue.set(
                            state.news[group_id][postIndex][
                                "comments"
                            ][commentIndex],
                            "post_text",
                            comment_text
                        );
                    }
                }
            } else {
                if (!state.user_news) return;
                const postIndex = state.user_news.findIndex(post => post.post_id === post_id);
                if (postIndex !== -1) {
                    const commentIndex = state.user_news[
                        postIndex
                    ].comments.findIndex(
                        comment =>
                            comment.post_id === comment_id
                    );
                    if (commentIndex !== -1) {
                        Vue.set(
                            state.user_news[postIndex][
                                "comments"
                            ][commentIndex],
                            "post_text",
                            comment_text
                        );
                    }
                }
            }
            
        },

        ADD_NEWS_POST_COMMENT: (state, {group_id, post_id, new_comment, group_news}) => {
            // locate comment
            if (group_news) {
                state.news[group_id].map((post, post_index) => {
                    if (
                        parseInt(post["post_id"]) ===
                        parseInt(post_id)
                    ) {
                        if (
                            !state.news[group_id][post_index][
                                "comments"
                            ]
                        ) {
                            state.news[group_id][post_index][
                                "comments"
                            ] = [];
                        }
                        state.news[group_id][post_index][
                            "comments"
                        ].push(new_comment);
                    }
                });
            } else {
                state.user_news.map((post, post_index) => {
                    if (
                        parseInt(post["post_id"]) ===
                        parseInt(post_id)
                    ) {
                        if (
                            !state.user_news[
                                post_index
                            ]["comments"]
                        ) {
                            state.user_news[post_index][
                                "comments"
                            ] = [];
                        }
                        state.user_news[
                            post_index
                        ]["comments"].push(new_comment);
                    }
                });
            }

        },

        DELETE_NEWS_POST: (state, {group_id, post_id}) => {
            // locate comment
            if (state.news && state.news[group_id]) {
                state.news[group_id].map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        Vue.delete(state.news[group_id], post_index)
                    }
                })
            }

            if (state.user_news) {
                state.user_news.map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        Vue.delete(state.user_news, post_index)
                    }
                })
            }

        },

        DELETE_NEWS_POST_COMMENT: (state, {group_id, post_id, comment_id}) => {
            // locate comment
            if (state.news && state.news[group_id]) {
                state.news[group_id].map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        if (state.news[group_id][post_index]['comments'] && state.news[group_id][post_index]) {
                            state.news[group_id][post_index]['comments'].map((comment, comment_index) => {
                                if (parseInt(comment['post_id']) === parseInt(comment_id)) {
                                    Vue.delete(state.news[group_id][post_index]['comments'], comment_index)
                                }
                            })
                        }
                    }
                })
            }

            if (state.user_news) {
                state.user_news.map((post, post_index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        if (state.user_news[post_index]['comments'] && state.user_news[post_index]) {
                            state.user_news[post_index]['comments'].map((comment, comment_index) => {
                                if (parseInt(comment['post_id']) === parseInt(comment_id)) {
                                    Vue.delete(state.user_news[post_index]['comments'], comment_index)
                                }
                            })
                        }
                    }
                })
            }

        },

        EDIT_NEWS_POST: (state, {group_id, post_id, post_data}) => {
            // locate comment
            if (state.news && state.news[group_id]) { 
                const post_index = state.news[group_id].findIndex(post => parseInt(post.post_id) === parseInt(post_id))
                if (post_index !== -1) {
                  const post = { ...state.news[group_id][post_index], ...post_data }
                  Vue.set(state.news[group_id], post_index, post)
                }
            }
            if(state.user_news) { 
                const post_index = state.user_news.findIndex(post => parseInt(post.post_id) === parseInt(post_id))
                if (post_index !== -1) {
                  const post = { ...state.user_news[post_index], ...post_data }
                  Vue.set(state.user_news, post_index, post)
                }
            }

        },

        REGISTER_NEWS_SEEN: (state, {group_id, time}) => {
            Vue.set(state.news_seen, group_id, time)
        },

        SET_GROUP_NEWS: (state, {group_id, news}) => {
            Vue.set(state.news, group_id, news)
        },

        SET_POST_PINNED: (state, { post_id, group_id, pinned }) => {
            if (Object.entries(state.news).length > 0) {
                if (state.news[group_id]) {
                    state.news[group_id].map((post, index) => {
                        if (parseInt(post['post_id']) === parseInt(post_id)) {
                            Vue.set(state.news[group_id][index], 'pinned', pinned)
                        }
                    })
                }
            }
            

            if (state.user_news) {
                state.user_news.map((post, index) => {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        Vue.set(state.user_news[index], 'pinned', pinned)
                        
                    }
                })
            }

        },

        SET_USER_NEWS: (state, {news}) => {
            Vue.set(state, 'user_news', news)
        },
    }
}
