import axios from 'axios'

export default {
    /**
     * Delete adaptive question
     * @param {Object} data - Adaptive question data
     * @param {number} data.question_id
     * @return {Promise} response
     */
    async deleteAdaptiveQuestion(data) {
        const { question_id } = data

        return await axios.delete(`/adaptive-test/questions/${question_id}`)
    },

    async getAdaptiveTestData({ test_id }) {
        return await axios.get(`/adaptive-tests/data/${test_id}`)
    },

    async getAllAdaptiveTests({ class_id=0, level_id=0 }) {
        return await axios.get(`/adaptive-tests/${class_id}/${level_id}`)
    },

    async getAllAdaptiveTestsFromTestId({ test_id }) {
        return await axios.get(`/adaptive-tests/${test_id}`)
    },

    /**
     * Get adaptive questions
     * @param {Object} data - Adaptive question data
     * @param {number} data.adaptive_class_id
     * @param {number} data.adaptive_level_id
     * @param {number} data.adaptive_subject_id
     * @param {number} data.adaptive_topic_id
     * @return {Promise} response
     */
    async getAdaptiveTestQuestions(data) {
        const {
            adaptive_class_id,
            adaptive_level_id,
            adaptive_subject_id,
            adaptive_topic_id,
        } = data

        return await axios.get(`/adaptive-test/questions/${adaptive_level_id}/${adaptive_class_id}/${adaptive_subject_id}/${adaptive_topic_id}`)
    },

    async getNextAdaptiveQuestion({ test_id, difficulty}) {
        return await axios.get(`/adaptive-test/question/next/${test_id}/${difficulty}`)
    },

    /**
     * Save adaptive question
     * @param {Object} data - Adaptive question data
     * @param {number} data.question_id
     * @param {number} data.adaptive_class_id
     * @param {number} data.adaptive_grade_id
     * @param {number} data.adaptive_level_id
     * @param {number} data.adaptive_subject_id
     * @param {number} data.adaptive_topic_id
     * @return {Promise} response
     */
    async saveAdaptiveQuestion(data) {
        const {
            question_id,
            adaptive_class_id,
            adaptive_grade_id,
            adaptive_level_id,
            adaptive_subject_id,
            adaptive_topic_id,
        } = data

        return await axios.post('/adaptive-test/questions', {
            question_id: parseInt(question_id),
            adaptive_class_id: parseInt(adaptive_class_id),
            adaptive_grade_id: parseInt(adaptive_grade_id),
            adaptive_level_id: parseInt(adaptive_level_id),
            adaptive_subject_id: parseInt(adaptive_subject_id),
            adaptive_topic_id: parseInt(adaptive_topic_id),
        })
    },

    async saveAdaptiveTestResults( { test_id, irt_score, latest_difficulty, standard_error, streak, avg_response_time, increment } ) {
        return await axios.put(`/adaptive-test/score/${test_id}`, {
            is : irt_score,
            ld : latest_difficulty,
            s : streak,
            se : standard_error,
            art : avg_response_time,
            increment : increment
        })
    },

    async saveAndValidateAdaptiveQuestionAnswer(question_data) {
        return await axios.post('/adaptive-test/question/evaluate', question_data)
    },

}
