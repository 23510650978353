import axios from 'axios'

const base_url = '/questionnaires'

export default {

    async createQuestion(question_data) {
        return await axios.post(`${base_url}/questions`, question_data)
    },

    async createQuestionnaire(questionnaire_data) {
        return await axios.post(base_url, questionnaire_data)
    },

  async fetchAllGroupQuestionnaires(group_id) {
        return await axios.get(`${base_url}/group/${group_id}`)
    },

    async getAllGroupQuestions(group_id) {
        return await axios.get(`${base_url}/questions/group/${group_id}`)
    },

    async fetchQuestion(question_id) {
        return await axios.get(`${base_url}/questions/${question_id}`)
    },

    async fetchQuestionForTest(question_id) {
        return await axios.get(`${base_url}/questions/test/${question_id}`)
    },

    async fetchQuestionnaireData(questionnaire_id) {
        return await axios.get(`${base_url}/${questionnaire_id}`)
    },

    async fetchQuestionnaireResultData(questionnaire_id) {
        return await axios.get(`${base_url}/result/${questionnaire_id}`)
    },

    /**
     * @desc Register questionnaire start
     * @param {Object} data - Questionnaire data
     * @param {number} data.questionnaire_id
     * @param {boolean} isMockStudent 
     * @return {Promise} response
     */
    async registerQuestionnaireStarted(data, isMockStudent = false) {
      const { questionnaire_id } = data

      // Handle real user
      if (!isMockStudent) return await axios.put(`${base_url}/start/${questionnaire_id}`, {})

      // Mock response
      return {
        data: {
          questionnaire_started: Date.now() / 1000,
        },
      }
    },

    /**
     * @desc Register questionnaire end
     * @param {Object} data - Questionnaire data
     * @param {number} data.questionnaire_id
     * @param {boolean} isMockStudent 
     * @return {Promise} response
     */
    async registerQuestionnaireEnded(data, isMockStudent = false) {
      const { questionnaire_id } = data

      // Handle real user
      if (!isMockStudent) return await axios.put(`${base_url}/end/${questionnaire_id}`, {})

      // Mock response
      return {
        data: {
          questionnaire_ended: Date.now() / 1000,
        },
      }
    },

    async removeQuestion(question_id) {
        return await axios.delete(`${base_url}/question/${question_id}`)
    },

    async removeQuestionnaire(questionnaire_id) {
        return await axios.delete(`${base_url}/${questionnaire_id}`)
    },

    async saveQuestion(question_id, question_data) {
        return await axios.put(`${base_url}/questions/${question_id}`, question_data)
    },

    async saveQuestionnaire(questionnaire_id, questionnaire_data) {
        return await axios.put(`${base_url}/${questionnaire_id}`, questionnaire_data)
    },

    /**
     * @desc Save user questionnaire answer
     * @param {Object} data - Questionnaire data
     * @param {Object} data.question_data
     * @param {boolean} isMockStudent 
     * @return {Promise} response
     */
    async saveUserQuestionnaireAnswer(data, isMockStudent = false) {
      const { question_data } = data

      if (!isMockStudent) return await axios.post(`${base_url}/answer`, question_data)

      return {
        data: {
          ...question_data,
        },
      }
    },


}
