import talkApi from "@/api/talkAPI";
import chatAPI from "@/api/chatAPI";
const getTime = timestamp => {
    let h = new Date(timestamp).getHours();
    let m = new Date(timestamp).getMinutes();

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;

    return h + ":" + m;
};

export const talkStore = {
    namespaced: true,

    state: {
        app: "edaptio",
        rooms: [],
        room: null,
        joinToken: null,
        lastTouchedRoom: null,
        local: null,
        localName: null,
        participants: {},
        focusedParticipantId: null,
        localVideo: null,
        localAudio: null,
        knockingUsers: [],
        bannedFromKnockin: [],
        chatMessages: [],
        userColorMap: {},
        userHandUpMap: [],
        userEmojiMap: {},
        emojiRefresh: 1,
        localHandUp: false,
        mic: {},
        camera: {},
        speaker: {},
        nextOffset: "",
        participantsLoading: true,
        joinRoomToken: null
    },
    mutations: {
        setRooms(state, rooms) {
            state.rooms = rooms;
        },
        setRoom(state, room) {
            state.room = room;
        },
        setJoinToken(state, token) {
            state.joinToken = token;
        },
        setLastTouchedRoom(state, room) {
            state.lastTouchedRoom = room;
        },
        addRoom(state, room) {
            state.rooms.push(room);
        },
        replaceRoom(state, newRoom) {
            state.rooms = state.rooms.map(room =>
                room.id === newRoom.id ? newRoom : room
            );
        },
        deleteRoom(state, room) {
            const id = typeof room === "object" ? room.id : String(room);
            const index = state.rooms.findIndex(r => r.id === id);

            if (index !== -1) {
                state.rooms.splice(index, 1);
            }
        },
        setLocalParticipant(state, data) {
            state.local = data;
        },
        setParticipants(state, data) {
            state.participants = data;
        },
        setParticipantsLoading(state, data) {
            state.participantsLoading = data;
        },
        setParticipantData(state, data) {
            let allParticipants = { ...state.participants, local: state.local };
            Object.keys(allParticipants).map(key => {
                if (allParticipants[key].user_id === data.id) {
                    state.participants[key][data.attribute] = data.value;
                }
            });
        },
        saveLocalName(state, data) {
            state.localName = data;
        },
        setFocusedParticipantId(state, id) {
            state.focusedParticipantId = id;
        },
        setLocalName(state, name) {
            state.local.user_name = name;
        },
        setLocalAudio(state, data) {
            state.localAudio = data;
        },
        setLocalVideo(state, data) {
            state.localVideo = data;
        },
        setKnokingUser(state, data) {
            if (
                !state.knockingUsers.filter(
                    user => user && user.userId == data.userId
                ).length
            ) {
                state.knockingUsers = [...state.knockingUsers, data];
            }
        },
        deleteUserFromKnockingList(state, userId) {
            state.knockingUsers = state.knockingUsers.filter(user => {
                if (user && user.userId !== userId) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        pushMessageToChat(state, message) {
            if (
                !Object.keys(state.userColorMap).includes(message.data.senderId)
            ) {
                state.userColorMap[message.data.senderId] =
                    Object.keys(state.userColorMap).length + 1;
            }
            state.chatMessages = [
                ...state.chatMessages,
                {
                    ...message,
                    color: +state.userColorMap[message.data.senderId] % 2 == 0,
                    time: getTime(message.data.timestamp)
                }
            ];
        },
        fetchMessages(state, data) {
            const { messages, nextOffset } = data;
            let chatMessages = messages.filter(
                message => !Object.keys(message.attributes.extra).length
            );
            chatMessages = chatMessages.map(item => {
                if (
                    !Object.keys(state.userColorMap).includes(
                        item.attributes.senderId
                    )
                ) {
                    state.userColorMap[item.attributes.senderId] =
                        Object.keys(state.userColorMap).length + 1;
                }
                return {
                    data: { ...item.attributes },
                    color:
                        +state.userColorMap[item.attributes.senderId] % 2 == 0,
                    time: getTime(item.attributes.timestamp)
                };
            });
            state.nextOffset = nextOffset !== null ? nextOffset : "dontLoad";
            state.chatMessages = [
                ...chatMessages.reverse(),
                ...state.chatMessages
            ];
        },
        setHandUp(state, message) {
            if (
                !state.userHandUpMap.filter(
                    user => user && user.senderId == message.senderId
                ).length
            ) {
                state.userHandUpMap = [...state.userHandUpMap, message];
                if (message.senderId == state.local.user_id) {
                    state.localHandUp = true;
                }
            } else {
                state.userHandUpMap = state.userHandUpMap.map(user => {
                    if (user.senderId == message.senderId) {
                        user.extra.handupTimestamp =
                            message.extra.handupTimestamp;
                        user.extra.value = message.extra.value;
                        if (message.senderId == state.local.user_id) {
                            state.localHandUp = message.extra.value;
                        }
                    }
                    return user;
                });
            }
        },
        setEmoji(state, message) {
            state.emojiRefresh += 1;
            state.userEmojiMap[message.senderId] = {
                ...message,
                show: true,
                emoji: message.text
            };
        },
        setEmojiShowState(state, message) {
            state.emojiRefresh += 1;
            state.userEmojiMap[message.senderId] = {
                ...message,
                show: false,
                emoji: message.text
            };
        },
        fetchRoomData(state, message) {
            const {
                userHandUpMap,
                bannedFromKnockin,
                knockingUsers,
                userColorMap,
                focusedParticipantId
            } = message.extra.roomData;
            if (
                userHandUpMap &&
                userHandUpMap.length > state.userHandUpMap.length
            ) {
                state.userHandUpMap = userHandUpMap;
                state.userHandUpMap.map(user => {
                    user.userId === state.local.user_id &&
                        (state.localHandUp = true);
                });
            }
            if (
                bannedFromKnockin &&
                bannedFromKnockin.length > state.bannedFromKnockin.length
            ) {
                state.bannedFromKnockin = bannedFromKnockin;
            }
            if (
                knockingUsers &&
                knockingUsers.length > state.knockingUsers.length
            ) {
                state.knockingUsers = knockingUsers;
            }
            if (
                userColorMap &&
                Object.keys(userColorMap).length >
                    Object.keys(state.userColorMap).length
            ) {
                state.userColorMap = userColorMap;
            }
            state.focusedParticipantId = focusedParticipantId;
        },
        setLocalHandUp(state, data) {
            state.localHandUp = data;
        },
        setUserDevices(state, data) {
            state.mic = data.mic;
            state.camera = data.camera;
            state.speaker = data.speaker;
        },
        clearParticipantDataById(state, message) {
            state.userHandUpMap = state.userHandUpMap.filter(user => {
                return user.extra.userId !== message.extra.userId;
            });
            state.focusedParticipantId === message.extra.userId &&
                (state.focusedParticipantId = "");
            state.knockingUsers = state.knockingUsers.filter(
                user => user.userId !== message.extra.userId
            );
        },
        clearStateData(state) {
            state = {
                app: "edaptio",
                rooms: [],
                room: null,
                joinToken: null,
                lastTouchedRoom: null,
                local: null,
                localName: null,
                participants: {},
                focusedParticipantId: null,
                localVideo: null,
                localAudio: null,
                knockingUsers: [],
                bannedFromKnockin: [],
                chatMessages: [],
                userColorMap: {},
                userHandUpMap: [],
                userEmojiMap: {},
                emojiRefresh: 1,
                localHandUp: false,
                mic: {},
                camera: {},
                speaker: {},
                nextOffset: "",
                joinRoomToken: null
            };
        },
        setJoinRoomToken(state, data) {
            state.joinRoomToken = data.attributes.token
        }
    },
    actions: {
        async getRooms({ state, commit }) {
            commit(
                "setRooms",
                (await talkApi.getRooms({ app: state.app })).data.map(room => {
                    delete room.type;
                    return room;
                })
            );
        },
        async getRoom({ commit }, id) {
            const room = (await talkApi.getRoom(id)).data;
            delete room.type;

            commit("setRoom", room);
        },
        async roomsJoin({ commit }, id) {
            const room = (await talkApi.roomsJoin(id)).data;

            commit("setJoinRoomToken", room);
        },
        async createRoom({ commit }, attributes) {
            const room = (await talkApi.createRoom(attributes)).data;
            delete room.type;

            commit("setLastTouchedRoom", room);
            commit("addRoom", room);
            return room;
        },
        async updateRoom({ commit }, { id, attributes }) {
            const room = (await talkApi.updateRoom(id, attributes)).data;
            delete room.type;

            commit("setLastTouchedRoom", room);
            commit("replaceRoom", room);
        },
        async deleteRoom({ commit }, id) {
            await talkApi.deleteRoom(id);
            commit("deleteRoom", id);
        },
        async joinRoom({ commit, state }, data) {
            commit(
                "setJoinToken",
                (await talkApi.joinRoom(
                    data.id,
                    state.localName,
                    state.localAudio,
                    state.localVideo,
                    data.userId
                )).data.attributes.token
            );
        },
        async sendMessage(dx, message_data) {
            const { commit, state } = dx;
            const { channel, data } = message_data;

            await chatAPI.sendChatMessage({
                channel: channel,
                data: data
            });
        },
        async letUserJoin({ state }, id) {
            await talkApi.updateRoom(state.room.id, {
                members: { add: [{ user: id }] }
            });
        },
        async removeUserFromRoom({ state }, id) {
            await talkApi.updateRoom(state.room.id, {
                members: { remove: [{ user: id }] }
            });
        },
        async getChatMessages(dx, data) {
            const { commit, state } = dx;
            const { channel, limit = 50, nextOffset = "" } = data;

            const response = await chatAPI.getChatMessages(data);
            const messages = response.data.data;
            commit("fetchMessages", {
                messages,
                nextOffset: response.data.meta.pagination.nextOffset
            });

            // return response.data
        }
    }
};
