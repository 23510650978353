import axios from 'axios'

export default {

    async fetchAllGroupConferencePosts(group_id) {
        return await axios.get(`/conference/group/${group_id}`)
    },

    async fetchConferencePostsAnswers({ post_id }) {
        return await axios.get(`/conference/answers/${post_id}`)
    },

    /**
     * @desc Register conference seen
     * @param {Object} data - Conference data
     * @param {number} data.group_id
     * @param {number} data.post_id
     * @param {boolean} isMockStudent
     * @return {Promise} response
     */
    async registerConferenceSeen(data, isMockStudent = false) {
      const { group_id, post_id = 0 } = data

      // Handle real user
      if (!isMockStudent) {
        return await axios.put(`/conference/seen/${group_id}`, {
          post_id,
        })
      }

      // Mock response
      return {}
    },


}
