import axios from 'axios'

export default {

    async createGeogebraElement(element_data) {
        return await axios.post('/geogebra', element_data)
    },

    async fetchGeogebraElements(group_id) {
        return await axios.get(`/geogebra/group/${group_id}`)
    },

    async removeGeogebraElement(interactive_id) {
        return await axios.delete(`/geogebra/${interactive_id}`)
    },

    async saveGeogebraElement({ interactive_id, element_data }) {
        return await axios.put(`/geogebra/${interactive_id}`, element_data)
    },


}
