import Vue from 'vue'
import statisticsApi from '@/api/statisticsAPI'

export const statisticsStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
        assignments_matrix_data : {},
        latest_group_id : 0,
        latest_sub_group_id : 0,
        quiz_matrix_data : {},
        quizzes_statistics : {},
        courses_progression_data : {},
        lessons_progression_data: {},
        elements_progression_data: {},
        users_assignments_behind : {}
    },


    getters: {},


    actions : {


        /*
  * Function for getting data for quiz matrix in group
  */
        async getGroupActivityAssignmentsMatrixData ({ commit, state }, { group_id, sub_group_id, course_id=0, lesson_id=0 }) {
            group_id = parseInt(group_id)
            sub_group_id = parseInt(sub_group_id)
            if (state.assignments_matrix_data[group_id] &&
                state.assignments_matrix_data[group_id][sub_group_id] &&
                state.assignments_matrix_data[group_id][sub_group_id][course_id] &&
                state.assignments_matrix_data[group_id][sub_group_id][course_id][lesson_id]) {
                return state.assignments_matrix_data[group_id][sub_group_id][course_id][lesson_id]
            } else { 

                const response = await statisticsApi.getGroupActivityAssignmentsMatrixData({
                    group_id : group_id,
                    sub_group_id : sub_group_id,
                    course_id : course_id,
                    lesson_id : lesson_id,
                })

                commit("SET_ASSIGNMENTS_MATRIX_DATA", {
                    group_id : group_id,
                    sub_group_id : sub_group_id,
                    course_id : course_id,
                    lesson_id : lesson_id,
                    data : response.data
                })

                return response.data
            }
            
        },

        /*
        * Function for getting data for quiz matrix in group
        */
        async getGroupActivityQuizMatrixData ({ commit, state }, { group_id, sub_group_id, course_id=0, lesson_id=0 }) {
            group_id = parseInt(group_id)
            sub_group_id = parseInt(sub_group_id)
            if (state.quiz_matrix_data[group_id] &&
                state.quiz_matrix_data[group_id][sub_group_id] &&
                state.quiz_matrix_data[group_id][sub_group_id][course_id] &&
                state.quiz_matrix_data[group_id][sub_group_id][course_id][lesson_id]) {
                return state.quiz_matrix_data[group_id][sub_group_id][course_id][lesson_id]
            } else {

                const response = await statisticsApi.getGroupActivityQuizMatrixData({
                    group_id : group_id,
                    sub_group_id : sub_group_id,
                    course_id : course_id,
                    lesson_id : lesson_id,
                })

                commit("SET_QUIZ_MATRIX_DATA", {
                    group_id : group_id,
                    sub_group_id : sub_group_id,
                    course_id : course_id,
                    lesson_id : lesson_id,
                    data : response.data
                })

                return response.data
            }

        },

        /*
        * Function for getting data for quiz matrix in group
        */
        async getGroupActivityUserCoursesProgressionData ({ commit, state }, { group_id, sub_group_id }) {
            group_id = parseInt(group_id)
            sub_group_id = parseInt(sub_group_id)
            if ((state.latest_group_id === group_id) && (state.latest_sub_group_id === sub_group_id) ) {
                return state.courses_progression_data[group_id][sub_group_id]
            } else {

                const response = await statisticsApi.getGroupActivityCoursesProgressionData({
                    group_id : group_id,
                    sub_group_id : sub_group_id
                })

                commit("SET_COURSES_PROGRESSION_DATA", {
                    group_id : group_id,
                    sub_group_id : sub_group_id,
                    data : response.data
                })

                return response.data

            }
        },

         async getGroupActivityLessonsProgressionData({ commit }, { group_id, course_id }) {
            group_id = parseInt(group_id)
            course_id = parseInt(course_id)

                const response = await statisticsApi.getGroupActivityLessonsProgressionData({
                    group_id: group_id,
                    course_id : course_id
                })

                commit("SET_LESSONS_PROGRESSION_DATA", {
                    group_id : group_id,
                    course_id : course_id,
                    data: response.data
                })

                return response.data
        },

        async getGroupActivityElementsProgressionData({ commit }, { group_id, lesson_id }) {
            group_id = parseInt(group_id)
            lesson_id = parseInt(lesson_id)

                const response = await statisticsApi.getGroupActivityElementsProgressionData({
                    group_id: group_id,
                    lesson_id : lesson_id
                })

                commit("SET_ELEMENTS_PROGRESSION_DATA", {
                    group_id : group_id,
                    lesson_id : lesson_id,
                    data: response.data
                })

                return response.data
        },

        /*
        * Function for getting data for quiz matrix in group
        */
        async getGroupStatisticsQuizzesData ({ commit, state }, { group_id, sub_group_id }) {
            group_id = parseInt(group_id)
            sub_group_id = parseInt(sub_group_id)
            if (state.quizzes_statistics[group_id] &&state.quizzes_statistics[group_id][sub_group_id] && (state.latest_group_id === group_id) && (state.latest_sub_group_id === sub_group_id) ) {
                return state.quizzes_statistics[group_id][sub_group_id]
            } else {

                const response = await statisticsApi.getGroupStatisticsQuizzesData({
                    group_id : group_id,
                    sub_group_id : sub_group_id
                })

                commit("SET_QUIZZES_STATISTICS_DATA", {
                    group_id : group_id,
                    sub_group_id : sub_group_id,
                    data : response.data
                })

                return response.data

            }
        },


        async getUserAssignmentsBehindForGroup({ commit, state }, { group_id }) {

            if (state.users_assignments_behind[group_id] && state.users_assignments_behind[group_id].length > 5) {
                return state.users_assignments_behind[group_id]
            }

            const response = await statisticsApi.getUserAssignmentsBehindForGroup({
                group_id : group_id
            })

            commit('SET_USERS_ASSIGNMENTS_BEHIND', {
                group_id : group_id,
                users : response.data
            })

            return response.data
        },

    },


    mutations: {

        SET_ASSIGNMENTS_MATRIX_DATA: (state, { group_id, sub_group_id, course_id, lesson_id, data }) => {

            if (!state.assignments_matrix_data[group_id]) {
                Vue.set(state.assignments_matrix_data, group_id, {})
            }
            if (!state.assignments_matrix_data[group_id][sub_group_id]) {
                Vue.set(state.assignments_matrix_data[group_id], sub_group_id, {})
            }
            if (!state.assignments_matrix_data[group_id][sub_group_id][course_id]) {
                Vue.set(state.assignments_matrix_data[group_id][sub_group_id], course_id, {})
            }
            if (!state.assignments_matrix_data[group_id][sub_group_id][course_id][lesson_id]) {
                Vue.set(state.assignments_matrix_data[group_id][sub_group_id][course_id], lesson_id, {})
            }
            Vue.set(state.assignments_matrix_data[group_id][sub_group_id][course_id], lesson_id, data)
        },

        SET_QUIZ_MATRIX_DATA: (state, { group_id, sub_group_id, course_id, lesson_id, data }) => {

            if (!state.quiz_matrix_data[group_id]) {
                Vue.set(state.quiz_matrix_data, group_id, {})
            }
            if (!state.quiz_matrix_data[group_id][sub_group_id]) {
                Vue.set(state.quiz_matrix_data[group_id], sub_group_id, {})
            }
            if (!state.quiz_matrix_data[group_id][sub_group_id][course_id]) {
                Vue.set(state.quiz_matrix_data[group_id][sub_group_id], course_id, {})
            }
            if (!state.quiz_matrix_data[group_id][sub_group_id][course_id][lesson_id]) {
                Vue.set(state.quiz_matrix_data[group_id][sub_group_id][course_id], lesson_id, {})
            }
            Vue.set(state.quiz_matrix_data[group_id][sub_group_id][course_id], lesson_id, data)
        },

        SET_QUIZZES_STATISTICS_DATA: (state, { group_id, sub_group_id, data }) => {
            if (!state.quizzes_statistics[group_id]) {
                Vue.set(state.quizzes_statistics, group_id, {})
            }
            Vue.set(state.quizzes_statistics[group_id], sub_group_id, data)
        },

        SET_COURSES_PROGRESSION_DATA: (state, { group_id, sub_group_id, data }) => {

            // update latest ids
            Vue.set(state, 'latest_group_id', group_id)
            Vue.set(state, 'latest_sub_group_id', sub_group_id)

            Vue.set(state.courses_progression_data, group_id, { members : data.members })

            data.progression.map( (entry, index) => {
                if (!state.courses_progression_data[group_id][entry.user_id]) {
                    Vue.set(state.courses_progression_data[group_id], entry.user_id, {})
                }

                Vue.set(state.courses_progression_data[group_id][entry.user_id], entry.group_course_id, {
                    elements : entry.count_elements,
                    understood : entry.understood_count
                })
            })
        },

        SET_USERS_ASSIGNMENTS_BEHIND: (state, { group_id, users }) => {

            Vue.set(state.users_assignments_behind, group_id, users)

        },

        SET_LESSONS_PROGRESSION_DATA: (state, { group_id, course_id, data }) => {
            if (!state.lessons_progression_data[group_id]) {
                Vue.set(state.lessons_progression_data, group_id, {})
            }
            Vue.set(state.lessons_progression_data[group_id], course_id, data)

            data.progression.map( (entry, index) => {
                if (!state.lessons_progression_data[group_id][course_id][entry.user_id]) {
                    Vue.set(state.lessons_progression_data[group_id][course_id], entry.user_id, {})
                }

                Vue.set(state.lessons_progression_data[group_id][course_id][entry.user_id], entry.group_material_id, {
                    elements : entry.count_elements,
                    understood : entry.understood_count
                })
            })
        },

        SET_ELEMENTS_PROGRESSION_DATA: (state, { group_id, lesson_id, data }) => {
            if (!state.elements_progression_data[group_id]) {
                Vue.set(state.elements_progression_data, group_id, {})
            }
            Vue.set(state.elements_progression_data[group_id], lesson_id, data)

            data.progression.map( (entry, index) => {
                if (!state.elements_progression_data[group_id][lesson_id][entry.user_id]) {
                    Vue.set(state.elements_progression_data[group_id][lesson_id], entry.user_id, {})
                }

                Vue.set(state.elements_progression_data[group_id][lesson_id][entry.user_id], entry.group_material_element_id, {
                    elements : entry.count_elements,
                    understood : entry.understood_count
                })
            })
        },

    }
}
