import Vue from 'vue'
import conferenceApi from '@/api/conferenceAPI'
import postApi from '@/api/postAPI'

export const conferenceStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced  : true,

    state: {
        conference_seen : {},
        conference_posts_seen : {},
        posts : {},
        posts_obj: {},
        pagination: {
          page: 1,
          perPage: 6,
          totalPages: 1,
          sortBy: 'last_answered',
          descending: false,
          searchQuery: ""
        }
    },


    getters: {

    },


    actions : {

        /**
         * @desc Create conference post answer
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Conference data
         * @param {number} data.group_id
         * @param {string} data.post_text
         * @param {number} data.post_id
         * @param {number} data.user_id
         * @param {Array} data.attachments
         * @return {Promise} response
         */
        async addNewConferencePostAnswer (vx, data) {
          const { commit, rootState } = vx
          const { group_id, post_text, post_id, user_id, attachments } = data

          const post_data = {
            post_status: 'conference_post',
            parent_post: post_id,
            post_text,
            user_id,
            group_id,
            attachments,
          }

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Create conference post answer
          const response = await postApi.createNewPost(post_data, isMockStudent)

          // Add conference post answer to state
          commit('ADD_NEW_CONFERENCE_ANSWER', {
            answer: response.data,
            group_id,
            post_id,
          })

          return response
        },

        /**
         * @desc Create conference post
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Conference data
         * @param {number} data.group_id
         * @param {string} data.post_text
         * @param {number} data.user_id
         * @param {Array} data.attachments
         * @return {Promise} response
         */
        async createNewConferencePost(vx, data) {
          const { commit, rootState } = vx
          const { group_id, post_text, post_title, user_id, attachments } = data

          const post_data = {
            post_status: 'conference_post',
            parent_post: 0,
            post_text,
            post_title,
            user_id,
            group_id,
            attachments,
          }

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Create conference post
          const response = await postApi.createNewPost(post_data, isMockStudent)

          // Add conference post to state
          commit('ADD_CONFERENCE_POST', {
            new_post: response.data,
            group_id,
          })

          return response
        },

        /**
         * @desc Delete conference post
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Conference data
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @return {Promise} response
         */
        async deleteConferencePost(vx, data) {
          const { commit, rootState } = vx
          const { group_id, post_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Delete conference post
          const response = await postApi.removePost(post_id, isMockStudent)

          // Remove conference post from state
          commit('DELETE_CONFERENCE_POST', {
            group_id,
            post_id,
          })

          return response
        },

        /**
         * @desc Delete conference post answer
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Conference data
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @param {number} data.answer_id
         * @return {Promise} response
         */
        async deleteConferencePostAnswer(vx, data) {
          const { commit, rootState } = vx
          const { group_id, post_id, answer_id } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Delete conference post answer
          const response = await postApi.removePost(answer_id, isMockStudent)

          // Remove conference post answer from state
          commit('DELETE_CONFERENCE_POST_ANSWER', {
            group_id,
            post_id,
            answer_id,
          })

          return response
        },

        async editConferencePost({ commit, state }, { group_id, post_id, data }) {

            const api_response = await postApi.editPost(post_id, data)

            commit('EDIT_CONFERENCE_POST', {
                group_id : group_id,
                post_id : post_id,
                data : data
            })

            return api_response

        },

        async editConferencePostAnswer({ commit, state }, { post_id, answer_id, data }) {

            const api_response = await postApi.editPost(answer_id, data)

            commit('EDIT_CONFERENCE_POST_ANSWER', { post_id, answer_id, data })

            return api_response
        },

        /**
         * Function for pulling group news
         */
        async pullAllGroupConferencePosts({ commit, state }, { group_id }) {


            if (state.posts[group_id] && state.posts[group_id].length) {
                return state.posts[group_id]
            } else {

                const api_response = await conferenceApi.fetchAllGroupConferencePosts(group_id)

                commit('SET_CONFERENCE_POSTS', {
                    group_id : group_id,
                    posts : api_response.data
                })

                return api_response
            }

        },

        /**
        * Function for getting answers to a conference post
        */
        async pullConferencePostsAnswers({ commit, state }, { post_id }) {

            if (state.posts_obj[post_id] && state.posts_obj[post_id]['answers'] && state.posts_obj[post_id]['answers'].length) {
                return state.posts_obj[post_id]['answers']
            } else {

                const api_response = await conferenceApi.fetchConferencePostsAnswers({
                    post_id : post_id
                })

                let answers = api_response.data
                    commit('SET_CONFERENCE_POSTS_ANSWERS', {
                    post_id,
                    answers
                })

                return answers
            }

        },

        /**
         * @desc Register conference seen
         * @param {Object} vx - Vuex specific functions
         * @param {Object} data - Conference data
         * @param {number} data.group_id
         * @param {number} data.post_id
         * @return {Promise} response
         */
        async registerConferenceSeen(vx, data) {
          const { commit, rootState } = vx
          const { group_id, post_id = 0 } = data

          // Check if user is a mock student
          const isMockStudent = rootState.groupStore.isMockStudent || false

          // Register as seen
          const response = await conferenceApi.registerConferenceSeen({
            group_id,
            post_id,
          }, isMockStudent)

          const time = Date.now()

          // Update conference state
          commit('REGISTER_CONFERENCE_SEEN', {
            group_id,
            post_id,
            time,
          })

          return response
        },

        async setConferenceSeen({ commit, state }, { group_id, post_id, time }) {
            commit('REGISTER_CONFERENCE_SEEN', {
                group_id : group_id,
                post_id : post_id,
                time : time
            })
        },

    },


    mutations: {
      
        UPDATE_CONFERENCE_PAGINATION: (state, pagination) => {
            const currentPagination = state.pagination
            state.pagination = {...currentPagination, ...pagination}
        },

        ADD_CONFERENCE_POST: (state, { group_id, new_post }) => {
            const post = {...new_post, ...{last_answered: new_post.post_date, post_count: '0', answers: []}}
            state.posts[group_id].push(post)
            Vue.set(state.posts_obj, new_post.post_id, post)
        },

        ADD_NEW_CONFERENCE_ANSWER: (state, { group_id, post_id, answer }) => {

            if (
                state.posts_obj &&
                state.posts_obj[post_id] &&
                state.posts_obj[post_id]['answers']
            ) {
                state.posts_obj[post_id]['answers'].push(answer)
            }


            if (state.posts && state.posts[group_id]) {
                // increment post count on parent
                const posts = state.posts[group_id]
                for (let post of posts) {
                    if (parseInt(post['post_id']) === parseInt(post_id)) {
                        const post_index = state.posts[group_id].indexOf(post)
                        const count = parseInt(state.posts[group_id][post_index]['post_count'])
                        Vue.set(state.posts[group_id][post_index], 'post_count', (count+1) )
                    }
                }
            }

        },

        DELETE_CONFERENCE_POST: (state, { group_id, post_id }) => {
            // locate comment
            const posts = state.posts[group_id]
            for (let post of posts) {
                if (parseInt(post['post_id']) === parseInt(post_id)) {
                    const post_index = state.posts[group_id].indexOf(post)
                    Vue.delete(state.posts[group_id], post_index)
                }
            }

            Vue.delete(state.posts_obj, post_id)

        },

        DELETE_CONFERENCE_POST_ANSWER: (state, { group_id, post_id, answer_id }) => {
            // locate answer
            const answers = state.posts_obj[post_id]['answers']
            for (let post of answers) {
                if (parseInt(post['post_id']) === parseInt(answer_id)) {
                    const answer_index = state.posts_obj[post_id]['answers'].indexOf(post)
                    Vue.delete(state.posts_obj[post_id]['answers'], answer_index)
                }
            }
            // decrement post count on parent
            const posts = state.posts[group_id]
            for (let post of posts) {
                if (parseInt(post['post_id']) === parseInt(post_id)) {
                    const post_index = state.posts[group_id].indexOf(post)
                    const count = parseInt(state.posts[group_id][post_index]['post_count'])
                    Vue.set(state.posts[group_id][post_index], 'post_count', (count-1) )
                }
            }

        },

        EDIT_CONFERENCE_POST: (state, { group_id, post_id, data }) => {
            // locate post
            const posts = state.posts[group_id]
            const post_index = posts.findIndex(post => parseInt(post.post_id) === parseInt(post_id))
            if (post_index !== -1) {
                Vue.set(state.posts[group_id][post_index], 'post_title', data.post_title)
                Vue.set(state.posts[group_id][post_index], 'post_text', data.post_text)
                Vue.set(state.posts[group_id][post_index],'attachments', data.attachments)
            }

            Vue.set(state.posts_obj, post_id, state.posts[group_id][post_index])

        },

        EDIT_CONFERENCE_POST_ANSWER: (state, { post_id, answer_id, data }) => {
            // locate comment
            const answers = state.posts_obj[post_id]['answers']
            for (let post of answers) {
                if (parseInt(post['post_id']) === parseInt(answer_id)) {
                    const post_index = state.posts_obj[post_id]['answers'].indexOf(post)
                    Vue.set(state.posts_obj[post_id]['answers'], post_index, data)
                }
            }

        },

        REGISTER_CONFERENCE_SEEN: (state, { group_id, post_id, time }) => {
            Vue.set(state.conference_seen, group_id, time)
            if (post_id) {
                Vue.set(state.conference_posts_seen, post_id, time)
            }
        },

        SET_CONFERENCE_POSTS: (state, { group_id, posts }) => {
            // create object too for faster lookup in components
            for (let post of posts) {
                if (!post.answers || !post.answers.length) {
                    post.answers = []
                }
                Vue.set(state.posts_obj, post.post_id, post)
            }

            Vue.set(state.posts, group_id, posts)
        },


        SET_CONFERENCE_POSTS_ANSWERS: (state, { post_id, answers }) => {
            if (!state.posts_obj[post_id]) {
                Vue.set(state.posts_obj, post_id, {})
            }
            Vue.set(state.posts_obj[post_id], 'answers', answers)
        },
    }
}
