import Vue from 'vue'


export const generalStore = {
    // This makes your getters, mutations, and actions accessed by, eg: 'userStore/{name}' instead of mounting getters, mutations, and actions to the root namespace.
    namespaced: true,

    state: {
      toastData: false,
      device: 'laptop'
    },


    getters: {},


    actions: {
        showToast({ commit, state }, { type, icon, text }) {
            commit('SET_TOAST_DATA', { type, icon, text })
        },
        clearToast({ commit, state }) {
            commit('CLEAR_TOAST_DATA')
        },
        setCurrentDevice({ commit, state }, data) {
            if(state.device === data) return 0
            commit('SET_CURRENT_DEVICE', data)
        }

    },


    mutations: {
        SET_TOAST_DATA: (state, data) => {
            Vue.set(state, 'toastData', data)
        },
        CLEAR_TOAST_DATA: (state) => {
            Vue.set(state, 'toastData', false)
        },
        SET_CURRENT_DEVICE: (state, data) => {
            Vue.set(state, 'device', data)
        }
    }
}
