import aiAPI from '@/api/aiAPI'

export const aiStore = {
    namespaced: true,

    state: {
        currentTask: null
    },
    actions: {
        async runTask({ commit }, { parameters }) {
            const setupResult = await aiAPI.setupPipelines(parameters)
            if (!setupResult || !setupResult.data) return 0
            commit('UPDATE_TASK', setupResult.data)
            await aiAPI.runPipelines(setupResult.data.id)
        },
        clearTask({ commit }) {
            commit('CLEAR_TASK')
        }
    },
    mutations: {
        UPDATE_TASK: (state, data) => {
            state.currentTask = data
        },
        CLEAR_TASK: (state) => {
            state.currentTask = null
        }
    }
}