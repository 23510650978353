import axios from 'axios'

export default {

    async login(username, password, admin=false, auth_service='',auth_service_access_token='') {
        return await axios.post('/auth/login', {
            username: username,
            password: password,
            admin : admin,
            auth_service : auth_service,
            auth_service_access_token : auth_service_access_token
        })
    },

    async logout(admin=false) {
        let token = admin ? localStorage.getItem('admin_token') : localStorage.getItem('id_token')
        return await axios.post('/auth/logout', {
            token : token
        })
    },

    async requestForgottenEmail({ email }) {
        return await axios.post('/auth/forgotten-password', {
            email : email
        })
    },

    async validateMoodleToken(token) {
        return await axios.post('/auth/moodle/validate', {
            token: token
        })
    },

    async validateUniloginToken(token) {
        return await axios.post('/auth/unilogin/validate', {
            token: token
        })
    },

    async pullLinkedSocialMediaAccounts() {
        return await axios.get('/user/auth/external/linked')
    },

    async linkSocialMediaAccountToUser(auth_service, auth_service_access_token) {
        return await axios.post('/users/auth/external/link-service', {
            auth_service : auth_service,
            auth_service_access_token : auth_service_access_token
        })
    },

    async clearSocialMediaAccount(auth_provider){
        return await axios.delete(`/user/external/auth/link/${auth_provider}`)
    },
}
