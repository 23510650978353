import authAPI from './api/authAPI'
import axios from 'axios'

// set auth header on start up if token is present
if (localStorage.getItem('id_token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('id_token')
}

let checkAuth = () => {
    return !!localStorage.getItem('id_token')
}

let checkAdminAuth = () => {
    return !!localStorage.getItem('admin_logged_in')
}

let logout = () => {
    return authAPI.logout()
        .then( response =>  {
            localStorage.removeItem('id_token')
            localStorage.removeItem('admin_logged_in')
            return new Promise( (resolve, reject) => {
                resolve()
            })
        })
}

// function to check for user authentication
let requireAuth = (to, from, next) => {
    if (!checkAuth()) {
        let path = '/login'
        next({
            path: path
        })
    } else {
        next()
    }
}

export default {
    checkAuth,
    checkAdminAuth,
    async login (username, password, admin=0, auth_service='',auth_service_access_token='') {
        return await authAPI.login(username, password, admin, auth_service, auth_service_access_token)
    },
    logout,
    requireAuth
}
